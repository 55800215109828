import React, { useState } from "react";
import { FiSearch } from "react-icons/fi";
import { useQuery } from "react-query";
import { getFaq } from "../../../Services/FaqServices";
import { Trash, Pencil, ChevronDown, ChevronUp } from "lucide-react";
import Spinner from "../../Spinner/Spinner";
import { showTheme } from "../../Theme/Theme";
function UserFaq() {
  const [activeIndex, setActiveIndex] = useState(null);

  const { data: faq, refetch, isLoading } = useQuery({
    queryFn: getFaq,
    queryKey: ["faq"],
  });
  const handleClick = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <>
    <div
      className="bg-white shadow-md border pb-5 rounded-2xl  ml-3 mr-3 sm:ml-3"
      style={{
        maxHeight: "80vh",
        overflowY: "auto",
        backgroundColor: showTheme("card"),
        border: showTheme("borderCard"),
      }}
    >
      <h4 className="px-6 mt-8 font-semibold text-2xl mb-5" style={{ color: showTheme("text") }}>FAQ</h4>
      {isLoading ? (
        <div className="flex justify-center" style={{ marginTop: "10vh" }}>
          <Spinner />
        </div>
      ) : (
        <div  className=""
        style={{
          backgroundColor: showTheme("card"),
        }}>
          {faq?.faqDetails?.map((item, index) => (
            <div key={item.title} className="p-5 justify-between">
              <div className="flex justify-between items-center">
                <button
                  onClick={() => handleClick(index)}
                  className="font-semibold flex justify-between w-full mt-2"
                  title="Icon for see the answer"
                  style={{ color: showTheme("text") }}
                >
                  {item.question}
                  {index === activeIndex ? (
                    <ChevronUp
                      className="icon mr-2"
                      style={{ color: showTheme("text") }}
                    />
                  ) : (
                    <ChevronDown
                      className="icon mr-2"
                      style={{ color: showTheme("text") }}
                    />
                  )}
                </button>
              </div>
              {index === activeIndex && (
                <div className="flex justify-between">
                  <p className="p-3" style={{ color: showTheme("text"),paddingLeft:"6px" }}>{item.answer}</p>
                 </div>
              )}
              <hr className="border-gray-300 border-2  mt-4"></hr>
            </div>
          ))}
        </div>
      )}
    </div>
  </>
  

  );
}

export default UserFaq;
