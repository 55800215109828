import React, { useState, useRef, useEffect } from "react";
import { createTicket, getUserTickets } from "../../../Services/TicketServices";
import { useQuery } from "react-query";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { getToken } from "../../../utils/getToken";
import Spinner from "../../Spinner/Spinner";
import { showTheme } from "../../Theme/Theme";
import CryptoJS from 'crypto-js';
import { Pagination } from "@mui/material";
import './Transaction.css'

const ContactUS = () => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [errors, setErrors] = useState({});
  const [saveLoading, setSaveLoading] = useState(false);

  const [recordsPerPage, setRecordsPerPage] = useState(5);
  const [decryptedUserData, setDecryptedUserData] = useState(null)
  const secretKey = 'your-secret-key';
  // Retrieving encrypted data from local storage
  const encryptedDataFromStorage = localStorage.getItem('encryptedData');
  // Decrypting the data
  useEffect(() => {
    if (encryptedDataFromStorage) {
      // Decrypting the data
      const bytes = CryptoJS.AES.decrypt(encryptedDataFromStorage, secretKey);
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

      // Using the decrypted data
      console.log(decryptedData);
      setDecryptedUserData(decryptedData);
    }
  }, [encryptedDataFromStorage])
  const {
    data: userTicket,
    refetch,
    isLoading,
  } = useQuery({
    queryFn: () => getUserTickets(currentPage, recordsPerPage),
    queryKey: ["user_tickets", currentPage, recordsPerPage],
  });
  const userTicketData = userTicket?.ticketDetails;
  const totalPages = Math.ceil(userTicket?.noOfEnteries / recordsPerPage);

  const start = (currentPage - 1) * recordsPerPage + 1;
  const end = Math.min(recordsPerPage * currentPage, userTicket?.noOfEnteries);

  if (end > userTicket?.noOfEnteries) {
    end = userTicket?.noOfEnteries;
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = {};
    if (!email.trim()) errors.email = "Email is required";
    else if (!/\S+@\S+\.\S+/.test(email.trim()))
      errors.email = "Invalid email format";
    if (!mobileNumber.trim()) errors.mobileNumber = "Mobile number is required";
    else if (!/^\d{10}$/.test(mobileNumber.trim()))
      errors.mobileNumber = "Mobile number must be 10 digits";
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    setLoading(true);
    try {
      const formData = {
        name: decryptedUserData?.first_name ? decryptedUserData?.first_name + ' ' + decryptedUserData?.last_name : decryptedUserData?.name,
        email: email.trim(),
        phone: mobileNumber.trim(),
        issueSubject: message.trim(),
      };
      const { data } = await createTicket(formData);
      toast.success("Ticket Send Successfully!");
      refetch();
      setEmail("");
      setMobileNumber("");
      setMessage("");
    } catch (e) {
      toast.error(e?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const sendMessageToAdminApi = async () => {
    try {
      const token = getToken();
      const response = await axios.post(
        "https://techtime.pb18.in/api/message/sendToAdmin",
        {
          message: userInput,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast.success("Message Send Successfully ");
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const [isChatboxOpen, setIsChatboxOpen] = useState(false);
  const chatboxRef = useRef(null);
  const [userInput, setUserInput] = useState("");

  const toggleChatbox = () => {
    setIsChatboxOpen(!isChatboxOpen);
  };

  const addUserMessage = (message) => {
    const messageElement = document.createElement("div");
    messageElement.classList.add("mb-2", "text-right");
    messageElement.innerHTML = `<p class="bg-purple-600 text-white rounded-lg py-2 px-4 inline-block">${message}</p>`;
    chatboxRef.current.appendChild(messageElement);
    chatboxRef.current.scrollTop = chatboxRef.current.scrollHeight;
  };

  const addBotMessage = (message) => {
    const messageElement = document.createElement("div");
    messageElement.classList.add("mb-2");
    messageElement.innerHTML = `<p class="bg-gray-200 text-gray-700 rounded-lg py-2 px-4 inline-block">${message}</p>`;
    chatboxRef.current.appendChild(messageElement);
    chatboxRef.current.scrollTop = chatboxRef.current.scrollHeight;
  };

  const handleUserInputChange = (event) => {
    setUserInput(event.target.value);
  };

  // const handleUserInputEnter = (event) => {
  //   if (event.key === "Enter") {
  //     handleSendButtonClick();
  //   }
  // };

  const respondToUser = (userMessage) => {
    // Replace this with your chatbot logic
    setTimeout(() => {
      addBotMessage("Admin will reply soon.");
    }, 500);
  };

  const handleSendButtonClick = () => {
    const userMessage = userInput.trim();
    if (userMessage !== "") {
      addUserMessage(userMessage);
      respondToUser(userMessage);
      setUserInput("");
    }
  };

  return (
    <div>
      <ToastContainer />
        <div
          className=" border p-4 bg-white  shadow-md rounded-2xl  ml-3 mr-3 sm:ml-3 border-style"
          style={{

            backgroundColor: showTheme("card"),
            border: showTheme("borderCard")
          }}
        >
          <div className="flex flex-row w-full align-center mb-5">
            <h1 className="flex sm:text-3xl px-4  w-1/2 font-semibold mt-5 text-nowrap text-2xl" style={{ color: showTheme("text") }}>
              Contact Admin
            </h1>
            <div className="flex w-1/2 justify-end">
              {" "}

            </div>
          </div>

          <div className="grid px-4 grid-cols-1 md:grid-cols-2 gap-0 md:gap-2 xl:gap-7 2xl:gap-7 md:gap-2" >
            <div className="w-full md:col-span-1 grid grid-cols-1  gap-4" >
              <div className="w-full md:col-span-1 ">
                <label htmlFor="fullName" className="mb-1 block mt-5" style={{ color: showTheme("text") }}>
                  Full Name:
                  <span style={{ color: "#E57373", fontWeight: "bold" }}> *</span>
                </label>
                <input
                  type="text"
                  id="fullName"
                  disabled
                  value={decryptedUserData?.first_name ? decryptedUserData?.first_name + ' ' + decryptedUserData?.last_name : decryptedUserData?.name}
                  onChange={(e) => setFullName(e.target.value)}
                  className="py-2 px-3 border border-gray-300 rounded-md focus:ring focus:ring-purple-100 focus:outline-none mt-2"
                  placeholder="Enter your name"
                  required
                  style={{ backgroundColor: showTheme("topHeader"), border: showTheme("fieldBorder"), width: "100%", color: showTheme("text") }}
                  validation={{
                    required: {
                      value: true,
                      message: "required",
                    },
                  }}
                />
              </div>


            </div>
            <div className="w-full md:col-span-1 grid grid-cols-1  gap-4" >
              <div className="w-full" >
                <label htmlFor="email" className="mb-1 block w-full mt-5" style={{ color: showTheme("text") }}>
                  Email:
                  <span style={{ color: "#E57373", fontWeight: "bold" }}> *</span>
                </label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="py-2 px-3 border border-gray-300 rounded-md w-full focus:ring focus:ring-purple-100 focus:outline-none mt-2"
                  placeholder="Enter Your Email"
                  style={{ backgroundColor: showTheme("topHeader"), border: showTheme("fieldBorder") }}
                  required
                />
                {errors.email && <p style={{ color: "#E57373", fontSize: "10px" }}>{errors.email}</p>}
              </div>
            </div>

          </div>
          <div className="grid px-4 grid-cols-1  gap-0 md:gap-2 xl:gap-7 2xl:gap-7" >

            <div className="w-full md:col-span-1 grid grid-cols-1  gap-4" >
              <div className="w-full" >
                <label htmlFor="mobileNumber" className="mb-1 block mt-5 w-full" style={{ color: showTheme("text") }}>
                  Mobile Number:
                  <span style={{ color: "#E57373", fontWeight: "bold" }}> *</span>
                </label>
                <input
                  type="number"
                  id="mobileNumber"
                  value={mobileNumber}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (inputValue.length <= 10) {
                      setMobileNumber(inputValue);
                    }
                  }}
                  className="py-2 px-3 border border-gray-300 rounded-md w-full focus:ring focus:ring-purple-100 focus:outline-none mt-2"
                  required
                  autoComplete="tel"
                  placeholder="Enter Your Number"
                  style={{ backgroundColor: showTheme("topHeader"), border: showTheme("fieldBorder") }}
                />
                {errors.mobileNumber && <p style={{ color: "#E57373", fontSize: "10px" }}>{errors.mobileNumber}</p>}
              </div>
            </div>

          </div>
          <div className="grid px-4 grid-cols-1  gap-7 md:gap-2" >

            <div className="w-full md:col-span-1 grid grid-cols-1  gap-4" >
              <div className="w-full" >
                <label htmlFor="mobileNumber" className="mb-1 block mt-5 w-full" style={{ color: showTheme("text") }}>
                  Message:
                  <span style={{ color: "#E57373", fontWeight: "bold" }}> *</span>
                </label>
                <textarea
                  id="message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  className="py-2 px-3 border border-gray-300 rounded-md w-full h-32 focus:ring focus:ring-purple-100 focus:outline-none mt-2"
                  placeholder="Enter Your Message"
                  required
                  style={{ backgroundColor: showTheme("topHeader"), border: showTheme("fieldBorder") }}
                />

              </div>
            </div>

          </div>


          <div className="grid px-4 grid-cols-1  gap-7 md:gap-2" >

            <div className="col-span-2 flex sm:w-full sm:justify-center">
              <button
                title="Send Query to Admin"
                type="submit"
                className="bg-purple-700 text-white py-2 px-14 rounded-md hover:bg-purple-500 cursor-pointer ml-auto mt-5 w-full sm:w-auto"
                onClick={handleSubmit}
              >
                {
                  loading ?
                    <div class="three-body-button">
                      <div class="three-body__dot"></div>
                      <div class="three-body__dot"></div>
                      <div class="three-body__dot"></div>
                    </div>
                    :
                    'Send'
                }

              </button>
            </div>


          </div>
          {userTicketData?.length > 0 ? (
            <div className="p-4 mt-10">
              <div className="flex justify-between">
                <h1 className="text-xl font-semibold" style={{ color: showTheme("text") }}>
                  {" "}
                  Your Ticket Details
                </h1>
              </div>
              <div className="overflow-auto mt-2">
                <table className="min-w-full mt-4 space-y-4 border border-gray-200 ">
                  <thead className="" style={{ backgroundColor: showTheme("topHeader"), border: showTheme("borderCard") }}>
                    <tr style={{ fontSize: "16px", color: showTheme("text") }}>
                      <th className="py-4 px-4 text-center">No</th>
                      <th className="py-4 px-4 text-center">ID</th>
                      <th className="py-4 px-4 text-center">Name</th>
                      <th className="py-4 px-4 text-center">Phone No</th>
                      <th className="py-4 px-4 text-center">Email</th>
                      <th className="py-4 px-4 text-center">Message</th>
                      <th className="py-4 px-4 text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody className="" style={{ backgroundColor: showTheme("cardBack"), border: showTheme("borderCard") }}>
                    {userTicketData &&
                      userTicketData.map((ticket, index) => (
                        <tr key={ticket._id} style={{ borderBottom: showTheme("borderCard") }}>
                          <td className="p-4 text-center" style={{ color: showTheme("text") }}>{start + index}</td>
                          <td className="p-4 text-center" style={{ color: showTheme("text") }}>{ticket?.ticketId}</td>
                          <td className="p-4 text-center" style={{ color: showTheme("text") }}>{ticket?.name}</td>
                          <td className="p-4 text-center" style={{ color: showTheme("text") }}>{ticket?.phone}</td>
                          <td className="p-4 text-center" style={{ color: showTheme("text") }}>{ticket?.email}</td>
                          <td className="p-4 text-center" style={{ color: showTheme("text") }}>{ticket?.issueSubject}</td>
                          <td className="p-4 text-center">
                            {ticket?.solveStatus === "IN-PROGRESS" ? (
                              <span className="text-green-600">
                                IN-PROGRESS
                              </span>
                            ) : (
                              <span className="text-red-500">SOLVED</span>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              {/* pagination  */}
              <div className="flex flex-wrap items-center gap-4 justify-between my-6 max-[700px]:justify-start max-[700px]:gap-0 max-[700px]:text-xs">
                <div className="text-purple-700">
                  {`${start}-${end} of ${userTicket?.noOfEnteries} items`}
                </div>
                <div className="flex items-center gap-4 justify-center">
                  <Pagination count={totalPages} size="large" showFirstButton showLastButton page={currentPage} onChange={(event, value) => { setCurrentPage(value) }}
                    className={`${localStorage.getItem("darkMode") == "true" && 'pagination-transaction'} pagination-all`}
                  />
                </div>
                <div>
                  <select className="border rounded p-1" style={{ backgroundColor: showTheme('card'), color: showTheme('logoColor') }} value={recordsPerPage} onChange={(e) => { setRecordsPerPage(e.target.value) }}>
                    <option value='5'>Show 5</option>
                    <option value='10'>Show 10</option>
                    <option value='15'>Show 15</option>
                  </select>
                </div>
              </div>
              {/* Message Box */}
              <div
                id="chat-container"
                className={`fixed bottom-1 right-1 w-96 ${isChatboxOpen ? "" : "hidden"
                  }`}
              >
                <div className="bg-white shadow-md rounded-lg max-w-lg w-full">
                  <div className="p-4 border-b bg-purple-600 text-white rounded-t-lg flex justify-between items-center">
                    <p className="text-lg font-semibold">Message</p>
                    <button
                      id="close-chat"
                      className="text-gray-300 hover:text-gray-200 focus:outline-none focus:text-gray-400 "
                      onClick={toggleChatbox}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-6 h-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M6 18L18 6M6 6l12 12"
                        ></path>
                      </svg>
                    </button>
                  </div>
                  <div
                    id="chatbox"
                    ref={chatboxRef}
                    className="p-4 h-80 overflow-y-auto "
                  >
                    {/* Chat messages will be displayed here */}
                  </div>
                  <div className="p-4 border-t flex">
                    <input
                      id="user-input"
                      type="text"
                      placeholder="Type a message"
                      className="w-full px-3 py-2 border rounded-l-md focus:outline-none focus:ring-2 focus:ring-purple-600 "
                      value={userInput}
                      onChange={handleUserInputChange}
                    // onKeyUp={handleUserInputEnter}
                    />
                    <button
                      title="Send"
                      id="send-button"
                      className="bg-purple-600 text-white px-4 py-2 rounded-r-md  transition duration-300 hover:bg-purple-500"
                      onClick={() => {
                        handleSendButtonClick();
                        sendMessageToAdminApi();
                      }}
                    >
                      Send
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="mt-11 font-semibold text-2xl ml-5 mb-11" style={{ color: showTheme("text") }}>
              No Ticket Raised
            </div>
          )}
          <ToastContainer />
        </div>
    </div>
  );
};

export default ContactUS;
