import { AlertCircle, Check, ChevronLeft, X } from "lucide-react";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import {
  addBankDetails,
  getAllBankDetails,
  getIfsc,
} from "../../../../Services/BankServices";
import BankList from "./BankList";
import { useQuery } from "react-query";
import axios from "axios";
import { Link } from "react-router-dom";
import { showTheme } from "../../../Theme/Theme";
import { Checkbox } from "@material-tailwind/react";

const BankAccount = () => {
  const [popUp, setPopUp] = useState(false);
  const [saved, setSaved] = useState(false);
  const [name, setName] = useState(null);
  const [accountNo, setAccountNo] = useState(null);
  const [confirmAcc, setConfirmAcc] = useState(null);
  const [mobileNo, setMobileNo] = useState(null);
  const [mobileValid, setMobileValid] = useState('');
  const [ifsc, setIfsc] = useState(null);
  const [checkAccountNo, setCheckAccountNo] = useState(false);
  const [ifscDetails, setIfscDetails] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [ifscValid, setIfscValid] = useState(null);

  const isIfscValid = (ifsc) => {
    const ifscRegex = /^[A-Za-z]{4}0[A-Z0-9]{6}$/;
    return ifscRegex.test(ifsc);
  };

  const { data: bank, refetch } = useQuery({
    queryFn: getAllBankDetails,
    queryKey: ["bank_details"],
  });
  useEffect(() => {
    if (accountNo !== confirmAcc) {
      // toast("Check account Number")
      setCheckAccountNo(true);
      // return;
    } else {
      setCheckAccountNo(false);
    }
  }, [confirmAcc])
  const validateName = (value) => {
    if (!value) {
      setErrorMessage('Name is required');
    } else if (!/^[a-zA-Z\s]+$/.test(value)) {
      setErrorMessage('Name can only contain letters and spaces');
    } else {
      setErrorMessage('');
    }
  };
  const validatePhoneNumber = (value) => {
    if (!value) {
      setMobileValid('Phone number is required');
    } else if (!/^\d+$/.test(value)) {
      setMobileValid('Phone number can only contain numbers');
    } else if (value.length !== 10) {
      setMobileValid('Phone number must be 10 digits');
    } else {
      setMobileValid('');
    }
  };

  const validateIFSC = async (value) => {
    if (!value) {
      setIfscDetails('IFSC code is required');
      return;
    } else if (!/^[A-Z]{4}0[A-Z0-9]{6}$/.test(ifsc)) {
      setIfscDetails('Invalid IFSC code format');
      return;
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (mobileValid || errorMessage || accountNo !== confirmAcc) {
      toast.warning('Please correct the errors before submitting');
      return;
    }

    const formData = {
      accountHolderName: name,
      accountNumber: accountNo,
      ifscCode: ifsc,
    };
    try {
      const { data } = await addBankDetails(formData);
      if (!data) return;
      setSaved(true);
      refetch();
    } catch (e) {
      toast.error(e?.response?.data?.message);
    }
  };

  const ifscFetch = async () => {
    const dataToSent = {
      "ifscCode": ifsc
    }
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/bank/verify/ifsc-code`,dataToSent);
      toast.success(response?.data?.message);
      if(response?.data?.data?.verificationAvailble){
        setIfscValid(true);
      }
    } catch (error) {
      setIfscValid(null);
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <>
      <div  
        className="bg-white shadow-md border rounded-2xl  ml-3 mr-3 sm:ml-3 border-style"
        style={{

          backgroundColor: showTheme("card"),
          border: showTheme("borderCard")
        }}
      >
        <div className="flex flex-col lg:flex-col space-y-4 mt-5 mx-5  lg:space-y-0 ">
            <div className="text-2xl text-gray-600 font-semibold text-center justify-center max-[520px]:text-base mt-5" style={{ color: showTheme("text") }}>
              Account Information for Withdrawal
            </div>

          <form onSubmit={handleSubmit}>
            <div className="grid gap-6 mb-6 grid-cols mt-5">
              <div>
                <label
                  htmlFor="name"
                  className="block mb-2 text-sm  text-gray-600 dark:text-black font-semibold"
                  style={{ color: showTheme("text") }}
                >
                  Name on account
                </label>
                <input
                  type="text"
                  id="name"
                  value={name}
                  onChange={(e) => { setName(e.target.value); validateName(e.target.value); }}
                  className="bg-gray-50 border border-gray-300 text-gray-600 text-sm rounded-lg   block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400 dark:text-black focus:ring focus:ring-purple-100 focus:outline-none"
                  placeholder="Enter your name on account"
                  required
                  style={{ backgroundColor: showTheme("topHeader"), border: showTheme("fieldBorder") }}
                />
                {errorMessage && <div style={{ color: 'red' }} className="text-sm">{errorMessage}</div>}
              </div>
              <div className="flex flex-col gap-2">
                <label
                  htmlFor="name"
                  className="block mb-2 text-sm  text-gray-600 dark:text-black font-semibold"
                  style={{ color: showTheme("text") }}
                >
                  Phone
                </label>
                <div className="relative">
                  <span className="absolute bottom-2.5 left-2 text-slate-500 ">+91</span>
                  <input type="tel"
                    maxLength={10}
                    id="phone"
                    value={mobileNo}
                    onChange={(e) => {
                      setMobileNo(e.target.value);
                      validatePhoneNumber(e.target.value);
                    }}
                    className=" pl-10 bg-gray-50 border border-gray-300 text-gray-600 text-sm rounded-lg   block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400 dark:text-black focus:ring focus:ring-purple-100 focus:outline-none"
                    placeholder="Enter your phone number"
                    required
                    style={{ backgroundColor: showTheme("topHeader"), border: showTheme("fieldBorder") }}

                  />
                </div>
                {mobileValid && <div style={{ color: 'red' }} className="text-sm">{mobileValid}</div>}
              </div>

              <div>
                <label
                  htmlFor="accountNo"
                  className="block mb-2 text-sm  text-gray-600 dark:text-black font-semibold"
                  style={{ color: showTheme("text") }}
                >
                  Account Number
                </label>
                <input
                  type="number"
                  id="accountNo"
                  value={accountNo}
                  onChange={(e) => setAccountNo(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-600 text-sm rounded-lg   block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 focus:ring focus:ring-purple-100 focus:outline-none"
                  placeholder="Enter Account Number"
                  required
                  style={{ backgroundColor: showTheme("topHeader"), border: showTheme("fieldBorder") }}
                />
              </div>
              <div>
                <label
                  htmlFor="confirmAcc"
                  className="block mb-2 text-sm font-semibold text-gray-600 dark:text-black"
                  style={{ color: showTheme("text") }}
                >
                  Confirm Account Number
                </label>
                <input
                  type="number"
                  id="confirmAcc"
                  value={confirmAcc}
                  onChange={(e) => {
                    setConfirmAcc(e.target.value);
                  }}
                  className={`${checkAccountNo ? "border-red-500" : "border-gray-300"
                    } bg-gray-50 border  text-gray-600 text-sm rounded-lg   block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400 dark:text-black focus:ring focus:ring-purple-100 focus:outline-none`}
                  placeholder="Confirm Account Number"
                  required
                  style={{ backgroundColor: showTheme("topHeader"), border: showTheme("fieldBorder") }}
                />
              </div>
              {checkAccountNo && (
                <div
                  className="text-red-500 flex items-center"
                  style={{ marginTop: "-20px" }}
                >
                  <div className="flex items-center h-5">
                    <AlertCircle width={20} />
                  </div>
                  <label className="ms-2 text-sm font-normal text-red dark:text-red-500" style={{ color: showTheme("text") }}>
                    Account Number must be match.
                  </label>
                </div>
              )}
              <div>
                <label
                  htmlFor="ifsc"
                  className="block mb-2 text-sm font-semibold text-gray-600 dark:text-black" style={{ color: showTheme("text") }}
                >
                  IFSC Code
                </label>
                <div className="flex items-center gap-5">
                  <div className="relative w-full">
                    <input
                      type="text"
                      id="ifsc"
                      pattern="[A-Za-z]{4}0[A-Z0-9]{6}"
                      value={ifsc}
                      onChange={(e) => { setIfsc(e.target.value); validateIFSC(e.target.value); }}
                      className="bg-gray-50 border border-gray-300 text-gray-600 text-sm rounded-lg block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black focus:ring focus:ring-purple-100 focus:outline-none"
                      placeholder="Enter your IFSC code"
                      required
                      style={{ backgroundColor: showTheme("topHeader"), border: showTheme("fieldBorder") }}
                    />
                    {ifscDetails && <div style={{ color: 'red' }} className="text-sm" >{errorMessage}</div>}
                    {/* Button for larger screens */}
                    {/*  <button
                        title="Search for IFSC"
                        data-modal-target="authentication-modal"
                        data-modal-toggle="authentication-modal"
                        type="button"
                        className="text-blue-600 border-0 font-semibold absolute top-2 right-5 lg:block hidden"
                        onClick={() => setPopUp(true)}
                      >
                        Search for IFSC
                      </button>*/}


                    {/* Search icon for smaller screens */}
                    {/* <button
                        title="Search for IFSC"
                        data-modal-target="authentication-modal"
                        data-modal-toggle="authentication-modal"
                        type="button"
                        className="text-blue-600 border-0 font-semibold absolute top-2 right-5 lg:hidden"
                        onClick={() => setPopUp(true)}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M9.293 4.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414-1.414l4-4zM13 10a1 1 0 100 2 1 1 0 000-2z" clipRule="evenodd" />
                          <path fillRule="evenodd" d="M17.707 16.707a1 1 0 01-1.414 1.414l-4-4a1 1 0 111.414-1.414l4 4z" clipRule="evenodd" />
                          <path fillRule="evenodd" d="M4.293 3.293a1 1 0 00-1.414 1.414l4 4a1 1 0 001.414-1.414l-4-4z" clipRule="evenodd" />
                          <path fillRule="evenodd" d="M3 10a7 7 0 1114 0 7 7 0 01-14 0z" clipRule="evenodd" />
                        </svg>
                      </button> */}
                  </div>

                  <button
                    title="Find IFSC"
                    type="button"
                    onClick={() => ifscFetch()}
                    className="text-purple-700 border-2 w-20 font-semibold border-purple-700 rounded-lg p-2 hover:text-purple-500"
                  >
                    Find
                  </button>
                </div>
              </div>
              <div className="flex items-center me-4">
                {/* <input
                  required id="purple-checkbox"
                  type="checkbox"
                  value=""
                  className="w-4 h-4 text-purple-600 bg-gray-100 border-gray-300 rounded focus:ring-purple-500 dark:focus:ring-purple-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  style={{ backgroundColor: showTheme("topHeader"), border: showTheme("fieldBorder") }}
                  
                  /> */}
                <Checkbox color="purple" required id="purple-checkbox" style={{ backgroundColor: showTheme("topHeader"), border: showTheme("fieldBorder") }}
                />
                <label
                  htmlFor="purple-checkbox"
                  className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300 max-[420px]:text-xs"
                  style={{ color: showTheme("text") }}
                > I attest that I am the owner and have full authorization to
                  this bank account.</label>
              </div>
              {/* <div className="flex items-start mb-6">
                <div className="flex items-center h-5">
                  <input
                    id="remember"
                    type="checkbox"
                    value=""
                    className="w-4 h-4  border border-gray-300 rounded bg-gray-50  dark:border-gray-600 dark:focus:ring-purple-600 dark:ring-offset-gray-800 focus:ring focus:ring-purple-100 focus:outline-none"
                    required
                    style={{ backgroundColor: showTheme("topHeader"), border: showTheme("fieldBorder") }}
                  />
                </div>
                <label
                  htmlFor="remember"
                  className="ms-2 text-sm font-normal text-gray-600 dark:text-gray-600"
                  aria-disabled
                  style={{ color: showTheme("text") }}
                >
                  I attest that I am the owner and have full authorization to
                  this bank account.
                </label>
              </div> */}

              <button
                title="Verify Bank"
                type="submit"
                className={`${ifscValid ?  'text-white bg-purple-700 hover:bg-purple-500 hover:bg-gray text-white  font-semibold rounded-lg text-sm w-full sm:w-auto px-8 py-4 text-center dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-800': 'text-slate-400 bg-purple-200 text-white  font-semibold rounded-lg text-sm w-full sm:w-auto px-8 py-4 text-center dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-800'}`}
                style={{ fontSize: "15px" }}
                disabled={!ifscValid}
              >
                VERIFY
              </button>
            </div>
          </form>

          {/* {popUp && (
            <div
              id="authentication-modal"
              tabindex="-1"
              aria-hidden="true"
              className="flex  overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
              style={{ backgroundColor: "rgb(0 0 0 / 57%)" }}
            >
              <div className="relative p-4 w-full max-w-md max-h-full">
                <div className="relative  rounded-lg shadow " style={{ backgroundColor: showTheme("card") }}>
                  <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                    <button
                      title="go back"
                      type="button"
                      className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-4 h-4  inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                      data-modal-hide="authentication-modal"
                      onClick={() => setPopUp(false)}
                    >
                      <ChevronLeft onClick={() => setPopUp(false)} />
                    </button>
                    <h3 className="w-full text-xl font-semibold text-gray-500 dark:text-black text-center" style={{ color: showTheme("text") }}>
                      Search for IFSC
                    </h3>
                  </div>

                  <div className="p-4 md:p-8" style={{ width: "100%" }}>
                    <form className="space-y-8" style={{ width: "100%" }}>
                      <div>
                        <label
                          htmlFor="confirmAcc"
                          className="block mb-2 text-sm font-semibold"
                          style={{ color: showTheme("text") }}
                        >
                          Bank Name
                        </label>
                        <input
                          type="text"
                          value={bankName}
                          onChange={(e) => setBankName(e.target.value)}
                          name="name"
                          id="name"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg   block w-full p-2.5  dark:placeholder-gray-400 dark:text-black focus:ring focus:ring-purple-100 focus:outline-none"
                          placeholder="Enter your Bank Name"
                          required
                          style={{ backgroundColor: showTheme("topHeader"), border: showTheme("fieldBorder") }}
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="confirmAcc"
                          className="block mb-2 text-sm font-semibold"
                          style={{ color: showTheme("text") }}
                        >
                          Bank Branch Name
                        </label>
                        <input
                          type="text"
                          value={bankBranch}
                          onChange={(e) => setBankBranch(e.target.value)}
                          name="branch"
                          id="branch"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg   block w-full p-2.5  dark:border-gray-500 dark:placeholder-gray-400 dark:text-black focus:ring focus:ring-purple-100 focus:outline-none"
                          placeholder="Bank branch"
                          required
                          style={{ backgroundColor: showTheme("topHeader"), border: showTheme("fieldBorder") }}
                        />
                      </div>
                      <div className="text-xl font-semibold " style={{ color: showTheme("text") }}>
                        IFSC : {ifsc}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "10px",
                        }}
                      >
                        <button
                          title="Continue"
                          className="w-full text-white bg-purple-700  hover:bg-purple-500  font-semibold rounded-lg text-sm px-5 py-2.5 text-center dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-800"
                          onClick={fetchIFSCFromBankDetails}
                        >
                          Continue
                        </button>
                        <button
                          title="Cancel"
                          className="w-full  bg-red-500 text-white  hover:bg-red-400 font-semibold rounded-lg text-sm px-5 py-2.5 text-center "
                          onClick={() => setPopUp(false)}
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          )} */}
          {saved && (
            <div
              id="authentication-modal"
              tabindex="-1"
              aria-hidden="true"
              className="flex  overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full backdrop-blur-sm"
              style={{ backgroundColor: "rgb(0 0 0 / 50%)" }}
            >
              <div className="relative p-4 w-full max-w-md max-h-full">
                <div className="relative bg-white rounded-lg shadow ">
                  <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                    <div className="w-full text-xl font-normal text-gray-500 dark:text-white text-center max-[520px]:text-sm">
                      Account Information For Withdrawal
                    </div>
                    <button
                      type="button"
                      className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8  inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                      data-modal-hide="authentication-modal"
                      onClick={() => setPopUp(false)}
                    >
                      {/* <X onClick={() => setSaved(false)} /> */}
                    </button>
                  </div>

                  <div className="p-4 md:p-5">
                    <div className="bg-purple-800 rounded-full text-black w-20 h-20 p-4 m-auto mb-4">
                      <Check className="w-12 h-12 text-white" />
                    </div>
                    <h3 className="w-full text-xl font-normal text-gray-500 dark:text-white text-center">
                      Information saved!
                    </h3>
                    <Link to="/bank-list">
                      {" "}
                      <button
                        type="submit"
                        className="mt-5 text-white w-full bg-purple-700 hover:bg-purple-800  font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-800"
                      >
                        Done
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}

          <ToastContainer />
        </div>
      </div>
    </>
  );
};

export default BankAccount;
