import React, { useEffect, useState } from 'react'

const AutochangeTitle = () => {
    const data = ['Invest!', 'Passive Income!', 'Make Money!']
    const [active, setActive] = useState(0);
    useEffect(() => {
        //Implementing the setInterval method
        const interval = setInterval(() => {
            setActive(previous => {
              if (previous === data.length-1) {
                return 0;
              } else {
                return previous + 1;
              }
            });
        }, 2000);
    
        //Clearing the interval
        return () => clearInterval(interval);
    }, [active]);
  return (
    <h1 className="text-xl leading-tight mt-10">
              Let's{" "}
              <span className="text-purple-700 text-2xl font-semibold">{data[active]}</span>
            </h1>
  )
}

export default AutochangeTitle
