// Theme.js

const lightTheme = {
  bg: "white",
  bgLighter: "white",
  text: "black",
  textSoft: "#606060",
  soft: "#f5f5f5",
  storeBg: "rgb(255, 255, 255)",
  storeT: "rgb(32, 32, 32)",
  logoColor: "#5f249f",
  topIcons: "black",
  drawer: "#a46ede",
  yourPlan:"#7B1FA2",
  cardBack:"white",
  card:"white",
  topHeader:"white",
  smallCard:"white",
  borderCard:"1px solid #E0E0E0",
  bordercardBack:"1px solid #757575",
  bordersmallCard:"1px solid #E0E0E0",
  fieldBorder: "1px solid #E0E0E0"
};

const darkTheme = {
  bg: "#3a4051",
  bgLighter: "#202020",
  text: "white",
  color:"white",
  textSoft: "#aaaaaa",
  soft: "#373737",
  storeBg: "rgb(32, 32, 32)",
  storeT: "rgb(249, 249, 249)",
  logoColor: "white",
  topIcons: "white",
  drawer: "white",
  yourPlan:"white",
  cardBack:"#1f1f1f",
  card:"#303134",
  topHeader:"#3c3c3c",
  smallCard:"#3c3c3c",
  borderCard:"1px solid #3c3c3c",
  bordercardBack:"1px solid #1f1f1f",
  bordersmallCard:"1px solid #3c3c3c",
  fieldBorder: "1px solid #3c3c3c"
};

export const showTheme = (key) => {
  if (localStorage.getItem("darkMode") == "true") {
    // console.log(darkTheme[key]);
    return darkTheme[key];
  } else {
    return lightTheme[key];
  }
};
