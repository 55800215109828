import React, { useState } from 'react';

const Scontact = () => {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [panCardNumber, setPanCardNumber] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

  };



  return (
    <div className=" border p-4 h-screen bg-white rounded-xl shadow-md ">
      <h1 className='text-3xl ml-4 mb-10'>Contact Client</h1>

      <div className='grid px-4 grid-cols-2 gap-4'>
        <div className=''>
          <label htmlFor="fullName" className="mb-1 block">
            Full Name:
          </label>
          <input
            type="text"
            id="fullName"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            className="py-2 px-3 border border-gray-300 rounded-md w-full focus:ring focus:ring-purple-100 focus:outline-none"
            required
            placeholder='Enter your full name'
          />
        </div>

        <div>
          <label htmlFor="email" className="mb-1 block">
            Email:
          </label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="py-2 px-3 border border-gray-300 rounded-md w-full focus:ring focus:ring-purple-100 focus:outline-none"
            required
            placeholder='Enter Your Email'
          />
        </div>

        <div>
          <label htmlFor="mobileNumber" className="mb-1 block">
            Mobile Number:
          </label>
          <input
            type="tel"
            id="mobileNumber"
            value={mobileNumber}
            onChange={(e) => setMobileNumber(e.target.value)}
            className="py-2 px-3 border border-gray-300 rounded-md w-full focus:ring focus:ring-purple-100 focus:outline-none"
            required
            placeholder='Enter Your Mobile Number'
          />
        </div>

        <div>
          <label htmlFor="panCardNumber" className="mb-1 block">
            PAN Card Number:
          </label>
          <input
            type="text"
            id="panCardNumber"
            value={panCardNumber}
            onChange={(e) => setPanCardNumber(e.target.value)}
            className="py-2 px-3 border border-gray-300 rounded-md w-full focus:ring focus:ring-purple-100 focus:outline-none"
            required
            placeholder='Enter Your PanCard Number'
          />
        </div>

        <div className="col-span-2">
          <label htmlFor="message" className="mb-1 block">
            Message:
          </label>
          <textarea
            id="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="py-2 px-3 border border-gray-300 rounded-md w-full h-32"
            required
            placeholder='Enter Your Message'
          />
        </div>

        <div className="col-span-2 flex justify-center">
          <button
          title='Send Query to admin'
            type="submit"
            className="bg-purple-700 text-white py-2 px-6 rounded-md hover:bg-purple-700 cursor-pointer"
            onClick={handleSubmit}
          >
            Send
          </button>
        </div>
      </div>
    </div>
  );
};

export default Scontact;



