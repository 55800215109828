import React, { useState, useEffect } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { FiUser, FiBell, FiHome, FiClock, FiMail } from "react-icons/fi";
import "react-toastify/dist/ReactToastify.css";
import "./admin.css";
import { RiQuestionnaireLine } from "react-icons/ri";
import { LuUsers2 } from "react-icons/lu";
import { MdOutlineSort } from "react-icons/md";
import { Box, Button, Drawer } from "@mui/material";
import CryptoJS from 'crypto-js';
import { Close } from "@mui/icons-material";

// import { Camera } from 'lucide-react';
const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [decryptedUserData, setDecryptedUserData] = useState(null)

  const secretKey = 'your-secret-key';
  // Retrieving encrypted data from local storage
  const encryptedDataFromStorage = localStorage.getItem('encryptedData');
  // Decrypting the data
  useEffect(() => {
    if (encryptedDataFromStorage) {
      // Decrypting the data
      const bytes = CryptoJS.AES.decrypt(encryptedDataFromStorage, secretKey);
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

      // Using the decrypted data
      setDecryptedUserData(decryptedData);
      if (location.pathname === '/') {
        if (decryptedData?.employeeDetails?.isAccessToDashboardTab) {
          navigate('admin-dashboard');
          return;
        }
        if (decryptedData?.employeeDetails?.isAccessToFinancialDataTab) {
          navigate('financial-data');
          return;
        }
        if (decryptedData?.employeeDetails?.isAccessToSupportTab) {
          navigate('support-desk');
          return;
        }
        if (decryptedData?.employeeDetails?.isAccessToReportTab) {
          navigate('reports');
          return;
        }
        if (decryptedData?.employeeDetails?.isAccessToMembersTab) {
          navigate('member');
          return;
        }
        if (decryptedData?.employeeDetails?.isAccessToClientTab) {
          navigate('clients');
          return;
        }
        if (decryptedData?.employeeDetails?.isAccessToNotificationTab) {
          navigate('notification');
          return;
        }
        if (decryptedData?.employeeDetails?.isAccessToPlansTab) {
          navigate('plans');
          return;
        }
        if (decryptedData?.employeeDetails?.isAccessToLeadsTab) {
          navigate('leads');
          return;
        }
        if (decryptedData?.employeeDetails?.isAccessToFaqTab) {
          navigate('faq');
          return;
        }
      }

    }
  }, [encryptedDataFromStorage])
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  useEffect(() => {

  }, [location])
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  useEffect(() => {
    const path = location.pathname;
    if (path.includes("/admin-dashboard")) {
      setActiveTab("Dashboard");
    } else if (path.includes("/financial-data")) {
      setActiveTab("Financial Data");
    } else if (path.includes("/support-desk")) {
      setActiveTab("Support Desk");
    } else if (path.includes("/reports")) {
      setActiveTab("Reports");
    } else if (path.includes("/member")) {
      setActiveTab("Member");
    } else if (path.includes("/clients")) {
      setActiveTab("Client");
    } else if (path.includes("/notification")) {
      setActiveTab("Notification");
    } else if (path.includes("/plans")) {
      setActiveTab("Plans");
    } else if (path.includes("/leads")) {
      setActiveTab("Leads");
    } else if (path.includes("/faq")) {
      setActiveTab("Faq");
    }
  }, [location.pathname]);

  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
      <div className="mt-5" style={{ width: "235px" }} >
        <div className="flex items-center justify-between">
          <h1 className="font-semibold text-purple-700 ml-3 text-xl " >
            General
          </h1>
          <Close onClick={toggleDrawer(false)} style={{ fontSize: "20px" }} />
        </div>
        <hr className="mt-3" />
        {decryptedUserData?.employeeDetails?.isAccessToDashboardTab && (
          <Link to="/admin-dashboard">
            <button
              className={`w-full flex items-center rounded-md p-2 hover:text-white hover:bg-purple-400 mt-5 ${activeTab === "Dashboard" ||
                location.pathname.includes("admin-dashboard")
                ? "bg-purple-700 text-white "
                : "hover:bg-purple-400 hover:text-white text-black"
                }`}
              onClick={() => handleTabClick("Dashboard")}
              style={{ borderLeft: "none" }}
            >
              <FiHome
                size={20}
                className={` ${activeTab === "Dashboard" ? "text-white" : "text-purple-500"
                  }`}
              />
              <span className={`ml-5 text-nowrap`}>Dashboard</span>
            </button>
          </Link>
        )}
        {decryptedUserData?.employeeDetails?.isAccessToFinancialDataTab && (
          <Link to="/financial-data">
            <button
              className={`w-full flex items-center rounded-md p-2 hover:text-white hover:bg-purple-400 mt-5 ${activeTab === "Financial-data" ||
                location.pathname.includes("financial-data")
                ? "bg-purple-700 text-white "
                : "hover:bg-purple-400 hover:text-white text-black"
                }`}
              onClick={() => handleTabClick("Financial Data")}
            >
              <FiClock
                size={20}
                className={` ${activeTab === "Financial Data" ? "text-white" : "text-purple-500"
                  } `}
              />
              <span className={`ml-5 text-nowrap`}>Financial Data</span>
            </button>
          </Link>
        )}
        {decryptedUserData?.employeeDetails?.isAccessToSupportTab && (
          <Link to="/support-desk">
            <button
              className={`w-full flex items-center rounded-md p-2 hover:text-white hover:bg-purple-400 mt-5 ${activeTab === "Support Desk" ||
                location.pathname.includes("/support-desk")
                ? "bg-purple-700 text-white "
                : "hover:bg-purple-400 hover:text-white text-black"
                }`}
              onClick={() => handleTabClick("Support Desk")}
            >
              <FiBell
                size={20}
                className={` ${activeTab === "Support Desk" ? "text-white" : "text-purple-500"
                  }`}
              />
              <span className={`ml-5 text-nowrap`}>Support Desk</span>
            </button>
          </Link>
        )}
        {decryptedUserData?.employeeDetails?.isAccessToReportTab && (
          <Link to="/reports">
            <button
              className={`w-full flex items-center rounded-md p-2 hover:text-white hover:bg-purple-400 mt-5 ${activeTab === "Reports" ||
                location.pathname.includes("/reports")
                ? "bg-purple-700 text-white "
                : "hover:bg-purple-400 hover:text-white text-black"
                }`}
              onClick={() => handleTabClick("Reports")}
            >
              <FiMail
                size={20}
                className={` ${activeTab === "Reports" ? "text-white" : "text-purple-700"
                  }`}
              />
              <span className={`ml-5 text-nowrap`}>Reports</span>
            </button>
          </Link>
        )}
        {decryptedUserData?.employeeDetails?.isAccessToMembersTab && (
          <Link to="/member">
            <button
              className={`w-full flex items-center rounded-md p-2 hover:text-white hover:bg-purple-400 mt-5 ${activeTab === "Member" ||
                location.pathname.includes("/member")
                ? "bg-purple-700 text-white"
                : "hover:bg-purple-400 hover:text-white "
                }`}
              onClick={() => handleTabClick("Member")}
            >

              <LuUsers2
                size={20}
                className={` ${activeTab === "Member"
                  ? "text-white"
                  : "text-purple-700"
                  }`}
              />
              <span className={`ml-5 text-nowrap`}>Member</span>
            </button>
          </Link>
        )}
        {decryptedUserData?.employeeDetails?.isAccessToClientTab && (
          <Link to="/clients">
            <button
              className={`w-full flex items-center rounded-md p-2 hover:text-white hover:bg-purple-400 mt-5 ${activeTab === "Client" ||
                location.pathname.includes("/clients")
                ? "bg-purple-700 text-white "
                : "hover:bg-purple-400 hover:text-white "
                }`}
              onClick={() => handleTabClick("Client")}
            >
              <LuUsers2
                size={20}
                className={`${activeTab === "Client"
                  ? "text-white"
                  : "text-purple-700"
                  }`}
              />
              <span className={`ml-5 text-nowrap`}>Client</span>
            </button>
          </Link>
        )}
        {decryptedUserData?.employeeDetails?.isAccessToNotificationTab && (
          <Link to="/notification">
            <button
              className={`w-full flex items-center rounded-md p-2 hover:text-white hover:bg-purple-400 mt-5 ${activeTab === "Notification" ||
                location.pathname.includes("/notification")
                ? "bg-purple-700 text-white"
                : "hover:bg-purple-400 hover:text-white"
                }`}
              onClick={() => handleTabClick("Notification")}
            >
              <FiBell
                size={20}
                className={`${activeTab === "Notification"
                  ? "text-white"
                  : "text-purple-700"
                  }`}
              />
              <span className={`ml-5 text-nowrap`}>Notification</span>
            </button>
          </Link>
        )}
        {decryptedUserData?.employeeDetails?.isAccessToPlansTab && (
          <Link to="/plans">
            <button
              className={`w-full flex items-center rounded-md p-2 hover:text-white hover:bg-purple-400 mt-5 ${activeTab === "Plans" ||
                location.pathname.includes("/plans")
                ? "bg-purple-700 text-white "
                : "hover:bg-purple-400 hover:text-white "
                }`}
              onClick={() => handleTabClick("Plans")}
            >
              <FiUser
                size={20}
                className={`${activeTab === "Plans" ? "text-white" : "text-purple-700"
                  }`}
              />
              <span className={`ml-5 text-nowrap`}>Plans</span>
            </button>
          </Link>
        )}
        {decryptedUserData?.employeeDetails?.isAccessToLeadsTab && (
          <Link to="/leads">
            <button
              className={`w-full flex items-center rounded-md p-2 hover:text-white hover:bg-purple-400 mt-5 ${activeTab === "Leads" ||
                location.pathname.includes("/leads")
                ? "bg-purple-700 text-white "
                : "hover:bg-purple-400 hover:text-white "
                }`}
              onClick={() => handleTabClick("Leads")}
            >
              <LuUsers2
                size={20}
                className={`${activeTab === "Leads" ? "text-white" : "text-purple-700"
                  }`}
              />
              <span className={`ml-5 text-nowrap`}>Leads</span>
            </button>
          </Link>
        )}
        {decryptedUserData?.employeeDetails?.isAccessToFaqTab && (
          <Link to="/faq">
            <button
              className={`w-full flex items-center rounded-md p-2 hover:text-white hover:bg-purple-400 mt-5 ${activeTab === "Faq" ||
                location.pathname.includes("/faq")
                ? "bg-purple-700 text-white"
                : "hover:bg-purple-400 hover:text-white"
                }`}
              onClick={() => handleTabClick("Faq")}
            >
              <RiQuestionnaireLine
                size={20}
                className={` ${activeTab === "Faq" ? "text-white" : "text-purple-700"
                  }`}
              />
              <span className={`ml-5 text-nowrap`}>FAQ</span>
            </button>
          </Link>
        )}


      </div>
    </Box>
  );

  return (
    <>
      <div className=" mt-12 lg:mt-16 xl:mt-16 2xl:mt-16 " style={{height: "100vh", overflow: "-moz-hidden-unscrollable" }}>
        <div className="">
          <Button onClick={toggleDrawer(true)}>
            <MdOutlineSort className='block lg:hidden xl:hidden 2xl:hidden w-11 h-11 p-1.5 text-black' />
          </Button>
          <Drawer open={open} onClose={toggleDrawer(false)}>
            {DrawerList}
          </Drawer>
          <div className="flex  mr-20" >
            <div
              className={`mb-5 ml-10 mr-5 rounded-2xl  ${isSidebarOpen ? "" : "hidden lg:block xl:block 2xl:block"
                }`}
              style={{ position: "fixed", top: "15", left: "5", overflowY: "auto", border: '1px solid rgb(224, 224, 224)' }}
            >
              <div
                className="flex flex-col justify-between max-h-full"
                style={{
                  height: "calc(100vh - 120px)",
                  maxHeight: "80vh",
                  overflowY: "auto",
                }}
              >
                <div className="mt-5  ml-5 mr-5 flex flex-col justify-between space-y-5">
                  <h1 className="font-semibold text-purple-700 ml-3 text-xl" >
                    General
                  </h1>
                  {decryptedUserData?.employeeDetails?.isAccessToDashboardTab && (
                    <Link to="/admin-dashboard">
                      <button
                        className={`w-full flex items-center rounded-md p-2 hover:text-white hover:bg-purple-400  ${activeTab === "Dashboard" ||
                          location.pathname.includes("admin-dashboard")
                          ? "bg-purple-700 text-white "
                          : "hover:bg-purple-400 hover:text-white text-black"
                          }`}
                        onClick={() => handleTabClick("Dashboard")}
                        style={{ borderLeft: "none" }}
                      >
                        <FiHome
                          size={20}
                          className={` ${activeTab === "Dashboard" ? "text-white" : "text-purple-500"
                            }`}
                        />
                        <span className={`ml-5 text-nowrap`}>Dashboard</span>
                      </button>
                    </Link>
                  )}
                  {decryptedUserData?.employeeDetails?.isAccessToFinancialDataTab && (
                    <Link to="/financial-data">
                      <button
                        className={`w-full flex items-center rounded-md p-2 hover:text-white hover:bg-purple-400 ${activeTab === "Financial-data" ||
                          location.pathname.includes("financial-data")
                          ? "bg-purple-700 text-white "
                          : "hover:bg-purple-400 hover:text-white text-black"
                          }`}
                        onClick={() => handleTabClick("Financial Data")}
                      >
                        <FiClock
                          size={20}
                          className={` ${activeTab === "Financial Data" ? "text-white" : "text-purple-500"
                            } `}
                        />
                        <span className={`ml-5 text-nowrap`}>Financial Data</span>
                      </button>
                    </Link>
                  )}
                  {decryptedUserData?.employeeDetails?.isAccessToSupportTab && (
                    <Link to="/support-desk">
                      <button
                        className={`w-full flex items-center rounded-md p-2 hover:text-white hover:bg-purple-400 ${activeTab === "Support Desk" ||
                          location.pathname.includes("/support-desk")
                          ? "bg-purple-700 text-white "
                          : "hover:bg-purple-400 hover:text-white text-black"
                          }`}
                        onClick={() => handleTabClick("Support Desk")}
                      >
                        <FiBell
                          size={20}
                          className={` ${activeTab === "Support Desk" ? "text-white" : "text-purple-500"
                            }`}
                        />
                        <span className={`ml-5 text-nowrap`}>Support Desk</span>
                      </button>
                    </Link>
                  )}
                  {decryptedUserData?.employeeDetails?.isAccessToReportTab && (
                    <Link to="/reports">
                      <button
                        className={`w-full flex items-center rounded-md p-2 hover:text-white hover:bg-purple-400 ${activeTab === "Reports" ||
                          location.pathname.includes("/reports")
                          ? "bg-purple-700 text-white "
                          : "hover:bg-purple-400 hover:text-white text-black"
                          }`}
                        onClick={() => handleTabClick("Reports")}
                      >
                        <FiMail
                          size={20}
                          className={` ${activeTab === "Reports" ? "text-white" : "text-purple-700"
                            }`}
                        />
                        <span className={`ml-5 text-nowrap`}>Reports</span>
                      </button>
                    </Link>
                  )}
                  {decryptedUserData?.employeeDetails?.isAccessToMembersTab && (
                    <Link to="/member">
                      <button
                        className={`w-full flex items-center rounded-md p-2 hover:text-white hover:bg-purple-400 ${activeTab === "Member" ||
                          location.pathname.includes("/member")
                          ? "bg-purple-700 text-white"
                          : "hover:bg-purple-400 hover:text-white "
                          }`}
                        onClick={() => handleTabClick("Member")}
                      >

                        <LuUsers2
                          size={20}
                          className={` ${activeTab === "Member"
                            ? "text-white"
                            : "text-purple-700"
                            }`}
                        />
                        <span className={`ml-5 text-nowrap`}>Member</span>
                      </button>
                    </Link>
                  )}
                  {decryptedUserData?.employeeDetails?.isAccessToClientTab && (
                    <Link to="/clients">
                      <button
                        className={`w-full flex items-center rounded-md p-2 hover:text-white hover:bg-purple-400  ${activeTab === "Client" ||
                          location.pathname.includes("/clients")
                          ? "bg-purple-700 text-white "
                          : "hover:bg-purple-400 hover:text-white "
                          }`}
                        onClick={() => handleTabClick("Client")}
                      >
                        <LuUsers2
                          size={20}
                          className={`${activeTab === "Client"
                            ? "text-white"
                            : "text-purple-700"
                            }`}
                        />
                        <span className={`ml-5 text-nowrap`}>Client</span>
                      </button>
                    </Link>
                  )}
                  {decryptedUserData?.employeeDetails?.isAccessToNotificationTab && (
                    <Link to="/notification">
                      <button
                        className={`w-full flex items-center rounded-md p-2 hover:text-white hover:bg-purple-400 ${activeTab === "Notification" ||
                          location.pathname.includes("/notification")
                          ? "bg-purple-700 text-white"
                          : "hover:bg-purple-400 hover:text-white"
                          }`}
                        onClick={() => handleTabClick("Notification")}
                      >
                        <FiBell
                          size={20}
                          className={`${activeTab === "Notification"
                            ? "text-white"
                            : "text-purple-700"
                            }`}
                        />
                        <span className={`ml-5 text-nowrap`}>Notification</span>
                      </button>
                    </Link>
                  )}
                  {decryptedUserData?.employeeDetails?.isAccessToPlansTab && (
                    <Link to="/plans">
                      <button
                        className={`w-full flex items-center rounded-md p-2 hover:text-white hover:bg-purple-400 ${activeTab === "Plans" ||
                          location.pathname.includes("/plans")
                          ? "bg-purple-700 text-white "
                          : "hover:bg-purple-400 hover:text-white "
                          }`}
                        onClick={() => handleTabClick("Plans")}
                      >
                        <FiUser
                          size={20}
                          className={`${activeTab === "Plans" ? "text-white" : "text-purple-700"
                            }`}
                        />
                        <span className={`ml-5 text-nowrap`}>Plans</span>
                      </button>
                    </Link>
                  )}
                  {decryptedUserData?.employeeDetails?.isAccessToLeadsTab && (
                    <Link to="/leads">
                      <button
                        className={`w-full flex items-center rounded-md p-2 hover:text-white hover:bg-purple-400 ${activeTab === "Leads" ||
                          location.pathname.includes("/leads")
                          ? "bg-purple-700 text-white "
                          : "hover:bg-purple-400 hover:text-white "
                          }`}
                        onClick={() => handleTabClick("Leads")}
                      >
                        <LuUsers2
                          size={20}
                          className={`${activeTab === "Leads" ? "text-white" : "text-purple-700"
                            }`}
                        />
                        <span className={`ml-5 text-nowrap`}>Leads</span>
                      </button>
                    </Link>
                  )}
                  {decryptedUserData?.employeeDetails?.isAccessToFaqTab && (
                    <Link to="/faq">
                      <button
                        className={`w-full flex items-center rounded-md p-2 hover:text-white hover:bg-purple-400  ${activeTab === "Faq" ||
                          location.pathname.includes("/faq")
                          ? "bg-purple-700 text-white"
                          : "hover:bg-purple-400 hover:text-white"
                          }`}
                        onClick={() => handleTabClick("Faq")}
                      >
                        <RiQuestionnaireLine
                          size={20}
                          className={` ${activeTab === "Faq" ? "text-white" : "text-purple-700"
                            }`}
                        />
                        <span className={`ml-5 text-nowrap`}>FAQ</span>
                      </button>
                    </Link>
                  )}

                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col flex-grow lg:ml-72 xl:ml-72  min-[400px]:mx-2  mb-5 ml-3 mr-3 sm:ml-3 min-[1660px]:ml-50 ">
            <Outlet />
          </div>

        </div>
      </div>
    </>
  );
};

export default Sidebar;
