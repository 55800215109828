import React, { useState, useEffect } from "react";
import {
  Link, useLocation, useNavigate, useSearchParams
} from "react-router-dom";
import "./topHeader.css";

import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { SiProgress } from "react-icons/si";
import { IoSettings } from "react-icons/io5";
import { RiNotification4Fill } from "react-icons/ri";
import axios from "axios";

// import TrendingUpIcon from '@mui/icons-material/TrendingUp';
// import { lightTheme, darkTheme } from "../../Theme/Theme";
import { BsFillCreditCard2BackFill } from "react-icons/bs";
import { RiFundsFill } from "react-icons/ri";

import { CrossIcon, LogOut, Menu, X } from "lucide-react";
import "react-toastify/dist/ReactToastify.css";
import styles from "../Dashboard/Dashboard.module.css";
import { useQuery } from "react-query";
import { getUserInfo } from "../../../Services/UserServices";
import { BiSolidDashboard } from "react-icons/bi";

import {
  checkNotification, getUserNotification
} from "../../../Services/NotificationServices";
import { useAuth } from "../../Context/AuthProvider ";
import { showTheme } from "../../Theme/Theme";
import { getToken } from "../../../utils/getToken.js";
import { FiArrowRight } from "react-icons/fi";


const TopHeader = ({
  handleSettingsClick,
  handleSettingsClick1,
  activeTab,
  setActiveTab,
}) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };


  const { setToken } = useAuth();
  const { data: user } = useQuery({
    queryFn: getUserInfo,
    queryKey: ["user_profile_info"],
  });
  const { data: userNotifications, refetch } = useQuery({
    queryFn: getUserNotification,
    queryKey: ["notifications"],

  });

  const notificationList = userNotifications?.notificationDetails || [];
  const params = useSearchParams();
  const [showNotifications, setShowNotifications] = useState(false);
  const [profileDropdown, setProfileDropdown] = useState(false);
  const [checkLogout, setCheckLogout] = useState(false);
  const [theme, setTheme] = useState("light");
  const [viewMoreNotification, setViewMoreNotification] = useState(false);
  const [notificationDropdown, setNotificationDropdown] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const handleNotificationClick = () => {
    setShowNotifications(!showNotifications);
  };
  useEffect(() => {
    refetch();
  }, [showNotifications,notificationDropdown])

  const logOut = () => {
    setToken();
    localStorage.removeItem("encryptedData");
    localStorage.removeItem("darkMode");
    navigate("/", { replace: true });
  };
  const [darkMode, setDarkMode] = useState(getInitialDarkModeState());

  function getInitialDarkModeState() {
    const storedDarkMode = localStorage.getItem("darkMode");
    return storedDarkMode ? JSON.parse(storedDarkMode) : false;
  }

  function toggleDarkMode() {
    const newDarkMode = !darkMode;
    setDarkMode(newDarkMode);
    localStorage.setItem("darkMode", JSON.stringify(newDarkMode));
    window.location.reload();
  }

  // useEffect to store darkMode state in local storage
  useEffect(() => {
    localStorage.setItem("darkMode", JSON.stringify(darkMode));
  }, [darkMode]);
  const initialNotifications = [
    {
      type: "success",
      message:
        '<span style="color: #4AC97E;">Success!</span> Profile update completed.',
      imageUrl: "/image/Sucess.png",
    },
    {
      type: "warning",
      message:
        '<span style="color: #E26F20;">Warning!</span> Please update your KYC.',
      imageUrl: "/image/Warning.png",
    },
    {
      type: "error",
      message:
        '<span style="color: #D0302F;">Something went wrong. </span>Last month deposit failed.',
      imageUrl: "/image/alert.png",
    },
    {
      type: "info",
      message:
        '<span style="color: #4D62E5;">New: New plans are available.</span>',
      imageUrl: "/image/bulb.png",
    },
  ];

  const [notifications, setNotifications] = useState(initialNotifications);
  const [showAllNotifications, setShowAllNotifications] = useState(false);

  const handleSeeAllNotifications = () => {
    setShowAllNotifications(!showAllNotifications);
    setNotifications(userNotifications);
  };

  const ResponsiveNavbar = (
    <div onClick={() => setMobileOpen(false)}>
      <div className=" flex flex-col  gap-6 pl-14 pb-5" style={{ backgroundColor: showTheme("topHeader"), height: '50vh', overflowY: "auto" }}>
        <Link to="/dashboard" onClick={handleSettingsClick1}>
          <button
            className={` flex items-center rounded-md  hover:text-white hover:bg-purple-400 p-2  ${activeTab === "landing" ||
              location.pathname.includes("/landing")
              ? "bg-purple-500 text-white "
              : "hover:bg-purple-400 hover:text-white"
              }`}
            onClick={() => setActiveTab("landing")}
            style={{ borderLeft: "none", color: showTheme("color") }}
          >
            <BiSolidDashboard
              size={20}
              className={` ${activeTab === "landing"
                ? "text-white"
                : "text-purple-700"
                } `}
            />
            <span className="ml-2 ">
              Dashboard
            </span>
          </button>
        </Link>

        <Link to="/index-plan" onClick={handleSettingsClick1}>
          <button
            className={` flex items-center rounded-md  hover:text-white hover:bg-purple-400 p-2  ${activeTab === "plans" ||
              location.pathname.includes("/index-plan")
              ? "bg-purple-500 text-white "
              : "hover:bg-purple-400 hover:text-white"
              }`}
            onClick={() => setActiveTab("plans")}
            style={{ borderLeft: "none", color: showTheme("color") }}
          >
            <BsFillCreditCard2BackFill
              size={20}
              className={` ${activeTab === "plans"
                ? "text-white"
                : "text-purple-700"
                } `}
            />
            <span className="ml-2 text-nowrap">
              My Plans
            </span>
          </button>
        </Link>

        <Link to="/funds" onClick={handleSettingsClick1}>
          <button
            className={` flex items-center rounded-md  hover:text-white hover:bg-purple-400 p-2  ${activeTab === "funds" ||
              location.pathname.includes("/funds")
              ? "bg-purple-500 text-white "
              : "hover:bg-purple-400 hover:text-white"
              }`}
            onClick={() => setActiveTab("funds")}
            style={{ borderLeft: "none", color: showTheme("color") }}
          >
            <RiFundsFill
              size={20}
              className={` ${activeTab === "funds"
                ? "text-white"
                : "text-purple-700"
                } `}
            />
            <span className="ml-2 ">
              Funds
            </span>
          </button>
        </Link>

        <Link to="/progress" onClick={handleSettingsClick1}>
          <button
            className={` flex items-center rounded-md  hover:text-white hover:bg-purple-400 p-2  ${activeTab === "progress" ||
              location.pathname.includes("/progress")
              ? "bg-purple-500 text-white "
              : "hover:bg-purple-400 hover:text-white"
              }`}
            onClick={() => setActiveTab("progress")}
            style={{ borderLeft: "none", color: showTheme("color") }}
          >
            <SiProgress
              size={20}
              className={` ${activeTab === "progress"
                ? "text-white"
                : "text-purple-700"
                } `}
            />
            <span className="ml-2 ">
              Progress
            </span>
          </button>
        </Link>

        <Link to="/settings" onClick={handleSettingsClick}>
          <button
            className={` flex items-center rounded-md  hover:text-white hover:bg-purple-400 p-2  ${activeTab === "settings" ||
              location.pathname.includes("/settings")
              ? "bg-purple-500 text-white "
              : "hover:bg-purple-400 hover:text-white"
              }`}
            onClick={() => setActiveTab("settings")}
            style={{ borderLeft: "none", color: showTheme("color") }}
          >
            <IoSettings
              size={20}
              className={` ${activeTab === "settings"
                ? "text-white"
                : "text-purple-700"
                } `}
            />
            <span className="ml-2 ">
              Settings
            </span>
          </button>
        </Link>
      </div>
    </div>
  );
  return (
    <div
      className="fixed top-0 left-0 right-0  z-10 py-2  justify-center flex items-center shadow"
      style={{
        color: showTheme("text"),
        backgroundColor: showTheme("topHeader"),
      }}
    >
      <div className="w-full flex items-center justify-between md:px-14 lg:md:px-6 xl:md:px-14 2xl:md:px-14 relative" >
        <Link to='/dashboard'>
          <div className=" flex  items-center ">
            <img
              className="h-auto mr-"
              src="/image/Logo(1).png"
              alt="techtime logo"
            />
            <div
              className="text-xl font-semibold"
              style={{ color: showTheme("logoColor") }}
            >
              Techtime
            </div>
          </div>
        </Link>
        <div className=" flex items-center   hidden sm:hidden md:hidden lg:flex xl:flex 2xl:flex">
          {/* <div className=" flex  gap-5"> */}
          <Link to="/dashboard" onClick={handleSettingsClick1}>
            <button
              className={`w-full flex items-center rounded-md  hover:text-white hover:bg-purple-400 p-2  ${activeTab === "landing" ||
                location.pathname.includes("/landing")
                ? "bg-purple-500 text-white "
                : "hover:bg-purple-400 hover:text-white"
                }`}
              onClick={() => setActiveTab("landing")}
              style={{ borderLeft: "none", color: showTheme("color") }}
            >
              <BiSolidDashboard
                size={20}
                className={` ${activeTab === "landing"
                  ? "text-white"
                  : "text-purple-700"
                  } `}
              />
              <span className="ml-2 ">
                Dashboard
              </span>
            </button>
          </Link>

          <Link to="/index-plan" onClick={handleSettingsClick1}>
            <button
              className={`w-full flex items-center rounded-md  hover:text-white hover:bg-purple-400 p-2  ${activeTab === "plans" ||
                location.pathname.includes("/index-plan")
                ? "bg-purple-500 text-white "
                : "hover:bg-purple-400 hover:text-white"
                }`}
              onClick={() => setActiveTab("plans")}
              style={{ borderLeft: "none", color: showTheme("color") }}
            >
              <BsFillCreditCard2BackFill
                size={20}
                className={` ${activeTab === "plans"
                  ? "text-white"
                  : "text-purple-700"
                  } `}
              />
              <span className="ml-2 text-nowrap">
                My Plans
              </span>
            </button>

          </Link>

          <Link to="/funds" onClick={handleSettingsClick1}>
            <button
              className={`w-full flex items-center rounded-md  hover:text-white hover:bg-purple-400 p-2  ${activeTab === "funds" ||
                location.pathname.includes("/funds")
                ? "bg-purple-500 text-white "
                : "hover:bg-purple-400 hover:text-white"
                }`}
              onClick={() => setActiveTab("funds")}
              style={{ borderLeft: "none", color: showTheme("color") }}
            >
              <RiFundsFill
                size={20}
                className={` ${activeTab === "funds"
                  ? "text-white"
                  : "text-purple-700"
                  } `}
              />
              <span className="ml-2 ">
                Funds
              </span>
            </button>

          </Link>

          <Link to="/progress" onClick={handleSettingsClick1}>
            <button
              className={`w-full flex items-center rounded-md  hover:text-white hover:bg-purple-400 p-2  ${activeTab === "progress" ||
                location.pathname.includes("/progress")
                ? "bg-purple-500 text-white "
                : "hover:bg-purple-400 hover:text-white"
                }`}
              onClick={() => setActiveTab("progress")}
              style={{ borderLeft: "none", color: showTheme("color") }}
            >
              <SiProgress
                size={20}
                className={` ${activeTab === "progress"
                  ? "text-white"
                  : "text-purple-700"
                  } `}
              />
              <span className="ml-2 ">
                Progress
              </span>
            </button>

          </Link>

          <Link to="/settings" onClick={handleSettingsClick}>
            <button
              className={`w-full flex items-center rounded-md  hover:text-white hover:bg-purple-400 p-2  ${activeTab === "settings" ||
                location.pathname.includes("/settings")
                ? "bg-purple-500 text-white "
                : "hover:bg-purple-400 hover:text-white"
                }`}
              onClick={() => setActiveTab("settings")}
              style={{ borderLeft: "none", color: showTheme("color") }}
            >
              <IoSettings
                size={20}
                className={` ${activeTab === "settings"
                  ? "text-white"
                  : "text-purple-700"
                  } `}
              />
              <span className="ml-2 ">
                Settings
              </span>
            </button>

          </Link>
          {/* </div> */}
        </div>
        <div className="flex items-center justify-between "
          // onMouseLeave={() => {
          //   setNotificationDropdown(false);
          //   setProfileDropdown(false);
          // }}
        >
          <div style={{ position: "relative", display: "inline-block" }} className="flex mr-3.5 max-[380px]:mr-2.5">
            <div style={{ position: "absolute", top: "-10px", right: "-10px", backgroundColor: "#5f249f", borderRadius: "50%", width: "17px", height: "17px", fontSize: "12px" }}><span style={{ marginLeft: "5px", color: "white" }}>{userNotifications?.unCheckedNotificationCount}</span></div>
            <RiNotification4Fill
              size={20}
              className="text-black"
              style={{ color: showTheme("text") }}
              onClick={() => {
                setNotificationDropdown(!notificationDropdown);
                setProfileDropdown(false);
              }}
            />
          </div>
          <div className="block ">
            <div className="mr-5">
              {notificationDropdown ? (
                <FaAngleUp
                  style={{ color: showTheme("topIcons") }}
                  className="cursor-pointer"
                  onClick={() => {
                    setNotificationDropdown(false);
                    setProfileDropdown(null);
                  }}
                />
              ) : (
                <FaAngleDown
                  onClick={() => {
                    setNotificationDropdown(true);
                    setProfileDropdown(null);
                  }}
                  style={{ color: showTheme("topIcons") }}
                  className="cursor-pointer"
                />
              )}
            </div>
            {notificationDropdown && (
              <div
                className="block shadow-lg ring-1 ring-black ring-opacity-5 absolute z-50 top-10 lg:top-9 xl:top-9 2xl:top-9 right-0 bg-white w-full lg:w-1/2 xl:w-1/2 2xl:w-1/2 shadow-xl "
                style={{ backgroundColor: showTheme("smallCard") }}
              >
                <div style={{ backgroundColor: showTheme("smallCard") }}>
                  <div className=" flex flex-col   gap-6 pb-5 overflow-auto" style={{ backgroundColor: showTheme("smallCard"), height: notificationList?.length > 5 && '70vh' }}>
                    <div>
                      {
                        notificationList?.length > 0 ?
                          !viewMoreNotification ?
                            notificationList?.slice(0, 5).map((notification, index) => {
                              return (
                                <>
                                  <div key={index} className="flex items-center rounded-lg border-b w-full shadow-sm max-[420px]:text-xs">
                                    <div className="flex items-center">
                                      <img src={`${notification?.notificationId?.messageType === 'success' ? '/image/Sucess.png' : (notification?.notificationId?.messageType === 'warning' ? '/image/Warning.png' : (notification?.notificationId?.messageType === 'something went wrong' ? '/image/alert.png' : (notification?.notificationId?.messageType === 'tip' ? '/image/bulb.png' : '/image')))}`} alt='notification' style={{ width: "50px", height: "50px" }} className='mt-7' />
                                      <p className="font-semibold">
                                        <span className={`text-${notification?.notificationId?.messageType === 'success' ? 'green' : (notification?.notificationId?.messageType === 'warning' ? 'orange' : (notification?.notificationId?.messageType === 'something went wrong' ? 'red' : (notification?.notificationId?.messageType === 'error' ? 'red' : 'blue')))}-600`}>
                                          {notification?.notificationId?.messageType}:
                                        </span>
                                        <span> {notification?.notificationId?.message}</span>
                                        {notification?.notificationId?.redirectLink && notification?.notificationId?.recieverId ? (
                                          <Link to={`${notification?.notificationId?.redirectLink}/${notification?.notificationId?.recieverId}`}> <button className="font-normal py-1 max-[360px]:px-0 px-6 rounded-md ml-3 text-purple-700" onClick={() => {
                                          }}>Check</button></Link>
                                        )
                                          :
                                          <Link to={`${notification?.notificationId?.redirectLink}`}> <button className="font-normal py-1  max-[360px]:px-0 px-6 rounded-md ml-3 text-purple-700" onClick={() => {
                                          }}>Check</button></Link>

                                        }

                                      </p>
                                    </div>
                                  </div>


                                </>
                              );
                            })
                            :
                            notificationList?.slice(0, 100000000).map((notification, index) => {
                              return (

                                <div key={index} className="flex items-center rounded-lg whitespace-nowrap border-b shadow-sm max-[420px]:text-xs">
                                  <div className="flex items-center ">
                                    <img src={`${notification?.notificationId?.messageType === 'success' ? '/image/Sucess.png' : (notification?.notificationId?.messageType === 'warning' ? '/image/Warning.png' : (notification?.notificationId?.messageType === 'something went wrong' ? '/image/alert.png' : (notification?.notificationId?.messageType === 'tip' ? '/image/bulb.png' : '/image')))}`} alt='notification' style={{ width: "50px", height: "50px" }} className='mt-7' />
                                    <p className="font-semibold">
                                      <span className={`text-${notification?.notificationId?.messageType === 'success' ? 'green' : (notification?.notificationId?.messageType === 'warning' ? 'orange' : (notification?.notificationId?.messageType === 'something went wrong' ? 'red' : (notification?.notificationId?.messageType === 'error' ? 'red' : 'blue')))}-600`}>
                                        {notification?.notificationId?.messageType}:
                                      </span>
                                      <span> {notification?.notificationId?.message}</span>
                                      {notification?.notificationId?.redirectLink && notification?.notificationId?.recieverId ? (
                                        <Link to={`${notification?.notificationId?.redirectLink}/${notification?.notificationId?.recieverId}`}> <button className="font-normal py-1 max-[360px]:px-0 px-6 rounded-md ml-3 text-purple-700" onClick={() => {
                                        }}>Check</button></Link>
                                      )
                                        :
                                        <Link to={`${notification?.notificationId?.redirectLink}`}> <button className="font-normal py-1  max-[360px]:px-0 px-6 rounded-md ml-3 text-purple-700" onClick={() => {
                                        }}>Check</button></Link>

                                      }

                                    </p>
                                  </div>
                                </div>
                              );
                            })

                          :
                          'No notification Found'
                      }
                      {
                        notificationList?.length > 5 &&
                        <div style={{ display: "flex", justifyContent: "right", paddingInline: "20px", alignItems: "center" }} className="text-purple-700"><div className="flex" style={{ alignItems: "center", cursor: "pointer" }} onClick={() => { setViewMoreNotification(!viewMoreNotification) }}>{viewMoreNotification ? 'View less' : 'View More'} <FiArrowRight className="text-purple-700 " /></div></div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="flex items-center">
            <img
              src={`${user?.profileImageLink
                ? user?.profileImageLink
                : "https://img.freepik.com/premium-photo/marine-biologist-digital-avatar-generative-ai_934475-9003.jpg?w=740"
                }`}
              alt="User Avatar"
              className="w-7 h-7 rounded-full mr-2 "
            />
            <div className="hidden sm:hidden md:hidden lg:flex xl:flex 2xl:flex">
              {/* <p className="font-bold">{user?.name}</p> */}
              <p
                className="break-all "
                style={{ color: showTheme("text"), fontSize: "14px" }}
              >
                {user?.email}
              </p>
            </div>
            <div className="flex ">
              <div className="relative inline-block text-left ">
                <div>
                  {profileDropdown ? (
                    <FaAngleUp className="cursor-pointer" onClick={() => {
                      setProfileDropdown(false);
                      setNotificationDropdown(null);
                    }} />
                  ) : (
                    <FaAngleDown
                      onClick={() => {
                        setProfileDropdown(true);
                        setNotificationDropdown(null);
                      }}
                      className="cursor-pointer"
                    />
                  )}
                </div>
                {profileDropdown && (
                  <div
                    className="absolute right-0 z-10 mt-1 w-56 origin-top-right rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="menu-button"
                    tabindex="-1"
                    style={{
                      backgroundColor: showTheme("smallCard"),
                      border: showTheme("borderCard"),
                    }}
                  >
                    <div
                      className="py-1"
                      role="none"
                      style={{
                        backgroundColor: showTheme("smallCard"),
                        border: showTheme("borderCard"),
                      }}
                    >
                      <div className="flex sm:flex md:flex lg:hidden xl:hidden 2xl:hidden border-b justify-center">
                        {/* <p className="font-bold break-all">{user?.name}</p> */}
                        <p
                          className="break-all p-2"
                          style={{ color: showTheme("text"), fontSize: "14px" }}
                        >
                          {user?.email}
                        </p>
                      </div>
                      <div>
                        <button
                          onClick={toggleDarkMode}
                          className="text-gray-700 block px-1 py-2 text-sm flex items-center gap-2 cursor-pointer focus:outline-none"
                          role="menuitem"
                          tabIndex="-1"
                          id="menu-item-0"
                          style={{
                            color: showTheme("text"),
                            backgroundColor: showTheme("background"),
                            border: "1px solid " + showTheme("border"),
                            borderRadius: "9999px", // to make it round
                            transition: "background-color 0.3s, border-color 0.3s",
                          }}
                        >
                          <span className="sr-only">Toggle dark mode</span>
                          {/* <Palette width={18} /> */}
                          <span className="flex-grow"></span>{" "}
                          {/* Add a flexible space */}
                          <span
                            className={`w-5 h-5 rounded-full ${darkMode ? "bg-yellow-500" : "bg-gray-500"
                              }`}
                          >
                            {darkMode ? (
                              <span role="img" aria-label="Moon">
                                {/* <IoSunnyOutline/> */}
                              </span>
                            ) : (
                              <span role="img" aria-label="Sun"></span>
                            )}
                          </span>
                          <p className="text-nowrap">Change Theme</p>
                        </button>
                      </div>
                      <button
                        title="logout"
                        className="text-gray-700 block w-full px-4 py-2 text-left text-sm flex items-center gap-2"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-3"
                        onClick={() => setCheckLogout(true)}
                        style={{
                          color: showTheme("text"),
                        }}
                      >
                        <LogOut width={18} style={{ color: showTheme("text") }} />
                        Log out
                      </button>
                    </div>
                  </div>
                )}
              </div>
              {checkLogout && (
                <div className=" fixed inset-0 z-50 overflow-auto flex items-center justify-center backdrop-blur-md bg-gray-900  bg-opacity-20 ">

                  <div
                    className=" rounded-xl flex flex-col w-96 py-6 ml-3 mr-3 sm:ml-3"
                    style={{ backgroundColor: showTheme("card") }}
                  >

                    <h2
                      className="text-2xl font-semibold mb-4 text-center"
                      style={{ color: showTheme("text") }}
                    >
                      Are you Sure?
                    </h2>
                    <div className="flex gap-3 w-full justify-center">
                      <button
                        title="Want to logout"
                        className="bg-purple-700 block px-4 py-2 text-sm flex items-center gap-2  rounded text-white hover:bg-purple-500"
                        onClick={() => logOut()}
                      >
                        Yes
                      </button>
                      <button
                        title=" Stay Log in"
                        className="text-purple-700 bg-white block px-4 py-2 text-sm flex items-center gap-2  rounded text-purple-700"
                        onClick={() => setCheckLogout(false)}
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {
            mobileOpen ?
              <X className=' block lg:hidden xl:hidden 2xl:hidden w-11 h-11 p-1.5 ms-5' onClick={handleDrawerToggle} />
              :
              <Menu className=' block lg:hidden xl:hidden 2xl:hidden w-11 h-11 p-1.5 ms-5' onClick={handleDrawerToggle} />
          }
        </div>
        {
          mobileOpen &&
          <div
            onClose={handleDrawerToggle}
            className="block lg:hidden xl:hidden 2xl:hidden absolute top-12 left-0 bg-white w-full shadow-xl"
          >
            {ResponsiveNavbar}
          </div>}
      </div>

    </div>
  );
};

export default TopHeader;
