import React, { useState, useEffect } from "react";
// import { FiUser, FiTrendingUp } from 'react-icons/fi';
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { getAllBankDetails } from "../../../Services/BankServices";
import { useQuery } from "react-query";
import { FaIdCardAlt, FaMoneyBill, FaQuestionCircle } from "react-icons/fa";
import { showTheme } from "../../Theme/Theme";
import { FaUser } from "react-icons/fa";
import { FaPaperPlane } from "react-icons/fa6";
import { BiSolidBank } from "react-icons/bi";
import { AiFillMessage } from "react-icons/ai";
import { RiGitRepositoryPrivateFill } from "react-icons/ri";
import { HiMiniDocumentCheck } from "react-icons/hi2";
import { MdOutlineSort } from "react-icons/md";
import { Box, Button, Drawer } from "@mui/material";
import { Close } from "@mui/icons-material";
import { IoIosNotifications } from "react-icons/io";

const Sidebar = ({ content, activeTab, setActiveTab }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [,] = useState("Dashboard");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const { data: bank, refetch } = useQuery({
    queryFn: getAllBankDetails,
    queryKey: ["bank_details"],
  });
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
 useEffect(() => {
    if (location.pathname === '/') {
      navigate('/dashboard')
    }
  }, [location])

  useEffect(() => {
    setActiveTab("Dashboard");
  }, []);
  const renderTabs = () => {
    switch (content) {
      case "settings":
        return (
          <>
            <Link to="/profile">
              <button
                className={`w-full flex items-center rounded-md p-2 hover:text-white hover:bg-purple-400 mt-2 ${activeTab === "Profile" ||
                  location.pathname.includes("profile")
                  ? "bg-purple-500 text-white "
                  : "hover:bg-purple-400 hover:text-white text-black"
                  }`}
                onClick={() => handleTabClick("Profile")}
                style={{ borderLeft: "none", color: showTheme("color") }}
              >
                <FaUser
                  size={20}
                  className={` ${activeTab === "Profile" ? "text-white" : "text-purple-700"
                    }`}
                />
                <span className={`ml-5`} >Profile</span>
              </button>
            </Link>

            <Link to="/setting-notifications">
              <button
                className={`w-full flex items-center rounded-md p-2 hover:text-white hover:bg-purple-400 ${activeTab === "Notification" ||
                  location.pathname.includes("/setting-notifications")
                  ? "bg-purple-500 text-white "
                  : "hover:bg-purple-400 hover:text-white "
                  }`}
                onClick={() => handleTabClick("Notification")}
                style={{ borderLeft: "none", color: showTheme("color") }}
              >

                <IoIosNotifications
                  size={20}
                  className={` ${activeTab === "Notification"
                    ? "text-white"
                    : "text-purple-700"
                    } `}
                />
                <span className={`ml-5 `} >Notification</span>
              </button>
            </Link>

            <Link to="/setting-privacy">
              <button
                className={`w-full flex items-center text-nowrap rounded-md p-2 hover:text-white hover:bg-purple-400 ${activeTab === "Privacy-policy" ||
                  location.pathname.includes("/setting-privacy")
                  ? "bg-purple-500 text-white "
                  : "hover:bg-purple-400 hover:text-white "
                  }`}
                onClick={() => handleTabClick("Privacy-policy")}
                style={{ borderLeft: "none", color: showTheme("color") }}
              >
                <RiGitRepositoryPrivateFill
                  size={20}
                  className={` ${activeTab === "Privacy-policy"
                    ? "text-white"
                    : "text-purple-700"
                    }`}
                />
                <span className={`ml-5`} >Privacy policy</span>
              </button>
            </Link>

            <Link to="/setting-term-and-condition">
              <button
                className={`w-full flex items-center text-nowrap rounded-md p-2 hover:text-white hover:bg-purple-400 ${activeTab === "Term-condition" ||
                  location.pathname.includes("/setting-term-and-condition")
                  ? "bg-purple-500 text-white "
                  : "hover:bg-purple-400 hover:text-white "
                  }`}
                onClick={() => handleTabClick("Term-condition")}
                style={{ borderLeft: "none", color: showTheme("color") }}
              >
                <HiMiniDocumentCheck
                  size={20}
                  className={` ${activeTab === "Term-condition"
                    ? "text-white"
                    : "text-purple-700"
                    }`}
                />
                <span className={`ml-5`} >T & C</span>
              </button>
            </Link>

            <Link to="/contact">
              <button
                className={`w-full flex items-center rounded-md p-2 hover:text-white hover:bg-purple-400 ${activeTab === "Contact" ||
                  location.pathname.includes("/contact")
                  ? "bg-purple-500 text-white "
                  : "hover:bg-purple-400 hover:text-white "
                  }`}
                onClick={() => handleTabClick("Contact")}
                style={{ borderLeft: "none", color: showTheme("color") }}
              >
                <FaPaperPlane
                  size={20}
                  className={` ${activeTab === "Contact" ? "text-white" : "text-purple-700"
                    }`}
                />
                <span className={`ml-5 `} >Contact</span>
              </button>
            </Link>
          </>
        );

      default:
        return (
          <div style={{ background: showTheme("card") }} >
            <Link to="/profile">
              <button
                className={`w-full flex items-center rounded-md p-2 hover:text-white hover:bg-purple-400 mt-2 ${activeTab === "Profile" ||
                  location.pathname.includes("profile")
                  ? "bg-purple-500 text-white "
                  : "hover:bg-purple-400 hover:text-white text-black"
                  }`}
                onClick={() => handleTabClick("Profile")}
                style={{ borderLeft: "none", color: showTheme("color") }}
              >
                <FaUser
                  size={20}
                  className={` ${activeTab === "Profile" ? "text-white" : "text-purple-700"
                    }`}
                />
                <span className={`ml-5 `} >Profile</span>
              </button>
            </Link>

            <Link to="/transactions">
              <button
                className={`w-full flex items-center rounded-md p-2 hover:text-white hover:bg-purple-400 mt-5 ${activeTab === "Transactions" ||
                  location.pathname.includes("transactions")
                  ? "bg-purple-500 text-white "
                  : "hover:bg-purple-400 hover:text-white text-black"
                  }`}
                onClick={() => handleTabClick("Transactions")}
                style={{ borderLeft: "none", color: showTheme("color") }}
              >
                <FaMoneyBill
                  size={20}
                  className={` ${activeTab === "Transactions" ? "text-white" : "text-purple-700"
                    }`}

                />
                <span className={`ml-5
                 `} >Transactions</span>
              </button>
            </Link>

            <Link to="/userplans">
              <button
                className={`w-full flex items-center rounded-md p-2 hover:text-white hover:bg-purple-400 mt-5 ${activeTab === "Plans" ||
                  location.pathname.includes("userplans")
                  ? "bg-purple-500 text-white "
                  : "hover:bg-purple-400 hover:text-white text-black"
                  }`}
                onClick={() => handleTabClick("Plans")}
                style={{ borderLeft: "none", color: showTheme("color") }}
              >
                <FaIdCardAlt
                  size={20}
                  className={` ${activeTab === "Plans" ? "text-white" : "text-purple-700"
                    }`}
                />
                <span className={`ml-5 `} >Plans</span>
              </button>
            </Link>

            <Link to="/contact">
              <button
                className={`w-full flex items-center rounded-md p-2 hover:text-white hover:bg-purple-400 mt-5 ${activeTab === "Contact" ||
                  location.pathname.includes("contact")
                  ? "bg-purple-500 text-white "
                  : "hover:bg-purple-400 hover:text-white text-black"
                  }`}
                onClick={() => handleTabClick("Contact")}
                style={{ borderLeft: "none", color: showTheme("color") }}
              >
                <FaPaperPlane
                  size={20}
                  className={` ${activeTab === "Contact" ? "text-white" : "text-purple-700"
                    }`}
                />
                <span className={`ml-5`} >Contact</span>
              </button>
            </Link>

            <Link to="/user-faq">
              <button
                className={`w-full flex items-center rounded-md p-2 hover:text-white hover:bg-purple-400 mt-5  ${activeTab === "Faq" || location.pathname.includes("user-faq")
                  ? "bg-purple-500 text-white "
                  : "hover:bg-purple-400 hover:text-white text-black"
                  }`}
                onClick={() => handleTabClick("Faq")}
                style={{ borderLeft: "none", color: showTheme("color") }}
              >
                <FaQuestionCircle
                  size={20}
                  className={` ${activeTab === "Faq" ? "text-white" : "text-purple-700"
                    }`}
                />
                <span className={`ml-5`} >Faq</span>
              </button>
            </Link>
            {bank?.data?.userBankDetail?.length !== 0 ? (
              <>
                <Link to="/bank-list ">
                  <button
                    className={`w-full flex items-center rounded-md p-2 hover:text-white hover:bg-purple-400 mt-5  ${activeTab === "bank-list" || location.pathname.includes("bank-list")
                      ? "bg-purple-500 text-white "
                      : "hover:bg-purple-400 hover:text-white text-black"
                      }`}
                    onClick={() => handleTabClick("bank-list")}
                    style={{ borderLeft: "none", color: showTheme("color") }}
                  >
                    <BiSolidBank
                      size={20}
                      className={` ${activeTab === "bank-list" ? "text-white" : "text-purple-700"
                        }`}
                    />
                    <span className={`ml-5`} >Bank Account</span>
                  </button>

                </Link>

              </>
            ) : (
              <>
                <Link to="/bank">
                  <button
                    className={`w-full flex items-center rounded-md p-2 hover:text-white hover:bg-purple-400 mt-5  ${activeTab === "bank" || location.pathname.includes("bank")
                      ? "bg-purple-500 text-white "
                      : "hover:bg-purple-400 hover:text-white text-black"
                      }`}
                    onClick={() => handleTabClick("Bank")}
                    style={{ borderLeft: "none", color: showTheme("color") }}
                  >
                    <BiSolidBank
                      size={20}
                      className={` ${activeTab === "bank" ? "text-white" : "text-purple-700"
                        }`}
                    />
                    <span className={`ml-5`} >Bank Account</span>
                  </button>

                </Link>
              </>
            )}

            {/* </div> */}
          </div>
        );
    }
  };
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  const DrawerList = (
    <Box sx={{ width: 250,background:showTheme("card"),height:"100%" }} role="presentation" onClick={toggleDrawer(false)} >
      {/* <div className="flex" style={{ background: showTheme("cardBack") }}> */}

      <div className="mt-5 flex flex-col justify-between" style={{ width: "235px" }}>
        <div className="flex items-center justify-between">
          <h1 className="font-semibold text-purple-700 ml-3 text-xl " style={{ color: showTheme("logoColor") }}>
            General
          </h1>
          <Close onClick={toggleDrawer(false)} style={{ fontSize: "20px",color:showTheme('logoColor') }} />
        </div>
        <hr className="mt-3" />
        {renderTabs()}
      </div>
      {/* </div> */}
    </Box>
  );

  return (
    <div className=" mt-12 lg:mt-16 xl:mt-16 2xl:mt-16 " style={{ background: showTheme("cardBack"), height: "100vh", overflow: "-moz-hidden-unscrollable" }}>
      <div className="">
        <Button onClick={toggleDrawer(true)} >
          <MdOutlineSort className=' block lg:hidden xl:hidden 2xl:hidden w-11 h-11 p-1.5 text-black' style={{ color: showTheme("text") }} />
        </Button>
        <Drawer open={open} onClose={toggleDrawer(false)}>
          {DrawerList}
        </Drawer>
      </div>
      <div className="flex  mr-20" style={{ background: showTheme("cardBack") }}>
        <div
          className={`mb-5 ml-10 mr-5 rounded-2xl ${isSidebarOpen ? "" : "hidden lg:block xl:block 2xl:block"
            }`}
          style={{ position: "fixed", top: "15", left: "5", overflowY: "auto", background: showTheme("card"), border: showTheme("bordersmallCard") }}
        >
          <div
            className="flex flex-col justify-between max-h-full"
            style={{
              height: "calc(100vh - 120px)",
              maxHeight: "80vh",
              overflowY: "auto",

            }}
          >
            <div className="mt-5  ml-5 mr-5 flex flex-col justify-between space-y-5" >
              <h1 className="font-semibold text-purple-700 ml-3 text-xl" style={{ color: showTheme("logoColor") }}>
                General
              </h1>
              {renderTabs()}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col flex-grow lg:ml-72 xl:ml-72  min-[400px]:mx-2  mb-5 ml-3 mr-3 sm:ml-3 min-[1660px]:ml-50 "
        style={{
          background: showTheme("cardBack")
        }}
      >
        <Outlet />
      </div>
    </div>

  );
};

export default Sidebar;
