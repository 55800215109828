import { defaultApi } from "./api";

export async function getAdminNotification() {
    const res = await defaultApi.get(`/api/notification/user-notification`);
    return res.data.data;
  }

  export async function deleteAdminNotification(id) {
    const res = await defaultApi.delete(`/api/notification/delete/${id}`);
    return res;
  }
  
  export async function getUserNotification() {
    const res = await defaultApi.get(`/api/notification/user-notification`);
    return res.data.data;
  }

  export function checkNotification(id,data) {
    // const token = getToken(); 
    return defaultApi.put(`/api/notification/check-notification/${id}`,data);
  } 
  export function settingNotification({data}) {
    // const token = getToken(); 
    return defaultApi.put(`/api/notification/enable`,data);
  }  
  export async function getSettingNotification() {
    const res = await defaultApi.get(`/api/notification/enable-setting`);
    return res.data.data;
  }

 