import React, { useEffect, useState } from "react";
import { getToken } from "../../../../utils/getToken";
import axios from "axios";
import { Link } from "react-router-dom";
import { cancelPlan, getPurchasePlan } from "../../../../Services/PlanServices";
import { useQuery } from "react-query";
import { FiPlus } from "react-icons/fi";
import Spinner from "../../../Spinner/Spinner";
import { showTheme } from "../../../Theme/Theme";

const PlanContent = ({ purchasePlan, refetch, getPurchasePlan }) => {
  const [loading, setLoading] = useState(false);
  const [showPopupCancel, setShowPopupCancel] = useState(false);
  const planCancel = async (id) => {
    setLoading(true);
    try {
      const { data } = await cancelPlan(id);
      refetch();
      setShowPopupCancel(false);
      // navigate('/dashboard/add-funds')
    } catch (err) {
      alert("error");
    } finally {
      setLoading(false);
    }
  };
  const openPopUp = () => {
    setShowPopupCancel(true);
  }
  const closePopup = () => {

    setShowPopupCancel(false);
  };

  //
  //   return (
  // <div className="bg-white shadow-md border rounded-lg  ml-4 ">
  //   <div className="flex flex-col items-center mt-10">
  //     <h1 className="font-bold text-5xl text-purple-700">Your Plans</h1>

  return (
    <div>
      {loading ? (
        <div className="flex justify-center " style={{ marginTop: "35vh" }}>
          <Spinner />
        </div>
      ) : (
        <div
          className=" rounded-2xl  ml-3 mr-3 sm:ml-3"
          style={{
            height: "calc(100vh - 120px)",
            // minHeight: "calc(100vh - 120px)",
            maxHeight: "80vh",
            overflowY: "auto",
            background: showTheme("card"),

          }}
        >
          <div className="flex flex-col items-center  mt-10  ">
            <h1 className="font-bold text-3xl  min-[640px]:text-5xl" style={{ color: showTheme("text") }}>
              Your Plans
            </h1>

            <h1 className="text-md min-[640px]:text-lg  mt-4 text-center" style={{ color: showTheme("text") }}>
              Join millions of other customers on this platform
            </h1>
            {purchasePlan &&
              purchasePlan?.userPlansDetail &&
              purchasePlan?.userPlansDetail?.length > 0 ? (
              <div className="relative flex flex-col flex-wrap sm:flex-row justify-center max-w-4xl w-full rounded-full gap-10 px-5 py-10">
                {purchasePlan?.userPlansDetail?.map((i) => (
                  <div className=" rounded-xl text-center" key={i?._id}>
                    <div className="rounded-lg shadow-md p-6 flex flex-col justify-between h-full" style={{ background: showTheme("smallCard"), border: showTheme("borderCard") }}>
                      <div>
                        <h2 className="text-2xl font-semibold mb-4" style={{ color: showTheme("text") }}>
                          {i?.planId?.planName}
                        </h2>
                        <p className="mb-4" style={{ color: showTheme("text") }}>
                          {i?.planId?.returnPercent}% / {i?.planId?.description}
                        </p>
                      </div>
                      <div className="flex flex-col sm:flex-row w-full"> {/* Change to flex-col for smaller screens */}
                        {purchasePlan?.isApprovedForInvestment === false ? (
                          <Link to={`/userplans`} className="w-full mb-3 sm:mb-0 sm:mr-3"> {/* Adjust margins for spacing */}
                            <button
                              title="Choose Plan"
                              className="bg-slate-200 text-black py-3 px-4 rounded-xl w-full hover:bg-slate-100"
                            >
                              Please Wait For Approval
                            </button>
                          </Link>
                        ) : (
                          <div className="flex flex-col sm:flex-row items-center w-full"> {/* Change to flex-col for smaller screens */}
                            <Link to={`/add-funds/${i?._id}`} className="w-full mb-3 sm:mb-0 sm:mr-3"> {/* Adjust margins for spacing */}
                              <button
                                title="Current Plan"
                                className="bg-purple-700 text-white py-3 px-4 rounded-xl text-nowrap w-full hover:bg-purple-400"
                              >
                                + Add Fund
                              </button>
                            </Link>
                            <button
                              title="Cancel"
                              className="bg-slate-200 text-end hover:bg-slate-300 py-3 px-4 rounded-xl text-nowrap w-full text-black flex" style={{justifyContent:"center", textAlign:"center"}}
                              onClick={() => {
                                openPopUp();
                              }}
                            >
                              Cancel
                            </button>
                            {showPopupCancel && (
                              <div className="fixed inset-0 flex items-center rounded-xl justify-center bg-black backdrop-blur bg-opacity-50  ">
                                <div className="bg-white p-8  shadow-md rounded-xl ml-5 mr-5 sm:ml-5">
                                  <h2 className="text-2xl font-bold mb-4 justify-center" style={{ width: "100%", display: "flex" }}>
                                    Cancel Plan
                                  </h2>
                                  <p className="text-lg">
                                    Are you sure you want to cancel plan ?
                                  </p>
                                  <div className="flex justify-center gap-10 mt-10">
                                    <button
                                      title="Cancel"
                                      className="bg-purple-700 text-white px-4 py-2 rounded mt-4 flex hover:bg-purple-300"
                                      style={{
                                        paddingTop: "10px",
                                        paddingLeft: "10px",
                                        paddingRight: "10px",
                                        paddingBottom: "10px",
                                        width: "40%",
                                        fontSize: "14px",
                                        justifyContent: "center",
                                        alignContent: "center",
                                        alignItems: "center",
                                      }}
                                      onClick={() => {
                                        planCancel(i?._id);
                                      }}
                                    >
                                      Cancel
                                    </button>
                                    <button
                                      title="Close"
                                      className="bg-slate-200 text-black px-4 py-2 rounded mt-4 flex hover:bg-slate-300"
                                      style={{
                                        paddingTop: "10px",
                                        paddingLeft: "10px",
                                        paddingRight: "10px",
                                        paddingBottom: "10px",
                                        width: "40%",
                                        fontSize: "14px",
                                        justifyContent: "center",
                                        alignContent: "center",
                                        alignItems: "center",
                                      }}
                                      onClick={closePopup}
                                    >
                                      Close
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div>
                <p className="text-lg text-gray-600 mt-20 mb-5 font-semibold">
                  No Plans Purchased
                </p>
                <button
                  title="Purchase Plan"
                  className="bg-purple-400 text-white py-3 px-4 rounded-xl text-nowrap w-50 hover:bg-purple-3000 mb-20 ml-6"
                >
                  Purchase Plan
                </button>
              </div>
            )}

            <div
              className="flex items-center justify-around  w-full flex-wrap px-5 gap-5 mb-10"

            >
              <div className="flex space-x-4 items-center">
                <img
                  src="/image/icon(2).png"
                  className="h-full"
                  alt="image"
                />
                <h1 style={{ color: showTheme("text") }}>Flexible Plan</h1>
              </div>
              <div className="flex space-x-4 items-center">
                <img
                  src="/image/icon(2).png"
                  className="h-full"
                  alt="image"
                />
                <h1 style={{ color: showTheme("text") }}>Change anytime</h1>
              </div>
              <div className="flex space-x-4 items-center">
                <img
                  src="/image/icon(2).png"
                  className="h-full"
                  alt="image"
                />
                <h1 style={{ color: showTheme("text") }}>Customer Support</h1>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PlanContent;
