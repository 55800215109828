import React, { useState, useEffect } from "react";
import { FiSearch } from "react-icons/fi";
import axios from "axios";
import { getToken } from "../../utils/getToken";
import Spinner from "../Spinner/Spinner";
import Pagination from '@mui/material/Pagination';
import { Close } from "@mui/icons-material";
import moment from "moment";

const Reports = () => {

  const [selectedReportType, setSelectedReportType] = useState("employee");
  const [employeeData, setEmployeeData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [leadData, setLeadData] = useState([]);
  const [clientData, setClientData] = useState([]);
  const [financialData, setFinancialData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(5);
  const [searchForAll, setSearchForAll] = useState('');
  const [sortKey, setSortKey] = useState('');
  const [dateValue, setDateValue] = useState('');

  const [query, setQuery] = useState({
    name: "",
    phone: "",
    email: "",
    id: "",
    duration: "",
    from: "",
    to: "",
    sortOrder: "",
  });
  const [isFilter, setIsFilter] = useState(false);

  const handleQueryChange = (e) => {
    let temp = { ...query };
    temp[e.target.name] = e.target.value;
    setQuery(temp);
  };

  /* Search FIlters */
  const handleQuery = (e) => {
    e.preventDefault();
    let tempQuery = "";
    if (query.name) {
      tempQuery += `&name=${query.name}`;
    }
    if (query.phone) {
      tempQuery += `&phone=${query.phone}`;
    }
    if (query.email) {
      tempQuery += `&email=${query.email}`;
    }
    if (query.id) {
      if (selectedReportType === "employee") {
        tempQuery += `&employeeId=${query.id}`;
      } else {
        tempQuery += `&memberId=${query.id}`;
      }
    }
    if (query.duration) {
      tempQuery += `&timeFilter=${query.duration}`;
    }
    if (query.from && query.to && query.duration === "custom") {
      tempQuery += `&startDate=${query.from}&endDate=${query.to}`;
    }
    if (query.sortOrder) {
      tempQuery += `&sortOrder=${query.sortOrder}`;
    }

    if (tempQuery !== "") {
      setIsFilter(true);
      handleFormSubmit(tempQuery);
    }
  };
  const handleReportTypeChange = (event) => {
    setCurrentPage(1)
    setRecordsPerPage(5)
    setSelectedReportType(event.target.value);
  };

  const handleFormSubmit = async (additionalQuery = "") => {

    try {
      const token = getToken();
      let response;
      let url;
      let keySort = '';
      let keyOrder = '';
      if (sortKey) {
        const a = sortKey.split(' ');
        const b = a[a.length - 2];
        const c = a[a.length - 1];
        keySort = b;
        keyOrder = c;
      }
      if (selectedReportType === "employee") {
        url = `${process.env.REACT_APP_BASE_URL}/api/reports/employees?page=${currentPage}&pageSize=${recordsPerPage}&keyword=${searchForAll}&sortOrder=${keyOrder}&sortKey=${keySort}&dateValue=${dateValue}`;
      } else if (selectedReportType === "lead") {
        url = `${process.env.REACT_APP_BASE_URL}/api/reports/leads?page=${currentPage}&pageSize=${recordsPerPage}&keyword=${searchForAll}&sortOrder=${keyOrder}&sortKey=${keySort}&dateValue=${dateValue}`;
      } else if (selectedReportType === "client") {
        url = `${process.env.REACT_APP_BASE_URL}/api/reports/clients?page=${currentPage}&pageSize=${recordsPerPage}&keyword=${searchForAll}&sortOrder=${keyOrder}&sortKey=${keySort}&dateValue=${dateValue}`;
      } else if (selectedReportType === "financial") {
        url = `${process.env.REACT_APP_BASE_URL}/api/reports/financial?page=${currentPage}&pageSize=${recordsPerPage}&keyword=${searchForAll}&sortOrder=${keyOrder}&sortKey=${keySort}&dateValue=${dateValue}`;
      }

      const fullUrl = `${url}${additionalQuery}`;

      response = await axios.get(fullUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (selectedReportType === "employee") {
        setEmployeeData(response.data.data);
      } else if (selectedReportType === "lead") {
        setLeadData(response.data.data);
      } else if (selectedReportType === "client") {
        setClientData(response.data.data);
      } else if (selectedReportType === "financial") {
        setFinancialData(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    } finally {
      setLoading(false);
    }
  };

  let start = (currentPage - 1) * recordsPerPage + 1

  useEffect(() => {
    handleFormSubmit("");
  }, [selectedReportType, currentPage, recordsPerPage, searchForAll, sortKey, dateValue]);


  const renderTable = () => {
    if (selectedReportType === "employee") {
      return <EmployeeTable data={employeeData?.employeeReportsData} />;
    } else if (selectedReportType === "lead") {
      return <LeadTable data={leadData?.leadReportsData} />;
    } else if (selectedReportType === "client") {
      return <ClientTable data={clientData?.clientReportsData} />;
    } else if (selectedReportType === "financial") {
      return <FinanicialTable data={financialData?.financialReportsData} />;
    }
  };

  const EmployeeTable = ({ data }) => (
    <table className="min-w-full mt-4 space-y-4 border border-gray-200 text-nowrap">
      <thead>
        <tr className="text-left" style={{ fontSize: "16px" }}>
          <th className="py-2 px-4">Name</th>
          <th className="py-2 px-4">Employee Id</th>
          <th className="py-2 px-4">Designation</th>
          <th className="py-2 px-4">Email</th>
          <th className="py-2 px-4">Phone</th>
          <th className="py-2 px-4">DOB</th>
          <th className="py-2 px-4">PAN Number</th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {data?.length > 0 &&
          data?.map((employee, index) => (
            <tr key={index} style={{ fontSize: "14px" }}>
              <td className="py-2 px-4">{employee?.name}</td>
              <td className="py-2 px-4">{employee?.employeeId}</td>
              <td className="py-2 px-4">{employee?.designation}</td>
              <td className="py-2 px-4">{employee?.userId?.email}</td>
              <td className="py-2 px-4">{employee?.userId?.phone}</td>
              <td className="py-2 px-4">
                {moment(employee?.dateOfBirth).format('DD/MM/YYYY')}
              </td>
              <td className="py-2 px-4">{employee?.panNumber}</td>
            </tr>
          ))}
      </tbody>
    </table>
  );

  const LeadTable = ({ data }) => (
    <table className="min-w-full mt-4 space-y-4 border border-gray-200 text-nowrap">
      <thead>
        <tr className="text-left" style={{ fontSize: "16px" }}>
          {/* <th className="py-2 px-4">Name</th> */}
          <th className="py-2 px-4">Member Id</th>
          {/* <th className="py-2 px-4">Address</th> */}
          <th className="py-2 px-4">Email</th>
          <th className="py-2 px-4">Phone</th>
          <th className="py-2 px-4">PAN Number</th>
          <th className="py-2 px-4">Status</th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {data?.length > 0 &&
          data?.map((lead, index) => (
            <tr key={index} style={{ fontSize: "14px" }}>
              {/* <td className="py-2 px-4">{lead.name}</td> */}
              <td className="py-2 px-4">{lead?.memberId}</td>
              {/* <td className="py-2 px-4">{lead.address}</td> */}
              <td className="py-2 px-4">{lead?.email}</td>
              <td className="py-2 px-4">{lead?.phone}</td>
              <td className="py-2 px-4">
                {lead?.panCard ? lead?.panCard?.number : ""}
              </td>
              <td className="py-2 px-4">{lead?.status}</td>
            </tr>
          ))}
      </tbody>
    </table>
  );

  const ClientTable = ({ data }) => (
    <table className="min-w-full mt-4 space-y-4 border border-gray-200 text-nowrap">
      <thead>
        <tr className="text-left" style={{ fontSize: "16px" }}>
          <th className="py-2 px-4">Member Id</th>
          <th className="py-2 px-4">Email</th>
          <th className="py-2 px-4">Phone</th>
          <th className="py-2 px-4">PAN Number</th>
          <th className="py-2 px-4">Status</th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {data?.length > 0 &&
          data?.map((client, index) => (
            <tr key={index} style={{ fontSize: "14px" }}>
              <td className="py-2 px-4">{client?.memberId}</td>
              <td className="py-2 px-4">{client?.email}</td>
              <td className="py-2 px-4">{client?.phone}</td>
              <td className="py-2 px-4">
                {client?.panCard ? client?.panCard?.number : ""}
              </td>
              <td className="py-2 px-4">{client?.status}</td>
            </tr>
          ))}
      </tbody>
    </table>
  );

  const FinanicialTable = ({ data }) => (
    <table className="min-w-full mt-4 space-y-4 border border-gray-200 text-nowrap">
      <thead>
        <tr className="text-left" style={{ fontSize: "16px" }}>
          <th className="py-2 px-4">Email</th>
          <th className="py-2 px-4">Phone</th>
          <th className="py-2 px-4">User Type</th>
          <th className="py-2 px-4">Amount</th>
          <th className="py-2 px-4">Transaction Type</th>
          <th className="py-2 px-4">Status</th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {data?.length > 0 &&
          data?.map((financial, index) => (
            <tr key={index} style={{ fontSize: "14px" }}>
              <td className="py-2 px-4">{financial?.userId?.email}</td>
              <td className="py-2 px-4">{financial?.userId?.phone}</td>
              <td className="py-2 px-4">{financial?.userId?.userType}</td>
              <td className="py-2 px-4">{financial?.amount}</td>
              <td className="py-2 px-4">{financial?.transactionType}</td>
              <td className="py-2 px-4">{financial?.status}</td>
            </tr>
          ))}
      </tbody>
    </table>
  );
  const handleReset = () => {
    setDateValue('');
    setSortKey('');
    setSearchForAll('')
  }
  return (
    <div className="bg-white rounded-2xl shadow-md mb-8 ml-3 mr-3 sm:ml-3 border-style p-2"
    >
      {loading ? (
        <div style={{ display: "flex", marginTop: "20%" }} className="flex w-full justify-center"> <Spinner /></div>
      ) : (
        <div >
          <div>
            <h1 className="text-xl font-semibold ml-4 text-black">Reports</h1>
            <div className="flex items-center mt-5 mb-4">
              <label className="block text-gray-700 text-xl ml-4  mr-2">
                Report Type:
              </label>
              <select
                value={selectedReportType}
                onChange={handleReportTypeChange}
                className="p-2 border rounded-md bg-white"
              >
                <option
                  value=""
                  style={{
                    backgroundColor: selectedReportType === "" ? "#7C35F1" : "",
                    color: selectedReportType === "" ? "white" : "",
                  }}
                >
                  Select Report Type
                </option>
                <option
                  value="employee"
                  style={{
                    backgroundColor:
                      selectedReportType === "employee" ? "#7C35F1" : "",
                    color: selectedReportType === "employee" ? "white" : "",
                  }}
                >
                  Employee
                </option>
                <option
                  value="lead"
                  style={{
                    backgroundColor: selectedReportType === "lead" ? "#7C35F1" : "",
                    color: selectedReportType === "lead" ? "white" : "",
                  }}
                >
                  Lead
                </option>
                <option
                  value="client"
                  style={{
                    backgroundColor:
                      selectedReportType === "client" ? "#7C35F1" : "",
                    color: selectedReportType === "client" ? "white" : "",
                  }}
                >
                  Client
                </option>
                <option
                  value="financial"
                  style={{
                    backgroundColor:
                      selectedReportType === "financial" ? "#7C35F1" : "",
                    color: selectedReportType === "financial" ? "white" : "",
                  }}
                >
                  Financial
                </option>
              </select>
            </div>
            <div className="combined-input-container mt-3">
              <div className="relative combined-input w-full ">
                <input
                  className="focus:outline-none"
                  type="text"
                  placeholder="Search here..."
                  id="id"
                  autoFocus
                  name="searchForAll"
                  value={searchForAll}
                  onChange={(e) => {
                    setSearchForAll(e.target.value);
                  }}
                />
                <div className="right-0 top-0  absolute flex">
                  <div
                    className="w-full text-purple-700 hover:text-purple-800 font-semibold border-0 px-4 pt-1 mt-auto -mb-0.5"
                    onClick={() => {
                      setSearchForAll('');
                    }}
                  ><Close /></div>
                </div>
              </div>
              <div className="w-full combined-input-2">
                <input
                  type="date"
                  className="focus:outline-none w-full"
                  id="id"
                  name="dateValue"
                  value={dateValue}
                  onChange={(e) => {
                    setDateValue(e.target.value);
                  }}
                />
              </div>
              <div className=" combined-input-3 flex whitespace-nowrap gap-2 items-center">
                <select className="focus:border-purple-500 focus:ring-2 focus:ring-purple-200 focus:outline-none"
                  // disabled={isFilter}
                  name="sortKey"
                  value={sortKey}
                  onChange={(e) => setSortKey(e.target.value)}>
                  <option value=''>Sort</option>
                  <option value='name DESC'>A-Z</option>
                  <option value='name ASC'>Z-A</option>
                  <option value='createdAt DESC'>Oldest First</option>
                  <option value='createdAt ASC'>Newest First</option>
                </select>
              </div>
            </div>
            <div>
              <button className="text-red-600" onClick={handleReset}>Reset</button>
            </div>
          </div>

          {/*Filters*/}

          <div className=" overflow-auto">
            <div className="flex">
            </div>
            {renderTable()}
          </div>
        </div>
      )}
      {
        !loading ?
          selectedReportType === "employee" ?
            <div className="flex flex-wrap items-center gap-4 justify-between mt-6 max-[700px]:justify-start max-[700px]:gap-0 max-[700px]:text-xs">
              <div className="text-purple-700">
                {`${start}-${employeeData?.noOfEnteries} of ${employeeData?.noOfEnteries} items`}
              </div>
              <div className="flex items-center gap-4 justify-center">
                <Pagination count={Math.ceil(employeeData?.noOfEnteries / recordsPerPage)} size="large" showFirstButton showLastButton page={currentPage} onChange={(event, value) => { setCurrentPage(value) }} />
              </div>
              <div>
                <select className="border rounded p-1" value={recordsPerPage} onChange={(e) => { setRecordsPerPage(e.target.value) }}>
                  {/* {
                  Array.from({ length: 10 }).map((_, index) =>
                    <option value={index + 1}>{index + 1}</option>
                  )} */}
                  <option value='5'>Show 5</option>
                  <option value='10'>Show 10</option>
                  <option value='15'>Show 15</option>
                </select>
                {/* <span className="text-purple-700"> items per page</span> */}
              </div>
            </div>
            :
            selectedReportType === "lead" ?
              <div className="flex flex-wrap items-center gap-4 justify-between mt-6 max-[700px]:justify-start max-[700px]:gap-0 max-[700px]:text-xs">
                <div className="text-purple-700">
                  {`${start}-${leadData?.noOfEnteries} of ${leadData?.noOfEnteries} items`}
                </div>
                <div className="flex items-center gap-4 justify-center">
                  <Pagination count={Math.ceil(leadData?.noOfEnteries / recordsPerPage)} size="large" showFirstButton showLastButton page={currentPage} onChange={(event, value) => { setCurrentPage(value) }} />
                </div>
                <div>
                  <select className="border rounded p-1" value={recordsPerPage} onChange={(e) => { setRecordsPerPage(e.target.value) }}>
                    {/* {
                  Array.from({ length: 10 }).map((_, index) =>
                    <option value={index + 1}>{index + 1}</option>
                  )} */}
                    <option value='5'>Show 5</option>
                    <option value='10'>Show 10</option>
                    <option value='15'>Show 15</option>
                  </select>
                  {/* <span className="text-purple-700"> items per page</span> */}
                </div>
              </div>
              :
              selectedReportType === "client" ?
                <div className="flex flex-wrap items-center gap-4 justify-between mt-6 max-[700px]:justify-start max-[700px]:gap-0 max-[700px]:text-xs">
                  <div className="text-purple-700">
                    {`${start}-${clientData?.noOfEnteries} of ${clientData?.noOfEnteries} items`}
                  </div>
                  <div className="flex items-center gap-4 justify-center">
                    <Pagination count={Math.ceil(clientData?.noOfEnteries / recordsPerPage)} size="large" showFirstButton showLastButton page={currentPage} onChange={(event, value) => { setCurrentPage(value) }} />
                  </div>
                  <div>
                    <select className="border rounded p-1" value={recordsPerPage} onChange={(e) => { setRecordsPerPage(e.target.value) }}>
                      {/* {
                  Array.from({ length: 10 }).map((_, index) =>
                    <option value={index + 1}>{index + 1}</option>
                  )} */}
                      <option value='5'>Show 5</option>
                      <option value='10'>Show 10</option>
                      <option value='15'>Show 15</option>
                    </select>
                    {/* <span className="text-purple-700"> items per page</span> */}
                  </div>
                </div>
                :
                <div className="flex flex-wrap items-center gap-4 justify-between mt-6 max-[700px]:justify-start max-[700px]:gap-0 max-[700px]:text-xs">
                  <div className="text-purple-700">
                    {`${start}-${financialData?.noOfEnteries} of ${financialData?.noOfEnteries} items`}
                  </div>
                  <div className="flex items-center gap-4 justify-center">
                    <Pagination count={Math.ceil(financialData?.noOfEnteries / recordsPerPage)} size="large" showFirstButton showLastButton page={currentPage} onChange={(event, value) => { setCurrentPage(value) }} />
                  </div>
                  <div>
                    <select className="border rounded p-1" value={recordsPerPage} onChange={(e) => { setRecordsPerPage(e.target.value) }}>
                      {/* {
                Array.from({ length: 10 }).map((_, index) =>
                  <option value={index + 1}>{index + 1}</option>
                )} */}
                      <option value='5'>Show 5</option>
                      <option value='10'>Show 10</option>
                      <option value='15'>Show 15</option>
                    </select>
                    {/* <span className="text-purple-700"> items per page</span> */}
                  </div>
                </div>
          :
          <></>
      }
    </div>
  );
};

export default Reports;
