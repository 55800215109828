import Login from "./Components/Login";
import Signup from "./Components/Signup";
import Pancard from "./Components/Pancard";
import LoginOtp from "./Components/LoginOtp";
import AccountCreated from "./Components/AccountCreated";
import Signupotp from "./Components/Signupotp";
import Admin from "./Components/Admin/Admin";
import Landing from "./Components/UserPanel/TopbarPages/Landing";
import Dashboard1 from "./Components/UserPanel/Dashboard/Dashboard";
import Funds from "./Components/UserPanel/TopbarPages/Funds";
import Progress from "./Components/UserPanel/TopbarPages/Progress";
import Setting from "./Components/UserPanel/TopbarPages/Setting";
import Profile from "./Components/UserPanel/SidebarPages/Profile";
import BankList from "./Components/UserPanel/SidebarPages/Bank/BankList";
import BankAccount from "./Components/UserPanel/SidebarPages/Bank/BankAccount";
import Transaction from "./Components/UserPanel/SidebarPages/Transaction";
import Achievement from "./Components/UserPanel/SidebarPages/Achievement";
import Assurance from "./Components/UserPanel/SidebarPages/Assurance";
import UserPlans from "./Components/UserPanel/SidebarPages/Plans";
import ContactUs from "./Components/UserPanel/SidebarPages/ContactUs";
import AdminDashboard from "./Components/AdminSlidbar/Dashboard";
import FinancialData from "./Components/AdminSlidbar/FinancialData";
import SupportDesk from "./Components/AdminSlidbar/SupportDesk";
import Reports from "./Components/AdminSlidbar/Reports";
import Member from "./Components/AdminSlidbar/Member/Member";
import Client from "./Components/AdminSlidbar/Client/Client";
import Notification from "./Components/AdminSlidbar/Notification";
import AdminPlans from "./Components/AdminSlidbar/Plans";
import Leads from "./Components/AdminSlidbar/Leads";
import Faq from "./Components/AdminSlidbar/Faq";
import AddFunds from "./Components/UserPanel/TopbarPages/plan/AddFunds";
import PlanContent from "./Components/UserPanel/TopbarPages/plan/PlanContent";
import PlanIndex from "./Components/UserPanel/TopbarPages/plan";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import AuthProvider, { useAuth } from "./Components/Context/AuthProvider ";
import { ProtectedRoute } from "./ProtectedRoute";
import Snotification from "./Components/UserPanel/SidebarPages/Snotification";
import UserFaq from "./Components/UserPanel/SidebarPages/UserFaq";
import Scontact from "./Components/UserPanel/SidebarPages/Scontact";
import Sprivacy from "./Components/UserPanel/SidebarPages/Sprivacy";
import Stermandcondition from "./Components/UserPanel/SidebarPages/Stermandcondition";
import styled from "styled-components";
import { ThemeContext } from "./Components/Context/ThemeContext";
import { useContext } from "react";
import { GlobalStyles } from "./Components/Theme/global";
import { showTheme } from "./Components/Theme/Theme";
import NotFound from "./Components/UserPanel/Common/NotFound";

const Routes = ({ toggleTheme }) => {
  
  const { token } = useAuth();

  const routesForAuthenticatedOnly = [
  
       {
        path: "/",
        element: <ProtectedRoute />,
        children: [
          {
            path: "/",
            element: <Admin />,
            children: [
                            {
                path: "admin-dashboard",
                element: <AdminDashboard />,
              },
              {
                path: "financial-data",
                element: <FinancialData />,
              },
              {
                path: "financial-data/:receiverID",
                element: <FinancialData />,
              },
              {
                path: "support-desk",
                element: <SupportDesk />,
              },
              {
                path: "support-desk/:receiverID",
                element: <SupportDesk />,
              },
              {
                path: "reports",
                element: <Reports />,
              },
              {
                path: "member",
                element: <Member />,
              },
              {
                path: "clients",
                element: <Client />,
              },
              {
                path: "clients/:receiverID",
                element: <Client />,
              },
            
              {
                path: "notification",
                element: <Notification />,
              },
              
              {
                path: "plans",
                element: <AdminPlans />,
              },
              
              {
                path: "leads",
                element: <Leads />,
              },
                           {
                path: "faq",
                element: <Faq />,
              },
              {
                path: "*",
                element: <NotFound />,
              },
            ],
          },
        ],
      },
       {
        path: "/",
        element: <ProtectedRoute />,
        children: [
          {
            path: "/",
            element: <Dashboard1 toggleTheme={toggleTheme} />,
            children: [
              {
                path: "dashboard",
                element: <Landing />,
              },
              {
                path: "index-plan",
                element: <PlanIndex />,
              },
             
              {
                path: "add-funds/:userplanid",
                element: <AddFunds />,
              },
              {
                path: "plan-content",
                element: <PlanContent />,
              },
              {
                path: "funds",
                element: <Funds />,
              },

              {
                path: "progress",
                element: <Progress />,
              },
              {
                path: "settings",
                element: <Setting />,
              },
              // sidebar pages
              {
                path: "profile",
                element: <Profile />,
              },
              {
                path: "transactions",
                element: <Transaction />,
              },
              {
                path: "achievements",
                element: <Achievement />,
              },
              
              {
                path: "assurance",
                element: <Assurance />,
              },
              {
                path: "userplans",
                element: <UserPlans />,
              },
              {
                path: "contact",
                element: <ContactUs />,
              },
              {
                path: "bank",
                element: <BankAccount />,
              },
              {
                path: "bank-list",
                element: <BankList />,
              },
              {
                path: "user-faq",
                element: <UserFaq />,
              },
              {
                path: "setting-notifications",
                element: <Snotification />,
              },
              {
                path: "setting-contact",
                element: <Scontact />,
              },
              {
                path: "setting-privacy",
                element: <Sprivacy />,
              },
              {
                path: "setting-term-and-condition",
                element: <Stermandcondition />,
              },
            ],
          },
          {
            path: "/pancard",
            element: <Pancard />,
          },
          {
            path: "/account",
            element: <AccountCreated />,
          },
        ],
      },
  ];

  // Define routes accessible only to non-authenticated users
  const routesForNotAuthenticatedOnly = [
    {
      path: "/",
      element: <Login />,
    },
    {
      path: "/otp/:userId",
      element: <LoginOtp />,
    },
    {
      path: "/signup",
      element: <Signup />,
    },
    {
      path: "/sotp",
      element: <Signupotp />,
    },


  ];

  // Combine and conditionally include routes based on authentication status
  const router = createBrowserRouter([
    // ...routesForPublic,
    ...(!token ? routesForNotAuthenticatedOnly : []),
    ...routesForAuthenticatedOnly,
  ]);

  // Provide the router configuration using RouterProvider
  return <RouterProvider router={router} />;
};
const Wrapper = styled.div`
  background-color: ${(props) => props.theme.background};
  color: ${(props) => props.theme.text};
  width: 100vw !important;
`;

function App() {
  const { theme, toggleTheme } = useContext(ThemeContext);
  // const backgroundColor = showTheme("bg");

  return (
    <AuthProvider>
      <Wrapper>
        <GlobalStyles
          style={{ backgroundColor: showTheme("bg"), color: showTheme("text") }}
        />
        <Routes />
      </Wrapper>
    </AuthProvider>
  );
}

export default App;
