import React, { useState, useEffect } from "react";
import { FiSearch } from "react-icons/fi";
import axios from "axios";
import { getToken } from "../../../utils/getToken.js";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import {
  getAllPlan,
  purchasePlan,
  getAllUserPlan,
} from "../../../Services/PlanServices.js";

import { useQuery } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { MdOutlineClose } from "react-icons/md";
import Spinner from "../../Spinner/Spinner.jsx";
import { showTheme } from "../../Theme/Theme.js";

function UserPlans() {
  const navigate = useNavigate();
  const [amount, setAmount] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [popup, setPopup] = useState(false);
  const [selectedPlanId, setSelectedPlanId] = useState(null);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [amountValid, setAmountValid] = useState(null);
  const [mobileValid, setMobileValid] = useState('');
  const [plansData, setPlansData] = useState(null);

  const { data: plans, isLoading } = useQuery({
    queryFn: getAllUserPlan,
    queryKey: ["plan_details"],
  });
  useEffect(() => {
    if (plans?.plans?.length > 0) {
      const a = plans?.plans?.filter(i => i?.isActive);
      setPlansData(a);
    }
  }, [plans])
  const handleAddFundsClick = (id, index) => {
    setSelectedPlanId(id);
    setPopup(index);
  };

  const handleToggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const myPlanClick = () => {
    navigate("/index-plan")
  }
  const addPurchasePlan = async () => {
    if (!selectedPlanId) {
      toast.error("Please select a plan before adding funds");
      return;
    }

    const dataToSent = {
      amount: amount,
    };
    try {
      setLoading(true);
      const { data } = await purchasePlan(selectedPlanId, dataToSent);
      toast.success("Plan Purchased");
      setSelectedPlanId(null);
      navigate("/index-plan");
    } catch (err) {
      toast.error(err.response.data.message);
    } finally {
      setLoading(false);
    }
  };
  const splitFileLink = (a) => {
    const x = a.split('/');
    const y = x[x.length - 1];
    const z = y.split('_');
    let kk = z.slice(1);
    const b = kk.join('');
    return decodeURIComponent(b);
  }

  return (

    <div>
      {isLoading ? (
        <div className="flex justify-center " style={{ marginTop: "35vh" }}>
          <Spinner />
        </div>
      ) : (
        <div className="bg-white rounded-2xl shadow-md border pb-3 ml-3 mr-3 sm:ml-3 border-style"
          style={{

            backgroundColor: showTheme("card"),
            border: showTheme("borderCard"),
          }}>
          <ToastContainer />

          <h1 className="text-3xl ml-4 font-semibold md:pb-5" style={{ marginTop: "15px", color: showTheme("text"), marginLeft: "20px" }}>All Plans</h1>
          <div className="overflow-x-auto">
            <div className="flex-col flex-wrap">
              {
                plansData?.length > 0 ?
                  plansData?.map((plan, index) => (
                    <div className="w-full p-4 rounded-xl mr-10" key={index}>
                      <div className="rounded-lg shadow-md p-6 flex items-center justify-center flex-col w-full max-w-[1040px] mx-auto sm:w-full" style={{ border: showTheme("borderCard"), backgroundColor: showTheme("topHeader") }}>
                        <h2 className="text-2xl font-semibold mb-2" style={{ color: showTheme("text") }}>
                          {plan.planName}
                        </h2>
                        <p className="mb-4 text-center" style={{ color: showTheme("text") }}>
                          {plan.returnPercent}% / {plan.description}
                        </p>

                        {plan.features.map(
                          (i, featureIndex) =>
                            i && (
                              <div
                                className="flex gap-2 items-center justify-center pe-5 w-full"
                                key={featureIndex}
                              >
                                <img
                                  src="/image/Groupplans.svg"
                                  alt="error"
                                  className="w-5 mb-2 justify-center h-5 "
                                />
                                <h5 className="text-nowrap mb-2" style={{ color: showTheme("text") }}>{i}</h5>
                              </div>
                            )
                        )}

                        {plan.isPurchased ? (
                          <div>
                            <p
                              className="text-green-500 flex"
                              style={{ fontWeight: "bold", marginInline: "center", alignItems: "center", justifyContent: "center" }}
                            >
                              Plan Already Purchased
                            </p>
                            <div className="text-center">
                              <button
                                title="My Plans"
                                className="bg-purple-600 item-center justify-center text-center text-white p-2 rounded-xl max-[400px]:w-full w-64 mx-auto hover:bg-purple-500 mt-3"
                                onClick={() => myPlanClick()}
                              >
                                My Plans
                              </button>
                            </div>
                          </div>
                        ) : (
                          <div>
                            <button
                              title="Add Funds"
                              className="bg-purple-600 item-center justify-center text-center text-white p-2 rounded-xl max-[400px]:w-full w-64 mx-auto hover:bg-purple-500 mt-3"
                              onClick={() => { handleAddFundsClick(plan._id, index); setAmountValid(plan?.minimumSubscriptionAmount) }}
                            >
                              + Add Funds
                            </button>
                            {popup === index && (
                              <div className="fixed inset-0 z-50 overflow-auto flex items-center justify-center backdrop-blur-lg bg-gray-900 bg-opacity-20">
                                {!confirmPopup ? (
                                  <div
                                    className="rounded-xl flex flex-col"
                                    style={{ height: "auto", backgroundColor: showTheme("card") }}
                                  >
                                    <div className="p-8 pt-0 text-center">
                                      <div className="flex justify-between">
                                        <h2
                                          className="text-3xl text-xl font-bold mb-4 text-center justify-center flex whitespace-nowrap mt-5"
                                          style={{
                                            alignItems: "center",
                                            marginInline: "center",
                                            width: "100%",
                                            marginLeft: "10px",
                                            color: showTheme("text")
                                          }}
                                        >
                                          Add Amount
                                        </h2>
                                        <MdOutlineClose
                                          onClick={() => setPopup(false)}
                                          className="mt-5"
                                          style={{
                                            fontSize: "25px",
                                            justifyContent: "center",
                                            marginInline: "center",
                                            color: showTheme("text")
                                          }}
                                        />
                                      </div>

                                      <input
                                        type="text"
                                        value={amount}
                                        placeholder="Enter The Amount"
                                        maxlength="8"
                                        min="0"
                                        onChange={(e) => {
                                          setAmount(e.target.value);
                                          if (!/^\d+$/.test(e.target.value)) {
                                            setMobileValid('Amount can only contain numbers');
                                          } else {
                                            setMobileValid('');
                                          }

                                        }}
                                        className="w-full border border-gray-400 rounded pl-1 p-1 py-2 focus:ring focus:ring-purple-100 focus:outline-none mb-2"
                                        style={{
                                          paddingTop: "15px",
                                          paddingLeft: "15px",
                                          paddingRight: "15px",
                                          paddingBottom: "15px",
                                          backgroundColor: showTheme("topHeader"),
                                          border: showTheme("fieldBorder"),
                                          color: showTheme("text")
                                        }}
                                      />
                                      {mobileValid && <div style={{ color: 'red' }} className="text-xs">{mobileValid}</div>}
                                      <div className={`text-start  text-xs w-full ${parseInt(amount) < parseInt(plan?.minimumSubscriptionAmount) ? 'text-red-500' : 'text-slate-900'}`} style={{ color: showTheme("text") }}>Minimum Investments Amount : {plan?.minimumSubscriptionAmount}</div>

                                      <button
                                        onClick={() => { setConfirmPopup(true) }}
                                        className="bg-purple-500 text-white py-2 px-4 rounded-lg hover:bg-purple-600 mt-5"
                                        style={{
                                          paddingTop: "10px",
                                          paddingLeft: "10px",
                                          paddingRight: "10px",
                                          paddingBottom: "10px",
                                          width: "40%",
                                          fontSize: "24px",
                                          width: "100%",
                                        }}
                                        disabled={parseInt(amount) < parseInt(amountValid) || mobileValid}
                                        title={parseInt(amount) < parseInt(amountValid) || mobileValid && 'disable'}
                                      >
                                        Add
                                      </button>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="bg-white rounded-xl flex flex-col  ml-5 mr-5 sm:ml-5" style={{ backgroundColor: showTheme("card") }}>
                                    <div className="flex justify-end pb-0 p-3"></div>
                                    <div className="p-8 pt-0 text-center">
                                      <h2
                                        className="text-xl font-semibold mb-10 mt-10 text-center"
                                        style={{
                                          paddingLeft: "20px",
                                          paddingRight: "20px",
                                          color: showTheme("text")
                                        }}
                                      >
                                        Are you sure you want to continue?
                                      </h2>
                                      <div
                                        className="flex justify-center space-x-4 mt-10"
                                        style={{
                                          paddingTop: "20px",
                                          paddingBottom: "20px",
                                          gap: "10px",
                                        }}
                                      >
                                        <button
                                          onClick={addPurchasePlan}
                                          className="bg-purple-700 text-white py-2 px-4 rounded-lg hover:bg-purple-600"
                                          style={{
                                            paddingTop: "20px",
                                            paddingLeft: "20px",
                                            paddingRight: "20px",
                                            paddingBottom: "20px",
                                            width: "40%",
                                            fontSize: "15px",
                                          }}
                                        >
                                          {
                                            loading ?
                                              <div class="three-body-button">
                                                <div class="three-body__dot"></div>
                                                <div class="three-body__dot"></div>
                                                <div class="three-body__dot"></div>
                                              </div>
                                              :
                                              'Yes'
                                          }
                                        </button>
                                        <button
                                          onClick={() => setConfirmPopup(false)}
                                          className="bg-purple-200 text-black py-2 px-4 rounded-lg hover:bg-purple-300"
                                          style={{
                                            paddingTop: "20px",
                                            paddingLeft: "20px",
                                            paddingRight: "20px",
                                            paddingBottom: "20px",
                                            width: "40%",
                                            fontSize: "15px",
                                          }}
                                        >
                                          No
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        )}

                        {plan?.termsAndConditionsDocumentLink &&
                          <div className="flex flex-wrap justify-center items-center text-xs lg:text-sm xl:text-sm 2xl:text-sm gap-1 ">
                            <label style={{ color: showTheme("text") }}>Terms & Condition file:</label>
                            <Link to={`${plan?.termsAndConditionsDocumentLink}`}>
                              <div className="flex gap-2 justify-center items-center w-full">
                                <div className="text-blue-500">Click here to download</div>
                                {/* <div className="text-xs">{splitFileLink(plan?.termsAndConditionsDocumentLink)}</div> */}
                              </div>
                            </Link>
                          </div>
                        }
                        <div className="flex items-center space-x-4 mt-5">
                          <img
                            src="/image/Vector.svg"
                            className="w-5 h-5"
                            alt="error"
                          />
                          <div className="text-xs" style={{ color: showTheme("text") }}>
                            Minimum Investments Amount :
                            <span> {plan?.minimumSubscriptionAmount}</span>
                          </div>
                        </div>

                      </div>


                    </div>
                  ))
                  :
                  <div>
                    No Plan added
                  </div>
              }
            </div>
          </div>
        </div>
      )}
    </div>

  );
}

export default UserPlans;
