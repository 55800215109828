import React from 'react'
import Profile from '../SidebarPages/Profile';
const Setting = () => {
    return (
        <>
            <Profile />
        </>
    )
};

export default Setting
