import React, { useState, useEffect } from 'react';
import TopHeader from "../Common/TopHeader";
import Sidebar from "../Common/Sidebar";
import { useLocation, useNavigate } from 'react-router-dom';

const Dashboard = ({toggleTheme}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState('dashboard');
  const [sidebarContent, setSidebarContent] = useState('default');
  const [highlightTab, setHighlightTab] = useState("dashboard");
  
  const handleSettingsClick = () => {
    setActiveTab('settings');
    
    setSidebarContent('settings');
    
  };

  const handleSettingsClick1 = () => {
    setSidebarContent('default');
    
  };
  useEffect(() => {
    if(location.pathname.includes("/profile")){
      navigate('/profile');
    }
   
  }, [navigate]);
  
  

  return (
    <container className="bg-gray-100 container">
      <TopHeader handleSettingsClick={handleSettingsClick} handleSettingsClick1={handleSettingsClick1} toggleTheme={toggleTheme} activeTab={highlightTab} setActiveTab={setHighlightTab}/>
      <Sidebar content={sidebarContent} activeTab={highlightTab} setActiveTab={setHighlightTab} />
    </container>
  );
};

export default Dashboard;


