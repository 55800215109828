import { authApi, defaultApi } from "./api";
import { getToken } from "../utils/getToken";

export async function getAdminTickets(page, size, searchForAll, sortKey, dateValue) {
  let keySort = '';
  let keyOrder = '';
  if (sortKey) {
    const a = sortKey.split(' ');
    const b = a[a.length - 2];
    const c = a[a.length - 1];
    keySort = b;
    keyOrder = c;
  }
  const res = await defaultApi.get(`/api/ticket/admin/get-ticket?page=${page}&pageSize=${size}&keyword=${searchForAll}&sortOrder=${keyOrder}&sortKey=${keySort}&dateValue=${dateValue}`);
  // const res = await defaultApi.get(`/api/ticket/admin/get-ticket?page=${page}&pageSize=${size}&solveStatus=${solveStatus}&name=${name}&ticketId=${ticketId}`);
  return res.data.data;
}

export function createTicket(data) {
  return defaultApi.post(`/api/ticket/create`, data);
}

export async function getUserTickets(page, size) {
  const res = await defaultApi.get(`/api/ticket/user/get-ticket?page=${page}&pageSize=${size}`);
  // const res = await defaultApi.get(`/api/ticket/user/get-ticket?page=${page}&pageSize=${size}&solveStatus=${solveStatus}&name=${name}&ticketId=${ticketId}`);
  return res.data.data;
}

export function updateTicket(ticketId, data) {
  return defaultApi.put(`/api/ticket/update/${ticketId}`, data);
}

export async function getDashboard({ queryKey }) {
  const { 1: page = 1 } = queryKey;
  const res = await defaultApi.get('/api/ticket/get/dashboard');
  return res.data.data;
}







