import React from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { getTotalInvestment } from "../../../Services/PlanServices";
import Spinner from "../../Spinner/Spinner";
import { showTheme } from "../../Theme/Theme";
import TimelineIcon from '@mui/icons-material/Timeline';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';

const TotalInvestment = ({ setShowPopup, showPopup }) => {
  // const [loading, setLoading] = useState(false);

  const {
    data: totalInvestment,
    refetch,
    status,
    error,
    isLoading,
  } = useQuery({
    queryFn: getTotalInvestment,
    queryKey: ["total_investment"],
  });
  if (status === "error") {
    return <span>Error: {error.message}</span>;
  }

  return (
    <div>
      {isLoading ? (
        <div className="flex justify-center" style={{ marginTop: "35vh" }}>
          <Spinner />
        </div>
      ) : (
        <div className="shadow-md py-4 px-8 rounded-2xl mt-5 mr-5 " style={{ backgroundColor: showTheme("card"), border: showTheme("borderCard") }}>
          <h1 className="text-lg lg:text-xl xl:text-2xl font-bold mb-5 mt-5" style={{ color: showTheme("text") }}>Total Investments</h1>
          <p className="text-xl md:text-2xl lg:text-3xl font-bold mb-3" style={{ color: showTheme("text") }}>
            ₹ {Number(totalInvestment?.totalInvestmentBalance).toFixed(2)}
          </p>
          <p className="text-base md:text-lg lg:text-xl mb-5" style={{ color: showTheme("text") }}>
            You add{" "}
            <span className="text-green-500">
              +₹{Number(totalInvestment?.monthlyInvestment).toFixed(2)}
            </span>{" "}
            this month
          </p>
          {/* <div className="flex flex-col md:flex-row items-center md:items-start">
        <Link to={`/index-plan`} className="mb-3 md:mb-0 mr-0 md:mr-2">
          <button className="bg-purple-700 text-white rounded-lg px-4 py-2">
            Add Money
          </button>
        </Link>
        <button className="bg-slate-200 text-black rounded-lg px-4 py-2" onClick={() => setShowPopup(true)}>
          Withdrawal
        </button>
      </div> */}

          <div className="mt-24 flex flex-col md:flex-row justify-between gap-10 w-full mb-7">
            <div className="flex flex-col md:flex-row gap-2 py-4 shadow-lg space-x-10 border p-3 rounded-md relative w-full" style={{ backgroundColor: showTheme("cardBack"), border: showTheme("bordersmallCard") }}>
              <div className="flex items-center gap-5">
                <h1 className="text-sm md:text-lg xl:text-xl ml-2" style={{ color: showTheme("text") }}>2024 (Added)</h1>
                <TimelineIcon style={{color:"green"}} />
              </div>
              <div className="flex items-center gap-5 md:flex-grow">
                <h1 className="text-lg md:text-xl xl:text-2xl md:mr-2 ml-auto" style={{ color: showTheme("text") }}>₹ {Number(totalInvestment?.totalInvestmentOfCurrentYear).toFixed(2)}</h1>
              </div>
            </div>

            <div className="flex flex-col md:flex-row gap-2 py-4 shadow-lg border p-3 rounded-md relative w-full" style={{ backgroundColor: showTheme("cardBack"), border: showTheme("bordersmallCard") }}>
              <div className="flex items-center gap-5">
                <h1 className="text-sm md:text-lg xl:text-xl ml-2" style={{ color: showTheme("text") }}>2024 (Withdrawal)</h1>
                <TrendingDownIcon style={{color:"red"}} />
              </div>
              <div className="flex items-center gap-5 md:flex-grow">
                <h1 className="text-lg md:text-xl xl:text-2xl md:mr-2 ml-auto" style={{ color: showTheme("text") }}>₹ {Number(totalInvestment?.totalWithdrawalOfCurrentYear).toFixed(2)}</h1>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>

  );
};

export default TotalInvestment;
