import React, { useState } from "react";
import { FiArrowRight, FiSearch } from "react-icons/fi";
import ReactSwitch from "react-switch";
import { IoIosArrowBack } from "react-icons/io";
import { createEmployee } from "../../../Services/EmployeeServices";
import { toast, ToastContainer } from 'react-toastify';
import Spinner from "../../Spinner/Spinner";
import { verifyPancard } from "../../../Services/UserServices";

function AddMember({ setShowAddMember, handleMember }) {
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [panCardNumber, setPanCardNumber] = useState('');
    const [address, setAddress] = useState('');
    const [department, setDepartment] = useState('');
    const [designation, setDesignation] = useState('');
    const [dob, setDob] = useState("");
    const [loading, setLoading] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [error, setError] = useState('');
    const [errors, setErrors] = useState('');
    const [panName, setPanName] = useState('');
    const [checked0, setChecked0] = useState(false);
    const [checked1, setChecked1] = useState(false);
    const [checked2, setChecked2] = useState(false);
    const [checked3, setChecked3] = useState(false);

    const [checked4, setChecked4] = useState(false);
    const [checked5, setChecked5] = useState(false);
    const [checked6, setChecked6] = useState(false);
    const [checked7, setChecked7] = useState(false);
    const [checked8, setChecked8] = useState(false);
    const [checked9, setChecked9] = useState(false);
    const [errorPan, setErrorPan] = useState(false);

    const handleChange0 = (val) => {
        setChecked0(val);
    };
    const handleChange1 = (val) => {
        setChecked1(val);
    };
    const handleChange2 = (val) => {
        setChecked2(val);
    };
    const handleChange3 = (val) => {
        setChecked3(val);
    };
    const handleChange4 = (val) => {
        setChecked4(val);
    };
    const handleChange5 = (val) => {
        setChecked5(val);
    };
    const handleChange6 = (val) => {
        setChecked6(val);
    };
    const handleChange7 = (val) => {
        setChecked7(val);
    };
    const handleChange8 = (val) => {
        setChecked8(val);
    };

    const handleChange9 = (val) => {
        setChecked9(val);
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        const errors = {};
        if (!panName) {
            toast.error('Pan details not found');
            return;
        }
        if (errorPan) {
            toast.error('Pan details not found');
            return;
        }
        // if (!fullName.trim()) errors.fullName = "Full name is required";
        if (!email.trim()) errors.email = "Email is required";
        else if (!/\S+@\S+\.\S+/.test(email.trim()))
            errors.email = "Invalid email format";
        if (!mobileNumber.trim()) errors.mobileNumber = "Mobile number is required";
        else if (!/^\d{10}$/.test(mobileNumber.trim()))
            errors.mobileNumber = "Mobile number must be 10 digits";
        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            return;
        }
        try {
            setSaveLoading(true);
            const formData = {
                "name": panName?.first_name + ' ' + panName?.last_name,
                "email": email.trim(),
                "phone": mobileNumber.trim(),
                "designation": designation,
                "department": department,
                "dateOfBirth": dob,
                "address": address,
                "panNumber": panCardNumber,
                "isAccessToDashboardTab": checked0,
                "isAccessToFinancialDataTab": checked1,
                "isAccessToSupportTab": checked2,
                "isAccessToReportTab": checked3,
                "isAccessToMembersTab": checked4,
                "isAccessToClientTab": checked5,
                "isAccessToNotificationTab": checked6,
                "isAccessToPlansTab": checked7,
                "isAccessToLeadsTab": checked8,
                "isAccessToFaqTab": checked9,
            }
            const { data } = await createEmployee(formData);
            toast.success("Employee Registred Succesfully!")
            setShowAddMember(false);
            handleMember("");

        } catch (e) {
            console.log(e);
            toast.error(e?.response?.data?.message)
        } finally {
            setSaveLoading(false);
        }
    };
    const validateEmail = (email) => {
        // Email validation regex
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };
    const handlePanCardNumberChange = async (e) => {
        const inputValue = e.target.value.toUpperCase(); // Convert input to uppercase
        setPanCardNumber(inputValue);
        if (/^([A-Z]){5}([0-9]){4}([A-Z]){1}$/i.test(inputValue) || inputValue === '') {
            setError('');
        } else {
            setError('Invalid PAN card format');
            return;
        }
    };
    const handlePanCardVerify = async () => {
        if (/^([A-Z]){5}([0-9]){4}([A-Z]){1}$/i.test(panCardNumber) || panCardNumber === '') {
            setError('');
        } else {
            setError('Invalid PAN card format');
            return;
        }
        try {
            setLoading(true);
            const formData = {
                pan: panCardNumber.toUpperCase()
            }
            const { data } = await verifyPancard(formData);
            console.log('pan name', data?.data?.first_name);
            setPanName(data?.data);
            setErrorPan(false);
            toast.success(data?.message)
        } catch (error) {
            setErrorPan(true);
        } finally {
            setLoading(false);
        }
    };
    const handleEmailChange = (e) => {
        const inputValue = e.target.value.trim(); // Trim extra spaces
        setEmail(inputValue);
        if (/^\S+@\S+\.\S+$/.test(inputValue) || inputValue === '') {
            setErrors('');
        } else {
            setErrors('Invalid email format');
        }
    };

    return (
        <>
            <ToastContainer />
            <div className="w-full shadow-2xl rounded-2xl px-8 mb-5 p-3" style={{ height: "calc(100vh - 120px)", maxHeight: "80vh", overflowY: "auto" }}>

                <form onSubmit={handleSubmit}>
                    <div className="relative mr-10 flex flex-row align-middle">
                        <IoIosArrowBack
                            className="flex align-middle mt-5 border rounded-full"
                            style={{ borderColor: "#7C35F1", color: "#7C35F1" }}
                            size={35}
                            onClick={() => setShowAddMember(false)}
                        />

                        {/* <input
                        type="text"
                        className="w-full py-4 pl-10  mt-1 mb-4 ml-4 rounded-xl border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200"
                        placeholder="Search"
                    />
                    <div className="absolute inset-y-0 left-0 flex items-center " style={{ marginLeft: "6%" }}>
                        <FiSearch className="h-5 w-4 text-purple-700 mb-3" />
                    </div> */}
                    </div>
                    <h1 className='text-3xl mt-8 mb-8 text-black font-semibold'>Add Member</h1>


                    <div className='grid grid-cols-2 max-[470px]:grid-cols-1 gap-5'>
                        <div>
                            <label htmlFor="fullName" className="mb-1 block">
                                Full Name :
                                <span style={{ color: "#E57373", fontWeight: "bold" }}> *</span>
                            </label>
                            <input
                                type="text"
                                id="fullName"
                                value={panName && panName?.first_name + ' ' + panName?.last_name}
                                disabled
                                className="py-2 px-3 border border-gray-300 rounded-md w-full focus:ring focus:ring-purple-100 focus:outline-none"
                                required
                                placeholder='Will be fetch from Pan Details'
                            />
                        </div>

                        <div>
                            <label htmlFor="email" className="mb-1 block">
                                Email :
                                <span style={{ color: "#E57373", fontWeight: "bold" }}> *</span>
                            </label>
                            <input
                                type="email"
                                id="email"
                                value={email}
                                onChange={handleEmailChange}
                                className="py-2 px-3 border border-gray-300 rounded-md w-full focus:ring focus:ring-purple-100 focus:outline-none"
                                placeholder="Enter Your Email"
                                required
                            />
                            {errors && <p style={{ color: "red", fontSize: "10px", marginTop: "5px" }}>{errors}</p>}
                        </div>

                        <div>
                            <label htmlFor="mobileNumber" className="mb-1 block">
                                Mobile Number :
                                <span style={{ color: "#E57373", fontWeight: "bold" }}> *</span>
                            </label>
                            <input
                                type="number"
                                id="mobileNumber"
                                value={mobileNumber}
                                onChange={(e) => {
                                    const inputValue = e.target.value;
                                    if (inputValue.length <= 10) {
                                        setMobileNumber(inputValue);
                                    }
                                }}
                                className="py-2 px-3 border border-gray-300 rounded-md w-full focus:ring focus:ring-purple-100 focus:outline-none"
                                required
                                placeholder='Enter Your Mobile Number'
                            />
                            {errors.mobileNumber && <p style={{ color: "#E57373", fontSize: "10px" }}>{errors.mobileNumber}</p>}
                        </div>

                        <div>
                            <label htmlFor="designation" className="mb-1 block">
                                Designation :
                            </label>
                            <input
                                type="tel"
                                id="designation"
                                value={designation}
                                onChange={(e) => setDesignation(e.target.value)}
                                className="py-2 px-3 border border-gray-300 rounded-md w-full focus:ring focus:ring-purple-100 focus:outline-none"
                                required
                                placeholder='Enter Your Designation'
                            />
                        </div>

                        <div>
                            <label htmlFor="department" className="mb-1 block">
                                Department :
                            </label>
                            <input
                                type="text"
                                id="department"
                                value={department}
                                onChange={(e) => setDepartment(e.target.value)}
                                className="py-2 px-3 border border-gray-300 rounded-md w-full focus:ring focus:ring-purple-100 focus:outline-none"
                                required
                                placeholder='Enter Your Department'
                            />
                        </div>

                        <div>
                            <label htmlFor="dob" className="mb-1 block">
                                Date of Birth :
                            </label>
                            <input
                                type="date"
                                id="dob"
                                value={dob}
                                onChange={(e) => setDob(e.target.value)}
                                className="py-2 px-3 border border-gray-300 rounded-md w-full focus:ring focus:ring-purple-100 focus:outline-none"
                                required
                                placeholder='DD/MM/YYYY'
                            />
                        </div>

                        <div>
                            <label htmlFor="panCardNumber" className="mb-1 block">
                                PAN Card Number :
                                <span style={{ color: "#E57373", fontWeight: "bold" }}> *</span>
                            </label>
                            <div className="relative items-center flex">
                                <input
                                    type="text"
                                    id="panCardNumber" jh3eb
                                    value={panCardNumber}
                                    onChange={handlePanCardNumberChange}
                                    className="py-2 px-3 border border-gray-300 rounded-md w-full focus:ring focus:ring-purple-100 focus:outline-none"
                                    required
                                    placeholder='ABCDE1234F'
                                />
                                <button
                                    title='Verify Pan'
                                    type="submit"
                                    className="text-purple-700 bg-white rounded-md cursor-pointer absolute right-2" onClick={handlePanCardVerify}>

                                    {
                                        loading ?
                                            <div class="three-body-verify">
                                                <div class="three-body__dot"></div>
                                                <div class="three-body__dot"></div>
                                                <div class="three-body__dot"></div>
                                            </div>
                                            :
                                            'Verify'
                                    }

                                </button>
                            </div>
                            {error && <p style={{ color: "red", fontSize: "10px", marginTop: "5px" }}>{error}</p>}
                        </div>
                        <div>
                            <label htmlFor="address" className="mb-1 block">
                                Address :
                            </label>
                            <input
                                type="text"
                                id="address"
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                                className="py-2 px-3 border border-gray-300 rounded-md w-full focus:ring focus:ring-purple-100 focus:outline-none"
                                required
                                placeholder='Enter Your Address'
                            />
                        </div>
                    </div>

                    <h1 className="text-2xl mt-10  text-black font-semibold">Permissions</h1>
                    <div className="flex flex-wrap mt-8">
                        <div className=" flex w-1/2 max-[440px]:w-full mb-4 px-2  align-middle">
                            <div className="flex text-lg p-4 flex align-middle w-1/2">
                                Dashboard{" "}
                            </div>
                            <ReactSwitch
                                checked={checked0}
                                onChange={() => handleChange0(true)}
                                className="pl-8 flex align-middle my-auto"
                            />
                        </div>
                        <div className=" flex w-1/2 max-[440px]:w-full mb-4 px-2  align-middle">
                            <div className="flex text-lg p-4 flex align-middle w-1/2">
                                Financial{" "}
                            </div>
                            <ReactSwitch
                                checked={checked1}
                                onChange={() => handleChange1(true)}
                                className="pl-8 flex align-middle my-auto"
                            />
                        </div>
                        <div className=" flex w-1/2 max-[440px]:w-full mb-4 px-2  align-middle">
                            <div className="flex text-lg p-4 flex align-middle w-1/2">
                                Support{" "}
                            </div>
                            <ReactSwitch
                                checked={checked2}
                                onChange={() => handleChange2(true)}
                                className="pl-8 flex align-middle my-auto"
                            />
                        </div>

                        <div className=" flex w-1/2 max-[440px]:w-full mb-4 px-2  align-middle">
                            <div className="flex text-lg p-4 flex align-middle w-1/2">
                                Report{" "}
                            </div>
                            <ReactSwitch
                                checked={checked3}
                                onChange={() => handleChange3(true)}
                                className="pl-8 flex align-middle my-auto"
                            />
                        </div>

                        <div className=" flex w-1/2 max-[440px]:w-full mb-4 px-2  align-middle">
                            <div className="flex text-lg p-4 flex align-middle w-1/2">
                                Member{" "}
                            </div>
                            <ReactSwitch
                                checked={checked4}
                                onChange={() => handleChange4(true)}
                                className="pl-8 flex align-middle my-auto"
                            />
                        </div>

                        <div className=" flex w-1/2 max-[440px]:w-full mb-4 px-2  align-middle">
                            <div className="flex text-lg p-4 flex align-middle w-1/2">
                                Client{" "}
                            </div>
                            <ReactSwitch
                                checked={checked5}
                                onChange={() => handleChange5(true)}
                                className="pl-8 flex align-middle my-auto"
                            />
                        </div>

                        <div className=" flex w-1/2 max-[440px]:w-full mb-4 px-2 align-middle">
                            <div className="flex text-lg p-4 flex align-middle w-1/2">
                                Notification{" "}
                            </div>
                            <ReactSwitch
                                checked={checked6}
                                onChange={() => handleChange6(true)}
                                className="pl-8 flex align-middle my-auto"
                            />
                        </div>

                        <div className=" flex w-1/2 max-[440px]:w-full mb-4 px-2 align-middle">
                            <div className="flex text-lg p-4 flex align-middle w-1/2">Plans </div>
                            <ReactSwitch
                                checked={checked7}
                                onChange={() => handleChange7(true)}
                                className="pl-8 flex align-middle my-auto"
                            />
                        </div>

                        <div className=" flex w-1/2 max-[440px]:w-full mb-4 px-2  align-middle">
                            <div className="flex text-lg p-4 flex align-middle w-1/2">Leads </div>
                            <ReactSwitch
                                checked={checked8}
                                onChange={() => handleChange8(true)}
                                className="pl-8 flex align-middle my-auto"
                            />
                        </div>

                        <div className=" flex w-1/2 max-[440px]:w-full mb-4 px-2  align-middle">
                            <div className="flex text-lg p-4 flex align-middle w-1/2">Faq </div>
                            <ReactSwitch
                                checked={checked9}
                                onChange={() => handleChange9(true)}
                                className="pl-8 flex align-middle my-auto"
                            />
                        </div>
                    </div>

                    <div className="col-span-2 flex justify-center my-5">
                        <button
                            title='Add Member'
                            type="submit"
                            className="bg-purple-700 text-white py-3 px-9 rounded-md hover:bg-purple-700 cursor-pointer"
                        >
                            {saveLoading ?
                                <div class="three-body-button">
                                    <div class="three-body__dot"></div>
                                    <div class="three-body__dot"></div>
                                    <div class="three-body__dot"></div>
                                </div>
                                :
                                'Add'}
                        </button>
                    </div>
                </form>
            </div>
        </>
    )
}

export default AddMember



