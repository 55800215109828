import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const defaultApi = axios.create({
  baseURL: "https://techtime.pb18.in",
});
export const ifscFetch = axios.create({
  baseURL: "https://ifsc.razorpay.com",
});

defaultApi.interceptors.request.use(
  config => {
    const token = window.localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);
export const authApi = axios.create({ 
  baseURL: "https://techtime.pb18.in" ,
});
// Response interceptor
defaultApi.interceptors.response.use(
  response => response,
  error => {
    // Handle error globally
    if (!error.response) {
      // Network error (server not reachable, CORS issues, etc.)
      // alert('Network error')
    } else if (error.response.status === 403) {
      // Handle 403 Forbidden errors
      toast.error(`API blocked - 403 Forbidden`)
    } 
    else {
      console.log(error);
      if(error.response.data.message === 'User is Blocked'){
        alert(`Error: ${error.response.status} - ${error.response.data.message}`);
       }
      if(error.response.data.message === 'PAN Details Not found'){
        toast.error(`Error: ${error.response.status} - ${error.response.data.message}`);
      }
      if(error.response.data.message === 'Account Not Found.Please Register'){
        toast.error(`Error: ${error.response.status} - ${error.response.data.message}`);
      }
      if(error.response.data.message === 'Otp does not match'){
        toast.error(`Error: ${error.response.status} - ${error.response.data.message}`);
      }
      // return <Navigate to="/" />;
    }
    return Promise.reject(error);
  }
);

authApi.interceptors.request.use(
  (config) => { 
    const token = localStorage.getItem("token"); 
    if (token) { 
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    console.log('auth api',error);

    return Promise.reject(error);

  } 
); 

authApi.interceptors.response.use(function  (response) {
 
  return response;
}, function (error) { 
    window.location.href='*';
  return Promise.reject(error);
});
