import React, { useState, useEffect } from "react";
import { FiArrowRight, FiSearch } from "react-icons/fi";
import ReactSwitch from "react-switch";
import { IoIosArrowBack } from "react-icons/io";
import axios from "axios";
import { getToken } from "../../../utils/getToken.js";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "../../Spinner/Spinner.jsx";

const ClientProfileDetail = ({ setIsProfile, selectedUserId }) => {
  const [userData, setUserData] = useState(null);
  const [employeeDetails, setEmployeeDetails] = useState([]);
  const [employeeDetailsId, setEmployeeDetailsId] = useState([]);
  const [blocked, setBlocked] = useState(false);
  const [isApprovedForInvestment, setIsApprovedForInvestment] = useState(false);
  const [loading, setLoading] = useState(true);
  const [blockLoader, setBlockLoader] = useState(false);
  const [rejectLoader, setRejectLoader] = useState(false);

  const handleProfileDetail = async () => {
    try {
      const token = getToken();
      const userId = selectedUserId;
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/user/client/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setUserData(response.data.data);
      setBlocked(response.data.data.clientData.isBlocked);
      setEmployeeDetails(response.data.data.userPlansDetail);
      setEmployeeDetailsId(response.data.data.clientData._id);
      // console.log("Plan", response.data.data.userPlansDetail);
      // console.log("CheckTest", response.data.data);
    } catch (error) {
      console.error("Failed fetching profile detail", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    handleProfileDetail();
  }, [selectedUserId]);

  const data = [
    { month: "Jan", progress: 0 },
    { month: "Feb", progress: 0 },
    { month: "Mar", progress: 0 },
    { month: "Apr", progress: 0 },
    { month: "May", progress: 0 },
    { month: "Jun", progress: 100 },
    { month: "Jul", progress: 0 },
    { month: "Aug", progress: 0 },
    { month: "Sep", progress: 0 },
    { month: "Oct", progress: 0 },
    { month: "Nov", progress: 0 },
    { month: "Dec", progress: 0 },
  ];

  const calculatePercentage = (value) => {
    return `${value}%`;
  };

  const handleBlock = async () => {
    try {
      setBlockLoader(true);
      const token = getToken();
      const userId = selectedUserId;
      const blockAction = blocked ? "unblock" : "block";

      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/user/block-status/${userId}`,
        { blockAction },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      setBlocked(!blocked);
      const actionMessage = blocked ? "Unblocked" : "Blocked";
      toast.success(`${actionMessage} Successfully`);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setBlockLoader(false);

    }
  };

  const handleApprove = async (isApproved) => {
    try {
      setRejectLoader(true);
      const token = getToken();
      const userId = selectedUserId;
      const approveAction = isApproved ? "reject" : "approve";

      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/user/approve-status/${userId}`,
        {
          approveAction,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      setIsApprovedForInvestment(!isApprovedForInvestment);
      const actionMessage = isApproved ? "Rejected" : "Approved";
      handleProfileDetail();
      toast.success(`${actionMessage} Successfully`);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setRejectLoader(false);

    }
  };

  const progressBars = data.map((item, index) => (
    <div
      key={index}
      className="flex flex-col items-center mx-2 justify-between"
    >
      <div className="h-24  bg-gray-300 w-10 rounded-t"></div>
      <div
        className="h-24 bg-blue-500 w-10 rounded-b"
        style={{ height: calculatePercentage(item.progress) }}
      ></div>
      <div className="mt-2">{item.month}</div>
    </div>
  ));

  return (
    <div
    className="bg-white rounded-2xl shadow-md mb-8 ml-3 mr-3 sm:ml-3 border-style p-2"
      // style={{
      //   height: "calc(100vh - 120px)",
      //   maxHeight: "80vh",
      //   overflowY: "auto"
      // }}
    >
      <div className="relative mr-10 flex flex-row align-middle">
        <IoIosArrowBack
          onClick={() => setIsProfile(false)}
          className="flex align-middle mt-3 border rounded-full"
          style={{ borderColor: "#7C35F1", color: "#7C35F1" }}
          size={35}
        />
      </div>
      {loading ? (
        <div
          style={{ display: "flex", marginTop: "15%" }}
          className="flex w-full justify-center"
        >
          {" "}
          <Spinner />
        </div>
      ) : (
        <div>
          <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 max-[768px]:gap-0 gap-8 mr-4 mb-2">
            <div className="bg-white flex flex-col shadow-md rounded-lg p-4 mt-5 ml-4 max-[1024px]:ml-0 justify-items-center w-full border">
              <div className="flex flex-row items-center  max-[540px]:flex-col max-[1024px]:items-center">
                <img
                  src={
                    userData?.clientData?.profileImageLink
                      ? userData?.clientData?.profileImageLink
                      : "/image/Avatar.png"
                  }
                  className="bg-slate-100 rounded-full inline-block w-48 h-48 max-[500px]:w-40 max-[500px]:h-40"
                  alt="error"
                />
                <div className="flex flex-col w-1/2 justify-center align-middle  my-auto">
                  <div className="flex justify-end"></div>
                  <h1 className="text-center font-bold text-2xl text-black">
                    {userData?.clientData?.name}
                  </h1>
                  <p className="text-center">{userData?.clientData?.address}</p>
                  <p className="text-center text-gray-900">
                    Client ID : {userData?.clientData?.memberId}
                  </p>
                  <div className="">
                    <button
                      title="Block Function"
                      className={`bg-purple-700 rounded py-2 mx-2 px-2 text-white mt-5 mx-auto flex `}
                      onClick={handleBlock}
                    >
                      {
                        blockLoader ?
                          <div class="three-body-button">
                            <div class="three-body__dot"></div>
                            <div class="three-body__dot"></div>
                            <div class="three-body__dot"></div>
                          </div>
                          :
                          blocked ? "Unblock" : "Block"
                      }
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-white shadow-md rounded-lg p-2 mt-5 ml-4 max-[1024px]:ml-0 border flex">
              <div className=" my-auto flex w-full ">
                <div className="space-y-2 ">
                  <div className="flex">
                    <div className="pl-2">
                      <h1 style={{ fontSize: "14px", color: "black" }}>Phone Number</h1>
                      <p className="text-gray-600" style={{ fontSize: "15px" }}>
                        {userData?.clientData?.phone}
                      </p>
                    </div>
                  </div>
                  <div className="flex">
                    <div className="pl-2">
                      <h1 style={{ fontSize: "14px", color: "black" }}>Email</h1>
                      <p className="text-gray-600" style={{ fontSize: "15px" }}>
                        {userData?.clientData?.email}
                      </p>
                    </div>
                  </div>
                  <div className="flex">
                    <div className="pl-2">
                      <h1 style={{ fontSize: "14px", color: "black" }}>Address</h1>
                      <p className="text-gray-600" style={{ fontSize: "15px" }}>
                        {userData?.clientData?.address}{" "}
                      </p>
                    </div>
                  </div>
                  <div className="flex">
                    <div className="pl-2">
                      <h1 style={{ fontSize: "14px", color: "black" }}>PAN Number</h1>
                      <p className="text-gray-600" style={{ fontSize: "15px" }}>
                        {userData?.clientData?.panCard?.number}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="flex w-full items-center">
              <div className="text-xl ml-4 flex w-1/2">Plans</div>
              <div className="flex w-1/2">
                <button
                  title="Approve or Reject function"
                  className={`bg-purple-700 text-white rounded py-2 px-4 ml-auto `}
                  onClick={() =>
                    handleApprove(userData?.clientData?.isApprovedForInvestment)
                  }
                  style={{ marginRight: "24px" }}
                >
                  {
                    rejectLoader  ?
                     <div class="three-body-button">
                       <div class="three-body__dot"></div>
                       <div class="three-body__dot"></div>
                       <div class="three-body__dot"></div>
                     </div>
                     :
                     userData?.clientData?.isApprovedForInvestment
                     ? "Reject"
                     : "Approve"
                  }
                </button>
              </div>

              {/* ))
          ) : (
            <p className="text-xl ml-4"></p>
          )} */}
            </div>
            <div className="flex flex-wrap  mt-4 p-2 border-red-900" >
              {employeeDetails.length > 0 ? (
                employeeDetails.map((employee) => (
                  <div
                    className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 mb-4 px-2"
                    key={employee?.planId?._id}
                  >
                    <div className="bg-white shadow-md rounded-lg p-4 flex border">
                      <div>
                        <h3 className="text-md mb-2 font-bold">
                          {employee?.planId?.planName}
                        </h3>
                        <p className="">
                          Invested: {employee?.totalfundInvested}
                        </p>
                        <p className="">
                          Return: {employee?.totalProfitEarned}
                        </p>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-xl ml-4">No plans selected.</p>
              )}
            </div>
          </div>
        </div>
      )}

      <ToastContainer />
    </div>
  );
};

export default ClientProfileDetail;
