import React, { useState, useEffect } from "react";
import { FiSearch } from "react-icons/fi";
import axios from "axios";
import { getToken } from "../../utils/getToken.js";
import { MdOutlineClose } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "../Spinner/Spinner.jsx";
import { Link } from "react-router-dom";
import { Remove } from "@mui/icons-material";
import { Delete, Trash2 } from "lucide-react";
import { Checkbox } from "@material-tailwind/react";

function Plans() {
  const [planDetails, setPlanDetails] = useState([]);
  const [showAddPlanModal, setShowAddPlanModal] = useState(false);
  const [showEditPlanModel, setShowEditPlanModel] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [selectedPlan, setSelectedPlan] = useState({
    _id: "",
    planName: "",
    description: "",
    returnPercent: 0,
    minimumSubscriptionAmount: "",
    termsAndConditions: "",
    termsAndConditionsDocumentLink: "",
    isActive: false,
    // Add other properties as needed
  });
  const [uploadedFileName, setUploadedFileName] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState(null);
  const [updateLoader, setUpdateLoader] = useState(false);
  const [addLoader, setAddLoader] = useState(false);


  useEffect(() => {
    handleSearch();
  }, []);

  const handleSearch = async () => {
    try {
      const token = getToken();
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/plan/allPlan`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setPlanDetails(response.data.data.planDetails);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddPlanClick = () => {
    setShowAddPlanModal(true);
    setSelectedPlan({
      _id: "",
      planName: "",
      description: "",
      returnPercent: 0,
    });
  };

  const handleAddPlanClose = () => {
    setShowAddPlanModal(false);
  };

  const handleEditPlanClick = (planId) => {
    const selected = planDetails.find((plan) => plan._id === planId);
    setSelectedPlan(selected);
    setShowEditPlanModel(true);
  };

  const handleEditPlanClose = () => {
    setShowEditPlanModel(false);
  };


  const handleAddPlan = async (formData) => {
    try {
      setAddLoader(true);
      const token = getToken();
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/plan/create`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      toast.success("Plan Added Succesfully!")
      setShowAddPlanModal(false);
      setSelectedFileName('');
      handleSearch();
    } catch (error) {
      console.error("Error adding plan:", error);
    } finally {
      setAddLoader(false);
    }
  };

  const handleUpdatePlan = async (selectedPlanId, formData) => {
    setUpdateLoader(true);
    try {
      const token = getToken();
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/plan/update/${selectedPlanId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      toast.success("Plan Updated!")
      setShowEditPlanModel(false);
      setSelectedFileName('');
      handleSearch();
    } catch (error) {
      console.error("Error updating plan:", error);
    } finally {
      setUpdateLoader(false);
    }
  };

  const handleDelete = async (selectedPlanId) => {
    setDeleteId(selectedPlanId);
    setIsOpen(true);
  };

  const handleConfirmDelete = async () => {
    setIsOpen(false);
    setLoading(true);
    try {
      const token = getToken();
      await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/api/plan/delete/${deleteId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast("Plan Deleted!");
      handleSearch();
    } catch (error) {
      console.error("Error deleting plan:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    // Get values from the form inputs
    // const planName = event.target.elements.planName.value;
    // const description = event.target.elements.description.value;
    // const returnPercent = event.target.elements.returnPercent.value;
    // const minimumSubscriptionAmount = event.target.elements.minimumSubscriptionAmount.value;
    // const termsAndConditions = event.target.elements.termsAndConditions.value;
    // const termsAndConditionsDocumentLink = event.target.elements.termsAndConditionsDocumentLink.files;
    // const isActive = event.target.elements.isActive.value;

    // Create formData object
    const formData = new FormData();
    formData.append('planName', selectedPlan.planName);
    formData.append('description', selectedPlan.description);
    formData.append('returnPercent', selectedPlan.returnPercent);
    formData.append('minimumSubscriptionAmount', selectedPlan.minimumSubscriptionAmount);
    formData.append('termsAndConditions', selectedPlan.termsAndConditions);
    formData.append('termsAndConditionsDocumentLink', selectedPlan.termsAndConditionsDocumentLink);
    formData.append('isActive', selectedPlan.isActive);

    if (event.target.elements.features) {
      // formData["features"] = event.target.elements.features.value.split(",");
      formData.append("features", event.target.elements.features.value.split(","));
    }

    if (selectedPlan._id) {
      handleUpdatePlan(selectedPlan._id, formData);
    } else {
      handleAddPlan(formData);
    }
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file?.size > 2097152) {
      toast.warning('Entity is too large')
      return;
    };
    setSelectedPlan({
      ...selectedPlan,
      termsAndConditionsDocumentLink: file,
    })
    setSelectedFileName(file.name);
  };
  const splitFileLink = (a) => {
    const x = a.split('/');
    const y = x[x.length - 1];
    const z = y.split('_');
    let kk = z.slice(1);
    const b = kk.join('');
    return decodeURIComponent(b);
  }
  const splitFileLinkManual = (a) => {
    const x = a.split('/');
    const y = x[x.length - 1];
    return decodeURIComponent(y);
  }
  return (
    <div 
    className="bg-white rounded-2xl shadow-md mb-8 ml-3 mr-3 sm:ml-3 border-style p-2"    >
      {loading ? (
        <div style={{ display: "flex", marginTop: "20%" }} className="flex w-full justify-center"> <Spinner /></div>
      ) : (
        <div>

          <div className="flex items-center justify-between px-4 max-[360px]:px-0 mt-4">
            <h1 className="text-2xl font-semibold ml-6 max-[360px]:ml-0 text-black">Plans</h1>
            <button
              title='Add More plans'
              className="rounded py-2 text-nowrap  items-center text-center px-4 text-white  bg-purple-700 hover:bg-purple-500"
              onClick={handleAddPlanClick}
            >
              + Add more plans
            </button>
          </div>

          <div className="grid grid-cols-1 min-[600px]:grid-cols-2  min-[1150px]:grid-cols-3 mb-10 gap-6  mx-4 max-[360px]:mx-0">
            {
              planDetails?.length > 0 ?
                planDetails.map((plan, index) => (
                  <div
                    key={index}
                    className=" w-full rounded-xl max-[600px]:text-center mt-4"
                  >
                    <div className="rounded-lg bg-white shadow-md p-5 flex flex-col justify-between h-full border ">
                      <div className="">
                        <h2 className="text-2xl font-normal mb-4">{plan.planName}</h2>
                        <p
                          className="text-gray-600 mb-4 overflow-hidden"
                          style={{
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                        >
                          {plan.returnPercent}% / {plan.description}
                        </p>
                        <p
                          className="text-gray-600 mb-4 overflow-hidden"
                          style={{
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                        >
                          Subscription Amount : {plan.minimumSubscriptionAmount}
                        </p>
                      </div>
                      <div className="flex flex-row w-full max-[600px]:justify-center">
                        <button
                          title='Customize Plan'
                          className="bg-slate-200 text-black py-3 px-4 rounded-xl hover:bg-slate-100 text-nowrap"
                          onClick={() => handleEditPlanClick(plan._id)}
                        >
                          Customize Plan
                        </button>
                        <div className="flex" style={{ width: "20%" }}>
                          <RiDeleteBinLine
                            style={{
                              color: "#7C35F1",
                              alignItems: "center",
                              justifyContent: "center",
                              margin: "auto",
                              cursor: "pointer",
                            }}
                            size={30}
                            onClick={() => handleDelete(plan._id)}
                          />
                        </div>
                      </div>


                    </div>
                  </div>
                ))
                :
                'No Plan found'
            }
          </div>

          {showEditPlanModel && (
            <div className="fixed inset-0 z-50 overflow-auto flex items-center justify-center  bg-gray-900 bg-opacity-25 backdrop-blur-sm">
              <div className="bg-white rounded-xl flex flex-col " style={{ width: "100vh",height:'100%' }}>
                <div className="flex justify-between p-3 bg-purple-700 text-white rounded-t-xl">
                  <h2 className="text-xl font-bold text-center w-full">Edit Plans</h2>
                  <MdOutlineClose
                    onClick={handleEditPlanClose}
                    className="cursor-pointer w-8 h-8 text-white hover:text-gray-300"
                  />
                </div>

                <div className="p-6 pt-0 overflow-auto">
                  <form onSubmit={(e) => handleFormSubmit(e)} className="grid grid-cols-2 max-[640px]:grid-cols-1">
                    <div className="flex flex-col my-2 gap-4 w-full">
                      <label style={{ fontWeight: 600, fontSize: "16px" }}>
                        Plan Name
                      </label>
                      <input
                        style={{ fontSize: "15px" }}
                        className="border-b border-gray-300 text-gray-900 focus:ring focus:ring-purple-100 focus:outline-none"
                        placeholder="Enter Plan Name"
                        type="text"
                        name="planName"
                        value={selectedPlan.planName}
                        onChange={(e) =>
                          setSelectedPlan({
                            ...selectedPlan,
                            planName: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="flex flex-col my-2 gap-4 w-full">
                      <label style={{ fontWeight: 600, fontSize: "16px" }}>
                        Description
                      </label>
                      <input
                        style={{ fontSize: "15px" }}
                        className="border-b border-gray-300 focus:ring focus:ring-purple-100 focus:outline-none"
                        placeholder="Enter description"
                        type="text"
                        name="description"
                        value={selectedPlan.description}
                        onChange={(e) =>
                          setSelectedPlan({
                            ...selectedPlan,
                            description: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="flex flex-col my-2 gap-4 w-full">
                      <label style={{ fontWeight: 600, fontSize: "16px" }}>
                        Return Percentage
                      </label>
                      <input
                        style={{ fontSize: "15px" }}
                        className="border-b border-gray-300 focus:ring focus:ring-purple-100 focus:outline-none"
                        placeholder="Enter return percentage"
                        type="number"
                        name="returnPercent"
                        value={selectedPlan.returnPercent}
                        onChange={(e) =>
                          setSelectedPlan({
                            ...selectedPlan,
                            returnPercent: parseFloat(e.target.value),
                          })
                        }
                      />
                    </div>
                    <div className="flex flex-col my-2 gap-4 w-full">
                      <label style={{ fontWeight: 600, fontSize: "16px" }}>
                        Subscription Amount
                      </label>
                      <input
                        style={{ fontSize: "15px" }}
                        className="border-b border-gray-300 focus:ring focus:ring-purple-100 focus:outline-none"
                        placeholder="Enter subscription amount"
                        type="number"
                        name="minimumSubscriptionAmount"
                        value={selectedPlan.minimumSubscriptionAmount}
                        onChange={(e) =>
                          setSelectedPlan({
                            ...selectedPlan,
                            minimumSubscriptionAmount: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="flex flex-col my-2 gap-4 w-full">
                      <label style={{ fontWeight: 600, fontSize: "16px" }}>
                        Features
                      </label>
                      <input
                        style={{ fontSize: "15px" }}
                        className="border-b border-gray-300 focus:ring focus:ring-purple-100 focus:outline-none"
                        placeholder="Enter features"
                        type="text"
                        name="features"
                        value={selectedPlan.features}
                        onChange={(e) =>
                          setSelectedPlan({
                            ...selectedPlan,
                            features: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="flex flex-col my-2 gap-4 w-full">
                      <label style={{ fontWeight: 600, fontSize: "16px" }}>
                        Terms & Conditions
                      </label>
                      <input
                        style={{ fontSize: "15px" }}
                        className="border-b border-gray-300 focus:ring focus:ring-purple-100 focus:outline-none"
                        placeholder="Enter terms & Conditions"
                        type="text"
                        name="termsAndConditions"
                        value={selectedPlan.termsAndConditions}
                        onChange={(e) =>
                          setSelectedPlan({
                            ...selectedPlan,
                            termsAndConditions: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="flex flex-col gap-4 w-full">

                      <label
                        htmlFor="file-input"
                        style={{ cursor: "pointer", width: "100%" }}
                        className="flex justify-start items-center"
                      >

                        <input
                          id="file-input"
                          type="file"
                          name="image"
                          accept="image/*,.doc,.pdf,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                          style={{ display: "none" }}
                          onChange={handleFileChange}
                        />
                        <p className="text-start bg-white text-purple-800 rounded" style={{ fontWeight: 600, fontSize: "15px" }}>+ Update Terms & Conditions
                        </p>
                      </label>
                      {selectedFileName ?
                        <div className="flex gap-2 items-center" style={{ fontSize: "12px", marginTop: "-13px" }}>
                          <p >{splitFileLinkManual(selectedFileName)}</p>
                          <Trash2 className="text-red-500" onClick={() => {
                            setSelectedFileName(null);
                            setSelectedPlan({
                              ...selectedPlan,
                              termsAndConditionsDocumentLink: '',
                            })
                          }} />
                        </div>
                        :
                        <div className="flex gap-2 items-center">
                          {
                            selectedPlan?.termsAndConditionsDocumentLink &&
                            <a href={`${selectedPlan?.termsAndConditionsDocumentLink}`} download target="_blank" className='pointer flex flex-col' style={{ fontSize: "12px", textDecoration: "none", marginTop: "-13px" }}>
                              <p> {splitFileLink(selectedPlan?.termsAndConditionsDocumentLink)}</p>
                            </a>
                          }
                        </div>
                      }
                    </div>
                    <div className="flex items-center  gap-2 w-full ">
                      <label style={{ fontWeight: 600, fontSize: "16px" }}>
                        Active Plan
                      </label>
                      <Checkbox color="purple" value={(selectedPlan.isActive)} checked={selectedPlan.isActive} onChange={(e) => {
                        setSelectedPlan({
                          ...selectedPlan,
                          isActive: e.target.checked,
                        })
                      }} />
                    </div>
                    <button
                      title='Update Plan'
                      className="bg-purple-700 text-white py-2 px-4 rounded mt-5 w-full hover:bg-purple-500"
                      type="submit"
                    >
                      {
                        updateLoader ?
                          <div class="three-body-button">
                            <div class="three-body__dot"></div>
                            <div class="three-body__dot"></div>
                            <div class="three-body__dot"></div>
                          </div>
                          :
                          'Update Plan'
                      }

                    </button>
                  </form>
                </div>
              </div>
            </div>
          )}


          {showAddPlanModal && (
            <div className="fixed inset-0 z-50 overflow-auto flex items-center justify-center  bg-gray-900 bg-opacity-25 backdrop-blur-sm">
              <div className="bg-white rounded-xl flex flex-col " style={{ width: "100vh",height:"100%" }}>
                <div className="flex justify-between p-3 bg-purple-700 text-white rounded-t-xl">
                  <h2 className="text-xl font-bold text-center w-full ">  Add More Plans</h2>
                  <MdOutlineClose
                    onClick={handleAddPlanClose}
                    className="cursor-pointer w-8 h-8 text-white hover:text-gray-300"
                  />
                </div>
                <div className="p-4 pt-0 overflow-auto">
                  <form onSubmit={handleFormSubmit} className="grid grid-cols-2 max-[640px]:grid-cols-1">
                    <div className="flex flex-col my-2 gap-4 w-full">
                      <label style={{ fontWeight: 600, fontSize: "16px" }}>Plan Name</label>
                      <input
                        className="border-b border-gray-300 focus:ring focus:ring-purple-100 focus:outline-none"
                        placeholder="Enter plan name"
                        type="text"
                        name="planName"
                        maxLength="25"
                        value={selectedPlan.planName}
                        onChange={(e) =>
                          setSelectedPlan({
                            ...selectedPlan,
                            planName: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="flex flex-col my-2 gap-4 w-full">
                      <label style={{ fontWeight: 600, fontSize: "16px" }}>Description</label>
                      <input
                        className="border-b border-gray-300 focus:ring focus:ring-purple-100 focus:outline-none"
                        placeholder="Enter description"
                        type="text"
                        name="description"
                        value={selectedPlan.description}
                        onChange={(e) =>
                          setSelectedPlan({
                            ...selectedPlan,
                            description: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="flex flex-col my-2 gap-4 w-full">
                      <label style={{ fontWeight: 600, fontSize: "16px" }}>Return Percentage</label>
                      <input
                        className="border-b border-gray-300 focus:ring focus:ring-purple-100 focus:outline-none"
                        placeholder="Enter return percent"
                        type="number"
                        name="returnPercent"
                        value={selectedPlan.returnPercent}
                        onChange={(e) =>
                          setSelectedPlan({
                            ...selectedPlan,
                            returnPercent: parseFloat(e.target.value),
                          })
                        }
                        maxLength="4"
                      />
                    </div>
                    <div className="flex flex-col my-2 gap-4 w-full">
                      <label style={{ fontWeight: 600, fontSize: "16px" }}>Subscription Amount</label>
                      <input
                        className="border-b border-gray-300 focus:ring focus:ring-purple-100 focus:outline-none"
                        placeholder="Enter subscription amount"
                        type="number"
                        pattern="[0-9]*"
                        maxlength="7"
                        title="Please enter only up to 7 digits"
                        name="minimumSubscriptionAmount"
                        value={selectedPlan.minimumSubscriptionAmount}
                        onChange={(e) =>
                          setSelectedPlan({
                            ...selectedPlan,
                            minimumSubscriptionAmount: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="flex flex-col my-2 gap-4 w-full">
                      <label style={{ fontWeight: 600, fontSize: "16px" }}>Features</label>
                      <input
                        className="border-b border-gray-300 focus:ring focus:ring-purple-100 focus:outline-none"
                        placeholder="Enter features"
                        type="text"
                        name="features"
                      />
                    </div>
                    <div className="flex flex-col my-2 gap-4 w-full">
                      <label style={{ fontWeight: 600, fontSize: "16px" }}>
                        Terms & Conditions
                      </label>
                      <input
                        style={{ fontSize: "15px" }}
                        className="border-b border-gray-300 focus:ring focus:ring-purple-100 focus:outline-none"
                        placeholder="Enter terms & Conditions"
                        type="text"
                        name="termsAndConditions"
                        value={selectedPlan.termsAndConditions}
                        onChange={(e) =>
                          setSelectedPlan({
                            ...selectedPlan,
                            termsAndConditions: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="flex flex-col my-2 gap-4 w-full">

                      <label
                        htmlFor="file-input"
                        style={{ cursor: "pointer", width: "100%" }}
                        className="flex justify-start items-center"
                      >

                        <input
                          id="file-input"
                          type="file"
                          name="image"
                          accept="image/*,.doc,.pdf,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                          style={{ display: "none" }}
                          onChange={handleFileChange}
                        />
                        <p className="text-start bg-white text-purple-800 rounded" style={{ fontWeight: 600, fontSize: "15px" }}>+ Upload Terms & Conditions
                        </p>
                      </label>
                      {selectedFileName &&
                        <div className="flex gap-2 items-center" style={{ fontSize: "12px", marginTop: "-13px" }}>
                          <p >{selectedFileName}</p>
                          <Trash2 className="text-red-500" onClick={() => {
                            setSelectedFileName(null);
                            setSelectedPlan({
                              ...selectedPlan,
                              termsAndConditionsDocumentLink: '',
                            })
                          }} />
                        </div>
                      }
                    </div>
                    <div className="flex items-center  gap-2 w-full">
                      <label style={{ fontWeight: 600, fontSize: "16px" }}>
                        Active Plan
                      </label>
                      <Checkbox color="purple" value={(selectedPlan.isActive)} checked={selectedPlan.isActive} onChange={(e) => {
                        setSelectedPlan({
                          ...selectedPlan,
                          isActive: e.target.checked,
                        })
                      }} />
                    </div>
                    <button
                      title='Add Plan'
                      className="bg-purple-700 text-white py-2 px-4 rounded mt-5 w-full justify-center hover:bg-purple-500"
                      type="submit"
                    >
                      {
                        addLoader ?
                          <div class="three-body-button">
                            <div class="three-body__dot"></div>
                            <div class="three-body__dot"></div>
                            <div class="three-body__dot"></div>
                          </div>
                          :
                          'Add Plan'
                      }

                    </button>
                  </form>
                </div>
              </div>
            </div>

          )}
          {isOpen && (
            <div className="fixed inset-0 z-50 overflow-auto flex items-center justify-center backdrop-blur-md bg-gray-900 bg-opacity-25">
              <div className="bg-white rounded-xl flex flex-col w-96">
                <div className="p-6">
                  <p className="text-lg font-semibold mb-4">Are you sure you want to delete?</p>
                  <div className="flex justify-center">
                    <button className="bg-purple-500 text-white py-2 px-4 rounded mr-2" onClick={handleConfirmDelete}>Delete</button>
                    <button className="bg-gray-200 text-gray-800 py-2 px-4 rounded" onClick={() => setIsOpen(false)}>Cancel</button>
                  </div>
                </div>
              </div>
            </div>
          )}

        </div>)}
      <ToastContainer />
    </div>
  );
}

export default Plans;



// {index === isMessagePopupOpen && (
//   <div className="fixed inset-0 z-50 overflow-auto flex items-center justify-center backdrop-blur-md bg-gray-900 bg-opacity-25">
//     <div className="bg-white rounded-xl flex flex-col w-96">
//       <div className="flex justify-between p-3 bg-purple-700 text-white rounded-t-xl">
//         <h2 className="text-xl font-bold">Your Message</h2>
//         <MdOutlineClose
//           onClick={handleCloseMessagePopup}
//           className="cursor-pointer w-8 h-8 text-white hover:text-gray-300"
//         />
//       </div>
//       <div className="p-4">
//         <p className="mb-2 text-gray-800">Message: {ticket?.issueSubject}</p>
//         <p className="text-gray-600">Date: {new Date(ticket?.createdAt).toLocaleDateString()}</p>
//       </div>
//     </div>
//   </div>
// )}