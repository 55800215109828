import React, { useState, useEffect, useRef } from "react";
import { FiArrowRight, FiSearch } from "react-icons/fi";
import ReactSwitch from "react-switch";
import { IoIosArrowBack } from "react-icons/io";
import axios from "axios";
import { getToken } from "../../../utils/getToken.js";
import { ToastContainer, toast } from "react-toastify";
import {
  getEmployeeDetail,
  updateEmployee,
  uploadProfileImg,
} from "../../../Services/EmployeeServices.js";
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import Spinner from "../../Spinner/Spinner.jsx";

const MemberProfileDetail = ({ setIsProfile, selectedUserId }) => {
  const queryClient = useQueryClient();
  const [userData, setUserData] = useState(null);

  const [changedEmail, setChangedEmail] = useState(null);
  const [emailCheck, setEmailCheck] = useState(false);
  const [phoneCheck, setPhoneCheck] = useState(null);
  const [changedPhoneNo, setChangedPhoneNo] = useState(null);
  const [changedImg, setChangedImg] = useState(null);
  const [isLoadingUserData, setIsLoadingUserData] = useState(true);
  const [editableFields, setEditableFields] = useState(false);
  const fileInputRef = useRef(null);
  const {
    data: apiUserData,
    refetch,
    isLoading,
  } = useQuery({
    queryKey: ["getEmployeeDetail"],
    queryFn: () => getEmployeeDetail(selectedUserId),
  });

  useEffect(() => {
    if (apiUserData) {
      setUserData({ ...apiUserData.data.employeeDetails });
      setChangedEmail(apiUserData?.data?.employeeDetails?.userId?.email);
      setChangedPhoneNo(apiUserData?.data?.employeeDetails?.userId?.phone);
      setIsLoadingUserData(false);
    }
  }, [apiUserData]);

  const handleSwitch = (name, val) => {
    let tempObj = { ...userData };
    tempObj[name] = val;
    setUserData(tempObj);
  };
  const handleChange = (e) => {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    });
  };

  const handleUploadButtonClick = async () => {
    await fileInputRef.current.click();
  };
  const handleAvatarUpload = (e) => {
    const file = e.target.files[0];
    setChangedImg(file);
  };
  const mutateUserProfile = useMutation(updateEmployee, {
    onSuccess: (data) => {
      // Invalidate and refetch
      toast.success("Update Profile successfully!");
      setEditableFields(false);
      setEmailCheck(false);
      setPhoneCheck(false);
      refetch();
      queryClient.invalidateQueries("getEmployeeDetail");
      // handleProfileDetail()
    },
  });
  const mutateUserProfileImage = useMutation(uploadProfileImg, {
    onSuccess: (data) => {
      toast.success("Upload Profile image successfully!");
    },
  });

  const uploadProfile = async () => {
    let formData = new FormData();
    formData.append("profileImage", changedImg);
    mutateUserProfileImage.mutate({ id: userData._id, data: formData });
  };

  const handleUploadProfile = () => {
    let k;
    k = {
      name: userData?.name,
      designation: userData?.designation,
      department: userData?.department,
      dateOfBirth: userData?.dateOfBirth,
      address: userData?.address,
      isAccessToFinancialDataTab: userData.isAccessToFinancialDataTab,
      isAccessToReportTab: userData.isAccessToReportTab,
      isAccessToMembersTab: userData.isAccessToMembersTab,
      isAccessToLeadsTab: userData.isAccessToLeadsTab,
      isAccessToClientTab: userData.isAccessToClientTab,
      isAccessToPlansTab: userData.isAccessToPlansTab,
      isAccessToSupportTab: userData.isAccessToSupportTab,
      isAccessToNotificationTab: userData.isAccessToNotificationTab,
      isAccessToDashboardTab: userData.isAccessToDashboardTab,
      isAccessToFaqTab: userData.isAccessToFaqTab,
    };
    if (emailCheck) {
      k["email"] = changedEmail;
    }
    if (phoneCheck) {
      k["phone"] = changedPhoneNo;
    }
    mutateUserProfile.mutate({ id: userData._id, data: k });
  };

  const getImageSrc = () => {
    if (changedImg) {
      return URL.createObjectURL(changedImg);
    }

    if (userData?.userId?.profileImageLink) {
      return userData?.userId?.profileImageLink;
    }

    return "/image/Avatar.png";
  };
  const back = () => {
    setUserData(null);
    setIsProfile(false);
  };
  // if (isLoading || isLoadingUserData) {
  //   return <Spinner />;
  // }

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
      }}
      className="w-full shadow-2xl rounded-2xl mb-5 p-3"
      style={{
        height: "calc(100vh - 120px)",
        maxHeight: "80vh",
        overflowY: "auto",
      }}
    >
      {isLoadingUserData ? (
        <div
          style={{ display: "flex", marginTop: "20%" }}
          className="flex w-full justify-center"
        >
          <Spinner />
        </div>
      ) : (
        <div>
          <ToastContainer />
          <div className="relative mr-5 flex flex-row align-middle justify-between ">
            <IoIosArrowBack
              onClick={back}
              className="flex align-middle mt-5 border rounded-full"
              style={{
                borderColor: "#7C35F1",
                color: "#7C35F1",
                marginBlock: "auto",
              }}
              size={35}
            />
            {editableFields ? (
              <button
                title="Update Profile"
                type="button"
                onClick={() => {
                  handleUploadProfile();
                  uploadProfile();
                }}
                className="bg-purple-700 rounded p-3 text-white flex "
              >
                Save Profile
              </button>
            ) : (
              <button
                title="Edit Profile"
                type="button"
                onClick={() => setEditableFields(true)}
                className="bg-purple-500 hover:bg-gray text-white rounded p-3 flex "
              >
                Edit Profile
              </button>
            )}
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-8  max-[768px]:gap-0  mr-4 mb-4">
            <div className="bg-white flex flex-col shadow-md rounded-lg p-4 my-7 max-[1024px]:my-2 ml-4 max-[1024px]:ml-0 justify-items-center w-full border">
              <div className="flex flex-row gap-5 my-auto max-[450px]:flex-col max-[450px]:items-center ">
                <div className="text-center flex flex-col my-auto w-full items-center">
                  <img
                    src={getImageSrc()}
                    className="bg-slate-100 rounded-full inline-block"
                    alt="error"
                    style={{ width: "200px", height: "180px" }}
                  />

                  <label className="">
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleAvatarUpload}
                      ref={fileInputRef}
                      hidden
                    />
                    {editableFields ? (
                      <button
                        title="Upload Picture"
                        type="button"
                        className="bg-purple-700 rounded py-2 mx-2 px-2 text-white mt-2 text-nowrap"
                        onClick={() => {
                          handleUploadButtonClick();
                        }}
                      >
                        Change Picture
                      </button>
                    ) : (
                      <div></div>
                    )}
                  </label>
                </div>
                <div className="flex flex-col py-auto w-full">
                  <div className=" flex my-auto flex-col w-full">
                    {editableFields ? (
                      <input
                        type="text"
                        name="name"
                        value={userData?.name}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        className="border-b border-gray-400 w-full pl-1  p-1 my-2 focus:ring focus:ring-purple-100 focus:outline-none"
                      />
                    ) : (
                      <h1 className=" font-semibold text-xl text-black">
                        {userData?.name}
                      </h1>
                    )}
                    {editableFields ? (
                      <input
                        type="text"
                        name="department"
                        value={userData?.department}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        className="border-b border-gray-400 w-full  pl-1 p-1 my-2 focus:ring focus:ring-purple-100 focus:outline-none"
                      />
                    ) : (
                      <p className=" font-normal text-l">
                        {userData?.department}
                      </p>
                    )}
                    {editableFields ? (
                      <input
                        type="text"
                        name="designation"
                        value={userData?.designation}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        className="border-b border-gray-400 w-full pl-1 p-1 my-2 focus:ring focus:ring-purple-100 focus:outline-none"
                      />
                    ) : (
                      <p className="text-l font-normal">
                        {userData?.designation}
                      </p>
                    )}
                    {editableFields ? (
                      <p className=" text-gray-900 text-l my-2">
                        Employee ID :{userData?.employeeId}
                      </p>
                    ) : (
                      <p className=" text-gray-900 text-l">
                        Employee ID :{userData?.employeeId}
                      </p>
                    )}
                  </div>
                </div>
              </div>

              {/* <div className="">
            <button className="bg-purple-700 rounded py-2 mx-2 px-2 text-white mt-5 ml-auto flex">
              Send Message
            </button>
          </div> */}
            </div>

            <div className="bg-white flex flex-col shadow-md rounded-lg p-3 my-7  max-[1024px]:my-2 justify-items-center w-full border">
              {/* <div className="bg-white shadow-md rounded-lg p-2 my-5 ml-4"> */}
              <div className="">
                <div className="space-y-2">
                  <div className="flex w-full">
                    <div className="pl-2 w-full">
                      <h1 style={{ fontSize: "14px", color:"black" }}>Phone Number</h1>
                      {editableFields ? (
                        <input
                          type="text"
                          name="phone"
                          value={changedPhoneNo}
                          onChange={(e) => {
                            setChangedPhoneNo(e.target.value);
                            setPhoneCheck(true);
                          }}
                          className="border-b border-gray-400 w-full  pl-1 p-1 mt-1 focus:ring focus:ring-purple-100 focus:outline-none"
                        />
                      ) : (
                        <p
                          className="text-gray-600"
                          style={{ fontSize: "15px" }}
                        >
                          {userData?.userId?.phone}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="flex w-full">
                    <div className="pl-2 w-full">
                      <h1 style={{ fontSize: "14px", color:"black" }}>Email</h1>
                      {editableFields ? (
                        <input
                          type="text"
                          name="email"
                          value={changedEmail}
                          onChange={(e) => {
                            setChangedEmail(e.target.value);
                            setEmailCheck(true);
                          }}
                          className="border-b border-gray-400 w-full pl-1 p-1 mt-1 focus:ring focus:ring-purple-100 focus:outline-none"
                        />
                      ) : (
                        <p
                          className="text-gray-600"
                          style={{ fontSize: "15px" }}
                        >
                          {userData?.userId?.email}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="flex w-full">
                    <div className="pl-2 w-full">
                      <h1 style={{ fontSize: "14px", color:"black" }}>Address</h1>
                      {editableFields ? (
                        <input
                          type="text"
                          name="address"
                          value={userData?.address}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          className="border-b border-gray-400 w-full pl-1 p-1 mt-1 focus:ring focus:ring-purple-100 focus:outline-none"
                        />
                      ) : (
                        <p
                          className="text-gray-600"
                          style={{ fontSize: "15px" }}
                        >
                          {userData?.address}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="flex">
                    <div className="pl-2">
                      <h1 style={{ fontSize: "14px", color:"black" }}>PAN Number</h1>
                      <p className="text-gray-600" style={{ fontSize: "15px" }}>
                        {userData?.panNumber}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h1 className="text-xl ml-4 text-black">Permissions</h1>
          {editableFields ? (
          <div className="flex flex-wrap  ">
            <div className=" flex w-1/2 max-[440px]:w-full mb-4 px-2  align-middle">
              <div className="flex text-lg p-4 flex align-middle w-1/2">
                Dashboard{" "}
              </div>
              <ReactSwitch
                checked={userData?.isAccessToDashboardTab}
                onChange={(e) => {
                  handleSwitch("isAccessToDashboardTab", e);
                }}
                className="pl-8 flex align-middle my-auto"
              />
            </div>
            <div className=" flex w-1/2 max-[440px]:w-full mb-4 px-2  align-middle">
              <div className="flex text-lg p-4 flex align-middle w-1/2">
                Financial{" "}
              </div>
              <ReactSwitch
                checked={userData?.isAccessToFinancialDataTab}
                onChange={(e) => {
                  handleSwitch("isAccessToFinancialDataTab", e);
                }}
                className="pl-8 flex align-middle my-auto"
              />
            </div>
            <div className=" flex w-1/2 max-[440px]:w-full mb-4 px-2  align-middle">
              <div className="flex text-lg p-4 flex align-middle w-1/2">
                Support{" "}
              </div>
              <ReactSwitch
                checked={userData?.isAccessToSupportTab}
                onChange={(e) => {
                  handleSwitch("isAccessToSupportTab", e);
                }}
                className="pl-8 flex align-middle my-auto"
              />
            </div>

            <div className=" flex w-1/2 max-[440px]:w-full mb-4 px-2  align-middle">
              <div className="flex text-lg p-4 flex align-middle w-1/2">
                Report{" "}
              </div>
              <ReactSwitch
                checked={userData?.isAccessToReportTab}
                onChange={(e) => {
                  handleSwitch("isAccessToReportTab", e);
                }}
                className="pl-8 flex align-middle my-auto"
              />
            </div>

            <div className=" flex w-1/2 max-[440px]:w-full mb-4 px-2  align-middle">
              <div className="flex text-lg p-4 flex align-middle w-1/2">
                Member{" "}
              </div>
              <ReactSwitch
                checked={userData?.isAccessToMembersTab}
                onChange={(e) => {
                  handleSwitch("isAccessToMembersTab", e);
                }}
                className="pl-8 flex align-middle my-auto"
              />
            </div>

            <div className=" flex w-1/2 max-[440px]:w-full mb-4 px-2  align-middle">
              <div className="flex text-lg p-4 flex align-middle w-1/2">
                Client{" "}
              </div>
              <ReactSwitch
                checked={userData?.isAccessToClientTab}
                onChange={(e) => {
                  handleSwitch("isAccessToClientTab", e);
                }}
                className="pl-8 flex align-middle my-auto"
              />
            </div>

            <div className=" flex w-1/2 max-[440px]:w-full mb-4 px-2 align-middle">
              <div className="flex text-lg p-4 flex align-middle w-1/2">
                Notification{" "}
              </div>
              <ReactSwitch
                checked={userData?.isAccessToNotificationTab}
                onChange={(e) => {
                  handleSwitch("isAccessToNotificationTab", e);
                }}
                className="pl-8 flex align-middle my-auto"
              />
            </div>

            <div className=" flex w-1/2 max-[440px]:w-full mb-4 px-2 align-middle">
              <div className="flex text-lg p-4 flex align-middle w-1/2">
                Plans{" "}
              </div>
              <ReactSwitch
                checked={userData?.isAccessToPlansTab}
                onChange={(e) => {
                  handleSwitch("isAccessToPlansTab", e);
                }}
                className="pl-8 flex align-middle my-auto"
              />
            </div>

            <div className=" flex w-1/2 max-[440px]:w-full mb-4 px-2  align-middle">
              <div className="flex text-lg p-4 flex align-middle w-1/2">
                Leads{" "}
              </div>
              <ReactSwitch
                checked={userData?.isAccessToLeadsTab}
                onChange={(e) => {
                  handleSwitch("isAccessToLeadsTab", e);
                }}
                className="pl-8 flex align-middle my-auto"
              />
            </div>
            <div className=" flex w-1/2 max-[440px]:w-full mb-4 px-2  align-middle">
              <div className="flex text-lg p-4 flex align-middle w-1/2">
                Faq{" "}
              </div>
              <ReactSwitch
                checked={userData?.isAccessToFaqTab}
                onChange={(e) => {
                  handleSwitch("isAccessToFaqTab", e);
                }}
                className="pl-8 flex align-middle my-auto"
              />
            </div>
          </div>
        ) : (
        <div className="flex flex-wrap ">
            <div className=" flex w-1/2 max-[440px]:w-full mb-4 px-2  align-middle">
              <div className="flex text-lg p-4 flex align-middle w-1/2">
                Dashboard{" "}
              </div>
              <ReactSwitch
              disabled
                checked={userData?.isAccessToDashboardTab}
                onChange={(e) => {
                  handleSwitch("isAccessToDashboardTab", e);
                }}
                className="pl-8 flex align-middle my-auto"
              />
            </div>
            <div className=" flex w-1/2 max-[440px]:w-full mb-4 px-2  align-middle">
              <div className="flex text-lg p-4 flex align-middle w-1/2">
                Financial{" "}
              </div>
              <ReactSwitch disabled
                checked={userData?.isAccessToFinancialDataTab}
                onChange={(e) => {
                  handleSwitch("isAccessToFinancialDataTab", e);
                }}
                className="pl-8 flex align-middle my-auto"
              />
            </div>
            <div className=" flex w-1/2 max-[440px]:w-full mb-4 px-2  align-middle">
              <div className="flex text-lg p-4 flex align-middle w-1/2">
                Support{" "}
              </div>
              <ReactSwitch disabled
                checked={userData?.isAccessToSupportTab}
                onChange={(e) => {
                  handleSwitch("isAccessToSupportTab", e);
                }}
                className="pl-8 flex align-middle my-auto"
              />
            </div>

            <div className=" flex w-1/2 max-[440px]:w-full mb-4 px-2  align-middle">
              <div className="flex text-lg p-4 flex align-middle w-1/2">
                Report{" "}
              </div>
              <ReactSwitch disabled
                checked={userData?.isAccessToReportTab}
                onChange={(e) => {
                  handleSwitch("isAccessToReportTab", e);
                }}
                className="pl-8 flex align-middle my-auto"
              />
            </div>

            <div className=" flex w-1/2 max-[440px]:w-full mb-4 px-2  align-middle">
              <div className="flex text-lg p-4 flex align-middle w-1/2">
                Member{" "}
              </div>
              <ReactSwitch disabled
                checked={userData?.isAccessToMembersTab}
                onChange={(e) => {
                  handleSwitch("isAccessToMembersTab", e);
                }}
                className="pl-8 flex align-middle my-auto"
              />
            </div>

            <div className=" flex w-1/2 max-[440px]:w-full mb-4 px-2  align-middle">
              <div className="flex text-lg p-4 flex align-middle w-1/2">
                Client{" "}
              </div>
              <ReactSwitch disabled
                checked={userData?.isAccessToClientTab}
                onChange={(e) => {
                  handleSwitch("isAccessToClientTab", e);
                }}
                className="pl-8 flex align-middle my-auto"
              />
            </div>

            <div className=" flex w-1/2 max-[440px]:w-full mb-4 px-2 align-middle">
              <div className="flex text-lg p-4 flex align-middle w-1/2">
                Notification{" "}
              </div>
              <ReactSwitch disabled
                checked={userData?.isAccessToNotificationTab}
                onChange={(e) => {
                  handleSwitch("isAccessToNotificationTab", e);
                }}
                className="pl-8 flex align-middle my-auto"
              />
            </div>

            <div className=" flex w-1/2 max-[440px]:w-full mb-4 px-2 align-middle">
              <div className="flex text-lg p-4 flex align-middle w-1/2">
                Plans{" "}
              </div>
              <ReactSwitch disabled
                checked={userData?.isAccessToPlansTab}
                onChange={(e) => {
                  handleSwitch("isAccessToPlansTab", e);
                }}
                className="pl-8 flex align-middle my-auto"
              />
            </div>

            <div className=" flex w-1/2 max-[440px]:w-full mb-4 px-2  align-middle">
              <div className="flex text-lg p-4 flex align-middle w-1/2">
                Leads{" "}
              </div>
              <ReactSwitch disabled
                checked={userData?.isAccessToLeadsTab}
                onChange={(e) => {
                  handleSwitch("isAccessToLeadsTab", e);
                }}
                className="pl-8 flex align-middle my-auto"
              />
            </div>
            <div className=" flex w-1/2 max-[440px]:w-full mb-4 px-2  align-middle">
              <div className="flex text-lg p-4 flex align-middle w-1/2">
                Faq{" "}
              </div>
              <ReactSwitch disabled
                checked={userData?.isAccessToFaqTab}
                onChange={(e) => {
                  handleSwitch("isAccessToFaqTab", e);
                }}
                className="pl-8 flex align-middle my-auto"
              />
            </div>
          </div>)}
          

       

        </div>
      )}
    </form>
  );
};

export default MemberProfileDetail;
