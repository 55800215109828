import React, { useEffect } from 'react'
import { useQuery } from 'react-query';
import { getDashboard } from '../../Services/TicketServices';

const TicketDashboard = () => {

    const { data: dashboard, refetch } = useQuery({
        queryFn: getDashboard,
        queryKey: ['dashboard']
    })
    const filteredClosedData = dashboard?.ticketDashboardData?.filter((i) => i._id === 'CLOSED');
    const closedData = filteredClosedData?.map(i => ({
        ...i
    })).find(closed => closed);

    const filteredInProgressData = dashboard?.ticketDashboardData?.filter((i) => i._id === 'IN-PROGRESS')
    const inProgressData = filteredInProgressData?.map(i => ({
        ...i
    })).find(progress => progress);

    // const TotalClosedTicket=filteredClosedData?.map(obj => obj.totalfundInvested)
    //     .reduce((accumulator, current) => accumulator + current, 0)
    //     console.log("TotalClosedTicket",TotalClosedTicket);

    return (
        <div>
            <div className="mb-4 flex justify-between">
                <div>
                    <h2 className="text-xl font-semibold ml-4 ">Tickets</h2>
                   
                </div>
                {/* <button className="bg-purple-700 rounded py-0.5 mx-2 px-2 text-white mr-4">
          {" "}
          + Add clients
        </button> */}
            </div>
            <div className="flex w-full">
                {/* <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 mb-4 px-2">
                    <div className="bg-white shadow-md rounded-lg  p-4 flex flex-col justify-between">
                        <div className="flex flex-row">
                            <h3 className="flex text-md font-normal mb-2 ">New Tickets</h3>
                            <h1 className="flex text-green-800  ml-auto">+10%</h1>
                        </div>
                        <p className="text-lg font-bold">112</p>
                        <div className="bg-purple-200 rounded-lg mt-2 ">
                            <div
                                className="bg-purple-600 text-xs leading-none py-0.5 text-center text-white rounded"
                                style={{ width: "90%" }}
                            ></div>
                        </div>
                    </div>
                </div> */}

                <div className="w-full  mb-4 px-2">

                    <div className="bg-white shadow-md rounded-lg  p-4 flex flex-col justify-between border">
                        <div className="flex flex-row">
                            <h3 className="flex text-md font-semibold mb-2 ">Solved Tickets</h3>
                            <h1 className="flex text-green-800  ml-auto">+{closedData?.count}%</h1>
                        </div>
                        <p className="text-lg font-bold">{closedData?.count ? closedData?.count : "0"}</p>
                        <div className="bg-purple-200 rounded-lg mt-2 ">
                            <div
                                className="bg-purple-600 text-xs leading-none py-0.5 text-center text-white rounded"
                                style={{ width: `${closedData?.count}%` }}
                            ></div>
                        </div>
                    </div>


                </div>

                {/* <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 mb-4 px-2">
                    <div className="bg-white shadow-md rounded-lg  p-4 flex flex-col justify-between">
                        <div className="flex flex-row">
                            <h3 className="flex text-md font-normal mb-2 ">Open Pending</h3>
                            <h1 className="flex text-red-800  ml-auto">-10%</h1>
                        </div>
                        <p className="text-lg font-bold">100</p>
                        <div className="bg-purple-200 rounded-lg mt-2 ">
                            <div
                                className="bg-purple-600 text-xs leading-none py-0.5 text-center text-white rounded"
                                style={{ width: "40%" }}
                            ></div>
                        </div>
                    </div>
                </div> */}
                {
                    inProgressData?.count !== 0 &&
                    <div className="w-full  mb-4 px-2">
                        <div className="bg-white shadow-md rounded-lg  p-4 flex flex-col justify-between border">
                            <div className="flex flex-row">
                                <h3 className="flex text-md font-semibold  mb-2 ">
                                    Pending Tickets
                                </h3>
                                <h1 className="flex text-red-800  ml-auto">-{inProgressData?.count}%</h1>
                            </div>
                            <p className="text-lg font-bold">{inProgressData?.count ? inProgressData?.count : "0"}</p>
                            <div className="bg-purple-200 rounded-lg mt-2 ">
                                <div
                                    className="bg-purple-600 text-xs leading-none py-0.5 text-center text-white rounded"
                                    style={{ width: `${inProgressData?.count}%` }}
                                ></div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default TicketDashboard
