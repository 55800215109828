import React, { useEffect, useRef, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
// import { useUser } from '../../utils/UserContext';
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  getUserInfo,
  updateUserProfile,
  deleteUserPhoto,
} from "../../../Services/UserServices";
import axios from "axios";
import { getToken } from "../../../utils/getToken";
import Spinner from "../../Spinner/Spinner";
import { showTheme } from "../../Theme/Theme";
import { HiSave } from "react-icons/hi";
import { MdEdit } from "react-icons/md";
import { MdCloudUpload, MdDelete } from "react-icons/md";
import CryptoJS from 'crypto-js';

const Profile = () => {
  const initialUserData = {
    name: "Renu",
    memberId: "AD123",
    email: "123@gmail.com",
    mobileNumber: "1234567890",
    bankAccount: "1228009458",
    panCard: "ASDFGTR3RT",
    address: "123,abc nagar",
    avatar: null,
  };

  const queryClient = useQueryClient();

  const [idUserTest, setIdUserTest] = useState(null);
  const [changedName, setChangedName] = useState(null);
  const [changedMemberId, setChangedMemberId] = useState(null);
  const [changedEmail, setChangedEmail] = useState(null);
  const [changedPhoneNo, setChangedPhoneNo] = useState(null);
  // const [changedAddress, setChangedAddress] = useState(null);
  const [changedPANCard, setChangedPANCard] = useState(null);
  const [changedAddress, setChangedAddress] = useState(null);
  const [changedImg, setChangedImg] = useState(null);
  const [userData, setUserData] = useState(initialUserData);
  const [editableFields, setEditableFields] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [decryptedUserData, setDecryptedUserData] = useState(null)
  const [showMore, setShowMore] = useState(false)
  const [saveLoading, setSaveLoading] = useState(false)
  const secretKey = 'your-secret-key';
  // Retrieving encrypted data from local storage
  const encryptedDataFromStorage = localStorage.getItem('encryptedData');
  // Decrypting the data
  useEffect(() => {
    if (encryptedDataFromStorage) {
      // Decrypting the data
      const bytes = CryptoJS.AES.decrypt(encryptedDataFromStorage, secretKey);
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

      // Using the decrypted data
      setDecryptedUserData(decryptedData);
    }
  }, [encryptedDataFromStorage])
  console.log('user data', decryptedUserData);
  const fileInputRef = useRef(null);
  const {
    data: user,
    refetch,
    isLoading,
  } = useQuery({
    queryFn: getUserInfo,
    queryKey: ["user_profile_info"],
  });

  const mutateUserProfile = useMutation({
    mutationFn: updateUserProfile,
    mutationKey: ["upload_user_profile"],
    onSuccess: (data) => {
      refetch();
      queryClient.invalidateQueries("user_profile_info");
      toast.success(data.data.message);
      setChangedImg(null);
    },
    onError: (e) => {
      console.log(e);
      toast.error(error?.response?.data?.message);
    },
  });
  useEffect(() => {
    if (user) {
      setChangedMemberId(user?.memberId);
      setChangedEmail(user?.email);
      setChangedAddress(user?.address);
      setChangedPhoneNo(user?.phone);
      setChangedPANCard(user?.panCard?.number);
      setIdUserTest(user?._id);
    }
  }, [user]);

  const handleUploadProfile = () => {
    if (editableFields) {
      let formData = new FormData();
      formData.append("phone", changedPhoneNo);
      formData.append("email", changedEmail);
      formData.append("address", changedAddress);
      formData.append("profileImage", changedImg);
      mutateUserProfile.mutate(formData);
    } else {
      let formData = new FormData();
      formData.append("phone", user?.phone);
      formData.append("email", user?.email); //append the values with key, value pair
      formData.append("address", user?.address);
      formData.append("profileImage", user?.profileImageLink);
      mutateUserProfile.mutate(formData);
    }
    setEditableFields(false);
  };

  const toggleEditField = () => {
    setEditableFields(!editableFields);
  };
  const handleRemoveButtonClick = async () => {
    try {
      // Call deleteUserPhoto function
      await deleteUserPhoto();
      // Reset the selected image and display a success message
      setChangedImg(null);
      toast.success("Profile photo deleted successfully!");
      refetch();
    } catch (error) {
      // Handle errors
      toast.error(error?.response?.data?.message);
    }
  };
  const handleAvatarUpload = (e) => {
    const file = e.target.files[0];
    setChangedImg(file);
  };

  const handleUploadButtonClick = async () => {
    await fileInputRef.current.click();
    // handleUploadProfile();
    // setFlag(true);
  };

  // const handleRemoveButtonClick = () => {
  //   // setUserData({ ...userData, avatar: "" });
  //   setChangedImg(null);
  // };

  const getImageSrc = () => {
    if (changedImg) {
      return URL.createObjectURL(changedImg);
    }

    if (user?.profileImageLink) {
      return user?.profileImageLink;
    }

    return "/image/Avatar.png";
  };
  const [messages, setMessages] = useState([]);
  const [error, setError] = useState(null);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const getUser = JSON.parse(localStorage.getItem("user"));
    setUserId(getUser?._id);
  }, []);

  useEffect(() => {
    if (idUserTest) {
      fetchData();
    }
  }, [idUserTest]);


  const fetchData = async () => {
    try {
      const token = getToken();

      const response = await axios.get(
        `https://techtime.pb18.in/api/message/all/66963589f77d4bd48b34f91d/${idUserTest}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMessages(response.data.data.messageData);
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="">
      {isLoading ? (
        <div className="flex justify-center " style={{ marginTop: "35vh" }}>
          <Spinner />
        </div>
      ) : (
        <div
          className="mb-8 border-style"
          style={{
            color: showTheme("text"),
          }}
        >
          <div className="shadow-md border pb-5 rounded-2xl ml-3 mr-3 sm:ml-3"
            style={{
              height: "auto",
              backgroundColor: showTheme("card"),
              border: showTheme("borderCard"),
            }}
          >
            <div className="flex flex-col lg:flex-col space-y-4 mt-5 lg:space-y-0 gap-12 " style={{ overflow: "auto !important" }}>
              <div className=" flex flex-wrap max-[1085px]:flex-col min-[1085px]:flex-row gap-16 md:gap-10 items-center  w-full" style={{ overflow: "auto !important" }}>

                <div style={{ display: "flex", flexDirection: "row", overflow: "auto !important" }}>
                  <div className="flex flex-col items-center">
                    <div className="rounded-3xl w-11/12" style={{ overflow: "hidden", marginTop: "15px", border: showTheme("borderCard") }}>
                      <div className="sm:justify-center, md:justify-center" style={{ width: "250px", height: "auto", maxWidth: "250px", maxHeight: "220px", overflow: "hidden", marginLeft: "25" }}>
                        <img
                          src={getImageSrc()}
                          alt="Selected Image"
                          style={{ width: "100%", height: "220px", objectFit: "cover" }}
                        />
                      </div>
                    </div>

                    <div style={{ display: "flex", flexDirection: "row", gap: "20px" }} className="mt-2 w-full">
                      <div className="flex justify-center mt- text-nowrap items-center w-full" style={{ display: "flex", justifyContent: "right" }}>
                        {editableFields ? (
                          <button
                            title="Save Profile"
                            type="button"
                            onClick={handleUploadProfile}
                            className="bg-purple-700 text-white text-lg p-2 rounded-lg mt-2 hover:bg-purple-500 w-full"
                            style={{ marginTop: "5px", marginLeft: "10px", textAlign: "center", justifyContent: "center" }}
                          >
                            <div className="flex items-center gap-2 justify-center">
                              <HiSave /><span style={{ fontSize: "15px", fontWeight: "bold" }}>
                               Save Profile
                              </span>
                            </div>
                          </button>
                        ) : (
                          <button
                            title="Edit Profile"
                            type="button"
                            onClick={toggleEditField}
                            className="bg-purple-700 text-white text-lg p-2 rounded-lg mt-2 hover:bg-purple-500 w-full"
                            style={{ marginTop: "5px", marginLeft: "10px", textAlign: "center", justifyContent: "center" }}
                          >
                            <div className="flex gap-2 items-center justify-center">
                              <MdEdit /><span style={{ fontSize: "15px", fontWeight: "bold" }}>Edit Profile</span>
                            </div>
                          </button>
                        )}
                      </div>

                    </div>

                    {editableFields && (
                      <>
                        <div className="flex gap-2 mt-2 w-full"  >
                          <label className="w-full">
                            <input
                              type="file"
                              accept="image/*"
                              onChange={handleAvatarUpload}
                              ref={fileInputRef}
                              hidden
                            />
                            <button
                              title="Upload Picture"
                              className="bg-purple-700 text-white text-lg  p-2 rounded-lg   hover:bg-purple-500 text-nowrap w-full"
                              onClick={handleUploadButtonClick}
                            >
                              {/* {user?.profileImageLink
                                && */}
                              <div className="flex items-center gap-1 w-full justify-center">
                                <MdCloudUpload /><span style={{ fontSize: "10px", fontWeight: "semibold" }}>Upload Image</span>
                              </div>
                              {/* } */}
                            </button>
                          </label>
                          <button
                            title="Delete"
                            onClick={handleRemoveButtonClick}
                            className="bg-gray-700 text-white text-lg  p-2 rounded-lg  hover:bg-gray-500 w-full"

                          >
                            <div className="flex items-center gap-1 w-full justify-center">
                              <MdDelete /><span style={{ fontSize: "10px", fontWeight: "semibold" }}>Delete Image</span>
                            </div>
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </div>

                <form className="flex flex-col space-y-5 mt-5 max-[450px]:px-10" >
                  <div className="flex flex-wrap gap-y-10">
                    <div className="w-full lg:w-3/4">
                      <div className="flex flex-col">
                        <h1 className="font-semibold" style={{ color: showTheme("text") }}>Name:</h1>
                        <span className="bg-inherit font-normal text-slate-500 mt-2">{decryptedUserData?.first_name ? decryptedUserData?.first_name + ' ' + decryptedUserData?.last_name : decryptedUserData?.name}</span>
                      </div>
                    </div>
                    <div className="w-full lg:w-1/4">
                      <div className="flex flex-col">
                        <h1 className="font-semibold text-nowrap" style={{ color: showTheme("text") }}>Member ID:</h1>
                        <span className="bg-inherit font-normal text-slate-500 mt-2">{user?.memberId}</span>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-wrap gap-y-10">
                    <div className="w-full lg:w-3/4 ">
                      <div className="flex flex-col mr-5">
                        <h1 className="font-semibold" style={{ color: showTheme("text") }}>Email:</h1>
                        {editableFields ? (
                          <input
                            type="text"
                            value={changedEmail}
                            onChange={(e) => {
                              setChangedEmail(e.target.value);
                            }}
                            placeholder="Ryan Lee"
                            className="border border-gray-400  lg:w-40 w-full rounded pl-1 p-1 focus:ring focus:ring-purple-100 focus:outline-none mt-2 mr-5"
                          />
                        ) : (
                          <span className="bg-inherit font-normal text-slate-500 break-all">{user?.email}</span>
                        )}
                      </div>
                    </div>
                    <div className="w-full lg:w-1/4">
                      <div className="flex flex-col mr-5">
                        <h1 className="font-semibold text-nowrap" style={{ color: showTheme("text") }}>Phone Numbers:</h1>
                        {editableFields ? (
                          <input
                            type="tel"
                            maxLength={10}
                            value={changedPhoneNo}
                            onChange={(e) => {
                              const re = /^[0-9\b]+$/;
                              if (e.target.value === '' || re.test(e.target.value)) {
                                setChangedPhoneNo(e.target.value);
                              }
                            }}
                            placeholder="New Delhi"
                            className="border border-gray-400 lg:w-40 w-full rounded pl-1 p-1 focus:ring focus:ring-purple-100 focus:outline-none mt-2 mr-5"

                          />
                        ) : (
                          <span className="bg-inherit font-normal text-slate-500">{user?.phone}</span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-wrap gap-y-10" style={{ marginTop: "30px" }}>
                    <div className="w-full lg:w-3/4">
                      <div className="flex flex-col">
                        <h1 className="font-semibold" style={{ color: showTheme("text") }}>PAN Card:</h1>

                        <span className="bg-inherit font-normal text-slate-500">{decryptedUserData?.pan_number ? decryptedUserData?.pan_number : decryptedUserData?.panCard?.number}</span>

                      </div>
                    </div>
                    <div className="w-full lg:w-1/4">
                      <div className="flex flex-col mr-5">
                        <h1 className="font-semibold" style={{ color: showTheme("text") }}>Address:</h1>
                        {editableFields ? (
                          <textarea

                            value={changedAddress}
                            onChange={(e) => {
                              setChangedAddress(e.target.value);
                            }}
                            placeholder="New Delhi"
                            className="border border-gray-400 lg:w-40 w-full h-10 sm:h-12 rounded pl-1 p-1 focus:ring focus:ring-purple-100 focus:outline-none mt-2 mr-5"

                          />
                        ) : (
                          <span className="bg-inherit font-normal text-slate-500">{user?.address}</span>
                        )}
                      </div>
                    </div>
                  </div>

                </form>
              </div>
            </div>
          </div>
          <div className="shadow-md rounded-xl my-5  ml-3 mr-3 sm:ml-3 md-mb-5 " style={{ backgroundColor: showTheme("card"), border: showTheme("borderCard"), height: "max-content" }}>
            <div className="flex flex-col lg:flex-row space-y-4 lg:space-y-0 gap-12 bg-purple-600 p-6 rounded-t-xl">
              <div className="flex gap-20 w-full">
                <h1 className="text-xl font-normal text-white">Message From Admin</h1>
              </div>
            </div>
            <div className="message-container rounded-b-2xl" style={{ backgroundColor: showTheme("card") }}>
              {error ? (
                <p className="text-red-500 mt-5 ml-5">Error: {error}</p>
              )
                : messages.length > 0 ? (
                  <div className="mt-1 mx-2 p-2" style={{ height: "auto", overflowY: "auto" }}>
                    {messages?.slice(0, 5).map((message) => (
                      <div key={message._id} className="border-b border-gray-300 py-4" style={{ color: showTheme("text") }}>
                        <p>{message.message}</p>
                      </div>
                    ))}
                    {
                      showMore &&
                      messages?.slice(5, 10000).map((message) => (
                        <div key={message._id} className="border-b border-gray-300 py-4" style={{ color: showTheme("text") }}>
                          <p>{message.message}</p>
                        </div>
                      ))
                    }
                  </div>
                ) : (
                  <p className="mt-5 ml-7 w-full" style={{ color: showTheme("text") }}>No messages</p>
                )}
            </div>
            {
              messages?.length > 5 &&
              <button onClick={() => { setShowMore(!showMore) }} className="text-purple-700 pl-2">{showMore ? 'Show less...' : 'Show more...'}</button>
            }
          </div>
          <ToastContainer theme="light" />
        </div>
      )}
    </div>
  );
};

export default Profile;
