import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { FiCheckCircle } from 'react-icons/fi';
// import { useUser } from "../utils/UserContext";
import { verifyPancard } from '../Services/UserServices';
import { LayoutDashboard } from 'lucide-react';
import { NavLink } from "react-router-dom";
import { showTheme } from './Theme/Theme';
import { ToastContainer, toast } from 'react-toastify';
import CryptoJS from 'crypto-js';

function Pancard() {
  const navigate = useNavigate();
  const location = useLocation();
  const [userInfo, setUserInfo] = useState(null);

  const [panCard, setPanCard] = useState("");
  const [isValidPanCard, setIsValidPanCard] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [token, setToken] = useState(null);
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handlePanCardChange = (event) => {
    const inputValue = event.target.value;
    const isValidFormat = /^[A-Za-z]{5}\d{4}[A-Za-z]$/.test(inputValue);

    setPanCard(inputValue);
    setIsValidPanCard(isValidFormat);
  };

  const handleVerifyClick = () => {
    if (isValidPanCard) {
      setIsVerified(true);
    } else {
      setIsVerified(false);
    }
  };

  const verifyPan = async (event) => {
    event.preventDefault();

    try {
      setIsLoading(true);
      const formData = {
        pan: panCard.toUpperCase()
      }
      const { data } = await verifyPancard(formData);
      setShowPopup(true);
      if (data.status && data?.data) {
        const secretKey = 'your-secret-key';
      const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data?.data), secretKey).toString();
      localStorage.setItem('encryptedData', encryptedData);
        setUserInfo({
          firstName: data.data.first_name,
          lastName: data.data.last_name,
          panCard: data.data.pan_number,
        });
      }
      
    } catch (error) {
      setError(true);
      console.error(error);
    } finally{
      setIsLoading(false);
    }
  };
  return (
    <>
      <ToastContainer />
      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-80">
          <div className="bg-white w-1/2 mx-auto p-4 rounded-lg shadow-lg">
            <div className="fixed inset-0 flex items-center rounded-xl justify-center bg-black bg-opacity-50 backdrop-blur-sm z-50">
              <div className="p-8 shadow-md rounded-xl ml-5 mr-5 sm:ml-" style={{ backgroundColor: showTheme("cardBack"), overflow: "hidden" }}>
                <h1 className="text-xl leading-tight font-bold text-center">
                  <span style={{ fontWeight: "bold", fontWeight: "40px" }}>Congratulations!</span> <br></br><span style={{ fontSize: "14px" }}>Your account is created.</span>
                </h1>
                <img src='/image/completed.svg' width={200} className='m-auto' alt='image' />
                <p className="text-lg flex justify-center text-center mt-5" style={{ color: showTheme("text") }}>Please add your details to complete your registration.</p>
                <div className="flex justify-center gap-10 mt-10">
                  <div className="flex flex-col sm:flex-row w-full max-w-xl text-nowrap bg-indigo-500 hover:bg-indigo-400 focus:bg-indigo-400 text-white font-normal rounded-lg mx-2 px-4 py-3 items-center gap-2 justify-center">
                    <span className="text-center sm:text-left">Go to <Link to='/profile' className='text-yellow-300 underline'>Profile</Link></span></div>
                </div>
              </div>
            </div>

            <div className='w-full ml-5'>
              <h5 className='text-orange-500'>Note: do not close this window!</h5>
            </div>
          </div>
        </div>

      )}
      <section className="otp-page flex flex-col md:flex-row min-h-screen items-center justify-center">
        <div className="bg-white w-full md:w-full lg:w-1/2 xl:w-1/3 mx-auto  flex items-center justify-center px-4">
          <div className="w-full p-4">
            <div className="mb-20">
              <img className='w-20' src="image/Logo(1).png" alt="techtime logo" />
            </div>
            <h1 className="text-3xl font-medium leading-tight mt-12 text-purple-500">
              PAN Card Verification.
            </h1>
            <form className="mt-6" action="#" onSubmit={(event) => {
              verifyPan(event);
            }}>
              <div className="relative">
                <h1 className='mt-10 font-light text-purple-500'>PAN</h1>
                <div className="flex items-center">
                  <input
                    type="text"
                    name="panCard"
                    value={panCard}
                    onChange={handlePanCardChange}
                    placeholder="Enter your PAN"
                    className={`w-full px-2
                     py-3 rounded-lg mt-2 border 
                    ${panCard.length === 10 ? "border-purple-700" : "border-red-500"}
                      focus:bg-white focus:ring focus:ring-purple-100 focus:outline-none`}
                    autoFocus
                    autoComplete="off"
                    style={{ textTransform: "uppercase" }}
                    required
                  />
                  {panCard.length === 10 && (
                    <FiCheckCircle className="text-purple-500 ml-2" size={20} />
                  )}
                </div>
                {panCard.length !== 10 && (
                  <p className="text-red-500 text-sm mt-2 ">Please enter a valid PAN card number</p>
                )}
              </div>
              <div className="flex items-center justify-between mt-4">
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="agreeToTerms"
                    checked={agreeToTerms}
                    onChange={() => setAgreeToTerms(!agreeToTerms)}
                    className="mr-2"
                    required
                  />
                  <label htmlFor="default-checkbox" className="ml-2 text-sm font-medium text-gray-900">
                    I agree with
                    <NavLink to="/terms-and-conditions" className="text-purple-700">
                      {' '}Terms and Conditions
                    </NavLink>
                  </label>
                </div>
              </div>
              <button
                title='Verify'
                type="submit"
                onClick={handleVerifyClick}
                className={`w-full block rounded-lg px-4 py-3 mt-6 ${agreeToTerms
                  ? "w-full block bg-purple-700 hover:text-white-700 text-white font-semibold rounded-lg px-4 py-3 mt-6"
                  : "bg-purple-200 text-gray-600 cursor-not-allowed"
                  }`}
                disabled={!agreeToTerms}
              >
                 {
                      isLoading ?
                        <div class="three-body-button">
                          <div class="three-body__dot"></div>
                          <div class="three-body__dot"></div>
                          <div class="three-body__dot"></div>
                        </div>
                        :
                        'Verify'
                    }
                
              </button>
            </form>
            <h1 className="mt-36 font-light">
              TechTimes @2023
            </h1>
          </div>
        </div>
        <div className="hidden md:block lg:block sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2">
          <img
            src="/image/image.jpg"
            alt="image"
            className="w-full min-[768px]:w-auto h-auto max-w-full max-h-full"
          />
        </div>

      </section>
    </>

  )
}
export default Pancard;

