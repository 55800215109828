import React, { useEffect, useState } from "react";
import { showTheme } from "../../Theme/Theme";
import moment from "moment";
import { getTransactionLog } from "../../../Services/UserServices";
import { useQuery } from "react-query";
import { Pagination } from "@mui/material";
import { BiMessageAlt } from "react-icons/bi";
import { Spinner } from "@material-tailwind/react";

const Progress = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [recordsPerPage, setRecordsPerPage] = useState(5);
  const [timer, setTimer] = useState({ hours: 0, minutes: 0, seconds: 0 });

  const { data: transaction, isLoading } = useQuery({
    queryKey: ["transaction_log", currentPage, recordsPerPage],
    queryFn: () => getTransactionLog(currentPage, recordsPerPage),
  });

  const transactionData = transaction?.data?.data?.transactionLogData;
  const totalPages = Math.ceil(
    transaction?.data?.data?.noOfEnteries / recordsPerPage
  );

  const getItemProps = (index) => ({
    variant: currentPage === index ? "filled" : "text",
    color: "gray",
    onClick: () => setCurrentPage(index),
  });

  const next = () => {
    if (currentPage === totalPages) return;

    setCurrentPage(currentPage + 1);
  };

  const prev = () => {
    if (currentPage === 1) return;

    setCurrentPage(currentPage - 1);
  };

  let start = (currentPage - 1) * recordsPerPage + 1;
  let end = transaction?.data?.data?.noOfEnteries;

  if (recordsPerPage < transaction?.data?.data?.noOfEnteries) {
    end = recordsPerPage * currentPage;
    if (end > transaction?.data?.data?.noOfEnteries) {
      end = transaction?.data?.data?.noOfEnteries;
    }
  }

  useEffect(() => {
    if (transactionData && transactionData.length > 0) {
      const latestTransaction = transactionData[0]; // Get the latest transaction
      const latestTimestamp = moment(latestTransaction.createdAt); // Get the createdAt time

      const updateTimer = () => {
        const now = moment();
        const diff = moment.duration(now.diff(latestTimestamp)); // Calculate the time difference

        const hours = Math.floor(diff.asHours());
        const minutes = Math.floor(diff.minutes());
        const seconds = Math.floor(diff.seconds());

        setTimer({ hours, minutes, seconds });
      };

      // Initial timer calculation
      updateTimer();

      // Set up the interval to update the timer every second
      const interval = setInterval(updateTimer, 1000);

      return () => clearInterval(interval); // Clear the interval on component unmount
    }
  }, [transactionData]);


  return (<>
       <div
      className="bg-white rounded-2xl shadow-md border mb-8 ml-3 mr-3 sm:ml-3 border-style p-4"
      style={{
        backgroundColor: showTheme("card"),
        border: showTheme("borderCard"),
      }}
    >
      <div className="flex flex-col md:flex-row items-center justify-between md:space-x-4">
        <div className="flex flex-col items-center w-full md:w-1/3 md:space-y-4 md:items-start">
          <div
            className="w-full h-24 flex justify-center items-center rounded-md"
            style={{
              backgroundColor: showTheme("topHeader"),
              border: showTheme("borderCard"),
            }}
          >
            <span style={{ color: showTheme("text") }}>{timer.hours}</span>
            <span
              className=""
              style={{ color: showTheme("text"), marginLeft: "5px" }}
            >
              Hour
            </span>
          </div>
        </div>
        <div className="flex flex-col items-center w-full md:w-1/3 md:space-y-4 md:items-start">
          <div
            className="w-full h-24 flex justify-center items-center rounded-md"
            style={{
              backgroundColor: showTheme("topHeader"),
              border: showTheme("borderCard"),
            }}
          >
            <span style={{ color: showTheme("text") }}>{timer.minutes}</span>
            <span
              style={{ color: showTheme("text"), marginLeft: "5px" }}
            >
              Minute
            </span>
          </div>
        </div>
        <div className="flex flex-col items-center w-full md:w-1/3 md:space-y-4 md:items-start">
          <div
            className="w-full h-24 flex justify-center items-center rounded-md"
            style={{
              backgroundColor: showTheme("topHeader"),
              border: showTheme("borderCard"),
            }}
          >
            <h1 style={{ color: showTheme("text") }}>{timer.seconds}</h1>
            <span
              style={{ color: showTheme("text"), marginLeft: "5px" }}
            >
              Second
            </span>
          </div>
        </div>
      </div>


      <div >
      {isLoading ? (
        <div className="flex justify-center " style={{ marginTop: "35vh" }}>
          <Spinner />
        </div>
      ) : (
        <div >
          {transactionData?.length > 0 ? (
            <div className="flex flex-col lg:flex-col space-y-4 mt-5 ml-5 lg:space-y-0 gap-12" >
             
              <div className="me-6">
                <div className="overflow-x-auto">
                  <table className="min-w-full">
                    <thead className="" style={{ backgroundColor: showTheme("topHeader"), border: showTheme("borderCard") }}>

                      <tr style={{ fontSize: "16px", color: showTheme("text") }}>
                        <th className="p-4 text-left">Plan</th>
                        <th className="p-4 text-left">Date</th>
                        <th className="p-4 text-left">Amount</th>
                        <th className="p-4 text-left">Transaction Type</th>
                      </tr>
                    </thead>
                    <tbody className="" style={{ backgroundColor: showTheme("cardBack"), border: showTheme("borderCard") }}>

                      {transactionData.map((i) => (
                        <tr
                          key={i.id}
                          className=""
                          style={{ borderBottom: showTheme("borderCard") }}
                        >
                          <td className="p-4 text-left" style={{ color: showTheme("text"), textTransform:"capitalize" }}>
                            
                            {i?.userPlanId?.planId?.planName}
                            </td>
                          <td className="p-4 text-left" style={{ color: showTheme("text"), textTransform:"capitalize" }}>
                          {moment(i.createdAt).format("Do MMMM YYYY, h:mm a")}
                          </td>

                          
                          <td className="p-4 text-left" style={{ color: showTheme("text"), textTransform:"capitalize" }}>
                            
                              {i?.amount}
                          </td>
                          <td className="p-4 text-left" style={{ color: showTheme("text"), textTransform:"capitalize" }}>
                          {i?.transactionType}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="flex flex-wrap items-center gap-4 justify-between my-6 max-[700px]:justify-start max-[700px]:gap-0 max-[700px]:text-xs">
                  <div className="text-purple-700">
                    {`${start}-${end} of ${transaction?.data?.data?.noOfEnteries} items`}
                  </div>
                  <div className="flex items-center gap-4 justify-center" style={{ color: showTheme("text") }}>
                    <Pagination count={totalPages} size="large" showFirstButton showLastButton page={currentPage} onChange={(event, value) => { setCurrentPage(value) }}
                      className={`${localStorage.getItem("darkMode") == "true" && 'pagination-transaction'} pagination-all`}
                    />
                  </div>
                  <div>
                    <select className="border rounded p-1" style={{backgroundColor:showTheme('card'),color:showTheme('logoColor')}} value={recordsPerPage} onChange={(e) => { setRecordsPerPage(e.target.value) }}>
                      <option value='5' >Show 5</option>
                      <option value='10' >Show 10</option>
                      <option value='15' >Show 15</option>
                    </select>
                  </div>
                </div>

              </div>
            </div>
           ) : (
            <div
              className=" mt-11 font-semibold text-2xl ml-10 "
              style={{ color: showTheme("text") }}
            >
              No Transaction History
            </div>
          )}
        </div>
      )}
    </div>

    
    
    </div>
   
    </>
  );
};

export default Progress;
