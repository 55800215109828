import React, { useEffect, useRef, useState } from "react";
import { MoreVertical, } from 'lucide-react';
import { MdOutlineClose } from "react-icons/md";
import { useQuery } from "react-query";
import { getAdminTickets, updateTicket } from "../../Services/TicketServices";
import moment from "moment";
import { getToken } from "../../utils/getToken.js";
import TicketDashboard from "./TicketDashboard";
import '../Admin/admin.css'
import Spinner from "../Spinner/Spinner";
import ClientProfileDetail from "./Client/ClientProfileDetail";
import { useNavigate, useParams } from 'react-router-dom';
import Pagination from '@mui/material/Pagination';
import { Close } from "@mui/icons-material";

function SupportDesk() {
  const params = useParams('receiverID');
  const navigate = useNavigate();
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(null);
  const [isMessagePopupOpen, setMessagePopupOpen] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(5);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [isProfile, setIsProfile] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [searchForAll, setSearchForAll] = useState('');
  const [sortKey, setSortKey] = useState('');
  const [sortOrder, setSortOrder] = useState('DESC');
  const [dateValue, setDateValue] = useState('');


  const { data: tickets, refetch, error, isLoading } = useQuery({
    queryFn: () => getAdminTickets(currentPage, recordsPerPage, searchForAll, sortKey, dateValue),
    queryKey: ['tickets', currentPage, recordsPerPage, searchForAll, sortKey, dateValue],
  })
  useEffect(() => {
    if (sortKey === 'name A') {
      setSortOrder('ASC')
    }
  }, [sortKey])
  const handleMoreVerticalClick = (id, index) => {
    // setMenuOpen(!isMenuOpen);
    setMenuOpen((prevIndex) => (prevIndex === index ? null : index));
  };
  const close = () => {
    setMenuOpen(!isMenuOpen);
  }
  useEffect(() => {
    if (!getToken()) {
      navigate("/")
    }
  }, [])

  const handleMessageClick = (index) => {
    setMessagePopupOpen((prevIndex) => (prevIndex === index ? null : index));
    setMenuOpen(!isMenuOpen);
  };


  const handleCloseMessagePopup = () => {
    setMessagePopupOpen(null);
  };

  const handleStatusChange = async (id) => {
    try {
      const formData = new FormData();
      formData.append('solveStatus', 'CLOSED')
      const { data } = await updateTicket(deleteIdP, formData);
      // console.log(data);
      refetch();
      setDeleteIdP("");
    }
    catch (e) {
      console.log(e);
    }
  };
  const totalPages = Math.ceil(tickets?.noOfEnteries / recordsPerPage);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setIsSearch(!isSearch)
    refetch();
  }
  let start = (currentPage - 1) * recordsPerPage + 1
  let end = tickets?.noOfEnteries

  if (recordsPerPage < tickets?.noOfEnteries) {
    end = recordsPerPage * currentPage
    if (end > tickets?.noOfEnteries) {
      end = tickets?.noOfEnteries;
    }
  }
  useEffect(() => {
    if (params?.receiverID) {
      handleViewProfileClick(params?.receiverID);
    }
  }, [params])
  const handleViewProfileClick = (clientId) => {
    setIsProfile(true);
    setSelectedUserId(clientId);
  };
  const [deleteIdP, setDeleteIdP] = useState("");
  const handleOpenConfirmation = (id) => {
    setDeleteIdP(id);
    setConfirmationOpen(true);
  };

  // Function to handle closing the confirmation popup
  const handleCloseConfirmation = () => {
    setConfirmationOpen(false);
  };

  // Function to handle closing the ticket and closing both message details and confirmation popups
  const handleCloseTicket = async (id) => {
    try {
      // Close the ticket here
      await handleStatusChange(id);
      // Close the message details popup
      handleCloseMessagePopup();
      // Close the confirmation popup
      handleCloseConfirmation();
    } catch (error) {
      console.error('Error occurred while closing the ticket:', error);
    }
  };
  const handleReset = () => {
    setDateValue('');
    setSortKey('');
    setSearchForAll('')
  }
  return (<div>
    {isProfile ? (
      <ClientProfileDetail
        setIsProfile={setIsProfile}
        selectedUserId={selectedUserId}
      />
    ) : (
      <div
        className="bg-white rounded-2xl shadow-md mb-8 ml-3 mr-3 sm:ml-3 border-style p-2"
      >
        <div className="combined-input-container">
          <div className="relative combined-input w-full ">
            <input
              className="focus:outline-none"
              type="text"
              placeholder="Search here..."
              id="id"
              autoFocus
              name="searchForAll"
              value={searchForAll}
              onChange={(e) => {
                setSearchForAll(e.target.value);
              }}
            />
            <div className="right-0 top-0  absolute flex">
              <div
                className="w-full text-purple-700 hover:text-purple-800 font-semibold border-0 px-4 pt-1 mt-auto -mb-0.5"
                onClick={() => {
                  setSearchForAll('');
                }}
              ><Close /></div>
            </div>
          </div>
          <div className="w-full combined-input-2">
            <input
              type="date"
              className="focus:outline-none w-full"
              id="id"
              name="dateValue"
              value={dateValue}
              onChange={(e) => {
                setDateValue(e.target.value);
              }}
            />
          </div>
          <div className=" combined-input-3 flex whitespace-nowrap gap-2 items-center">
            <select className="focus:border-purple-500 focus:ring-2 focus:ring-purple-200 focus:outline-none"
              // disabled={isFilter}
              name="sortKey"
              value={sortKey}
              onChange={(e) => setSortKey(e.target.value)}>
              <option value=''>Sort</option>
              <option value='name DESC'>A-Z</option>
              <option value='name ASC'>Z-A</option>
              <option value='createdAt DESC'>Oldest First</option>
              <option value='createdAt ASC'>Newest First</option>
            </select>
          </div>
        </div>
        <div>
          <button className="text-red-600" onClick={handleReset}>Reset</button>
        </div>
        {isLoading ? (
          <div style={{ display: "flex", marginTop: "20%" }} className="flex w-full justify-center"> <Spinner /></div>
        ) : (
          <div className="mt-3">

            <TicketDashboard />


            {
              tickets?.noOfEnteries !== 0 ?
                <div className="p-4 overflow-auto">
                  <div className="flex justify-between">
                    <h1 className="text-xl font-semibold text-black">Ticket List</h1>
                  </div>
                  <div className=" w-full" >
                    <table className="w- mt-4 border border-gray-200 ">
                      <thead className="bg-gray-200">
                        <tr style={{ fontSize: "16px" }}>
                          <th className="py-2 px-4 text-center">No</th>
                          <th className="py-2 px-4 text-center">ID</th>
                          <th className="py-2 px-4 text-center">Name</th>
                          <th className="py-2 px-4 text-center">Phone No</th>
                          <th className="py-2 px-4 text-center">Email</th>
                          <th className="py-2 px-4 text-center">Message</th>
                          <th className="py-2 px-4 text-center">Created At</th>
                          <th className="py-2 px-4 text-center">Status</th>
                          <th className="py-2 px-4 text-center">Action</th>
                          <th></th>

                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200" id="search">
                        {
                          tickets?.ticketDetails?.length != 0 &&
                          tickets?.ticketDetails?.map((ticket, index) =>
                            <tr style={{ fontSize: "14px" }} key={ticket._id} id="#clientname">
                              <td className="py-2 px-4 text-center">{index + 1}</td>
                              <td className="py-2 px-4 text-center">{ticket?.ticketId}</td>
                              <td className="py-2 px-4 text-center" style={{ color: "blue", cursor: "pointer" }} onClick={() => {
                                handleViewProfileClick(ticket.userId);
                              }}>{ticket?.name}</td>
                              <td className="py-2 px-4 text-center">{ticket?.phone}</td>
                              <td className="py-2 px-4 text-center">{ticket?.email}</td>
                              <td className="py-2 px-4 text-center  inline-block overflow-hidden text-nowrap text-ellipsis w-24">{ticket?.issueSubject}</td>
                              <td className="py-2 px-4 text-center" style={{ whiteSpace: "nowrap" }}>
                                {moment(ticket?.createdAt).format('DD/MM/YYYY')}
                              </td>
                              <td className="py-2 px-4 text-center"> {ticket?.solveStatus}</td>
                              <td className="py-2 px-4  text-center">
                                {ticket?.solveStatus === 'CLOSED' ? (
                                  <span className="text-slate-500">Closed</span>
                                ) : (
                                  <select
                                    value={ticket?.solveStatus.toUpperCase()}
                                    onChange={(e) => {
                                      if (e.target.value === "CLOSED") {
                                        handleOpenConfirmation(ticket._id);
                                      } else {
                                        handleStatusChange(ticket._id);
                                      }
                                    }}
                                    className="py-2 px-4 bg-white border rounded focus:ring focus:ring-purple-200 focus:outline-none"
                                    disabled={ticket?.solveStatus === 'CLOSED'}
                                  >
                                    <option value="INPROGRESS">In Progress</option>
                                    <option value="CLOSED">Close</option>
                                  </select>
                                )}
                              </td>


                              <td className="py-2 px-4 text-center" onMouseLeave={close}>
                                <div className="relative inline-block text-left">
                                  <button
                                    title='Icon for Delete, message details'
                                    className="text-secondary"
                                    onMouseEnter={() => handleMoreVerticalClick(ticket._id, index)}
                                  >
                                    <MoreVertical />
                                  </button>

                                  {index === isMenuOpen && (
                                    <div className="absolute z-50 -ml-20 bottom-9 right-2 transform bg-white border rounded-md shadow-lg">
                                      <button
                                        className="block px-4 py-2 whitespace-nowrap text-gray-800 hover:bg-gray-200"
                                        onClick={() => handleMessageClick(index)}
                                      >
                                        Message Details
                                      </button>
                                    </div>
                                  )}
                                  {index === isMessagePopupOpen && (
                                    <div className="fixed inset-0 z-50 overflow-auto flex items-center justify-center backdrop-blur-lg  bg-gray-900 bg-opacity-25">
                                      <div className="bg-white rounded-xl flex flex-col w-96 max-[400px]:w-full">
                                        <div className="flex justify-between p-3 bg-purple-700 text-white rounded-t-xl ">
                                          <h2 className="text-xl font-bold text-center flex-grow">Message</h2>
                                          <MdOutlineClose
                                            onClick={handleCloseMessagePopup}
                                            className="cursor-pointer w-8 h-8 text-white hover:text-gray-300"
                                          />
                                        </div>
                                        <div className="p-4 overflow-auto w-96">
                                          <p className="mb-2 text-gray-800">Message: {ticket?.issueSubject}</p>
                                          <p className="text-gray-600 text-right">Date: {new Date(ticket?.createdAt).toLocaleDateString()}</p>
                                        </div>
                                      </div>
                                    </div>

                                  )}
                                  {/* // Confirmation popup */}
                                  {isConfirmationOpen && (
                                    <div className="fixed inset-0 z-50 overflow-auto flex items-center justify-center backdrop-blur-md bg-gray-900 bg-opacity-10">
                                      <div className="bg-white rounded-xl flex flex-col w-96">
                                        <div className="flex justify-between p-3 bg-purple-700 text-white rounded-t-xl">
                                          <h2 className="text-xl font-bold text-center flex-grow">Confirm Closure</h2>
                                          <MdOutlineClose
                                            onClick={handleCloseConfirmation}
                                            className="cursor-pointer w-8 h-8 text-white hover:text-gray-300"
                                          />
                                        </div>
                                        <div className="p-4 overflow-auto w-96">
                                          <p className="mb-4 text-gray-800 text-center" style={{ fontSize: "18px" }}>Are you sure you want to close this ticket?</p>
                                          <div className="flex justify-center">
                                            <button className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded-md mr-2" onClick={() => handleCloseTicket(ticket._id)}>Yes, Close Ticket</button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}


                                </div>
                              </td>
                            </tr>

                          )
                        }
                      </tbody>
                    </table>
                  </div>

                </div>
                :
                <div>
                  No Record Found.
                </div>
            }
            <div className="flex flex-wrap items-center gap-4 justify-between mt-6 max-[700px]:justify-start max-[700px]:gap-0 max-[700px]:text-xs">
              <div className="text-purple-700">
                {`${start}-${end} of ${tickets?.noOfEnteries} items`}
              </div>
              <div className="flex items-center gap-4 justify-center">
                <Pagination count={totalPages} size="large" showFirstButton showLastButton page={currentPage} onChange={(event, value) => { setCurrentPage(value) }} />
              </div>
              <div>
                <select className="border rounded p-1" value={recordsPerPage} onChange={(e) => { setRecordsPerPage(e.target.value) }}>

                  <option value='5'>Show 5</option>
                  <option value='10'>Show 10</option>
                  <option value='15'>Show 15</option>
                </select>
              </div>
            </div>
          </div>)}
      </div>)}
  </div>
  );
}

export default SupportDesk;
