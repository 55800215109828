import React, { useState } from "react";
import { getUserInfo } from "../../Services/UserServices";
import { FiArrowRight } from "react-icons/fi";
import { ChevronDown, ChevronUp, LogOut } from "lucide-react";
import {
  Link, useNavigate
} from "react-router-dom";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { SiProgress } from "react-icons/si";
import { IoSettings } from "react-icons/io5";
import { RiNotification4Fill } from "react-icons/ri";
import { BsFillCreditCard2BackFill } from "react-icons/bs";
import { RiFundsFill } from "react-icons/ri";
import "react-toastify/dist/ReactToastify.css";
import { useQuery } from "react-query";
import { BiSolidDashboard } from "react-icons/bi";
import { useAuth } from "../Context/AuthProvider ";
import { getAdminNotification } from "../../Services/NotificationServices";
import moment from "moment";



const TopHeader = () => {
  const { setToken } = useAuth();
  const { data: user } = useQuery({
    queryFn: getUserInfo,
    queryKey: ["user_profile_info"],
  });
  const [checkLogout, setCheckLogout] = useState(false);
  const [profileDropdown, setProfileDropdown] = useState(false);
  const [notificationDropdown, setNotificationDropdown] = useState(false);
  const navigate = useNavigate();

  const handleNotification = () => {
    navigate("/notification");
    setNotificationDropdown(false);
  }

  const logOut = () => {
    setToken();
    localStorage.removeItem("encryptedData");
    navigate("/", { replace: true });
  };

  const { data: notifications1, refetch } = useQuery({
    queryFn: getAdminNotification,
    queryKey: ['notifications1'],
  });
  const notificationList = notifications1?.notificationDetails || [];

  return (
    <div
      className="fixed top-0 left-0 right-0  z-10 py-2  justify-center flex items-center shadow"
    >
      <div className="w-full flex items-center justify-between pl-2 md:pl-14 lg:md:pl-6 xl:md:pl-14 2xl:md:pl-14  pr-6 md:pr-14 lg:md:pr-6 xl:md:pr-14 2xl:md:px-14 relative" >
        <Link to='/admin-dashboard'>
          <div className=" flex  items-center ">
            <img
              className="h-auto"
              src="/image/Logo(1).png"
              alt="techtime logo"
            />
            <div
              className="text-xl font-semibold"
            >
              Techtime
            </div>
          </div>
        </Link>

        <div className="flex items-center justify-between"
          onMouseLeave={() => {
            setNotificationDropdown(false);
            setProfileDropdown(false);
          }}

        >
          <img
            src="/image/Bell.svg"
            alt="Notification Bell"
            size={18}
            className="ml-20 cursor-pointer"
            onClick={() => {
              setNotificationDropdown(!notificationDropdown);
              setProfileDropdown(false);
            }}
          />
          <div className="block w-11">
            <div>
              {notificationDropdown ? (
                <FaAngleUp
                  style={{ color: "#7B35EE" }}
                  className="cursor-pointer"
                />
              ) : (
                <FaAngleDown
                  onMouseOver={() => {
                    setNotificationDropdown(true);
                    setProfileDropdown(null);
                  }}
                  style={{ color: "#7B35EE" }}
                  className="cursor-pointer"
                />
              )}
            </div>
            {notificationDropdown && (
              <div
                className="block absolute top-9 lg:top-8 xl:top-8 2xl:top-8 right-0 bg-white w-full lg:w-1/2 xl:w-1/2 2xl:w-1/2 shadow-xl"
              >
                <div className=" flex flex-col gap-6 pl-0 sm:pl-14 lg:pl-14 xl:pl-14 2xl:pl-14 pb-5 overflow-auto" style={{ height: "70vh" }} >
                  <div>
                    {
                      notificationList?.length > 0 ?
                        notificationList?.slice(0, 5).map((notification, index) => {
                          return (
                            <>
                              <div className="flex items-center border-b w-full" key={index}>
                                <div className="ms-3 w-96">
                                  <div className="flex items-center">
                                    <img src={`${notification?.notificationId?.messageType === 'success' ? '/image/Sucess.png' : (notification?.notificationId?.messageType === 'warning' ? '/image/Warning.png' : (notification?.notificationId?.messageType === 'something went wrong' ? '/image/alert.png' : (notification?.notificationId?.messageType === 'tip' ? '/image/bulb.png' : '/image')))}`} alt='notification' style={{ width: "50px", height: "50px" }} className='mt-7' />
                                    <h6 className="mb-0 ">
                                      <span className={`text-${notification?.notificationId?.messageType === 'success' ? 'green' : (notification?.notificationId?.messageType === 'warning' ? 'orange' : (notification?.notificationId?.messageType === 'something went wrong' ? 'red' : (notification?.notificationId?.messageType === 'error' ? 'red' : 'blue')))}-600`}>
                                        {notification?.notificationId?.messageType}:
                                      </span>
                                      <span> {notification?.notificationId?.message}</span></h6>
                                  </div>
                                  <div className="flex justify-between items-center">
                                    {notification?.notificationId?.redirectLink && notification?.notificationId?.recieverId ? (
                                      <Link to={`${notification?.notificationId?.redirectLink}/${notification?.notificationId?.recieverId}`}> <button className="font-normal py-1  max-[360px]:px-0 px-6 rounded-md ml-3 text-purple-700" onClick={() => {
                                      }}>Check</button></Link>
                                    )
                                      :
                                      <Link to={`${notification?.notificationId?.redirectLink}`}> <button className="font-normal py-1 rounded-md ml-3 text-purple-700" onClick={() => {
                                      }}>Check</button></Link>

                                    }
                                    <small className="float-right text-xs pr-4 md:pr-0 lg:pr-0 xl:pr-0 2xl:pr-0">
                                      {moment(notification?.notificationId?.createdAt).fromNow()}
                                    </small>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })
                        :
                        <div className="px-6">No notification found</div>
                    }
                    {
                      notificationList?.length > 5 &&
                      <div style={{ display: "flex", justifyContent: "right", paddingInline: "20px", alignItems: "center" }} className="text-purple-700"><div className="flex" style={{ alignItems: "center", cursor: "pointer" }} onClick={handleNotification}>View More <FiArrowRight className="text-purple-700 " /></div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="flex items-center">
            <img
              src={`${user?.profileImageLink
                ? user?.profileImageLink
                : "https://img.freepik.com/premium-photo/marine-biologist-digital-avatar-generative-ai_934475-9003.jpg?w=740"
                }`}
              alt="User Avatar"
              className="w-7 h-7 rounded-full mr-2 "
            />
            <div className="hidden sm:hidden md:hidden lg:flex xl:flex 2xl:flex">
              {/* <p className="font-bold">{user?.name}</p> */}
              <p
                className="break-all"
                style={{ fontSize: "14px" }}
              >
                {user?.email}
              </p>
            </div>
            <div className="flex ">
              <div className="relative inline-block text-left ">
                <div>
                  {profileDropdown ? (
                    <FaAngleUp className="cursor-pointer" style={{ color: "#7B35EE" }} />
                  ) : (
                    <FaAngleDown
                      style={{ color: "#7B35EE" }}
                      onMouseOver={() => {
                        setProfileDropdown(true);
                        setNotificationDropdown(null);
                      }}
                      className="cursor-pointer"
                    />
                  )}
                </div>
                {profileDropdown && (
                  <div
                    className="absolute right-0 z-50 bg-white mt-1 w-56 origin-top-right rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="menu-button"
                    tabindex="-1"
                  >
                    <div
                      className="py-1"
                      role="none"
                    >
                      <div className="flex sm:flex md:flex lg:hidden xl:hidden 2xl:hidden border-b justify-center">
                        {/* <p className="font-bold break-all">{user?.name}</p> */}
                        <p
                          className="break-all"
                          style={{ fontSize: "14px" }}
                        >
                          {user?.email}
                        </p>
                      </div>

                      <button
                        title="logout"
                        className="text-gray-700 block w-full px-4 py-2 text-left text-sm flex items-center gap-2"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-3"
                        onClick={() => setCheckLogout(true)}
                        style={{

                        }}
                      >
                        <LogOut width={18} />
                        Log out
                      </button>
                    </div>
                  </div>
                )}
              </div>
              {checkLogout && (
                <div className=" fixed inset-0 z-50 overflow-auto flex items-center justify-center backdrop-blur-md bg-gray-900  bg-opacity-20 ">

                  <div
                    className="bg-white rounded-xl flex flex-col w-96 py-6 ml-3 mr-3 sm:ml-3"
                  >

                    <h2
                      className="text-2xl font-semibold mb-4 text-center"

                    >
                      Are you Sure?
                    </h2>
                    <div className="flex gap-3 w-full justify-center">
                      <button
                        title="Want to logout"
                        className="bg-purple-700 block px-4 py-2 text-sm flex items-center gap-2  rounded text-white hover:bg-purple-500"
                        onClick={() => logOut()}
                      >
                        Yes
                      </button>
                      <button
                        title=" Stay Log in"
                        className="text-purple-700 bg-white block px-4 py-2 text-sm flex items-center gap-2  rounded text-purple-700"
                        onClick={() => setCheckLogout(false)}
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default TopHeader;
