import React, { useEffect, useRef, useState } from "react";
import { FiSearch, FiArrowRight } from "react-icons/fi";
import { Bar } from "react-chartjs-2";
import Profiledetail from "./MemberProfileDetail.jsx";
import Chart from "chart.js/auto";
import AddMember from "./AddMember";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { getToken } from "../../../utils/getToken.js";
import { getMessage } from "../../../Services/MessageService.js";
import { sendMessage } from "../../../Services/MessageService.js";
import Spinner from "../../Spinner/Spinner.jsx";
import { Close } from "@mui/icons-material";
import { RiDeleteBinLine } from "react-icons/ri";
import { toast } from "react-toastify";
const Member = () => {
  const [employeeDetails, setEmployeeDetails] = useState([]);
  const [messageBox, setMessageBox] = useState(false);
  const [isProfile, setIsProfile] = useState(false);
  const [showAddMember, setShowAddMember] = useState(false);
  const [isChatboxOpen, setIsChatboxOpen] = useState(true);
  const [userInput, setUserInput] = useState("");
  const chatboxRef = useRef(null);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [profileDetails, setProfileDetails] = useState({
    isProfile: false,
    selectedUserId: null,
  });
  const [isFilter, setIsFilter] = useState(false);
  const [query, setQuery] = useState({
    name: "",
    employeeId: "",
    sortOrder: "",
    sortKey: "",
  });
  const [searchForAll, setSearchForAll] = useState('');
  const [sortKey, setSortKey] = useState('');

  const toggleChatbox = () => {
    setIsChatboxOpen(!isChatboxOpen);
  };
  useEffect(() => {
    toggleChatbox();
  }, []);

  const handleViewProfileClick = (employeeId) => {
    setIsProfile(true);
    setSelectedUserId(employeeId);
  };

  const handleAddMemberClick = () => {

    setShowAddMember(!showAddMember);
  };

  const handleMember = async (queryURL) => {
    try {
      let keySort = '';
      let keyOrder = '';
      if (sortKey) {
        const a = sortKey.split(' ');
        const b = a[a.length - 2];
        const c = a[a.length - 1];
        keySort = b;
        keyOrder = c;
      }
      const token = getToken();
      let url = `${process.env.REACT_APP_BASE_URL}/api/employee/all?keyword=${searchForAll}&sortOrder=${keyOrder}&sortKey=${keySort}`;
      // if (queryURL !== "") {
      //   url += "?" + queryURL.substring(1); // Remove the leading "&" character
      // }

      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setEmployeeDetails(response.data.data.employeeDetails);
      setLoading(false);
    } catch (error) {
      console.log("Failed fetching member details", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleMember("");
  }, [searchForAll, sortKey]);

  const handleQueryChange = (e) => {
    let temp = { ...query }
    temp[e.target.name] = e.target.value
    setQuery(temp)
  }

  const handleQuery = () => {
    let tempQuery = ""
    if (query.name) {
      tempQuery += `&name=${query.name}`
    }
    if (query.employeeId) {
      tempQuery += `&employeeId=${query.employeeId}`
    }
    if (query.sortKey) {
      tempQuery += `&sortKey=${query.sortKey}`
    }
    if (query.sortOrder) {
      tempQuery += `&sortOrder=${query.sortOrder}`
    }
    if (tempQuery != "") {
      setIsFilter(true)
      handleMember(tempQuery)
    }
  }
  const handleDelete = async (userId) => {
    setDeleteId(userId);
    setIsOpen(true);
  };
  const handleConfirmDelete = async () => {
    setIsOpen(false);
    setLoading(true);
    try {
      const token = getToken();
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/api/employee/delete/${deleteId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      handleMember("");
      toast.success(response?.data?.message);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div >
      {isProfile ? (
        <Profiledetail
          setIsProfile={setIsProfile}
          selectedUserId={selectedUserId}
        />
      ) : showAddMember ? (
        <AddMember
          setShowAddMember={setShowAddMember}
          handleMember={handleMember}
        />
      ) : (
        <div className="bg-white rounded-2xl shadow-md mb-8 ml-3 mr-3 sm:ml-3 border-style p-2">
          <div className="mb-4 flex justify-between items-center" style={{ paddingTop: "1.5rem" }}>
            <div>
              <h2 className="text-xl font-semibold ml-4 ">Member</h2>
            </div>
            <button
              title='Add Member'
              className="bg-purple-700 rounded py-2 mx-2 px-2 text-white mr-4 hover:bg-purple-500"
              onClick={handleAddMemberClick}
            >
              + Add Member
            </button>
          </div>
          <div className="combined-input-container ">
            <div className="relative combined-input w-full ">
              <input
                className="focus:outline-none"
                type="text"
                placeholder="Search here..."
                id="id"
                autoFocus
                name="searchForAll"
                value={searchForAll}
                onChange={(e) => {
                  setSearchForAll(e.target.value);
                }}
              />
              <div className="right-0 top-0  absolute flex">
                <div
                  className="w-full text-purple-700 hover:text-purple-800 font-semibold border-0 px-4 pt-1 mt-auto -mb-0.5"
                  onClick={() => {
                    setSearchForAll('');
                  }}
                ><Close /></div>
              </div>
            </div>
            <div className=" combined-input-3 flex whitespace-nowrap gap-2 items-center">
              <select className="focus:border-purple-500 focus:ring-2 focus:ring-purple-200 focus:outline-none"
                // disabled={isFilter}
                name="sortKey"
                value={sortKey}
                onChange={(e) => setSortKey(e.target.value)}>
                <option value=''>Sort</option>
                <option value='name DESC'>A-Z</option>
                <option value='name ASC'>Z-A</option>
                <option value='createdAt DESC'>Oldest First</option>
                <option value='createdAt ASC'>Newest First</option>
              </select>
            </div>
          </div>

          <div className="mt-4">
            {/* <div style={{display:"flex", marginTop:"20vh"}} className="flex w-full justify-center"> <Spinner/></div> */}
            {loading ? (
              <div style={{ display: "flex", marginTop: "15%" }} className="flex w-full justify-center"> <Spinner /></div>
            ) : (
              <div className="grid grid-cols-1 min-[600px]:grid-cols-2  min-[1150px]:grid-cols-3 gap-4 mb-10">
                {employeeDetails.map((employee) => (
                  <div className="m-auto flex w-full" >
                    <div className="bg-white rounded-lg shadow-md flex flex-col items-center border gap-2 m-2 w-full" >
                      <img
                        src={
                          employee?.userId?.profileImageLink
                            ? employee?.userId?.profileImageLink
                            : "/image/Employee.png"
                        }
                        alt="Member"
                        className="w-auto mt-10  h-auto object-cover rounded-full"
                        style={{ width: "150px", height: "150px" }}
                      />
                      <div className="p-2 text-center">
                        <h3 className="text-xl font-semibold">{employee.name}</h3>
                        <p>Member ID: {employee.employeeId}</p>
                        {/* <p>{employee.designation}</p> */}
                        <div className="flex justify-center  px-1  gap-5 mt-4 w-full">
                          <button
                            title='View Profile'
                            className="bg-gray-200 text-black text-md px-2 py-2 rounded-lg hover:bg-gray-100 w-full text-nowrap"
                            onClick={() => {
                              handleViewProfileClick(employee._id);
                            }}
                          >
                            View Profile
                          </button>
                          <RiDeleteBinLine
                            style={{
                              color: "#7C35F1",
                              alignItems: "center",
                              justifyContent: "center",
                              margin: "auto",
                              cursor: "pointer",
                            }}
                            className="w-full"
                            size={30}
                            onClick={() => handleDelete(employee._id)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                {isOpen && (
                  <div className="fixed inset-0 z-50 overflow-auto flex items-center justify-center backdrop-blur-md bg-gray-900 bg-opacity-25">
                    <div className="bg-white rounded-xl flex flex-col w-96">
                      <div className="p-6">
                        <p className="text-lg font-semibold mb-4">Are you sure you want to delete?</p>
                        <div className="flex justify-center">
                          <button className="bg-purple-500 text-white py-2 px-4 rounded mr-2" onClick={handleConfirmDelete}>Delete</button>
                          <button className="bg-gray-200 text-gray-800 py-2 px-4 rounded" onClick={() => setIsOpen(false)}>Cancel</button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>)}
          </div>
        </div>
      )}
    </div>
  );
};

export default Member;



