import React, { useState, useEffect } from "react";
import { FiSearch } from "react-icons/fi";
import { Trash, Pencil, ChevronDown, ChevronUp } from "lucide-react";
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import {
  addFaq,
  deleteFaq,
  getFaq,
  updateFaq,
} from "../../Services/FaqServices";
import { MdOutlineClose } from "react-icons/md";
import Spinner from "../Spinner/Spinner";
import { ToastContainer, toast } from "react-toastify";

function Faq() {
  const queryClient = useQueryClient();
  const [activeIndex, setActiveIndex] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    question: "",
    answer: "",
  });
  const createUpdateFaq = (id, data) => {
    return async () => {
      if (isEditing) {
        await updateFaq(id, data);
      } else {
        await addFaq(data);
      }
    };
  };
  // Queries
  const {
    data: faq,
    refetch,
    isLoading,
  } = useQuery({ queryKey: ["faqFetch"], queryFn: getFaq });

  // Mutations
  const faqMutation = useMutation(createUpdateFaq(formData._id, formData), {
    onSuccess: (data) => {
      if (isEditing) {
        setShowPopup(false);
        setIsEditing(false);
      } else {
        setShowPopup(false);
      }
      toast.success('Successfully updated!')
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ["faqFetch"] });
      setFormData({ question: "", answer: "" });
    },
    onError: (error) => {
      console.log("Error");
    },
  });
  const deleteFaqMutation = useMutation(deleteFaq, {
    onSuccess: () => {
      toast.success('Deleted successfully!')
      queryClient.invalidateQueries("faqFetch");
    },
  });

  const handleClick = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleEdit = (item) => {
    setShowPopup(true);
    setIsEditing(true);
    setFormData(item);
  };
  const closePopup = () => {
    setShowPopup(false);
    setIsEditing(false);
    setFormData({ question: "", answer: "" });
  };

  return (
    <>
      <div
        className="bg-white rounded-2xl shadow-md mb-8 ml-3 mr-3 sm:ml-3 border-style p-2"    >
        {isLoading ? (
          <div style={{ display: "flex", marginTop: "20%" }} className="flex w-full justify-center"> <Spinner /></div>
        ) : (
          <div>
            <div
            >
              <div className="flex justify-between">
                <h4 className="text-xl font-semibold  mt-2">FAQ</h4>
                <div className="flex ">
                  <button
                    title="add more FAQ"
                    className="px-4 py-2 rounded bg-purple-600 text-white hover:bg-purple-500"
                    onClick={() => setShowPopup(true)}
                  >
                    + Add More FAQ
                  </button>
                </div>
              </div>

              {faq?.faqDetails?.map((item, index) => (
                <div key={item.title} className="py-5 pe-5 justify-between">
                  <div className="flex justify-between items-center">
                    <button
                      onClick={() => handleClick(index)}
                      className="font-semibold  flex justify-between w-full"
                    >
                      {item.question}
                      {index === activeIndex ? (
                        <ChevronUp
                          className="icon mr-2"
                          style={{ color: "#7B35EE" }}
                        />
                      ) : (
                        <ChevronDown
                          className="icon mr-2"
                          style={{ color: "#7B35EE" }}
                        />
                      )}
                    </button>
                    <div className="flex items-center gap-4 cursor-pointer">
                      <Trash
                        color="#8000ff"
                        size={18}
                        onClick={(e) => {
                          item?._id && deleteFaqMutation.mutate(item._id);
                        }}
                      />
                      <Pencil
                        color="#8000ff"
                        size={18}
                        onClick={() => handleEdit(item)}
                      />
                    </div>
                  </div>
                  {index === activeIndex && (
                    <div className="flex justify-between">
                      <p className="" style={{ paddingLeft: "6px" }}>{item.answer}</p>
                    </div>
                  )}
                  <hr className="border-gray-300 border-2  mt-4"></hr>
                </div>
              ))}
            </div>

            {showPopup && (
              <div className="fixed inset-0 z-50 overflow-auto flex items-center justify-center  bg-gray-900 bg-opacity-25">
                <div className="bg-white rounded-xl flex flex-col w-96 h-full overflow-auto">
                  <div className="flex justify-between p-3 bg-purple-700 text-white rounded-t-xl">
                    <h2 className="text-xl font-bold text-center w-full">
                      {" "}
                      {isEditing ? "Edit FAQ" : "Add More FAQ"}
                    </h2>
                    <MdOutlineClose
                      onClick={closePopup}
                      className="cursor-pointer w-8 h-8 text-white hover:text-gray-300"
                    />
                  </div>

                  <div className=" p-3 my-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Question:
                    </label>
                    <input
                      id="question-input"
                      type="text"
                      className="w-full border rounded-md p-2 focus:ring focus:ring-purple-100 focus:outline-none"
                      placeholder="Enter your question"
                      value={formData?.question}
                      onChange={handleChange}
                      name="question"
                    />
                  </div>
                  <div className=" p-3 mb-8">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Answer:
                    </label>
                    <textarea
                      id="answer-input"
                      rows="4"
                      className="w-full border rounded-md p-2 focus:ring focus:ring-purple-100 focus:outline-none"
                      placeholder="Enter your answer"
                      value={formData?.answer}
                      onChange={handleChange}
                      name="answer"
                    />
                  </div>
                  <div
                    className="flex justify-center"
                    style={{ paddingBottom: "1rem" }}
                  >
                    <button
                      className="text-sm bg-purple-700 text-white font-light rounded-lg px-4 py-3 hover:bg-purple-400 cursor-pointer "
                      onClick={(e) => faqMutation.mutate()}

                    >
                      {isEditing ? "UPDATE" : "SAVE"}
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      <ToastContainer />
        </div>
      </>
      );
}

      export default Faq;
