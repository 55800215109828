import React, { useEffect, useRef, useState } from "react";
import { FiSearch, FiArrowRight } from "react-icons/fi";
import { Bar } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { useQuery } from "react-query";
import { getAdminDetails, getClients, getUserInfo } from "../../../Services/UserServices";
import "./Client.css";
import Profiledetail from "./ClientProfileDetail.jsx";
import axios from "axios";
import { getToken } from "../../../utils/getToken.js";
import { getMessage } from "../../../Services/MessageService.js";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "../../Spinner/Spinner.jsx";
import { useParams } from "react-router-dom";
import { Close } from "@mui/icons-material";
import { RiDeleteBinLine } from "react-icons/ri";

const Client = () => {
  const [memberId, setMemberId] = useState("");
  const [memberName, setMemberName] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [sortKey, setSortKey] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const params = useParams('receiverID');
  const [searchForAll, setSearchForAll] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [deleteId, setDeleteId] = useState();

  const { data: clients, isLoading, refetch } = useQuery({
    queryFn: () => getClients(searchForAll, sortKey),
    queryKey: ["user_clients", searchForAll, sortKey],
  });
  const { data: getDetails } = useQuery({
    queryFn: () => getUserInfo(),
    queryKey: ["getDetails"],
  });

  const [selectedUserId, setSelectedUserId] = useState(null);
  const [isProfile, setIsProfile] = useState(false);
  const [profileDetails, setProfileDetails] = useState({
    isProfile: false,
    selectedUserId: null,
  });
  const chatboxRef = useRef(null);
  const [userInput, setUserInput] = useState("");
  const [userId, setUserId] = useState(null);
  const [isChatboxOpen, setIsChatboxOpen] = useState(true);
  const [messages, setMessages] = useState(() => {
    const storedMessages = localStorage.getItem("chatMessages");
    return storedMessages ? JSON.parse(storedMessages) : [];
  });
  useEffect(() => {
    if (params?.receiverID) {
      handleViewProfileClick(params?.receiverID);
    }
  }, [params])
  const handleViewProfileClick = (clientId) => {
    setIsProfile(true);
    setSelectedUserId(clientId);
  };
  const handleSelectedID = (selectID) => {
    setUserId(selectID);
  }
  const toggleChatbox = () => {
    setIsChatboxOpen(!isChatboxOpen);
  };
  const addUserMessage = (message) => {
    const messageElement = document.createElement("div");
    messageElement.classList.add("mb-2", "text-right");
    messageElement.innerHTML = `<p class="bg-purple-600 text-white rounded-lg py-2 px-4 inline-block">${message}</p>`;
    chatboxRef.current.appendChild(messageElement);
    chatboxRef.current.scrollTop = chatboxRef.current.scrollHeight;
  };
  const [loading, setLoading] = useState(true);
  const addBotMessage = (message) => {
    const messageElement = document.createElement("div");
    messageElement.classList.add("mb-2");
    messageElement.innerHTML = `<p class="bg-gray-200 text-gray-700 rounded-lg py-2 px-4 inline-block">${message}</p>`;
    chatboxRef.current.appendChild(messageElement);
    chatboxRef.current.scrollTop = chatboxRef.current.scrollHeight;
  };

  useEffect(() => {
    toggleChatbox();
    setLoading(false);
  }, []);

  const handleUserInputChange = (event) => {
    setUserInput(event.target.value);
  };

  const handleUserInputEnter = (e) => {
    if (e.key === "Enter") {
      handleSendMessage();
    }
  };
  const handleSendMessage = async () => {
    if (!userInput.trim() || !userId) {
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/message/send`,
        {
          senderId: getDetails?.data?.data?._id,
          reciverId: userId,
          message: userInput,
        }
      );

      toast.success("Message Sent");
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          _id: response.data.messageId,
          message: userInput,
          fromUser: true,
        },
      ]);

      setUserInput("");
    } catch (error) {
      toast.error(error?.response?.data?.message);

      if (error.response) {
        toast.error(error?.response?.data?.message);
      }
    }
  };
  const handleDelete = async (userId) => {
    setDeleteId(userId);
    setIsOpen(true);
  };
  const handleConfirmDelete = async () => {
    setIsOpen(false);
    setLoading(true);
    try {
      const token = getToken();
    const response=  await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/api/user/${deleteId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success(response?.data?.message);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="bg-white rounded-2xl shadow-md mb-8 ml-3 mr-3 sm:ml-3 border-style p-2">
      {isProfile ? (
        <Profiledetail
          setIsProfile={setIsProfile}
          selectedUserId={selectedUserId}
        />
      ) : (
        <div 
        // className="w-full shadow-2xl rounded-2xl mb-5 p-3" style={{ height: "calc(100vh - 120px)", maxHeight: "80vh", overflowY: "auto" }}
        >
          <div className="mb-4">
            <h4 className="mt-2 text-xl font-semibold ml-5">Clients</h4>
            <div className="combined-input-container mt-3">
              <div className="relative combined-input w-full ">
                <input
                  className="focus:outline-none"
                  type="text"
                  placeholder="Search here..."
                  id="id"
                  autoFocus
                  name="searchForAll"
                  value={searchForAll}
                  onChange={(e) => {
                    setSearchForAll(e.target.value);
                  }}
                />
                <div className="right-0 top-0  absolute flex">
                  <div
                    className="w-full text-purple-700 hover:text-purple-800 font-semibold border-0 px-4 pt-1 mt-auto -mb-0.5"
                    onClick={() => {
                      setSearchForAll('');
                    }}
                  ><Close /></div>
                </div>
              </div>
              <div className=" combined-input-3 flex whitespace-nowrap gap-2 items-center">
                <select className="focus:border-purple-500 focus:ring-2 focus:ring-purple-200 focus:outline-none"
                  // disabled={isFilter}
                  name="sortKey"
                  value={sortKey}
                  onChange={(e) => setSortKey(e.target.value)}>
                  <option value=''>Sort</option>
                  <option value='name DESC'>A-Z</option>
                  <option value='name ASC'>Z-A</option>
                  <option value='createdAt DESC'>Oldest First</option>
                  <option value='createdAt ASC'>Newest First</option>
                </select>
              </div>
            </div>

            {/* <div className="flex mt-4 items-center ml-5">
              <input
                type="text"
                value={memberId}
                onChange={(e) => setMemberId(e.target.value)}
                className="flex-grow w-1/2 px-4 py-2 rounded-lg border border-gray-300 focus:border-purple-500 focus:ring-2 focus:ring-purple-200 mr-3 focus:outline-none"
                placeholder="Client ID"
              />

              <input
                type="text"
                value={memberName}
                onChange={(e) => setMemberName(e.target.value)}
                className="flex-grow w-1/2 px-4 py-2 rounded-lg border border-gray-300 focus:border-purple-500 focus:ring-2 focus:ring-purple-200 mr-3 focus:outline-none"
                placeholder="Client Name"
              />
              <div className="flex whitespace-nowrap gap-2 items-center">
                <select className="border rounded border-gray-300 rounded-lg  px-4 py-2 focus:border-purple-500 focus:ring-2 focus:ring-purple-200 mr-3 focus:outline-none"
                  value={sortKey}
                  onChange={(e) => setSortKey(e.target.value)}>
                  <option >Sort</option>
                  <option value='createdAt'>sort by date</option>
                  <option value='name'>sort by name</option>
                </select>
              </div>
              <div className="flex whitespace-nowrap gap-2 items-center">
                <select className="border rounded border-gray-300 rounded-lg  px-4 py-2 focus:border-purple-500 focus:ring-2 focus:ring-purple-200 mr-3 focus:outline-none"
                  value={sortOrder}
                  onChange={(e) => setSortOrder(e.target.value)}>
                  <option >Sort by Date</option>
                  <option value='DESC'>Oldest First</option>
                  <option value='ASC'>Newest First</option>
                </select>
              </div>
              <button className="bg-purple-700 text-white px-4 py-2 rounded-lg flex-grow  mr-3" title='Search' onClick={handleFormSubmit}>
                {isSearch ? "Reset" : "Search"}
              </button>
            </div> */}
          </div>

          <div className="mt-4">
            {isLoading ? (
              <div style={{ display: "flex", marginTop: "15%" }} className="flex w-full justify-center"> <Spinner /></div>
            ) : (
              <div className="grid grid-cols-1 min-[600px]:grid-cols-2  min-[1150px]:grid-cols-3 gap-4 mt-4 mb-10">
                {
                  clients?.clientdetails?.length > 0 ?
                    clients?.clientdetails?.map((e, index) => (
                      <div
                        key={index}
                        className="m-auto flex w-full"
                      >
                        <div className="bg-white rounded-lg shadow-md flex flex-col items-center border w-full" >
                          <div style={{ display: "flex", fontSize: "12px", marginLeft: "auto", padding: "5px", color: e?.isApprovedForInvestment ? "Green" : "orange" }}>{e?.isApprovedForInvestment ? "Approved" : "Pending"}</div>
                          <img
                            src={e?.profileImageLink ? e?.profileImageLink : "/image/Employee.png"}
                            alt="Member"
                            className="w-auto mt-3  h-auto object-cover rounded-full"
                            style={{ width: "150px", height: "150px" }}
                          />
                          <div className="p-4 text-center">
                            <h3 className="text-xl font-semibold">
                              {e.name ? e.name : "N/A"}
                            </h3>
                            <p>Client ID: {e.memberId}</p>
                            {/* <p>Manager</p> */}
                            <div className="flex justify-between gap-2 mt-4 w-full">
                              <button
                                title='Message'
                                className="bg-purple-700 text-md text-white px-2 py-2 rounded-lg hover:bg-purple-500 w-full"
                                onClick={() => {
                                  toggleChatbox();
                                  handleSelectedID(e._id);
                                }}
                              >
                                Message
                              </button>
                              <button
                                title='View Profile'
                                className="bg-gray-200 text-md text-black px-2 py-2 rounded-lg hover:bg-gray-100 w-full"
                                onClick={() => {
                                  handleViewProfileClick(e._id);
                                }}
                              >
                                Profile
                              </button>
                              <RiDeleteBinLine
                                style={{
                                  color: "#7C35F1",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  margin: "auto",
                                  cursor: "pointer",
                                }}
                                className="w-full"
                                size={30}
                                onClick={() => handleDelete(e._id)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                    :
                    'No Data found!'
                }
              </div>
            )}
          </div>
          {/*Message Box*/}
          <div
            id="chat-container"
            className={`fixed bottom-1 right-1 w-96 max-[440px]:w-full ${isChatboxOpen ? "" : "hidden"
              }`}
          >
            <div className="bg-white shadow-md rounded-lg max-w-lg w-full">
              <div className="p-4 border-b bg-purple-600 text-white rounded-t-lg flex justify-between items-center">
                <p className="text-lg font-semibold">Message</p>
                <button

                  id="close-chat"
                  className="text-gray-300 hover:text-gray-400 focus:outline-none focus:text-gray-400"
                  onClick={toggleChatbox}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    ></path>
                  </svg>
                </button>
              </div>
              <div
                id="chatbox"
                ref={chatboxRef}
                className="p-4 h-80 overflow-y-auto"
              >
                {messages.map((message) => (
                  <div
                    key={message._id}
                    className={message.fromUser ? "text-left" : "text-right"}
                  >
                    <p>{message.message}</p>
                  </div>
                ))}
              </div>

              <div className="p-4 border-t flex">
                <input
                  id="user-input"
                  type="text"
                  placeholder="Type a message"
                  className="w-full px-3 py-2 border rounded-l-md focus:outline-none focus:ring-2 focus:ring-purple-600"
                  value={userInput}
                  onChange={handleUserInputChange}
                  onKeyUp={handleUserInputEnter}
                />

                <button
                  title='Send'
                  id="send-button"
                  className="bg-purple-600 text-white px-4 py-2 rounded-r-md hover:bg-purple-500 transition duration-300"
                  onClick={handleSendMessage}
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {isOpen && (
        <div className="fixed inset-0 z-50 overflow-auto flex items-center justify-center backdrop-blur-md bg-gray-900 bg-opacity-25">
          <div className="bg-white rounded-xl flex flex-col w-96">
            <div className="p-6">
              <p className="text-lg font-semibold mb-4">Are you sure you want to delete?</p>
              <div className="flex justify-center">
                <button className="bg-purple-500 text-white py-2 px-4 rounded mr-2" onClick={handleConfirmDelete}>Delete</button>
                <button className="bg-gray-200 text-gray-800 py-2 px-4 rounded" onClick={() => setIsOpen(false)}>Cancel</button>
              </div>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />

    </div>
  );
};

export default Client;
