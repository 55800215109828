import { authApi, defaultApi } from "./api";

export async function getAllPlan() {
  const res = await defaultApi.get(`/api/plan/allPlan`);
  return res.data.data;
}
export async function getAllUserPlan() {
  const res = await defaultApi.get(`/api/plan/planData`);
  return res.data.data;
}

export async function getPurchasePlan() {
  const res = await defaultApi.get(`/api/plan/purchased-plan`);
  return res.data.data;
}

export function purchasePlan(planId, data) {
  return defaultApi.post(`api/plan/purchase/${planId}`, data);
}

export function updateUserPurchasePlan(userPlanId, formData) {
  return defaultApi.put(`/api/plan/update-user-plan/${userPlanId}`, formData);
}

export function cancelPlan(userPlanId) {
  return defaultApi.put(`/api/plan/cancel-plan/${userPlanId}`);
}

export async function getTotalInvestment() {
  const res = await defaultApi.get(`/api/plan/get-total-investment`);
  return res.data.data;
}
