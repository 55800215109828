
import React, { useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { useQuery } from 'react-query';
import { checkNotification, getAdminNotification } from '../../Services/NotificationServices';
import { getToken } from '../../utils/getToken';
import axios from "axios";
import ClientProfileDetail from './Client/ClientProfileDetail';
import Spinner from '../Spinner/Spinner';
import { Link } from 'react-router-dom';
import moment from 'moment';

function Notification() {
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [isProfile, setIsProfile] = useState(false);
  const { data: notifications, refetch, isLoading } = useQuery({
    queryFn: getAdminNotification,
    queryKey: ['notifications'],
  });
  const notificationList = notifications?.notificationDetails || [];

  const handleCheck = async (id) => {
    try {
      const { data } = await checkNotification(id);
      refetch();
    }
    catch (err) {
      console.log(err);
    }
  }
  const handleViewProfileClick = (clientId) => {
    setIsProfile(true);
    setSelectedUserId(clientId);
  };

  return (<div>    {isProfile ? (
    <ClientProfileDetail
      setIsProfile={setIsProfile}
      selectedUserId={selectedUserId}
    />
  ) : (
    <div>
      {isLoading ? (
        <div className="w-full shadow-2xl rounded-2xl mb-5 pb-10" style={{ height: "calc(100vh - 120px)", maxHeight: "80vh", overflowY: "auto" }}>
          <div style={{ display: "flex", marginTop: "20%" }} className="flex w-full justify-center"> <Spinner /></div>
        </div>
      ) : (
        <div
          className="bg-white rounded-2xl shadow-md mb-8 ml-3 mr-3 sm:ml-3 border-style p-2"         >
          <h4 className="text-xl ml-4  font-semibold" style={{ paddingTop: "2.5rem" }}>Notifications</h4>

          <div className="bg-white  mr-4 space-y-2 mt-5">
            {
              notificationList?.length > 0 ?
                notificationList?.map((notification, index) => {
                  return (
                    <div className="flex items-center border-b w-full" key={index}>
                      <div className="ms-3">
                        <div className="flex items-center ">
                          <img src={`${notification?.notificationId?.messageType === 'success' ? '/image/Sucess.png' : (notification?.notificationId?.messageType === 'warning' ? '/image/Warning.png' : (notification?.notificationId?.messageType === 'something went wrong' ? '/image/alert.png' : (notification?.notificationId?.messageType === 'tip' ? '/image/bulb.png' : '/image')))}`} alt='notification' style={{ width: "50px", height: "50px" }} className='mt-7' />
                          <h6 className="mb-0 ">
                            <span className={`text-${notification?.notificationId?.messageType === 'success' ? 'green' : (notification?.notificationId?.messageType === 'warning' ? 'orange' : (notification?.notificationId?.messageType === 'something went wrong' ? 'red' : (notification?.notificationId?.messageType === 'error' ? 'red' : 'blue')))}-600`}>
                              {notification?.notificationId?.messageType}:
                            </span>
                            <span> {notification?.notificationId?.message}</span></h6>
                        </div>
                        <div className="flex justify-between items-center">
                          {notification?.notificationId?.redirectLink && notification?.notificationId?.recieverId ? (
                            // <button className="font-normal py-1 px-6 rounded-md ml-3 text-purple-700"
                            //  onClick={() => { handleViewProfileClick(notification?.notificationId?.recieverId); }}
                            //  >Check</button>
                            <Link to={`${notification?.notificationId?.redirectLink}/${notification?.notificationId?.recieverId}`}> <button className="font-normal py-1 px-6 rounded-md ml-3 text-purple-700" onClick={() => {
                            }}>Check</button></Link>
                          )
                            :
                            <Link to={`${notification?.notificationId?.redirectLink}`}> <button className="font-normal py-1 px-6 rounded-md ml-3 text-purple-700" onClick={() => {
                            }}>Check</button></Link>
                          }
                          <small className="float-right text-xs pr-4 md:pr-0 lg:pr-0 xl:pr-0 2xl:pr-0">
                            {moment(notification?.notificationId?.createdAt).fromNow()}
                          </small>
                        </div>
                      </div>
                    </div>
                    // <div key={index} className="flex items-center rounded-lg">

                    //   <div className="flex items-center ">
                    //     <img src={`${notification?.notificationId?.messageType === 'success' ? '/image/Sucess.png' : (notification?.notificationId?.messageType === 'warning' ? '/image/Warning.png' : (notification?.notificationId?.messageType === 'something went wrong' ? '/image/alert.png' : (notification?.notificationId?.messageType === 'tip' ? '/image/bulb.png' : '/image')))}`} alt='notification' style={{ width: "50px", height: "50px" }} className='mt-7' />
                    //     <p className="font-semibold">
                    //       <span className={`text-${notification?.notificationId?.messageType === 'success' ? 'green' : (notification?.notificationId?.messageType === 'warning' ? 'orange' : (notification?.notificationId?.messageType === 'something went wrong' ? 'red' : (notification?.notificationId?.messageType === 'error' ? 'red' : 'blue')))}-600`}>
                    //         {notification?.notificationId?.messageType}:
                    //       </span>
                    //       <span> {notification?.notificationId?.message}</span>
                    //       {notification?.notificationId?.messageType === 'tip' && notification?.notificationId?.redirectLink && notification?.notificationId?.recieverId ? (
                    //         <button className="font-normal py-1 px-6 rounded-md ml-3 text-purple-700" onClick={() => {
                    //           handleViewProfileClick(notification?.notificationId?.recieverId);
                    //         }}>Check</button>
                    //       )
                    //         :
                    //         <Link to={`${notification?.notificationId?.redirectLink}`}> <button className="font-normal py-1 px-6 rounded-md ml-3 text-purple-700" onClick={() => {
                    //         }}>Check</button></Link>

                    //       }

                    //     </p>
                    //   </div>
                    // </div>
                  );


                })
                :
                'No notification found'
            }
          </div>
        </div>
      )}

    </div>)}</div>
  );
}

export default Notification;
