import React, { useState, useEffect } from "react";
import CreditScoreChart from "../Dashboard/CreditScoreChart";
import axios from "axios";
import { getToken } from "../../../utils/getToken.js";
import Spinner from "../../Spinner/Spinner.jsx";
import { LineChart } from "@mui/x-charts/LineChart";
import { showTheme } from "../../Theme/Theme.js";
const Landing = () => {
  const [assetData, setAssetData] = useState();
  const [loading, setLoading] = useState(false);
  const [chart, setChart] = useState([]);
  const [yearFilter, setyearFilter] = useState("monthly");

  const pData = chart.map((entry) => entry?.totalInvestment);
  const lData = chart.map((entry) => entry?.monthlyProfit);
  const uData = chart.map((entry) => entry?.totalWithdrawal);
  const xLabels = chart.map((entry) => entry?.monthName?.substring(0, 3));
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const token = getToken();
        const response = await axios.get(
         `https://techtime.pb18.in/api/user/dashboard?timeFilter=${yearFilter}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setAssetData(response.data.data.data);
        setChart(response.data.data.data.graphMonthlyData);
        // console.log("big", chart)
        // const responseData = response.data;
        // if (responseData.status) {
        //   const {
        //     totalInvestment,
        //     totalProfit,
        //     totalWithdrawal,
        //     lastTransaction,
        //   } = responseData.data.data;

        //   setAssetData({
        //     totalInvestment,
        //     totalProfit,
        //     totalWithdrawal,
        //     lastTransaction,
        //   });
        // } else {
        //   console.error(responseData.message);
        // }
      } catch (error) {
        console.error(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [yearFilter]);

  return (
    <div className="rounded-2xl ">
      {loading ? (
        <div className="flex justify-center " style={{ marginTop: "35vh" }}>
          <Spinner />
        </div>
      ) : (
        <div
        className="bg-white rounded-2xl shadow-md border mb-8 ml-3 mr-3 sm:ml-3 border-style"
        style={{
           
            border: showTheme("borderCard"),
            backgroundColor: showTheme("card"),
          }}
        >
          <div className="lg:grid xl:grid grid-cols-2 gap-8 mx-4">
            {/* Card 1 */}
            <div
  className="rounded-lg mt-5 shadow-2xl border p-4"
  style={{
    backgroundColor: showTheme("card"),
    border: showTheme("borderCard"),
    overflow: "hidden",
  }}
>
  {/* Card 1 content */}
  <h2
    className="text-base font-normal mb-2 p-4"
    style={{ color: showTheme("text") }}
  >
    ASSET GENERATED
  </h2>
  <p
    className="text-4xl max:[400px]:text-sm font-bold mb-2 p-4 break-all"
    style={{ color: showTheme("text") }}
  >
    ₹ {assetData?.assetGenerated}
  </p>


    <div
      className="flex max-[670px]:p-7"
      style={{
        backgroundColor:
          localStorage.getItem("darkMode") === "true" ? "#a3a3a3" : undefined,
        borderRadius:
          localStorage.getItem("darkMode") === "true" ? '5px' : undefined,
      }}
    >
      <LineChart
        width={450}
        height={380}
        series={[
          { data: pData, label: "Investment", color: "#7e57c2", labelColor: "#FFFFFFb" },
          { data: uData, label: "Withdrawal", color: "red" },
          { data: lData, label: "Profit", color: "green", labelColor: "#FFFFFFb" },
        ]}
        xAxis={[{ scaleType: "point", data: xLabels }]}
      /> 
    </div>
       {assetData?.assetGenerated === 0 && (
        <p
          className="text-center text-lg font-medium p-4"
          style={{ color: showTheme("text") }}
        >
          No data to display
        </p>
      ) }
  
</div>


            {/* Card 2 */}
            <div className="rounded-lg mt-5 p-4 shadow-2xl border "
              style={{
                backgroundColor: showTheme("card"),
                border: showTheme("borderCard"),
              }}
            >
              {/* Card 2 content */}
              <h2
                className="text-base font-normal mb-2 p-4"
                style={{ color: showTheme("text") }}
              >
                YOUR CREDIT SCORE
              </h2>
              <div className="relative lg:mt-24  xl:mt-24 2xl:mt-24">
                <CreditScoreChart />
                <div className="absolute inset-0 flex flex-col items-center justify-center mt-24">
                  <span
                    className="text-4xl font-bold"
                    style={{ color: showTheme("text") }}
                  >
                    600
                  </span>
                  <p
                    className="text-lg text-center mt-5"
                    style={{ color: showTheme("text") }}
                  >
                    Your Credit Score is average
                  </p>
                  <p
                    className="text-base font-light"
                    style={{ color: showTheme("text") }}
                  >
                    Last Check on 21 Apr
                  </p>
                  <p
                    className="text-sm mt-4"
                    style={{ color: showTheme("text") }}
                  >
                    What do these stats mean?
                  </p>
                </div>
              </div>
            </div>

            {/* Card 3 */}
            <div className="bg-white  rounded-lg p-4 mt-5 col-span-2 mb-5 "
              style={{ backgroundColor: showTheme("card") }}
            >
              {/* Card 3 content */}
              <div className="flex justify-between">
                <h2
                  className="text-base font-normal mb-2"
                  style={{ fontSize: "18px", color: showTheme("text") }}
                >
                  Your total assets
                </h2>

                <div className="flex items-center py-2  rounded-md">
                  <select
                    id="years"
                    className=" p-2 rounded-xl"
                    value={yearFilter}
                    style={{
                      backgroundColor: showTheme("topHeader"),
                      color: showTheme("text"),
                      border: showTheme("borderCard"),
                    }}
                    onChange={(e) => setyearFilter(e.target.value)}
                  >
                    <option
                      value="monthly"
                      style={{
                        backgroundColor:
                          yearFilter === "Last 1 Year" ? "#7C35F1" : "white",
                        color: yearFilter === "Last 1 Year" ? "white" : "black",
                      }}
                    >
                     Monthly
                    </option>
                    <option
                      value="half-year"
                      style={{
                        backgroundColor:
                          yearFilter === "Last 2 Year" ? "#7C35F1" : "white",
                        color: yearFilter === "Last 2 Year" ? "white" : "black",
                      }}
                    >
                     Half Yearly
                    </option>

                    <option
                      value="yearly"
                      style={{
                        backgroundColor:
                          yearFilter === "Last 2 Year" ? "#7C35F1" : "white",
                        color: yearFilter === "Last 2 Year" ? "white" : "black",
                      }}
                    >
                     Yearly
                    </option>
                  </select>
                </div>
              </div>
              <div className="flex space-x-4">
                <p
                  className="text-4xl font-bold mb-2 break-all"
                  style={{ color: showTheme("text") }}
                >
                  ₹ {assetData?.assetGenerated}
                </p>
              </div>
              <div className="grid grid-cols-1 min-[640px]:cols-2 gap-4 mt-5">
                <div
                  className="shadow-md  py-4   rounded-lg"
                  style={{ border: showTheme("borderCard") }}
                >
                  <div className="flex gap-4  items-center">
                    <img
                      src="/image/Ellipse.svg"
                      className="ml-2 w-3 h-3"
                      alt="image"
                    />
                    <p
                      className="text-base font-normal"
                      style={{ color: showTheme("text") }}
                    >
                      Profit
                    </p>
                  </div>
                  <div
                    className="flex ml-2  space-x-2"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "20px",
                      marginLeft: "10px",
                      marginRight: "10px",
                      marginTop: "5px",
                    }}
                  >
                    <p
                      className="text-2xl break-all"
                      style={{
                        marginLeft: "25px",
                        width: "75%",
                        display: "flex",
                        fontWeight: "bold",

                        color: showTheme("text"),
                      }}
                    >
                      ₹{" "}
                      {assetData?.totalProfit}
                      {/* 54670 */}
                    </p>
                    {
                      assetData?.profitPerTotalAssetPercent &&
                    <p className="text-sm  flex text-green-700  bg-green-300 px-2 py-1 rounded-lg break-all text-center items-center">
                      {assetData?.profitPerTotalAssetPercent} %
                    </p>

                    }
                  </div>
                </div>
                <div
                  className="shadow-md  py-4 rounded-lg"
                  style={{ border: showTheme("borderCard") }}
                >
                  <div className="flex gap-4 ml-2 items-center">
                    <img src="/image/Dot.svg" className="w-3 h-3" alt="image" />
                    <p
                      className="text-base font-normal"
                      style={{ color: showTheme("text") }}
                    >
                      Last Transaction
                    </p>
                  </div>

                  <div
                    className="flex ml-2  space-x-2"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "20px",
                      marginLeft: "10px",
                      marginRight: "10px",
                      marginTop: "5px",
                    }}
                  >
                    <p
                      className="text-2xl break-all"
                      style={{
                        marginLeft: "25px",
                        width: "75%",
                        display: "flex",
                        fontWeight: "bold",

                        color: showTheme("text"),
                      }}
                    >
                      ₹{" "}
                      {assetData &&
                        assetData?.lastTrsaction?.amount &&
                        assetData?.lastTrsaction?.amount?.toFixed(2)}
                      {/* 54670 */}
                    </p>
                    {assetData?.lastTrsaction?.transactionType &&
                      <p className="text-sm  flex text-green-700  bg-green-300 px-2 py-1 rounded-lg break-all text-center items-center">
                        {assetData?.lastTrsaction?.transactionType &&
                          assetData?.lastTrsaction?.transactionType}
                      </p>
                    }
                  </div>
                </div>
                <div
                  className="shadow-md  py-4  rounded-lg"
                  style={{ border: showTheme("borderCard") }}
                >
                  <div className="flex ml-2  items-center gap-4">
                    {" "}
                    <img src="/image/Dot.svg" className="w-3 h-3" />
                    <p
                      className="text-base font-normal "
                      style={{ color: showTheme("text") }}
                    >
                      Total investment
                    </p>
                  </div>
                  <div
                    className="flex ml-2  space-x-2"
                    style={{
                      gap: "20px",
                      marginLeft: "10px",
                      marginTop: "5px",
                    }}
                  >
                    <p
                      className="text-2xl font-normal break-all"
                      style={{
                        marginLeft: "25px",
                        width: "75%",
                        fontWeight: "bold",
                        color: showTheme("text"),
                      }}
                    >
                      ₹{" "}
                      {assetData &&
                        assetData?.totalInvestment &&
                        assetData?.totalInvestment?.toFixed(2)}
                      {/* 43786750 */}
                    </p>
                    {/* <p className="text-lg   text-red-700">+7.0%</p> */}
                  </div>
                </div>
                <div
                  className="shadow-md  py-4  rounded-lg"
                  style={{ border: showTheme("borderCard") }}
                >
                  <div className="flex items-center gap-4 ml-2 ">
                    <img
                      src="/image/Ellipse.svg"
                      className="ml-2 w-3 h-3"
                      alt="image"
                    />
                    <p
                      className="text-base font-normal"
                      style={{ color: showTheme("text") }}
                    >
                      Total withdrawal
                    </p>
                  </div>
                  <div
                    className="flex ml-2  space-x-2"
                    style={{
                      gap: "20px",
                      marginLeft: "10px",
                      marginTop: "5px",
                    }}
                  >
                    <p
                      className="text-2xl font-normal break-all"
                      style={{
                        marginLeft: "30px",
                        width: "75%",
                        fontWeight: "bold",
                        color: showTheme("text"),
                      }}
                    >
                      ₹{" "}
                      {assetData &&
                        assetData?.totalWithdrawal &&
                        assetData?.totalWithdrawal?.toFixed(2)}
                    </p>
                    {/* <p className="text-lg   text-green-700">+7.0%</p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Landing;
