import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  cancelPlan,
  getPurchasePlan,
  getTotalInvestment,
} from "../../../Services/PlanServices";
import TimelineIcon from '@mui/icons-material/Timeline';
import { useQuery } from "react-query";
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import { BsGraphUpArrow } from "react-icons/bs";

import { ChevronDown, ChevronUp } from "lucide-react";
import {
  getSinglePlanTransaction,
  getTransactionLog,
} from "../../../Services/UserServices";
import moment from "moment";
import TotalInvestment from "../SidebarPages/TotalInvestment";
import axios from "axios";
import { getToken } from "../../../utils/getToken";
import { ToastContainer, toast } from "react-toastify";
import { showTheme } from "../../Theme/Theme";
import CloseIcon from "@mui/icons-material/Close";
import Spinner from "../../Spinner/Spinner";

const Investment = () => {
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const [showTransaction, setShowTransaction] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [planWiseTransactionId, setPlanWiseTransactionId] = useState(null);
  const [SwitchPopup, setSwitchPopup] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [allPlans, setAllPlans] = useState([]);
  const [allPlansFiltered, setAllPlansFiltered] = useState([]);
  const [withdrawalType, setWithdrawalType] = useState("");
  const [selectedColor, setSelectedColor] = useState(null);
  const [allPlansExisting, setAllPlansExisting] = useState([]);
  const [allPlansExistingFiltered, setAllPlansExistingFiltered] = useState([]);
  const [selectedPlanId, setSelectedPlanId] = useState(null);
  const [selectedNewPlanId, setSelectedNewPlanId] = useState(null);
  const [isSelectedNew, setIsSelectedNew] = useState(null);
  const [isSelectedExisting, setIsSelectedExisting] = useState(null);
  const [storeWithdrawalId, setStoreWithdrawalId] = useState("");
  const [fund, setFund] = useState("");
  const [withdrawalAmount, setWithdrawalAmount] = useState(0);
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [selectedType, setSelectedType] = useState("");
  const [showPopupCancel, setShowPopupCancel] = useState(false);
  const [cancelId, setCancelId] = useState(null);
  const [userPersonalId, setUserPersonalId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [withdrawalLoading, setWithdrawalLoading] = useState(false);
  const [isSelected, setIsSelected] = useState({
    monthly: false,
    profit: false,
    customProfit: false,
    customInvestment: false
  });
  const {
    data: purchasePlan,
    refetch,
    status,
    error,
    isloading,
  } = useQuery({
    queryFn: getPurchasePlan,
    queryKey: ["purchase_plan_details"],
  });
  /*for new plan*/
  const handlePlanSelect = (planId) => {
    setIsSelectedNew(planId);
  };

  const handlePlanSelect1 = (planIdE) => {
    setIsSelectedExisting(planIdE);
  };

  const handleWithdrawal = (investmentId) => {
    setStoreWithdrawalId(investmentId);
  };

  const changeColor = (color) => {
    setSelectedColor(color);
  };

  const handleWithdraw = async () => {
    setLoading(true);
    try {
      const token = getToken();
      const response = await axios.get(
        `https://techtime.pb18.in/api/plan/get-user-plan/${storeWithdrawalId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log('withdrawal');
      setFund(response.data.data.UserPlan);
    } catch (error) {
      console.log("Failed Fetching Details", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    handleWithdraw();
  }, [storeWithdrawalId, showPopup]);

  const handleDataClick = () => {
    setShowModal(true);
  };
  const handleDataClick1 = () => {
    setShowModal1(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setShowModal1(false);
  };

  useEffect(() => {
    handleAllNewPlans();
    handleAllExistingPlans();
  }, []);

  const filteredPlans = purchasePlan?.userPlansDetail?.filter((i) => {
    const a = i.isCanceled === false;
    return a;
  });

  const closePopup = () => {
    setShowPopup(false);
    setShowPopupCancel(false);
  };
  const openPopup = () => {
    setShowPopup(true);
  };

  const closeAnotherPopup = () => {
    setSwitchPopup(false);
  };
  const openAnotherPopup = () => {
    setSwitchPopup(true);
  };

  const planCancel = async (id) => {
    try {
      const { data } = await cancelPlan(id);
    } catch (err) {
      alert("error");
    }
  };

  const handleClick = (id, index) => {
    setPlanWiseTransactionId(id);
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleAllNewPlans = async () => {
    try {
      const token = getToken();
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/plan/allPlan`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setAllPlans(response.data.data.planDetails);
    } catch (error) {
      console.log("Failed fetching", error);
    }
  };

  const handleAllExistingPlans = async () => {
    setLoading(true);
    try {
      const token = getToken();
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/plan/purchased-plan`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setAllPlansExisting(response.data.data.userPlansDetail);
    } catch (error) {
      console.log("Failed fetching", error);
    } finally {
      setLoading(false);
    }
  };

  const switchPlan = (selectedetail) => {
    setSwitchPopup(true);
    setSelectedPlanId(selectedetail._id);
    setSelectedNewPlanId(selectedetail?.planId?._id);
  };
  useEffect(() => {
    if (selectedPlanId) {
      const a = allPlansExisting.filter((data) => data._id != selectedPlanId);
      setAllPlansExistingFiltered(a);
    }
    if (selectedNewPlanId) {
      const b = allPlans.filter((data) => data._id != selectedNewPlanId);
      setAllPlansFiltered(b);
    }
  }, [selectedPlanId, selectedNewPlanId])

  const cancelPlan = (planid) => {
    setCancelId(planid._id);
    setShowPopupCancel(true);
  };

  const handleCancel = async () => {
    try {
      const token = getToken();
      const response = await axios.put(
        `https://techtime.pb18.in/api/plan/cancel-plan/${cancelId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success("Plan Cancelled Successfully");
      refetch();
      setShowPopupCancel(false);
    } catch (error) {
      console.error(error);
      toast.error(error?.response?.data?.message);
    }
  };

  /*Switch to new Plan */
  const handleSwitchPlan = async () => {
    try {
      const token = getToken();
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/plan/switch/${isSelectedNew}/${selectedPlanId}`,
        {
          switchTo: "new",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json",
          },
        }
      );
      toast.success("Successfully switched to New Plan");
      setShowModal(false);
      setSwitchPopup(false);
      setIsSelectedNew(null);
      refetch();
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message);
    }
  };

  /*Switch to existing Plan */
  const handleSwitchPlanExisting = async () => {
    try {
      const token = getToken();
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/plan/switch/${isSelectedExisting}/${selectedPlanId}`,
        {
          switchTo: "existing",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json",
          },
        }
      );
      toast.success("Successfully switched to Existing Plan");
      setShowModal1(false);
      setSwitchPopup(false);
      setIsSelectedExisting(null);
      refetch();
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const handlePopupContinue = async () => {
    try {
      setWithdrawalLoading(true);
      const token = getToken();
      const response = await axios.put(
        `https://techtime.pb18.in/api/plan/update-user-plan/${storeWithdrawalId}`,
        {
          totalfundInvested: "",
          totalProfitEarned: "",
          amountWithdraw: withdrawalAmount || selectedAmount,
          withdrawalType: withdrawalType,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      toast.success("Withdrawal request submitted successfully");

      setShowPopup(false);
      setWithdrawalAmount("");
      setWithdrawalType("");

      refetch();
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setWithdrawalLoading(false);
    }
  };

  const handleSelectAmount = (type) => {

    setIsSelected({
      ...isSelected,
      monthly: type === "monthly",
      profit: type === "profit",
      customProfit: type === "Custom Profit",
      customInvestment: type === "Custom Investment"
    });
    let withdrawalType = "";
    if (type === "profit") {
      setSelectedType("profit");
      setWithdrawalAmount(fund?.profitBalance?.toFixed(2));
      setWithdrawalType("total-profit");
    } else if (type === "investment") {
      setSelectedType("investment");
      setWithdrawalAmount(fund?.totalfundInvested?.toFixed(2));
    } else if (type === "monthly") {
      setSelectedType("monthly");
      setWithdrawalAmount(fund?.monthlyProfit?.toFixed(2));
      setWithdrawalType("monthly-profit");

    } else if (type === "Custom Profit") {
      setSelectedType("Custom Profit");
      setWithdrawalType("custom");
      setWithdrawalAmount("");
    } else {
      setSelectedType("Custom Investment");

      setWithdrawalType("total-investment");
      setWithdrawalAmount("");
    }

  };
  if (status === "error") {
    return <span>Error: {error.message}</span>;
  }


  return (
    <>
      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-80 backdrop-blur-sm">

          {loading ? (
            <div className="flex justify-center" >
              <Spinner />
            </div>
          )
            : (
              <div
                className="sm:w-5/6 md:w-2/3 lg:w-1/2 xl:w-1/3 mx-auto p-5 rounded-lg shadow-lg"

                style={{ backgroundColor: showTheme("card"), overflow: "auto" }}
              >
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", width: "100%" }}>
                  <h1
                    className="text-2xl leading-tight font-lg font-bold justify-center text-center ml-5"
                    style={{ color: showTheme("text"), width: "95%" }}
                  >
                    Withdrawal
                  </h1>
                  <div className="flex justify-end" style={{ width: "5%" }}>
                    <button
                      className="text-gray-500 hover:text-gray-700"
                      onClick={() => {
                        closePopup();
                        setWithdrawalAmount(0)
                      }}
                    >
                      <CloseIcon />
                    </button>
                  </div></div>
                <p
                  className="font-light text-sm mt-1 justify-center text-center"
                  style={{ color: showTheme("text") }}
                >
                  Choose a mode for withdrawal
                </p>

                <div
                  className="mt-10 space-y-4 text-center rounded-md"
                  style={{ gap: "10px", paddingLeft: "10px", paddingRight: "10px" }}
                >
                  <div
                    className={`bg-slate-300 py-3 rounded-md ${selectedType === "monthly" && "bg-purple-700"
                      }`}
                    style={{
                      backgroundColor:
                        selectedColor === "#7d2fd0" ? "#7d2fd0" : "transparent",
                      border: showTheme("borderCard"),
                      cursor: "pointer",
                      color: isSelected.monthly ? "#fff" : showTheme("text")
                    }}
                    onClick={() => {
                      handleSelectAmount("monthly");
                      changeColor("#7d2fd0");
                    }}
                  >
                    Monthly Profit(automatic) - <span style={{ fontWeight: "semibold" }}>₹ {fund?.monthlyProfit?.toFixed(2)}</span>
                    {/* <div style={{ fontSize: "10px", marginTop: "5px" }}>
                  Balance Profit - {fund?.profitBalance?.toFixed(2)}
                </div> */}
                  </div>

                  <div
                    className={`bg-slate-300 py-3 rounded-md ${selectedType === "profit" && "bg-purple-700"
                      }`}
                    style={{
                      backgroundColor:
                        selectedColor === "#8a44d5" ? "#8a44d5" : "transparent",
                      border: showTheme("borderCard"),
                      cursor: "pointer",
                      color: isSelected.profit ? "#fff" : showTheme("text")
                    }}
                    onClick={() => {
                      handleSelectAmount("profit");
                      changeColor("#8a44d5");
                    }}
                  >
                    Total Profit - <span style={{ fontWeight: "semibold" }}>₹ {fund?.profitBalance?.toFixed(2)}</span>
                    {/* <div style={{ fontSize: "10px", marginTop: "5px" }}>
                  Balance Profit - {fund?.profitBalance?.toFixed(2)}
                </div> */}
                  </div>
                  <div
                    className={`bg-slate-300 py-3 rounded-md ${selectedType === "Custom Profit" && "bg-purple-700"
                      }`}
                    style={{
                      backgroundColor:
                        selectedColor === "#9759d9" ? "#9759d9" : "transparent",
                      border: showTheme("borderCard"),
                      cursor: "pointer",
                      color: isSelected.customProfit ? "#fff" : showTheme("text")
                    }}
                    onClick={() => {
                      handleSelectAmount("Custom Profit");
                      changeColor("#9759d9");
                    }}
                  >
                    Custom Amount from Profit
                    <div style={{ fontSize: "10px", marginTop: "2px" }}>
                      Balance Profit - <span style={{ fontWeight: "bold" }}>₹ {fund?.profitBalance?.toFixed(2)}</span>
                    </div>
                  </div>
                  <div
                    className={`bg-slate-300 py-3 rounded-md ${selectedType === "Custom Investment" && "bg-purple-700"
                      }`}
                    style={{
                      backgroundColor:
                        selectedColor === "#a46ede" ? "#a46ede" : "transparent",
                      border: showTheme("borderCard"),
                      cursor: "pointer",
                      color: isSelected.customInvestment ? "#fff" : showTheme("text")
                    }}
                    onClick={() => {
                      handleSelectAmount("Custom Investment");
                      changeColor("#a46ede");
                    }}
                  >
                    Custom Amount from Investment
                    <div style={{ fontSize: "10px", marginTop: "2px" }}>
                      Balance Investment - <span style={{ fontWeight: "bold" }}>₹ {fund?.investmentBalance?.toFixed(2)}</span>
                    </div>
                  </div>
                  <input
                    type="number"
                    className="border rounded-md px-2 py-3 w-full focus:ring focus:ring-purple-100 focus:outline-none"
                    placeholder={`Enter ${selectedType === "custom" ? "custom" : selectedType
                      } amount`}
                    value={withdrawalAmount}
                    onChange={(e) => {
                      const enteredAmount = parseFloat(e.target.value);
                      let validAmount = enteredAmount;
                      let errorMessage = "";
                      if (selectedType === "Custom Profit") {
                        validAmount = Math.min(enteredAmount, fund?.profitBalance?.toFixed(2));
                        if (enteredAmount > fund?.profitBalance) {
                          errorMessage = `Amount must not exceed available profit (${fund?.profitBalance?.toFixed(2)})`;
                        }
                      } else if (selectedType === "Custom Investment") {
                        validAmount = Math.min(enteredAmount, fund?.investmentBalance?.toFixed(2));
                        if (enteredAmount > fund?.investmentBalance) {
                          errorMessage = `Amount must not exceed available balance (${fund?.investmentBalance?.toFixed(2)})`;
                        }
                      }

                      setWithdrawalAmount(validAmount);

                      const errorMessageElement =
                        e.target.parentNode.querySelector(".error-message");
                      if (errorMessageElement) {
                        errorMessageElement.textContent = errorMessage;
                      }
                    }}
                    disabled={selectedType === "monthly" || selectedType === "profit"}
                    style={{
                      backgroundColor: showTheme("topHeader"),
                      border: showTheme("fieldBorder"),
                      color: showTheme("text")
                    }}
                  />

                  {withdrawalAmount > 0 && (
                    <p className="text-red-500 text-sm mt-1 error-message"></p>
                  )}
                </div>
                <div className="flex justify-center mt-3">
                  <button
                    title="Continue"
                    className={`text-sm ${withdrawalAmount === 0 ? "bg-gray-300 text-gray-600 cursor-not-allowed" : "bg-purple-700 text-white hover:bg-purple-500"} font-semibold rounded-lg px-3 py-3 w-full`}
                    onClick={handlePopupContinue}
                    disabled={withdrawalAmount === 0}
                  >
                    {
                      withdrawalLoading ?
                        <div class="three-body-button">
                          <div class="three-body__dot"></div>
                          <div class="three-body__dot"></div>
                          <div class="three-body__dot"></div>
                        </div>
                        :
                        'CONTINUE'
                    }
                  </button>
                </div>
              </div>
            )}
        </div>
      )}

      {SwitchPopup && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-80">
          <div
            className=" sm:w-1/5 md:w-1/3 lg:w-1/3 xl:w-1/3 p-4 rounded-lg shadow-lg"
            style={{ backgroundColor: showTheme("card") }}
          >
            <div className="flex justify-end">
              <button
                className="text-gray-500 hover:text-gray-700"
                onClick={closeAnotherPopup}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <h1
              className="text-xl leading-tight font-bold flex justify-center pb-5 "
              style={{ color: showTheme("yourPlan") }}
            >
              SWITCH PLANS
            </h1>
            <div
              className="flex justify-center space-x-4 mt-10"
              style={{
                paddingTop: "0px",
                paddingBottom: "20px",
                gap: "20px",
              }}
            >
              <button
                onClick={handleDataClick1}
                className="bg-purple-700 text-white text-nowrap py-2 px-4 rounded-lg hover:bg-purple-600"
                style={{
                  paddingTop: "20px",
                  paddingLeft: "10px",
                  paddingRight: "20px",
                  paddingBottom: "20px",
                  width: "40%",
                  fontSize: "15px",
                }}
              >
                Existing Plan
              </button>
              <button
                onClick={handleDataClick}
                className="bg-purple-200 text-nowrap text-black py-2 px-4 rounded-lg hover:bg-purple-300"
                style={{
                  paddingTop: "20px",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  paddingBottom: "20px",
                  width: "40%",
                  fontSize: "15px",
                }}
              >
                New Plan
              </button>
            </div>
          </div>
        </div>
      )}
      {isloading || withdrawalLoading ? (
        <div className="flex justify-center" style={{ marginTop: "35vh" }}>
          <Spinner />
        </div>
      ) : (
        <div
          className="bg-white rounded-2xl shadow-md border mb-8 ml-3 mr-3 sm:ml-3 border-style"
          style={{
            backgroundColor: showTheme("card"),
            border: showTheme("borderCard"),
          }}>
          <div className="mt-5 ml-5">
            <TotalInvestment showPopup={showPopup} setShowPopup={setShowPopup} />
            <div className="rounded-2xl">
              {filteredPlans &&
                filteredPlans.map((i, index) => (
                  <div className="bg-white shadow-xl py-8 px-8 flex flex-col rounded-2xl mt-5 mr-5" style={{ backgroundColor: showTheme("card"), border: showTheme("borderCard") }} key={i._id}>
                    <div className="">
                      <div className="flex flex-col md:flex-row items-center justify-between">
                        <div className="md:order-1">
                          <h1 className="text-lg font-bold mb-5" style={{ color: showTheme("text") }}>
                            Total Investment in{" "}
                            <span style={{ fontSize: "24px" }}>{i?.planId.planName}</span>
                          </h1>
                          <p className="text-2xl font-bold" style={{ color: showTheme("text") }}>
                            ₹ {Number(i?.investmentBalance).toFixed(2)}
                          </p>
                          <p className="" style={{ color: showTheme("text") }}>
                            You add{" "}
                            <span className="text-green-500">+₹{Number(i?.monthlyProfit).toFixed(2)}</span>{" "}
                            this month
                          </p>

                          <Link to={`/add-funds/${i?._id}`}>
                            <button title="Add Money" className="bg-purple-700 text-white rounded-lg px-4 py-2 mr-2 mt-4 hover:bg-purple-500">
                              Add Money
                            </button>
                          </Link>
                          <button title="Withdrawal" className="bg-slate-200 text-black rounded-lg px-4 py-2 hover:bg-slate-100" onClick={() => {
                            setShowPopup(true);
                            handleWithdrawal(i?._id);
                          }}>
                            Withdrawal
                          </button>
                        </div>

                        <div className="md:order-2 md:text-center mt-10 md:mt-0">
                          <h1 className="text-lg font-bold" style={{ color: showTheme("text") }}>
                            <span style={{ fontSize: "24px" }}>{i?.planId.planName}</span>
                          </h1>
                          <p style={{ marginTop: "10px", color: showTheme("text") }}>This is your current plan</p>
                          <p className="" style={{ color: showTheme("text") }}>
                            {i?.planId.returnPercent}% / {i?.planId.description}
                          </p>
                          <div className="space-y-2 justify-center mt-14" style={{ display: "grid" }}>
                            <button title="Switch Plan" className="bg-purple-700 w-56 text-white rounded-lg px-4 py-2 mr-2 hover:bg-purple-500" onClick={() => switchPlan(i)}>
                              Switch Plan
                            </button>
                            <button title="Cancel Plan" className="bg-slate-200 w-56 text-black rounded-lg px-4 py-2 hover:bg-slate-100" onClick={() => cancelPlan(i)}>
                              Cancel Plan
                            </button>
                            {showPopupCancel && (
                              <div className="fixed inset-0 flex items-center rounded-xl justify-center bg-black bg-opacity-50 backdrop-blur-sm z-50">
                                <div className="p-8 shadow-md rounded-xl ml-5 mr-5 sm:ml-" style={{ backgroundColor: showTheme("cardBack"), overflow: "hidden" }}>
                                  <h2 className="text-2xl font-bold mb-4 flex justify-center" style={{ color: showTheme("text") }}>Cancel Plan</h2>
                                  <p className="text-lg flex justify-center" style={{ color: showTheme("text") }}>Are you sure you want to cancel the plan?</p>
                                  <div className="flex justify-center gap-10 mt-10">
                                    <button title="Cancel" className="bg-purple-700 text-white px-4 py-2 rounded mt-4 flex hover:bg-purple-400" style={{ width: "40%", fontSize: "14px", justifyContent: "center", alignContent: "center", alignItems: "center" }} onClick={handleCancel}>
                                      Cancel
                                    </button>
                                    <button title="Close" className="bg-slate-200 text-black px-4 py-2 rounded mt-4 flex hover:bg-slate-300" style={{ width: "40%", fontSize: "14px", justifyContent: "center", alignContent: "center", alignItems: "center" }} onClick={closePopup}>
                                      Close
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className=" flex flex-col min-[1166px]:flex-row mt-24  justify-between gap-10 w-full mb-7">
                        <div className="flex flex-wrap gap-2 py-4 shadow-lg border p-3 rounded-md relative w-full" style={{ backgroundColor: showTheme("cardBack"), border: showTheme("bordersmallCard") }}>
                          <div className="flex items-center gap-5">
                            <h1 className="text-sm md:text-lg xl:text-xl ml-2" style={{ color: showTheme("text") }}>2024 (Added)</h1>
                            <TimelineIcon style={{ color: "green" }} />

                          </div>
                          <div className="flex items-center gap-5 ">
                            <h1 className="text-lg md:text-xl xl:text-2xl md:mr-2 ml-auto" style={{ color: showTheme("text") }}>₹ {Number(i?.getTotalInvestmentThisYear).toFixed(2)}</h1>
                          </div>
                        </div>

                        <div className="flex flex-wrap gap-2 py-4 shadow-lg border p-3 rounded-md relative w-full" style={{ backgroundColor: showTheme("cardBack"), border: showTheme("bordersmallCard") }}>
                          <div className="flex items-center gap-5">
                            <h1 className="text-sm md:text-lg xl:text-xl ml-2" style={{ color: showTheme("text") }}>2024 (Withdrawal)</h1>
                            <TrendingDownIcon style={{ color: "red" }} />

                          </div>
                          <div className="flex items-center gap-5 ">
                            <h1 className="text-lg md:text-xl xl:text-2xl md:mr-2 ml-auto" style={{ color: showTheme("text") }}>₹ {Number(i?.getTotalWithdrawalThisYear).toFixed(2)}</h1>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                ))}
            </div>
          </div>

          <div className="flex justify-center mt-12 mb-10">
            <Link to="/userplans">
              <button className="rounded-lg p-2 px-10 bg-purple-800 text-white hover:bg-purple-500" style={{ width: "100%" }}>
                Add Different Plan
              </button>
            </Link>
          </div>
        </div>
      )}

      {/*Switch new Plan*/}
      {
        showModal && (
          <div
            className=""
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 99,

            }}
          >
            <div
              className="ml-5 mr-5 sm:ml-5"
              style={{
                backgroundColor: showTheme("card"),
                padding: "20px",
                borderRadius: "5px",
                width: "500px",
              }}
            >
              <h1
                className="text-xl font-bold flex justify-center"
                style={{ color: showTheme("text") }}
              >
                Select New Plan{" "}
              </h1>
              <div
                className=" rounded my-2 flex flex-col"
                style={{ height: "400px", overflowY: "scroll" }}
              >
                {allPlansFiltered?.map((data, index) => (
                  <div
                    className={`flex shadow-lg m-1 p-1 rounded ${isSelectedNew === data._id ? "bg-gray-200" : ""
                      }`}
                    style={{ border: showTheme("borderCard") }}
                    key={index}
                  >
                    <div className="flex flex-col w-3/4">
                      <p
                        className="font-semibold text-l pl-2 "
                        style={{ fontSize: "18px", color: isSelectedNew === data._id ? showTheme("black") : showTheme("text") }}
                      >
                        {data?.planName}
                      </p>
                      <p
                        className="pl-2 mt-3"
                        style={{ color: isSelectedNew === data._id ? showTheme("black") : showTheme("text") }}
                      >
                        {data?.returnPercent}% / {data?.description}
                      </p>
                    </div>

                    <div className="flex">
                      <button
                        className={`p-3  text-white m-auto ${isSelectedNew === data._id
                          ? "bg-gray-400"
                          : "bg-purple-500"
                          }`}
                        style={{ borderRadius: "5px" }}
                        onClick={() => handlePlanSelect(data._id)}
                      >
                        {isSelectedNew === data._id ? "Selected" : "Select"}
                      </button>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <button
                  style={{
                    display: "flex",
                    margin: "5px",
                    fontSize: "20px",
                    border: "none",
                    borderRadius: "5px",
                    padding: "15px 20px",
                    cursor: "pointer",
                    width: "50%",
                    justifyContent: "center",
                  }}
                  onClick={handleSwitchPlan}
                  className="bg-purple-700 hover:bg-purple-500 text-white"
                >
                  Switch
                </button>
                <button
                  style={{
                    display: "flex",
                    margin: "5px",
                    fontSize: "20px",
                    border: "none",
                    borderRadius: "5px",
                    padding: "15px 20px",
                    cursor: "pointer",
                    width: "50%",
                    justifyContent: "center",
                  }}
                  onClick={handleCloseModal}
                  className="bg-slate-200 hover:bg-slate-300 text-black"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )
      }
      {/*Swith existing Plan*/}
      {
        showModal1 && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 99,
            }}
          >
            <div
              className="ml-5 mr-5 sm:ml-5"
              style={{
                backgroundColor: showTheme("card"),
                padding: "20px",
                borderRadius: "5px",
                width: "500px",
              }}
            >
              <h1
                className="text-xl font-bold flex justify-center"
                style={{ color: showTheme("text") }}
              >
                Select Existing Plan{" "}
              </h1>
              <div
                className=" rounded my-2 flex flex-col"
                style={{ height: "400px", overflowY: "scroll" }}
              >
                {allPlansExistingFiltered?.map((data, index) => (
                  <div
                    className={`flex  shadow-lg m-1 p-1 rounded ${isSelectedExisting === data?.planId?._id
                      ? "bg-gray-200"
                      : ""
                      }`}
                    style={{ border: showTheme("borderCard") }}
                    key={index}
                  >
                    <div className="flex flex-col w-3/4">
                      <p
                        className="font-bold text-l pl-2"
                        style={{ fontSize: "18px", color: showTheme("text") }}
                      >
                        {data?.planId?.planName}
                      </p>
                      <p
                        className="pl-2 mt-3 "
                        style={{ color: showTheme("text") }}
                      >
                        {data?.planId?.returnPercent}% /{" "}
                        {data?.planId?.description}
                      </p>
                    </div>

                    <div className="w-1/4 flex">
                      <button
                        className={`p-3 text-white m-auto ${isSelectedExisting === data?.planId?._id
                          ? "bg-gray-400"
                          : "bg-purple-500"
                          }`}
                        style={{ borderRadius: "5px" }}
                        onClick={() => handlePlanSelect1(data?.planId?._id)}
                      >
                        {isSelectedExisting === data?.planId?._id
                          ? "Selected"
                          : "Select"}
                      </button>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <button
                  title="Switch"
                  style={{
                    display: "flex",
                    margin: "5px",
                    fontSize: "20px",
                    border: "none",
                    borderRadius: "5px",
                    padding: "15px 20px",
                    cursor: "pointer",
                    width: "50%",
                    justifyContent: "center",
                  }}
                  onClick={handleSwitchPlanExisting}
                  className="bg-purple-700 hover:bg-purple-500 text-white"
                >
                  Switch
                </button>
                <button
                  title="Cancel"
                  style={{
                    display: "flex",
                    margin: "5px",
                    fontSize: "20px",
                    border: "none",
                    borderRadius: "5px",
                    padding: "15px 20px",
                    cursor: "pointer",
                    width: "50%",
                    justifyContent: "center",
                  }}
                  onClick={handleCloseModal}
                  className="bg-slate-200 hover:bg-slate-300 text-black"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )
      }
      <ToastContainer />
    </>
  );
};

export default Investment;
