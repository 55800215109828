import { defaultApi } from "./api";

  export async function createEmployee(data) {
    return await defaultApi.post(`/api/employee/create`, data);
  }

  export async function updateEmployee({id, data}) {
    return await defaultApi.put(`/api/employee/update/${id }`, data);
  }


  export async function getEmployeeDetail(id) {
    let res = await  defaultApi.get(`/api/employee/get/${id}`);
    return res.data;
  }

 export async function uploadProfileImg({id,data}) {
    return await defaultApi.put(`/api/employee/uploadProfileImage/${id}`, data);
  }

