import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
// import { UserProvider } from '../src/utils/UserContext';
import { QueryClient, QueryClientProvider } from 'react-query';
import AuthProvider from './Components/Context/AuthProvider ';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ThemeProvider } from './Components/Context/ThemeContext';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ToastContainer } from 'react-toastify';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <GoogleOAuthProvider clientId="664309932986-4p8of2nkoaf52c6595ak1dahpj8j4dij.apps.googleusercontent.com">
    {/* <UserProvider> */}
    <QueryClientProvider client={queryClient}>
      
    <ThemeProvider>
      <ToastContainer/>
      <App /> 
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      </ThemeProvider>
    </QueryClientProvider>
    </GoogleOAuthProvider>
    {/* </UserProvider> */}
  </React.StrictMode>
);
