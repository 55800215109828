import { authApi, defaultApi } from "./api";
import { getToken } from "../utils/getToken";

export async function UserGenerateOtp(data) {
  return await defaultApi.post(`/api/user/generate-otp`, data);
}

export function verifyOTP(data) {
  return defaultApi.post(`/api/user/verify-otp`, data);
}

export function verifyPancard(data) {
  return defaultApi.post(`/api/user/verify-pan`, data);
}


export async function getUserInfo({ queryKey }) {
  const { 1: page = 1 } = queryKey;
  const res = await defaultApi.get('/api/user/get-detail');
  return res.data.data;
}
export function deleteUserPhoto() {
  const token = getToken();
  return defaultApi.delete('/api/user/delete-profile', { headers: { "token": token } });
}
export function updateUserProfile(formData) {
  const token = getToken();
  return defaultApi.put('/api/user/update-detail', formData, { headers: { "token": token } });
}
//  admin panel apis
export async function getLeads(page, size, searchForAll, sortKey, dateValue) {
  let keySort = '';
  let keyOrder = '';
  if (sortKey) {
    const a = sortKey.split(' ');
    const b = a[a.length - 2];
    const c = a[a.length - 1];
    keySort = b;
    keyOrder = c;
  }
  const res = await defaultApi.get(`/api/user/leads?page=${page}&pageSize=${size}&keyword=${searchForAll}&sortOrder=${keyOrder}&sortKey=${keySort}&dateValue=${dateValue}`);
  return res.data.data;
}
export async function getClients(searchForAll, sortKey) {
  let keySort = '';
  let keyOrder = '';
  if (sortKey) {
    const a = sortKey.split(' ');
    const b = a[a.length - 2];
    const c = a[a.length - 1];
    keySort = b;
    keyOrder = c;
  }
  const res = await defaultApi.get(`/api/user/clients?keyword=${searchForAll}&sortOrder=${keyOrder}&sortKey=${keySort}`);
  return res.data.data;
}
export async function getTransactionLog(page, size) {
  // let res;
  // if(planId){
  //   console.log("planId");
  //    res= await defaultApi.get(`/api/user/transaction-log?&userPlanId=${planId}`);
  // }else{
  //   console.log("page size");
  //    res= await defaultApi.get(`/api/user/transaction-log?page=${page}&pageSize=${size}`);
  // }
  const res = await authApi.get(`/api/user/transaction-log?page=${page}&pageSize=${size}`);

  // const a=res.data.data.transactionLogData.map((i)=>{
  //   i.createdAt=new Date(i.createdAt);
  //   return i;
  // })
  return res;
}




