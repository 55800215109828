import { defaultApi } from "./api";

export async function getFaq() {
    const res = await defaultApi.get(`/api/faq/all`);
    return res.data.data;
  }

  export function addFaq(data) {
    return defaultApi.post(`/api/faq/create`, data);
  }

  export async function deleteFaq(id) {
    const res = await defaultApi.delete(`/api/faq/delete/${id}`);
    return res;
  }

  export function updateFaq(id,data) {
    // const token = getToken(); 
    return defaultApi.put(`/api/faq/update/${id}`,data);
  } 
