import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { FiCheckCircle, FiMail, FiPhone } from "react-icons/fi";
import { endpoint } from "../utils/apiUrls";
import Spinner from "./Spinner/Spinner";
import axios from "axios";
// import { useUser } from "../utils/UserContext";
import { UserGenerateOtp } from "../Services/UserServices";
import AutochangeTitle from "./UserPanel/Common/AutochangeTitle";
import { ToastContainer, toast } from "react-toastify";
import { GoogleOAuthProvider, useGoogleLogin } from "@react-oauth/google";
import { GoogleLogin } from "@react-oauth/google";
import jwt_decode from "jwt-decode";
import { MdEmail } from "react-icons/md";
import { FaMobileAlt } from "react-icons/fa";
import { useMounted } from "@casper124578/useful";
import qs from "qs";
import { defaultApi } from "../Services/api";

function Signup() {
  const mounted = useMounted();
  const navigate = useNavigate();

  // const { setUserInfo } = useUser();
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isMobileValid, setIsMobileValid] = useState(true);
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [loading, setLoading] = useState(false);

  const isEmailFormatValid = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isMobileFormatValid = (mobile) => {
    const mobileRegex = /^[0-9]{10}$/;
    return mobileRegex.test(mobile);
  };
  const handleMobileChange = (e) => {
    let inputValue = e.target.value;
    if (inputValue.length > 10) {
      inputValue = inputValue.slice(0, 10);
    }
    const isValidMobile = /^\d{10}$/.test(inputValue);
    setMobile(inputValue);
    setIsMobileValid(inputValue.length === 10 || inputValue === '' || isValidMobile);
  };


  const handleSignupSubmit = (e) => {
    e.preventDefault();

    const emailValid = isEmailFormatValid(email);
    const mobileValid = isMobileFormatValid(mobile);

    setIsEmailValid(emailValid);
    setIsMobileValid(mobileValid);


    if (emailValid && mobileValid) {
      generateOtp(e);
      // Update user context with email and mobile
      // setUserInfo({ email, mobile });
      // Pass email and mobile as parameters to Signupotp page
    }
  };
  const generateOtp = async (event) => {
    setLoading(true);

    event.preventDefault();
    const formData = {
      module: "sign-up",
      email: email,
      phone: mobile,
    };

    try {
      const { data } = await UserGenerateOtp(formData);
      if (!data) return;
      navigate(`/sotp?email=${email}&phone=${mobile}`);
    } catch (e) {
      console.log('error',e?.response?.data?.message);
      toast.error(e?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };
  const login = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
  });
  const [user, setUser] = useState([]);
  const handleGoogleLogin = useGoogleLogin({
    flow: "auth-code",
    onSuccess: async (codeResponse) => {
      try {
        let body = qs.stringify({
          client_id:
            "664309932986-4p8of2nkoaf52c6595ak1dahpj8j4dij.apps.googleusercontent.com",
          client_secret: "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",
          code: codeResponse.code,
          grant_type: "authorization_code",
          redirect_uri: window.location.origin,
        });

        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: "https://oauth2.googleapis.com/token",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          data: body,
        };
        axios
          .request(config)
          .then(async (response) => {
            const { data } = await axios.post(
              `${defaultApi}/user/social_login`,
              response.data.id_token,
              "google"
            );
            if (!data) return;
            else {
              window.localStorage.setLoginData("token", data.data.access_token);
              navigate("/dashboard");
            }
          })
          .catch((error) => {
            console.log(error);
            toast(mounted ? "somethingwrong" : null);
          });
        // console.log(data);
      } catch (e) {
        toast(mounted ? "somethingwrong" : null);
      }
    },
    onError: (error) => console.log("Login Failed:", error),
  });
  return (
        <section className="flex flex-col md:flex-row min-h-screen items-center justify-center">
          <div className="bg-white w-full md:w-1/3 lg:w-1/3 xl:w-1/3 mx-auto flex items-center justify-center">
            <div className="w-full p-4">
              <div className=" flex items-center">
                <img className="w-" src="/image/Logo(1).png" alt="techtime logo" />
                <div className="text-purple-700 text-xl font-semibold">
                  Techtime
                </div>
              </div>
              <AutochangeTitle />
              <h1 className=" leading-tight   font-light text-sm ">
                Start your journey with tech Times by singing up
              </h1>
              <form className="mt-5" onSubmit={handleSignupSubmit}>
                <div className="relative">
                  <div className="flex items-center">
                    <MdEmail
                      className="absolute w-10 rounded-l-lg text-3xl text-white bg-purple-700  p-2"
                      style={{ height: "85%", top: "8px" }}
                    />
                    <input
                      type="email"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Enter your email"
                      className={`ps-12 w-full px-10 py-3 rounded-lg bg-purple-50 mt-2 border ${isEmailValid ? "border-purple-500" : "border-red-500"
                        } focus:ring focus:ring-purple-100 focus:outline-none focus:bg-white `}
                      autoFocus
                      autoComplete="email"
                      required
                    />
                    {/* {isEmailValid ? (
              <FiCheckCircle className="text-green-500 ml-2" size={20} />
            ) : (
              <FiMail className="text-gray-500 ml-2" size={20} />
            )} */}
                  </div>
                </div>
                {!isEmailValid && (
                  <p className="text-red-500 text-sm">
                    Please enter a valid email address
                  </p>
                )}
                <div className="relative">
                  <div className="flex items-center">
                    <FaMobileAlt
                      className="absolute w-10 rounded-l-lg text-3xl text-white bg-purple-700  p-3"
                      style={{ height: "85%", top: "8px" }}
                    />
                    <input
                      type="text"
                      name="mobile"
                      value={mobile}
                      onChange={handleMobileChange}
                      placeholder="Enter your phone number"
                      className={`ps-12 w-full px-10 py-3 rounded-lg bg-purple-50 mt-2 border ${isMobileValid ? 'border-purple-500' : 'border-red-500'
                        } focus:ring focus:ring-purple-100 focus:outline-none focus:bg-white`}
                      autoComplete="tel"
                      required
                    />
                    {/* {isMobileValid ? (
              <FiCheckCircle className="text-green-500 ml-2" size={20} />
            ) : (
              <FiPhone className="text-gray-500 ml-2" size={20} />
            )} */}
                  </div>
                </div>
                {!isMobileValid && (
                  <p className="text-red-500 text-sm">Please enter a valid 10-digit mobile number</p>
                )}

                <div className="tearm-and-condition mt-4">
                  <input
                    type="checkbox"
                    id="agreeToTerms"
                    checked={agreeToTerms}
                    onChange={() => setAgreeToTerms(!agreeToTerms)}
                    className="mr-2"
                    required
                  />
                  <label htmlFor="agreeToTerms" className="text-sm">
                    By clicking Sign Up, you agree to our{" "}
                    <NavLink
                      to="/terms-and-conditions"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-purple-700 mr-1 "
                    >
                      Terms and Conditions
                    </NavLink>
                    and{" "}
                    <NavLink
                      to="/privacy-policy"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-purple-700"
                    >
                      Privacy Policy
                    </NavLink>
                    . You may receive SMS notifications from us and can opt out at
                    any time.
                  </label>
                </div>
                <button
                  title="Continue"
                  type="submit"
                  className={`w-full block rounded-lg px-4 py-3 mt-6 ${agreeToTerms
                    ? "bg-purple-500 hover:bg-purple-400 focus:bg-purple-400 text-white font-semibold"
                    : "bg-purple-200 text-gray-600 cursor-not-allowed"
                    }`}
                  disabled={!agreeToTerms}
                >
                   {
                      loading ?
                        <div class="three-body-button">
                          <div class="three-body__dot"></div>
                          <div class="three-body__dot"></div>
                          <div class="three-body__dot"></div>
                        </div>
                        :
                        'Continue'
                    }
                  
                </button>
              </form>


              <div className=" text-center mt-4">
                Already have an account?{" "}
                <button
                  className="text-purple-700 hover:text-blue-700 font-semibold hover:decoration-blue-400"
                  onClick={() => navigate("/")}
                  title="Login"
                >
                  Log in{" "}
                </button>
              </div>
            </div>
          </div>

          <div className="hidden md:block lg:block sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2">
            <img
              src="/image/image.jpg"
              alt="image"
              className="w-full h-auto max-w-full "
            />
          </div>
          <ToastContainer />
        </section>
  );
}

export default Signup;
