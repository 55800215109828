import React from "react";
import PlanContent from "./PlanContent";
import { getPurchasePlan } from "../../../../Services/PlanServices";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import Plans from "./Plans";
import Spinner from "../../../Spinner/Spinner";
import { showTheme } from "../../../Theme/Theme";

const PlanIndex = () => {
  const navigate = useNavigate();
  const { data: purchasePlan, refetch , isLoading} = useQuery({
    queryFn: getPurchasePlan,
    queryKey: ["purchase_plan_details"],
  });
  const handlePurchasePlanClick = () => {
    navigate("/userplans");
  };
  // console.log("purchase plans",purchasePlan.userPlansDetail);
  return (
    <div>
      {isLoading? (
        <div className="flex justify-center " style={{ marginTop: "35vh" }}>
          <Spinner />
        </div>
      ) : (
        <div
        className="bg-white rounded-2xl shadow-md border mb-8 ml-3 mr-3 sm:ml-3 border-style"
        style={{
        background: showTheme("card"),
        border: showTheme("borderCard"),

        overflow:"hidden"
      }}
    >
        <div>
      {
        purchasePlan?.userPlansDetail?.length !== 0 && (
          <PlanContent purchasePlan={purchasePlan} refetch={refetch} />
        )
      }
      {purchasePlan?.userPlansDetail?.length == 0 && (
        <>
          <div className="  rounded-lg " style={{ height:"100%"}} >
            <div className="flex flex-col items-center  mt-10  ">
              <h1 className="font-bold text-3xl  min-[640px]:text-5xl" style={{color:showTheme("text")}}>
                Your Plans
              </h1>

              <h1 className="text-md min-[640px]:text-lg  mt-4 text-center" style={{color:showTheme("text")}}>
                Join millions of other customers on this platform
              </h1>

              <p className="text-lg text-red-400 mt-5 min-[640px]:mt-20  mb-5 font-semibold">
                No Plans Purchased
              </p>
              <button
                title="Purchase Plan"
                className="bg-purple-700 text-white py-3 px-4 rounded-xl text-nowrap w-50 hover:bg-purple-3000 mb-20 "
                onClick={handlePurchasePlanClick}
              >
                Purchase Plan
              </button>
                <div
                  className="flex items-center justify-around  w-full flex-wrap px-5 gap-5"
                  
                >
                  <div className="flex space-x-4 items-center">
                    <img
                      src="/image/icon(2).png"
                      className="h-full"
                      alt="image"
                    />
                    <h1 style={{color:showTheme("text")}}>Flexible Plan</h1>
                  </div>
                  <div className="flex space-x-4 items-center">
                    <img
                      src="/image/icon(2).png"
                      className="h-full"
                      alt="image"
                    />
                    <h1 style={{color:showTheme("text")}}>Change anytime</h1>
                  </div>
                  <div className="flex space-x-4 items-center">
                    <img
                      src="/image/icon(2).png"
                      className="h-full"
                      alt="image"
                    />
                    <h1 style={{color:showTheme("text")}}>Customer Support</h1>
                  </div>
                </div>
            </div>
          </div>
        </>
      )}
      </div>
      </div>
      )}
    </div>
  );
};

export default PlanIndex;
