import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";

const BarChart = ({ chart }) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    if (chart && chart.length > 0) {
      const labels = chart.map(entry => entry.monthName);
      const investedData = chart.map(entry => entry.totalInvestment);
      const withdrawnData = chart.map(entry => entry.totalWithdrawal);

      setData({
        labels: labels,
        datasets: [
          {
            label: "Invested",
            data: investedData,
            backgroundColor: "green",
          },
          {
            label: "Withdrawn",
            data: withdrawnData,
            backgroundColor: "red",
          }
        ]
      });
    }
  }, [chart]);

  const options = {
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          const label = data.datasets[tooltipItem.datasetIndex].label;
          const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          return `${label}: ${value}`;
        },
      },
    },
  };

  return (
    <div style={{ height: "350px", width: "100%", overflowX:"auto" }}>
      {data && <Bar data={data} options={options} style={{ minHeight: "350px",minWidth:"800px", display:"flex", marginInline:"auto"}} />}
    </div>
  );
};

export default BarChart;
