import React, { useState } from "react";
import { useNavigate, useParams } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import { updateUserPurchasePlan } from "../../../../Services/PlanServices";
import { showTheme } from "../../../Theme/Theme";

const AddFunds = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [amount, setAmount] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [amountValid, setAmountValid] = useState(null);
  const [saveLoading, setSaveLoading] = useState(false);


  const back = () => {
    navigate("/dashboard");
  };
  const popup = () => {
    if (amountValid) {
      toast.warning('Amount invalid!');
      return;
    }
    setShowConfirmation(true);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (amountValid) {
      toast.warning('Amount invalid!');
      return;
    }
    const formData = {
      totalfundInvested: parseInt(amount),
      totalProfitEarned: "",
      amountWithdraw: "",
      withdrawalType: "",
    };
    try {
      setSaveLoading(true);
      const { data } = await updateUserPurchasePlan(
        params.userplanid,
        formData
      );
      toast.success(data.message);
      navigate("/funds");
    } catch (e) {
      console.log(e);
      toast.error(e?.response?.data?.message);
    } finally {
      setSaveLoading(false);

    }
  };

  const handleNumberClick = (number) => {
    setAmount((prevValue) => +prevValue + +number);
  };

  const confirmAction = async () => {
    // This function could be implemented based on your specific requirements
    // For now, I'll just submit the form
    handleSubmit();
  };
  const validateAmount = (value) => {
    if (!value) {
      setAmountValid('Amount is required');
    } else if (!/^\d+$/.test(value)) {
      setAmountValid('Amount can only contain Numbers');
    } else {
      setAmountValid('');
    }
  };

  return (
    <div className=" shadow-md rounded-2xl p-6 flex flex-col justify-center items-center ml-5 mr-5 sm:ml-5" style={{
      height: "calc(120vh - 120px)",
      maxHeight: "80vh",
      overflowY: "auto",
      backgroundColor: showTheme("card"),
      border: showTheme("borderCard")
    }}>
      <div className="text-violet-600 text-4xl font-semibold mt-8" style={{ color: showTheme("yourPlan") }}>Add Funds</div>
      <div className="w-full flex justify-center items-center">
        <div className="my-10 flex flex-col justify-center items-center w-full">
          <label className="text-slate-500 text-xl" style={{ color: showTheme("yourPlan") }}>Enter Amount</label>
          <input
            type="text"
            placeholder="₹ 0"
            value={amount}
            onChange={(e) => { setAmount(e.target.value); validateAmount(e.target.value); }}
            className="border border-grey-500 p-3 text-center w-1/2 my-4 rounded-full focus:ring focus:ring-purple-100 focus:outline-none"
            maxLength="8"
            style={{ backgroundColor: showTheme("topHeader"), border: showTheme("fieldBorder"), color: showTheme("text") }}
          />
          {amountValid && <div style={{ color: 'red' }} className="text-sm">{amountValid}</div>}
          <div className="flex flex-wrap justify-center items-center gap-10 w-full sm:w-1/2 my-4">
            <div
              className="border border-violet-600 rounded-full px-3 p-1 cursor-pointer flex-grow text-center mt-3 sm:mt-0"
              onClick={() => handleNumberClick("500")}
              style={{ backgroundColor: showTheme("topHeader"), color: showTheme("text") }}
            >
              + ₹500
            </div>
            <div
              className="border border-violet-600 rounded-full px-3 p-1 cursor-pointer flex-grow text-center mt-3 sm:mt-0"
              onClick={() => handleNumberClick("1000")}
              style={{ backgroundColor: showTheme("topHeader"), color: showTheme("text") }}
            >
              + ₹1000
            </div>
            <div
              className="border border-violet-600 rounded-full px-3 p-1 cursor-pointer flex-grow text-center mt-3 sm:mt-0"
              onClick={() => handleNumberClick("10000")}
              style={{ backgroundColor: showTheme("topHeader"), color: showTheme("text") }}
            >
              + ₹10000
            </div>
          </div>
          <div className="row flex gap-2 items-center ">
            <div className="col-md-6 col-sm-12">
              <button
                title="Add Funds"
                className="bg-purple-600 text-white p-3 rounded-xl w-full mt-10 hover:bg-purple-500"
                onClick={popup}
              >
                Add Funds
              </button>
            </div>
            <div className="col-md-6 col-sm-12">
              <button
                title="Cancel"
                className="bg-purple-200 text-black hover:bg-purple-300 rounded-xl w-full p-3 mt-10"
                onClick={() => navigate('/index-plan')}
              >
                Cancel
              </button>
            </div>
          </div>

        </div>
      </div>
      {showConfirmation && (
        <div className="fixed inset-0 z-50 overflow-auto flex items-center justify-center backdrop-blur-sm bg-gray-400 bg-opacity-20 ">
          <div className="bg-white rounded-xl flex flex-col ml-5 mr-5 sm:ml-5" style={{ backgroundColor: showTheme("card") }}>

            <div className="p-8 pt-0 text-center ">
              <h2 className="text-3xl font-bold mb-2 mt-10" style={{ color: showTheme("text") }}>Add Funds</h2>
              <p
                className="mb-10"
                style={{
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  color: showTheme("text")
                }}
              >
                Are you sure you want to continue?
              </p>
              <div className="flex justify-center space-x-4">
                <button
                  onClick={handleSubmit}
                  className="bg-purple-700 text-white py-2 px-4 rounded-lg hover:bg-purple-600"
                  style={{
                    paddingTop: "20px",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    paddingBottom: "20px",
                    width: "40%",
                    fontSize: "15px",
                  }}
                >
                  {
                    saveLoading ?
                      <div class="three-body-button">
                        <div class="three-body__dot"></div>
                        <div class="three-body__dot"></div>
                        <div class="three-body__dot"></div>
                      </div>
                      :
                      'Yes'
                  }

                </button>
                <button
                  onClick={() => setShowConfirmation(false)}
                  className="bg-purple-200 text-black py-2 px-4 rounded-lg hover:bg-purple-400"
                  style={{
                    paddingTop: "20px",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    paddingBottom: "20px",
                    width: "40%",
                    fontSize: "14px",
                  }}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default AddFunds;
