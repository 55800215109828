import React, { useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { getLeads } from "../../Services/UserServices";
import { useQuery } from "react-query";
import { MoreVertical } from "lucide-react";
import moment from "moment";
import { MdNorth, MdOutlineClose, MdOutlineSouth } from "react-icons/md";
import axios from "axios";
import { getToken } from "../../utils/getToken";
import { ToastContainer, toast } from "react-toastify";
import { Button, IconButton } from "@material-tailwind/react";
import { FaAnglesRight } from "react-icons/fa6";
import { FaAnglesLeft } from "react-icons/fa6";
import '../Admin/admin.css'
import Spinner from "../Spinner/Spinner";
import Pagination from '@mui/material/Pagination';
import { Close } from "@mui/icons-material";

function Leads() {
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(5);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [sortKey, setSortKey] = useState("");
  const [sortByName, setSortByName] = useState(false)
  const [sortByDate, setSortByDate] = useState(false)
  const [isSearch, setIsSearch] = useState(false)
  const [loading, setLoading] = useState(false);
  const [searchForAll, setSearchForAll] = useState('');
  const [dateValue, setDateValue] = useState('');


  const { data: leads, isLoading, refetch } = useQuery({
    queryFn: () => getLeads(currentPage, recordsPerPage, searchForAll, sortKey, dateValue),
    queryKey: ["user_leads", currentPage, recordsPerPage, searchForAll, sortKey, dateValue],
  });


  const totalPages = Math.ceil(leads?.noOfEnteries / recordsPerPage);
  let start = (currentPage - 1) * recordsPerPage + 1
  let end = leads?.noOfEnteries

  if (recordsPerPage < leads?.noOfEnteries) {
    end = recordsPerPage * currentPage
    if (end > leads?.noOfEnteries) {
      end = leads?.noOfEnteries;
    }
  }
  const handleReset = () => {
    setDateValue('');
    setSortKey('');
    setSearchForAll('')
  }
  return (
    <div
    className="bg-white rounded-2xl shadow-md mb-8 ml-3 mr-3 sm:ml-3 border-style p-2"     >
      <div className="combined-input-container">
        <div className="relative combined-input w-full ">
          <input
            className="focus:outline-none"
            type="text"
            placeholder="Search here..."
            id="id"
            autoFocus
            name="searchForAll"
            value={searchForAll}
            onChange={(e) => {
              setSearchForAll(e.target.value);
            }}
          />
          <div className="right-0 top-0  absolute flex">
            <div
              className="w-full text-purple-700 hover:text-purple-800 font-semibold border-0 px-4 pt-1 mt-auto -mb-0.5"
              onClick={() => {
                setSearchForAll('');
              }}
            ><Close /></div>
          </div>
        </div>
        <div className="w-full combined-input-2">
          <input
            type="date"
            className="focus:outline-none w-full"
            id="id"
            name="dateValue"
            value={dateValue}
            onChange={(e) => {
              setDateValue(e.target.value);
            }}
          />
        </div>
        <div className=" combined-input-3 flex whitespace-nowrap gap-2 items-center">
          <select className="focus:border-purple-500 focus:ring-2 focus:ring-purple-200 focus:outline-none"
            // disabled={isFilter}
            name="sortKey"
            value={sortKey}
            onChange={(e) => setSortKey(e.target.value)}>
            <option value=''>Sort</option>
            <option value='name DESC'>A-Z</option>
            <option value='name ASC'>Z-A</option>
            <option value='createdAt DESC'>Oldest First</option>
            <option value='createdAt ASC'>Newest First</option>
          </select>
        </div>
      </div>
      <div>
        <button className="text-red-600" onClick={handleReset}>Reset</button>
      </div>
      {isLoading ? (
        <div style={{ display: "flex", marginTop: "20%" }} className="flex w-full justify-center"> <Spinner /></div>
      ) : (
        <div>
          {/* <div className="py-8 px-4">
            <form className="flex  flex-col justify-between w-full gap-2">
              <div className="flex justify-between w-full gap-2">
                <input
                  type="text"
                  value={name}
                  placeholder="Lead Name"
                  onChange={(e) => setName(e.target.value)}
                  className="w-full px-4 py-2.5 rounded-lg border focus:ring focus:ring-purple-100 focus:outline-none"
                  required
                />
                <input
                  type="text"
                  value={email}
                  placeholder="Email address"
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full px-4 py-2.5 rounded-lg border focus:ring focus:ring-purple-100 focus:outline-none"
                  required
                />
                <input
                  type="text"
                  value={phone}
                  placeholder="Phone number"
                  onChange={(e) => setPhone(e.target.value)}
                  className="w-full px-4 py-2.5 rounded-lg border focus:ring focus:ring-purple-100 focus:outline-none"
                  required
                />
              </div>
              <div className="flex justify-between w-full gap-2">
                
                <div className="w-50 flex">
                  {
                    isSearch ?
                      <button className="w-full bg-purple-700 hover:bg-purple-800 text-white font-semibold rounded-lg px-4 py-2.5 mt-auto" title='Search' onClick={handleReset}>
                        Reset
                      </button>
                      :
                      <button className="w-full bg-purple-700 hover:bg-purple-800 text-white font-semibold rounded-lg px-4 py-2.5 mt-auto" title='Search' onClick={handleFormSubmit}>
                        Search
                      </button>

                  }
                </div>
              </div>


            </form>
          </div> */}

          <div className="p-4 overflow-auto">
            <div className="flex justify-between">
              <h1 className="text-xl font-semibold text-black">Leads</h1>
            </div>
            <table className="min-w-full mt-4 space-y-4 border border-gray-300 text-nowrap">
              <thead className="bg-gray-200">
                <tr className="">
                  <th className="py-2 font-medium text-black px-4 text-center">
                    No
                  </th>
                  <th className="py-2 font-medium text-black px-4 text-center flex items-center justify-center">
                    {
                      sortByName ?
                        <MdNorth onClick={() => { setSortByName(!sortByName); setSortByDate(false); setSortKey(''); refetch() }} />
                        :
                        <MdOutlineSouth onClick={() => { setSortByName(!sortByName); setSortByDate(false); setSortKey('name'); refetch() }} />
                    }
                    Lead Name
                  </th>
                  <th className="py-2 font-medium text-black px-4 text-center">
                    Email
                  </th>
                  <th className="py-2 font-medium text-black px-4 text-center">
                    Phone
                  </th>
                  <th className="py-2 font-medium text-black px-4 text-center flex items-center justify-center">
                    {
                      sortByDate ?
                        <MdNorth onClick={() => { setSortByDate(!sortByDate); setSortByName(false); setSortOrder('ASC'); refetch() }} />
                        :
                        <MdOutlineSouth onClick={() => { setSortByDate(!sortByDate); setSortByName(false); setSortOrder('DESC'); refetch() }} />
                    }
                    Created
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {
                  leads?.leadDetails?.length > 0 ?
                    leads?.leadDetails?.map((lead, index) => (
                      <tr key={lead.id}>
                        <td
                          className="py-2 px-4 text-center"
                          style={{ fontFamily: "Poppins;" }}
                        >
                          {index + 1}
                        </td>
                        <td className="py-2 font-light px-4 text-center">
                          {lead.name}
                        </td>
                        <td className="py-2 font-light px-4 text-center">
                          {lead.email}
                        </td>
                        <td className="py-2 font-light px-4 text-center">
                          {lead.phone}
                        </td>
                        <td className="py-2 font-light px-4 text-center" style={{ whiteSpace: "nowrap" }}>
                          {moment(lead?.createdAt).format('DD/MM/YYYY')}
                        </td>
                      </tr>
                    ))
                    :
                    <tr ><td className="text-center">No data found</td></tr>
                }
              </tbody>
            </table>


          </div>
            <div className="flex flex-wrap items-center gap-4 justify-between mt-6 max-[700px]:justify-start max-[700px]:gap-0 max-[700px]:text-xs">
              <div className="text-purple-700">
                {`${start}-${end} of ${leads?.noOfEnteries} items`}
              </div>
              <div className="flex items-center gap-4 justify-center">
                <Pagination count={totalPages} size="large" showFirstButton showLastButton page={currentPage} onChange={(event, value) => { setCurrentPage(value) }} />
              </div>
              <div>
                <select className="border rounded p-1" value={recordsPerPage} onChange={(e) => { setRecordsPerPage(e.target.value) }}>
                  <option value='5'>Show 5</option>
                  <option value='10'>Show 10</option>
                  <option value='15'>Show 15</option>
                </select>
              </div>
            </div>

        </div>)}
      <ToastContainer />
    </div>
  );
}

export default Leads;
