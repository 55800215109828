import React, { useEffect, useState } from 'react';
import { deleteBank, updateBank } from '../../../../Services/BankServices';
import { useQuery } from 'react-query';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { getAllBankDetails } from '../../../../Services/BankServices';

const BankList = () => {
    const [editPopup, setEditPopup] = useState(false);
    const [value, setValue] = useState(null);
    const [changedName, setChangedName] = useState(null);
    const [changedAccountNo, setChangedAccountNo] = useState(null);
    const [changedIfsc, setChangedIfsc] = useState(null);
    const [changedMobile, setChangedMobile] = useState(null);
    const [bankDetails, setBankDetails] = useState(null)
    const [removeInfo, setRemoveInfo] = useState(false)

    const { data: bank, refetch } = useQuery({
        queryFn: getAllBankDetails,
        queryKey: ["bank_details"],
    });
    // const bankDetails = bank?.data?.userBankDetail;
    useEffect(() => {
        setBankDetails(bank?.data?.userBankDetail);
    }, [bank])

    const handleDelete = async (id) => {
        let response = await deleteBank(id)
        refetch()
    }

    const handleValueChange = (id, name, accNo, ifsc, mobile) => {
        setEditPopup(true);
        setValue(id);
        setChangedName(name);
        setChangedAccountNo(accNo);
        setChangedIfsc(ifsc);
        setChangedMobile(mobile)
    }

    const handleChange = async () => {

        let data = {
            accountHolderName: changedName,
            accountNumber: changedAccountNo,
            ifscCode: changedIfsc,
        }

        const response = await updateBank(value, data)
        if (response) {
            toast(response.data.message)
            setEditPopup(false);
            refetch();
        } else {
            toast("Please try again")
        }
    }

    return (

        <div 
        className="bg-white rounded-2xl shadow-md mb-8 ml-3 mr-3 sm:ml-3 border-style p-2"
        >
            <div className="flex flex-col lg:flex-col mt-5  lg:space-y-0 gap-12 ">
                <div className='bg-white rounded-lg'>
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 rounded-lg">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50  dark:text-gray-400">
                            <tr>

                                <th scope="col" className="px-6 py-3">
                                    Account Holder Name
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Account Number
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    IFSC Code
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Phone
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                bankDetails && bankDetails?.map((e) =>
                                    <tr className="bg-white   dark:border-gray-700 hover:bg-gray-50 ">
                                        <td className="px-6 py-4">{e.accountHolderName}</td>
                                        <td className="px-6 py-4">{e.accountNumber}</td>
                                        <td className="px-6 py-4">{e.ifscCode}</td>
                                        <td className="px-6 py-4">{e?.userId?.phone}</td>
                                        <td className="flex items-center px-6 py-4">
                                            <div
                                                className="font-medium text-blue-600 dark:text-blue-500 hover:underline cursor-pointer"
                                                onClick={() => { handleValueChange(e?._id, e?.accountHolderName, e?.accountNumber, e?.ifscCode, e?.userId?.phone) }}
                                            >
                                                Edit
                                            </div>
                                            <div
                                                className="font-medium text-red-600 dark:text-red-500 hover:underline ms-3 cursor-pointer"
                                                onClick={() => setRemoveInfo(true)}
                                            >
                                                Remove
                                            </div>
                                        </td>
                                        {
                                            removeInfo &&
                                            <div className="fixed inset-0 z-50 overflow-auto flex items-center justify-center backdrop-blur-md bg-gray-900  bg-opacity-20 ">
                                                <div className="bg-white rounded-xl flex flex-col w-96 py-6">

                                                    <h2 className="text-2xl font-semibold mb-4 text-center">
                                                        Are you Sure?
                                                    </h2>
                                                    <div className='flex gap-3 w-full justify-center'>
                                                        <button title='Want to delete the info' className='bg-purple-700 block px-4 py-2 text-sm flex items-center gap-2 border rounded text-white hover:bg-purple-500' onClick={() => handleDelete(e?._id)}>Yes</button>
                                                        <button title='Want to keep the info' className='text-purple-700 bg-white block px-4 py-2 text-sm flex items-center gap-2 border rounded text-purple-700' onClick={() => setRemoveInfo(false)}>No</button>

                                                    </div>
                                                </div>
                                            </div>

                                        }
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>

                    <div className='w-full mt-3 ml-3'>
                        <button title='Add Bank Information' className='bg-purple-700 text-white rounded p-1 px-2 hover:bg-purple-500' > <Link to='/bank'>Add Bank</Link></button>
                    </div>
                </div>
                {
                    editPopup &&
                    <div id="authentication-modal" tabindex="-1" aria-hidden="true" className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full" style={{ backgroundColor: "rgb(0 0 0 / 57%)" }}>
                        <div className="relative p-4 w-full max-w-md max-h-full">
                            <div className="relative bg-white rounded-lg shadow ">


                                <div className="px-4 py-10 md:p-5">
                                    <form className="space-y-4 " action="#" onSubmit={(e) => { e.preventDefault() }}>
                                        <div>
                                            <input
                                                disabled
                                                value={value}
                                                // onChange={(e) => setChangedIfsc(e.target.value)}
                                                name="ifsc"
                                                className="bg-gray-50 border border-gray-300 text-gray-400 text-sm rounded-lg focus:ring-blue-500  block w-full p-2.5  dark:border-gray-500 dark:placeholder-gray-400 dark:text-white focus:ring focus:ring-purple-100 focus:outline-none" />
                                        </div>
                                        <div>
                                            <input
                                                type="text"
                                                value={changedName}
                                                onChange={(e) => setChangedName(e.target.value)}
                                                name="name"
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500  block w-full p-2.5  dark:border-gray-500 dark:placeholder-gray-400 dark:text-white focus:ring focus:ring-purple-100 focus:outline-none" placeholder="Change Account holder Name" />
                                        </div>
                                        <div>
                                            <input
                                                type="number"
                                                value={changedAccountNo}
                                                onChange={(e) => setChangedAccountNo(e.target.value)}
                                                name="account"
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500  block w-full p-2.5  dark:border-gray-500 dark:placeholder-gray-400 dark:text-white focus:ring focus:ring-purple-100 focus:outline-none" placeholder='Change Account number' />
                                        </div>
                                        <div>
                                            <input
                                                type="text"
                                                value={changedIfsc}
                                                onChange={(e) => setChangedIfsc(e.target.value)}
                                                name="ifsc"
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500  block w-full p-2.5  dark:border-gray-500 dark:placeholder-gray-400 dark:text-white focus:ring focus:ring-purple-100 focus:outline-none" placeholder='Change IFSC Code' />
                                        </div>
                                        <div>
                                            <input
                                                disabled
                                                type="tel"
                                                maxLength={10}
                                                value={changedMobile}
                                                onChange={(e) => setChangedMobile(e.target.value)}
                                                name="changedMobile"
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500  block w-full p-2.5  dark:border-gray-500 dark:placeholder-gray-400 dark:text-white focus:ring focus:ring-purple-100 focus:outline-none" placeholder='Phone Number' />
                                        </div>

                                        <button title='update bank Details' type="submit" className="w-full text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-800"
                                            onClick={handleChange}>
                                            Update</button>
                                        <button title='Cancel' className="w-full text-red-600 focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium text-sm px-5 py-2.5 text-center " onClick={() => setEditPopup(false)}>Cancel</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <ToastContainer
                />
            </div>
        </div>
    )
}

export default BankList
