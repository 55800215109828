import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { verifyOTP } from '../Services/UserServices';
import AutochangeTitle from './UserPanel/Common/AutochangeTitle';
import { ToastContainer, toast } from 'react-toastify';
import { useAuth } from './Context/AuthProvider ';
import Spinner from './Spinner/Spinner';

function Signupotp() {
  const { setToken } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [code1, setCode1] = useState("");
  const [code2, setCode2] = useState("");
  const [code3, setCode3] = useState("");
  const [code4, setCode4] = useState("");
  const [code5, setCode5] = useState("");
  const [code6, setCode6] = useState("");
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    // Parse email and phone from URL parameters
    const searchParams = new URLSearchParams(location.search);
    const emailParam = searchParams.get('email').split(" ").join("+");
    const phoneParam = searchParams.get('phone');

    // Set the state with retrieved values
    setEmail(emailParam || "");
    setPhone(phoneParam || "");
  }, [location.search]);

  const verifyOtp = async (event) => {
    event.preventDefault();
    const formData = {
      email: email,
      phone: phone,
      emailOtp: otp,
      phoneOtp: "",
      module: "sign-up"
    }


    try {
      setIsLoading(true)
      const { data } = await verifyOTP(formData);
      const token = data?.data?.token;
      setToken(token);
      navigate("/pancard", { replace: true });
    } catch (e) {
      console.log('sign up error', e);
      // toast.error(e?.response?.data?.message);
    } finally{
      setIsLoading(false);
    }
  };

  function focusNextInput(el, prevId, nextId) {
    if (el.value.length === 0) {
      // if (prevId) {
      //   document.getElementById(prevId).focus();
      // }
    } else {
      if (nextId) {
        document.getElementById(nextId).focus();
      }
    }
  }

  document.querySelectorAll('[data-focus-input-init]').forEach(function (element) {
    element.addEventListener('keyup', function () {
      const prevId = this.getAttribute('data-focus-input-prev');
      const nextId = this.getAttribute('data-focus-input-next');
      focusNextInput(this, prevId, nextId);
    });
  });



  return (
          <section className="otp-page flex flex-col md:flex-row min-h-screen items-center justify-center">
            <div className="bg-white w-full md:w-full lg:w-1/2 xl:w-1/3 mx-auto  flex items-center justify-center px-4">
              <div className="w-full p-4">
                <div className="mb-20">
                  <img className="w-20" src="/image/Logo(1).png" alt="techtime logo" />
                </div>
                <AutochangeTitle />
                <h1 className=" leading-tight mb-8   font-light text-sm ">
                  Log in to Tech Times to start investing.
                </h1>
                <form
                  className="mt-6"
                  onSubmit={(event) => {
                    verifyOtp(event);
                  }}
                >
                  <div>
                    <p className="font-light">
                      We have sent an OTP to <b>{email}</b>
                    </p>
                    <form className="">
                      <div className="flex mb-2 space-x-2 rtl:space-x-reverse">
                      <input type="number" length="6"
                            value={otp} onChange={(e) => setOtp(e.target.value)}
                            className='w-full  px-4 py-3 rounded-lg hover:border-purple-500 mt-2 border  focus:border-purple-200 focus:bg-white focus:outline-none'
                            required />
                      </div>
                    </form>

                    {otpError && (
                      <p className="text-red-600 font-light mt-5">
                        OTP does not match. Please try again.
                      </p>
                    )}
                  </div>
                  <button
                    title='Continue to submit'
                    type="submit"
                    className={`w-full block rounded-lg px-4 py-3 mt-6 ${otp === ''
                      ? "bg-purple-200 text-gray-600 cursor-not-allowed"
                      : " bg-purple-500 hover:bg-purple-400 focus:bg-purple-400 text-white font-semibold"
                      }`}
                    disabled={otp === ''}
                  >
                     {
                      isLoading ?
                        <div class="three-body-button">
                          <div class="three-body__dot"></div>
                          <div class="three-body__dot"></div>
                          <div class="three-body__dot"></div>
                        </div>
                        :
                        'Continue'
                    }
                  </button>
                </form>
              </div>
            </div>
            <div className="hidden md:block lg:block sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2">
              <img
                src="/image/image.jpg"
                alt=""
                className="w-full min-[768px]:w-auto h-auto max-w-full max-h-full"
              />
            </div>
            <ToastContainer />
          </section>
  )
}

export default Signupotp
