import React, { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import './admin.css'
import { useLocation, useNavigate } from 'react-router-dom';
import TopHeader from "./TopHeader";
import Sidebar from "./Sidebar";
import Dashboard from "../AdminSlidbar/Dashboard";
import { Outlet } from 'react-router-dom';
import AdminDashboard from '../AdminSlidbar/Dashboard';

const Admin = () => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.search.match('/')) {
            navigate('/dashboard');
        }

    }, [location]);

    return (
        <container className="bg-gray-100 container">
            <TopHeader />
            <Sidebar />
        </container>
    );
};

export default Admin;
