import { Button, Container } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
const NotFound = () => {
    let history =useNavigate()
    return (
        <Container className="p-0">
                <div className="no-gutters height-self-center">
                    <div className="text-center items-center">
                        <div className="iq-error relative mt-5 flex items-center justify-center flex-col">
                            <img src='/image/400.png' className="img-fluid iq-error-img text-center " alt=""/>
                            <h2 className="mb-0 text-center">Oops! This Page is Not Found.</h2>
                            <p className="text-center">The requested page dose not exist.</p>
                            <button className="mt-3 bg-purple-600 text-white rounded p-2 hover:bg-purple-300" onClick={() => history('/')}>Back to Home</button>                            
                        </div>
                    </div>
                </div>
            </Container>  
    )
}
export default NotFound;
