import React from 'react'

const Assurance = () => {
  return (
    <div>
      Assurance
    </div>
  )
}

export default Assurance
