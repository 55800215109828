import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "./Components/Context/AuthProvider ";
import CryptoJS from 'crypto-js';
import { useEffect, useState } from "react";
import NotFound from "./Components/UserPanel/Common/NotFound";

export const ProtectedRoute = () => {
  const { token } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [decryptedUserData, setDecryptedUserData] = useState(null)
  const secretKey = 'your-secret-key';

  // Retrieving encrypted data from local storage
  const encryptedDataFromStorage = localStorage.getItem('encryptedData');
  if (encryptedDataFromStorage) {
    // Decrypting the data
    const bytes = CryptoJS.AES.decrypt(encryptedDataFromStorage, secretKey);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      if (decryptedData?.userType === 'client' && location.pathname === '/') {
        return <Navigate to="/dashboard" />
        // navigate('/dashboard');
      }
    if (decryptedData?.userType === 'employee') {
      if (location.pathname === '/admin-dashboard' && decryptedData?.employeeDetails?.isAccessToDashboardTab) {
        return <Outlet />;
      }
      if (location.pathname.includes('/financial-data') && decryptedData?.employeeDetails?.isAccessToFinancialDataTab) {
        return <Outlet />;
      }
      if (location.pathname.includes('/support-desk') && decryptedData?.employeeDetails?.isAccessToSupportTab) {
        return <Outlet />;
      }
      if (location.pathname === '/reports' && decryptedData?.employeeDetails?.isAccessToReportTab) {
        return <Outlet />;
      }
      if (location.pathname === '/member' && decryptedData?.employeeDetails?.isAccessToMembersTab) {
        return <Outlet />;
      }
      if (location.pathname.includes('/clients') && decryptedData?.employeeDetails?.isAccessToClientTab) {
        return <Outlet />;
      }
      if (location.pathname === '/notification' && decryptedData?.employeeDetails?.isAccessToNotificationTab) {
        return <Outlet />;
      }
      if (location.pathname === '/plans' && decryptedData?.employeeDetails?.isAccessToPlansTab) {
        return <Outlet />;
      }
      if (location.pathname === '/leads' && decryptedData?.employeeDetails?.isAccessToLeadsTab) {
        return <Outlet />;
      }
      if (location.pathname === '/faq' && decryptedData?.employeeDetails?.isAccessToFaqTab) {
        return <Outlet />;
      }
      if (location.pathname === '/') {
        return <Outlet />;
      }
      else {
        return <NotFound />;
      }
    }
    if (decryptedData?.userType === 'client') {
     
      if (location.pathname === '/setting-term-and-condition') {
        return <Outlet />;
      }
      if (location.pathname === '/setting-privacy') {
        return <Outlet />;
      }
      if (location.pathname === '/setting-contact') {
        return <Outlet />;
      }
      if (location.pathname === '/setting-notifications') {
        return <Outlet />;
      }
      if (location.pathname === '/user-faq') {
        return <Outlet />;
      }
      if (location.pathname === '/bank-list') {
        return <Outlet />;
      }
      if (location.pathname === '/bank') {
        return <Outlet />;
      }
      if (location.pathname === '/contact') {
        return <Outlet />;
      }
      if (location.pathname === '/userplans') {
        return <Outlet />;
      }
      if (location.pathname === '/assurance') {
        return <Outlet />;
      }
      if (location.pathname === '/achievements') {
        return <Outlet />;
      }
      if (location.pathname === '/transactions') {
        return <Outlet />;
      }
      if (location.pathname === '/profile') {
        return <Outlet />;
      }
      if (location.pathname === '/settings') {
        return <Outlet />;
      }
      if (location.pathname === '/progress') {
        return <Outlet />;
      }
      if (location.pathname === '/funds') {
        return <Outlet />;
      }
      if (location.pathname === '/plan-content') {
        return <Outlet />;
      }
      if (location.pathname.includes('/add-funds')) {
        return <Outlet />;
      }
      if (location.pathname === '/index-plan') {
        return <Outlet />;
      }
      if (location.pathname === '/dashboard') {
        return <Outlet />;
      }
      if (location.pathname === '/pancard') {
        return <Outlet />;
      }
      if (location.pathname === '/') {
        return <Outlet />;
      }
      else {
        return <NotFound />;
      }
    }
  }
  // Check if the user is authenticated
  if (!token) {
    // If not authenticated, redirect to the login page
    return <Navigate to="/" />;
  }

  // If authenticated, render the child routes
  return <Outlet />;
};