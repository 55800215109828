import React, { useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { MoreVertical, Pencil, Search } from "lucide-react";
import { MdOutlineClose } from "react-icons/md";
import { getfinancialData } from "../../Services/WithdrawalServices";
import { useQuery } from "react-query";
import moment from "moment";
import { getToken } from "../../utils/getToken";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import {
  Scale,
  CircleDollarSign,
  IndianRupee,
  ShoppingBag,
} from "lucide-react";
import Spinner from "../Spinner/Spinner";
import { useNavigate, useParams } from "react-router-dom";
import ClientProfileDetail from "./Client/ClientProfileDetail";
import Pagination from '@mui/material/Pagination';
import { Close } from "@mui/icons-material";
import { TablePagination } from "@mui/material";

function FinancialData() {
  const params = useParams('receiverID');
  const [userId, setUserId] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [isProfile, setIsProfile] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(5);
  const [financialData, setFinancialData] = useState(null);
  const [editIndex, setEditIndex] = useState(null);
  const [loaderUpdate, setLoaderUpdate] = useState(true);
  const [saveLoading, setSaveLoading] = useState(false);
  const [searchForAll, setSearchForAll] = useState('');
  const [dateValue, setDateValue] = useState('');
  const [query, setQuery] = useState({
    transactionId: "",
    memberId: "",
    withdrawalType: "",
    duration: "",
    from: "",
    to: "",
    sortOrder: "",
    dateValue: " "
  });
  const [isFilter, setIsFilter] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const navigate = useNavigate();
  useEffect(() => {
    const token = getToken();
    if (!token) {
      navigate("/dashboard");
    }
  }, []);
  const handleQueryChange = (e) => {
    let temp = { ...query };
    temp[e.target.name] = e.target.value;
    setQuery(temp);
  };

  /* Search FIlters */
  const handleQuery = (e) => {
    e.preventDefault();
    let tempQuery = "";
    // if (query.duration) {
    //   tempQuery += `&timeFilter=${query.duration}`;
    // }
    // if (query.from && query.to && query.duration === "custom") {
    //   tempQuery += `&startDate=${query.from}&endDate=${query.to}`;
    // }
    // if (query.transactionId) {
    //   tempQuery += `&transactionId=${query.transactionId}`;
    // }
    // if (query.memberId) {
    //   tempQuery += `&memberId=${query.memberId}`;
    // }
    // if (query.withdrawalType) {
    //   tempQuery += `&withdrawalType=${query.withdrawalType}`;
    // }
    // if (query.sortOrder) {
    //   tempQuery += `&sortOrder=${query.sortOrder}`;
    // }
    if (query.dateValue) {
      tempQuery += `&dateValue=${query.dateValue}`;
    }

    if (tempQuery !== "") {
      setIsFilter(true);
      handleFormSubmit(tempQuery);
    }
  };

  const handleFormSubmit = async (queryURL) => {
    if (loaderUpdate) {
      setLoading(true);
    }
    try {
      const token = getToken();
      let url = `${process.env.REACT_APP_BASE_URL}/api/transaction/withdrawal-requests?page=${currentPage}&pageSize=${recordsPerPage}&keyword=${searchForAll}&dateValue=${dateValue}`;
      if (queryURL !== "") {
        url += "&" + queryURL.substring(1); // Remove the leading "&" character
      }

      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setFinancialData(response.data.data);
    } catch (error) {
      console.log("Failed fetching details", error);
    } finally {
      setLoading(false);
      setLoaderUpdate(false);
    }
  };

  useEffect(() => {
    handleFormSubmit("");
  }, [currentPage, recordsPerPage, searchForAll, dateValue]);
  useEffect(() => {
    if (params?.receiverID) {
      handleViewProfileClick(params?.receiverID);
    }
  }, [params])
  const handleViewProfileClick = (clientId) => {
    setIsProfile(true);
    setSelectedUserId(clientId);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const [updateData, setUpdateData] = useState({
    transactionId: "",
    status: "",
    message: "",
  });
  useEffect(() => {
    if (financialData) {
      setUpdateData({
        transactionId: financialData[editIndex]?.transactionId,
        status: financialData[editIndex]?.message,
        message: financialData[editIndex]?.status,
      })
    }
  }, [financialData]);
  const totalPages = Math.ceil(financialData?.noOfEnteries / recordsPerPage);

  const [isMenuOpen, setMenuOpen] = useState(null);
  const handleMoreVerticalClick = (id, index) => {
    // Toggle the menu open state
    // setMenuOpen(isMenuOpen === index ? null : index);
    setUserId(id);
    setMenuOpen(index);
  };

  let start = (currentPage - 1) * recordsPerPage + 1
  let end = financialData?.noOfEnteries

  const popDot = () => {
    setMenuOpen(!isMenuOpen);
  }
  const [showModal, setShowModal] = useState(false);
  const ticket = {
    /* Your ticket object */
  };
  const [loading, setLoading] = useState(false);
  const handleUpdate = async () => {
    try {
      setSaveLoading(true);
      const token = getToken();
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/transaction/update/${userId}`,
        {
          transactionId: updateData.transactionId,
          status: updateData.status,
          message: updateData.message,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json",
          },
        }
      );
      // refetch();
      toast.success("Updated Successfully");
      setMenuOpen(null);
      setShowModal(false);
      setLoaderUpdate(null);
      handleFormSubmit("");
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally{
      setSaveLoading(false);

    }
  };

  return (
    <div className="rounded-2xl ">
      {isProfile ? (
        <ClientProfileDetail
          setIsProfile={setIsProfile}
          selectedUserId={selectedUserId}
        />
      ) : (
        <div
        className="bg-white rounded-2xl shadow-md mb-8 ml-3 mr-3 sm:ml-3 border-style p-2"
        >
          {(loading) ? (
            <div
              style={{ display: "flex", marginTop: "20%" }}
              className="flex w-full justify-center"
            >
              {" "}
              <Spinner />
            </div>
          ) : (
            <div>
              <div className="flex flex-wrap justify-between ">
                <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/2 col-span-2 mb-4 px-2">
                  <div className=" shadow-md rounded-lg flex p-3  border text-black">
                    <CircleDollarSign
                      color="#8000ff"
                      className="rounded-full mt-2 w-8 h-8  "
                    />
                    <div className="ml-4">
                      <h3 className="text-sm font-semibold ">Total Spending</h3>
                      <p className="inline">₹ {financialData?.financialDataStats?.withdrawalSum?.toFixed(2)}</p>
                      {/* <h1 className='text-black'>6% Increase From Target</h1> */}
                    </div>
                  </div>
                </div>


                <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/2 col-span-2 mb-4 px-2">
                  <div className=" shadow-md rounded-lg flex p-3 bg-purple-700  text-white">
                    <IndianRupee
                      color="#ffffff"
                      className="rounded-full mt-2 w-8 h-8  "
                    />
                    <div className="ml-4">
                      <h3 className="text-sm font-semibold ">Total Earning</h3>
                      <p className="inline">₹ {financialData?.financialDataStats?.Balance?.toFixed(2)}</p>
                      {/* <h1 className='text-white'>12% Increase From Target</h1> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="combined-input-container">
                <div className="relative combined-input w-full ">
                  <input
                    className="focus:outline-none"
                    type="text"
                    placeholder="Search here..."
                    id="id"
                    autoFocus
                    name="searchForAll"
                    value={searchForAll}
                    onChange={(e) => {
                      setSearchForAll(e.target.value);
                    }}
                  />
                  <div className="right-0 top-0  absolute flex">
                    <div
                      className="w-full text-purple-700 hover:text-purple-800 font-semibold border-0 px-4 pt-1 mt-auto -mb-0.5"
                      onClick={() => {
                        setSearchForAll('');
                      }}
                    ><Close /></div>
                  </div>
                </div>
                <div className="w-full combined-input-2 " style={{ borderTopRightRadius: "4px", borderBottomRightRadius: "4px" }}>
                  <input
                    type="date"
                    placeholder="dd-mm-yyyy"
                    className="focus:outline-none w-full"
                    id="id"
                    name="dateValue"
                    value={dateValue}
                    onChange={(e) => {
                      setDateValue(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div>
                <button className="text-red-600" onClick={() => { setDateValue(''); setSearchForAll('') }}>Reset</button>
              </div>
              {/* <div className="flex rounded-lg border focus:ring focus:ring-purple-200 focus:outline-none  px-4 py-2">
                <div className="relative w-full">
                  <input
                    type="text" disabled={isFilter}
                    placeholder="Search here..."
                    className="w-full focus:outline-none"
                    id="id"
                    autoFocus
                    name="searchForAll"
                    value={searchForAll}
                    onChange={(e) => {
                      setSearchForAll(e.target.value);
                    }}
                  />
                  <div className="right-0 top-0  absolute flex">
                    <div
                      className="w-full text-purple-700 hover:text-purple-800 font-semibold border-0 px-4 py-2.5 mt-auto -mb-0.5"
                      onClick={() => {
                        setSearchForAll('');
                      }}
                    ><Close /></div>
                  </div>
                </div>
                <input
                  type="date" disabled={isFilter}
                  // placeholder="YYYY-MM-DD"
                  className="w-full focus:outline-none"
                  id="id"
                  autoFocus
                  name="dateValue"
                  value={query.dateValue}
                  onChange={(e) => {
                    handleQueryChange(e);
                  }}
                />

              </div> */}

              <div className="p-4">
                <div className="flex justify-between">
                  <h1 className="text-xl font-semibold text-center text-black">
                    Order List
                  </h1>
                  <div className="relative">
                    {/* <select
              className="px-6 py-2 border rounded shadow-md bg-white"
              onChange={(e) => setSelectedPeriod(e.target.value)}
              value={selectedPeriod}
            >
              <option value="Weekly" style={{ backgroundColor: "#7C35F1", color: "white" }}>Weekly</option>
              <option value="Monthly" style={{ backgroundColor: "#7C35F1", color: "white" }}>Monthly</option>
              <option value="Yearly" style={{ backgroundColor: "#7C35F1", color: "white" }}>Yearly</option>
            </select> */}

                    {/* <select
                      className="p-2 rounded border shadow-md bg-white"
                      value={selectedPeriod}
                      onChange={(e) => setSelectedPeriod(e.target.value)}
                    >
                    
                      <option
                        value="weekly"
                        style={{
                          backgroundColor:
                            selectedPeriod === "weekly" ? "#7C35F1" : "white",
                          color:
                            selectedPeriod === "weekly" ? "white" : "black",
                        }}
                      >
                        Weekly
                      </option>
                      <option
                        value="monthly"
                        style={{
                          backgroundColor:
                            selectedPeriod === "monthly" ? "#7C35F1" : "white",
                          color:
                            selectedPeriod === "monthly" ? "white" : "black",
                        }}
                      >
                        Monthly
                      </option>
                      <option
                        value="yearly"
                        style={{
                          backgroundColor:
                            selectedPeriod === "yearly" ? "#7C35F1" : "white",
                          color:
                            selectedPeriod === "yearly" ? "white" : "black",
                        }}
                      >
                        Yearly
                      </option>
                    </select> */}
                  </div>
                </div>
              </div>
              <div className="overflow-auto">
                <table className="min-w-full mt-4 space-y-4 border border-gray-300 text-nowrap">
                  <thead className="bg-gray-200">
                    <tr className="">
                      <th className="py-2 px-4 text-center">No</th>
                      <th className="py-2 px-4 text-center">User ID</th>
                      <th className="py-2 px-4 text-center">Transaction ID</th>
                      <th className="py-2 px-4 text-center">Date</th>
                      <th className="py-2 px-4 text-center">Plans Name</th>
                      <th className="py-2 px-4 text-center">Withdrawal Type</th>
                      <th className="py-2 px-4 text-center">Amount</th>
                      <th className="py-2 px-4 text-center">Note</th>
                      <th className="py-2 px-4 text-center">Status</th>
                      <th className="py-2 px-4 text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {financialData?.withdrawalRequestData?.map(
                      (order, index) => (
                        <tr key={order._id}>
                          <td className="py-2 px-4 text-center">{index + 1}</td>
                          <td
                            className="py-2 px-4 text-center"
                            style={{ color: "blue", cursor: "pointer" }}
                            onClick={() => {
                              handleViewProfileClick(order?.userId?._id);
                            }}
                          >
                            {order?.userId?.memberId}
                          </td>
                          <td className="py-2 px-4 text-center">
                            {order.transactionId ? order.transactionId : "N/A"}
                          </td>
                          <td
                            className="py-2 px-4 text-center"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            {moment(order?.createdAt).format('DD/MM/YYYY')}
                            {/* {new Date(order?.createdAt).toLocaleDateString()} */}
                          </td>
                          <td className="py-2 px-4 text-center">
                            {order?.userPlanId?.planId?.planName}
                          </td>
                          <td className="py-2 px-4 text-center">
                            {order?.withdrawalType}
                          </td>
                          <td className="py-2 px-4 text-center">
                            {order?.amount}
                          </td>
                          {/* <td className="py-2 px-4 text-center">{order?.message ? order?.message : "N/A"}</td> */}
                          <td>
                            <div
                              className="relative text-center"
                              style={{ maxWidth: "200px" }}
                              onMouseEnter={handleMouseEnter}
                              onMouseLeave={handleMouseLeave}
                            >
                              {isHovered
                                ? order?.message || "N/A"
                                : order?.message?.slice(0, 50)}
                            </div>
                          </td>
                          <td className="py-2 px-4 text-center">
                            {order?.status}
                          </td>
                          <td className="py-2 px-4 text-center" >
                            <div className="relative inline-block text-left">
                              <button
                                title="Icon For delete and edit"
                                className="text-secondary"
                                onClick={() =>
                                  handleMoreVerticalClick(order?._id, index)
                                }
                              >
                                {/* <MoreVertical /> */}
                                <Pencil color="#fb5a04" size={20} />
                              </button>

                              {isMenuOpen === index && (
                                <div className="fixed inset-0 z-50 overflow-auto flex items-center justify-center backdrop-blur-lg  bg-gray-900 bg-opacity-25">
                                  <div className="bg-white rounded-xl flex flex-col w-96 h-full overflow-auto">
                                    <div className="flex justify-center p-3 bg-purple-700 text-white rounded-t-xl">
                                      <h2 className="text-xl font-bold "> Update Details </h2>
                                    </div>
                                    <div className="w-full p-1 px-6 flex flex-col mt-2">
                                      <label className="text-l">Transaction ID</label>
                                      <input
                                        placeholder="Enter Transaction ID"
                                        className="border-b border-gray-800 focus:ring focus:ring-purple-100 focus:outline-none"
                                        defaultValue={order?.transactionId}
                                        value={updateData.transactionId}
                                        onChange={(e) =>
                                          setUpdateData({
                                            ...updateData,
                                            transactionId: e.target.value,
                                          })
                                        }
                                      />
                                    </div>
                                    <div className="w-full p-1 px-6 flex flex-col mt-2">
                                      <label className="text-l">Note</label>
                                      <input
                                        placeholder="Enter Note"
                                        className="border-b border-gray-800 focus:ring focus:ring-purple-100 focus:outline-none"
                                        defaultValue={order?.message}
                                        value={updateData.message}
                                        onChange={(e) =>
                                          setUpdateData({
                                            ...updateData,
                                            message: e.target.value,
                                          })
                                        }
                                      />
                                    </div>
                                    <div className="w-full p-1 px-6 flex flex-col my-5">
                                      <label className="text-l">Status</label>
                                      <select
                                        className="border-b border-gray-800 p-1"
                                        defaultValue={order?.status}
                                        value={updateData.status}
                                        onChange={(e) =>
                                          setUpdateData({
                                            ...updateData,
                                            status: e.target.value,
                                          })
                                        }
                                      >
                                        <option>Select Status</option>
                                        <option
                                          style={{
                                            backgroundColor:
                                              updateData.status === "hold" ? "#7C35F1" : "",
                                            color: updateData.status === "hold" ? "white" : "",
                                          }}
                                          value="hold"
                                        >
                                          Hold
                                        </option>
                                        <option
                                          style={{
                                            backgroundColor:
                                              updateData.status === "success" ? "#7C35F1" : "",
                                            color:
                                              updateData.status === "success" ? "white" : "",
                                          }}
                                          value="success"
                                        >
                                          Success
                                        </option>
                                        <option
                                          style={{
                                            backgroundColor:
                                              updateData.status === "rejected" ? "#7C35F1" : "",
                                            color:
                                              updateData.status === "rejected" ? "white" : "",
                                          }}
                                          value="rejected"
                                        >
                                          Reject
                                        </option>
                                      </select>
                                    </div>

                                    <div style={{ display: "flex", justifyContent: "center" }} className="w-full text-center items-center">
                                      <button
                                        title="update"
                                        style={{
                                          display: "flex",
                                          margin: "10px",
                                          fontSize: "16px",
                                          border: "none",
                                          borderRadius: "5px",
                                          padding: "5px 10px",
                                          cursor: "pointer",
                                        }}
                                        onClick={handleUpdate}
                                        className="hover:bg-purple-600 bg-purple-700  text-white w-full justify-center items-center"
                                      >
                                        {
                                          saveLoading ?
                                            <div class="three-body-button">
                                              <div class="three-body__dot"></div>
                                              <div class="three-body__dot"></div>
                                              <div class="three-body__dot"></div>
                                            </div>
                                            :
                                            'Update'
                                        }

                                      </button>
                                      <button
                                        title="Cancel"
                                        style={{
                                          display: "flex",
                                          margin: "10px",
                                          fontSize: "16px",
                                          border: "none",
                                          borderRadius: "5px",
                                          padding: "5px 10px",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => setMenuOpen(null)}
                                        className="hover:bg-slate-300 bg-slate-200 text-black w-full justify-center items-center"
                                      >
                                        Cancel
                                      </button>
                                    </div>
                                  </div>
                                </div>

                              )}
                            </div>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>

            </div>
          )}
          <ToastContainer />
          {
            !loading ?
              <div className="flex flex-wrap items-center gap-4 justify-between mt-6 max-[700px]:justify-start max-[700px]:gap-0 max-[700px]:text-xs">
                <div className="text-purple-700 ">
                  {`${start}-${end} of ${financialData?.noOfEnteries} items`}
                </div>
                <div className="flex items-center gap-4 justify-center">
                  <Pagination count={totalPages} size="large" showFirstButton showLastButton page={currentPage} onChange={(event, value) => { setCurrentPage(value) }} />
                </div>

                <div>
                  <select className="border rounded p-1" value={recordsPerPage} onChange={(e) => { setRecordsPerPage(e.target.value) }}>
                    <option value='5'>Show 5</option>
                    <option value='10'>Show 10</option>
                    <option value='15'>Show 15</option>
                  </select>
                </div>
              </div>
              :
              <></>
          }

        </div>
      )}
    </div>
  );
}

export default FinancialData;
