
import React, { useEffect, useState } from "react";
import { showTheme } from "../../Theme/Theme";
import { ToastContainer, toast } from "react-toastify";
import { getToken } from "../../../utils/getToken";
import axios from "axios";
import './Notification.css';
import Spinner from "../../Spinner/Spinner";
const Notification = () => {
  const [loading, setLoading] = useState(false);
  const [emailNotification, setEmailNotification] = useState(false);
  const [dashboardNotification, setDashboardNotification] = useState(false);
  const handleGetNotifications = async () => {
    try {
      const token = getToken();
      let url = `${process.env.REACT_APP_BASE_URL}/api/notification/enable-setting`;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setEmailNotification(response.data.data?.enableNotificationOnEmail);
      setDashboardNotification(response.data.data?.enableNotificationOnDashboard);
    } catch (error) {
      console.log("Failed fetching details", error);
    }
  };
  const handleUpdate = async (state, value) => {
    let formData;
    if (value === 'dashboard') {
      setDashboardNotification(state);
      formData = {
        enableNotificationOnDashboard: state,
      }
    }
    if (value === 'email') {
      setEmailNotification(state);
      formData = {
        enableNotificationOnEmail: state
      }
    }
    try {
      setLoading(true);
      const token = getToken();
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/notification/enable`, formData,

        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      handleGetNotifications();
      toast.success(response?.data?.message);
    } catch (error) {
      toast.error("Failed", error);
    } finally{
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetNotifications();
  }, []);

  return (
    <div className="rounded-2xl ">
      {loading ? (
        <div className="flex justify-center " style={{ marginTop: "35vh" }}>
          <Spinner />
        </div>
      ) : (
        <div
        className="bg-white rounded-2xl shadow-md border mb-8 ml-3 mr-3 sm:ml-3 border-style"
        style={{
            backgroundColor: showTheme("card"),
            border: showTheme("borderCard"),
            color: showTheme("text"),
          }}
        >
          <div className="flex items-center ml-5 mt-5">
            <h1 className="text-3xl font-semibold" style={{ color: showTheme("text") }}>Notifications</h1>
          </div>
          <div className="mt-4 mx-3 py-2 rounded-lg  flex items-center justify-between " style={{ backgroundColor: showTheme("cardBack") }}>
            <h2 className="text-md font-medium  py-2 ml-2">
              Get notifications from Tech Times on your email
            </h2>
            <div className={`toggle-switch ${emailNotification ? 'on' : 'off'}`} onClick={() => handleUpdate(!emailNotification, 'email')}>
              <div className="switch-handle" />
            </div>

            {/* <Switch
            onChange={(e) => {
              console.log(e);
              setEmailNotification(!emailNotification); handleUpdate();
            }}
            checked={emailNotification}
           
            className="react-switch mr-2"
            onColor="#7C35F1"
            offColor="#dddddd"
            uncheckedIcon={false}
            checkedIcon={false}
          /> */}
          </div>
          <div className="mt-4 mx-3 py-2 rounded-lg flex items-center justify-between " style={{ backgroundColor: showTheme("cardBack") }}>
            <h2 className="text-md font-medium -100 py-2 ml-2">
              Get notifications from Tech Times on your dashboard
            </h2>
            <div className={`toggle-switch ${dashboardNotification ? 'on' : 'off'}`} onClick={() => handleUpdate(!dashboardNotification, 'dashboard')}>
              <div className="switch-handle" />
            </div>
            {/* <Switch
            onChange={(e) => { handleEmailChange(e) }}
            checked={dashboardNotification}
            value={dashboardNotification}
            defaultValue={notification?.dashboardNotification}
            className="react-switch mr-2"
            onColor="#7C35F1"
            offColor="#dddddd"
            uncheckedIcon={false}
            checkedIcon={false}
          /> */}
          </div>
        </div>
      )
      }
      <ToastContainer />
    </div>
  );
};

export default Notification;
