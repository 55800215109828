import React from 'react';
import ReactEchartsCore from 'echarts-for-react/lib/core';
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/chart/gauge';
import 'echarts/lib/component/title';
import 'echarts/lib/component/tooltip';
import ReactEChartsCore from 'echarts-for-react';




const CreditScoreChart = ({ score }) => {
  const getScoreHexColor = () => {
    if (score >= 800) {
      return '#00C853'; // Green
    } else if (score >= 700) {
      return '#FFD600'; // Yellow
    } else if (score >= 600) {
      return '#FF6F00'; // Orange
    } else {
      return '#DD2C00'; // Red
    }
  };

  const gaugeOption = {
    series: [
      {
        type: 'gauge',
        startAngle: 180,
        endAngle: 0,
        min: 0,
        max: 100,
        splitNumber: 3,
        radius: '95%',
        center: ['50%', '50%'],
        pointer: {
          icon: 'circle',
          length: '12%',
          width: 50,
          offsetCenter: [0, '-90%'],
          itemStyle: {
            color: '#FFFFFF',
            borderColor: getScoreHexColor(),
            borderWidth: 5,
            shadowColor: 'rgba(10, 31, 68, 0.5)',
            shadowBlur: 2,
            shadowOffsetY: 0.1,
          },
        },
        axisLine: {
          show: true,
          roundCap: true,
          lineStyle: {
            width: 10,
            color: [
              [0.48, '#ee6352'],
              [0.52],
              [0.66, '#ff8b3b'],
              [0.7],
              [0.83, '#fac05e'],
              [0.87],
              [1, '#59cd90'],
            ],
          },
        },
        axisTick: {
          length: 2,
          lineStyle: {
            color: '#8a94a6',
            width: 2,
          },
        },
        splitLine: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
        title: {
          show: false,
        },
        detail: {
          formatter: `${score}`,
          offsetCenter: [0, '-20%'],
          textStyle: {
            fontSize: 36,
            fontWeight: 'bold',
          },
        },
      },
    ],
  };

  return (
      <ReactEChartsCore
        echarts={echarts}
        option={gaugeOption}
        style={{ height: '400px' }}
      />
  );
};

export default CreditScoreChart;





