import React, { useEffect, useState } from "react";
import { getTransactionLog } from "../../../Services/UserServices";
import { useQuery } from "react-query";
import moment from "moment";
import { FaAnglesRight } from "react-icons/fa6";
import { FaAnglesLeft } from "react-icons/fa6";
import "../../Admin/admin.css";
import { Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import { Button, IconButton } from "@material-tailwind/react";
import { BiMessageAlt } from "react-icons/bi";
import Spinner from "../../Spinner/Spinner";
import { showTheme } from "../../Theme/Theme";
import { Pagination } from "@mui/material";
import './Transaction.css'
Chart.register(...registerables);

const state = {
  labels: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  datasets: [
    {
      label: "Strength",
      backgroundColor: [
        "Indigo",
        "Purple",
        "Yellow",
        "Teal",
        "Red",
        "Navy",
        "Brown",
      ],
      fill: false,
      lineTension: 0.5,
      borderColor: "rgba(0,0,0,1)",
      borderWidth: 2,
      data: [10, 14, 17, 16, 19, 16, 17, 14, 14, 17, 16, 19],
    },
  ],
};

const Transaction = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [mobile, setMobile] = useState("");

  const [recordsPerPage, setRecordsPerPage] = useState(5);
  const [disablePrevious, setDisablePrevious] = useState(false);
  const [disableNext, setDisableNext] = useState(false);

  const { data: transaction, isLoading } = useQuery({
    queryKey: ["transaction_log", currentPage, recordsPerPage],
    queryFn: () => getTransactionLog(currentPage, recordsPerPage),
  });

  const transactionData = transaction?.data?.data?.transactionLogData;
  const totalPages = Math.ceil(
    transaction?.data?.data?.noOfEnteries / recordsPerPage
  );

  const getItemProps = (index) => ({
    variant: currentPage === index ? "filled" : "text",
    color: "gray",
    onClick: () => setCurrentPage(index),
  });

  const next = () => {
    if (currentPage === totalPages) return;

    setCurrentPage(currentPage + 1);
  };

  const prev = () => {
    if (currentPage === 1) return;

    setCurrentPage(currentPage - 1);
  };

  let start = (currentPage - 1) * recordsPerPage + 1;
  let end = transaction?.data?.data?.noOfEnteries;

  if (recordsPerPage < transaction?.data?.data?.noOfEnteries) {
    end = recordsPerPage * currentPage;
    if (end > transaction?.data?.data?.noOfEnteries) {
      end = transaction?.data?.data?.noOfEnteries;
    }
  }

  return (
    <div >
      {isLoading ? (
        <div className="flex justify-center " style={{ marginTop: "35vh" }}>
          <Spinner />
        </div>
      ) : (
        <div
          className="bg-white rounded-2xl shadow-md border mb-8 ml-3 mr-3 sm:ml-3 border-style"
          style={{
           
            backgroundColor: showTheme("card"),
            border: showTheme("borderCard"),
          }}
        >
          {transactionData?.length > 0 ? (
            <div className="flex flex-col lg:flex-col space-y-4 mt-5 ml-5 lg:space-y-0 gap-12">
              <div
                className="mt-6 font-semibold text-2xl "
                style={{ color: showTheme("text") }}
              >
                Latest Transaction
              </div>
              <div className="me-6">
                <div className="overflow-x-auto">
                  <table className="min-w-full">
                    <thead className="" style={{ backgroundColor: showTheme("topHeader"), border: showTheme("borderCard") }}>

                      <tr style={{ fontSize: "16px", color: showTheme("text") }}>
                        <th className="p-4 text-left">Transaction Type</th>
                        <th className="p-4 text-left">Status</th>
                        <th className="p-4 text-left">Amount</th>
                      </tr>
                    </thead>
                    <tbody className="" style={{ backgroundColor: showTheme("cardBack"), border: showTheme("borderCard") }}>

                      {transactionData.map((i) => (
                        <tr
                          key={i.id}
                          className=""
                          style={{ borderBottom: showTheme("borderCard") }}
                        >
                          <td className="flex items-center p-4">
                            <BiMessageAlt className="text-purple-600 text-2xl" />
                            <div className="ml-4" style={{ color: showTheme("text") }}>
                              <b>{i?.userPlanId?.planId?.planName}</b>
                              <h1 className="">{i?.transactionType}</h1>
                              <p className="text-sm text-slate-500">
                                {moment(i.createdAt).format("Do MMMM, h:mm a")}
                              </p>
                            </div>
                          </td>

                          <td className="p-4">
                            <p
                              className={`text-${i?.transactionType === "deposit"
                                ? i?.status === "success"
                                  ? "green"
                                  : i?.status === "in-progress"
                                    ? "orange"
                                    : "red"
                                : i?.transactionType === "withdrawal"
                                  ? i?.status === "success"
                                    ? "green"
                                    : i?.status === "in-progress"
                                      ? "orange"
                                      : "red"
                                  : i?.transactionType === "profit-earned"
                                    ? i?.status === "success"
                                      ? "green"
                                      : "red"
                                    : i?.transactionType === "failed"
                                      ? "red"
                                      : "orange"
                                }-500 amount text-md`}
                            >
                              {i?.status}
                            </p>
                          </td>
                          <td className="p-4">
                            <p
                              className={`text-${i?.transactionType === "failed"
                                ? "red"
                                : i?.transactionType === "deposit"
                                  ? i?.status === "success"
                                    ? "green"
                                    : i?.status === "in-progress"
                                      ? "orange"
                                      : "red"
                                  : i?.transactionType === "withdrawal"
                                    ? i?.status === "success"
                                      ? "green"
                                      : i?.status === "in-progress"
                                        ? "orange"
                                        : "red"
                                    : i?.transactionType === "profit-earned"
                                      ? "green"
                                      : "orange"
                                }-500 amount text-md`}
                            >
                              ₹{i?.amount}
                            </p>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="flex flex-wrap items-center gap-4 justify-between my-6 max-[700px]:justify-start max-[700px]:gap-0 max-[700px]:text-xs">
                  <div className="text-purple-700">
                    {`${start}-${end} of ${transaction?.data?.data?.noOfEnteries} items`}
                  </div>
                  <div className="flex items-center gap-4 justify-center" style={{ color: showTheme("text") }}>
                    <Pagination count={totalPages} size="large" showFirstButton showLastButton page={currentPage} onChange={(event, value) => { setCurrentPage(value) }}
                      className={`${localStorage.getItem("darkMode") == "true" && 'pagination-transaction'} pagination-all`}
                    />
                  </div>
                  <div>
                    <select className="border rounded p-1" style={{backgroundColor:showTheme('card'),color:showTheme('logoColor')}} value={recordsPerPage} onChange={(e) => { setRecordsPerPage(e.target.value) }}>
                      <option value='5' >Show 5</option>
                      <option value='10' >Show 10</option>
                      <option value='15' >Show 15</option>
                    </select>
                  </div>
                </div>

              </div>
            </div>
          ) : (
            <div
              className=" mt-11 font-semibold text-2xl ml-10 "
              style={{ color: showTheme("text") }}
            >
              No Transaction History
            </div>
          )}
        </div>
      )}
    </div>


  );
};

export default Transaction;
