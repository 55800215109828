import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { verifyOTP } from '../Services/UserServices';
import { ToastContainer, toast } from 'react-toastify';
import AutochangeTitle from './UserPanel/Common/AutochangeTitle';
import './LoginOtp.css';
import { useAuth } from './Context/AuthProvider ';
import CryptoJS from 'crypto-js';
import Spinner from './Spinner/Spinner';

function LoginOtp() {
  const { setToken } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [showPopup, setShowPopup] = useState(false);
  const inputs = useRef([]);
  const correctOtp = "123456"; // Replace with the correct OTP
  const [isLoading, setIsLoading] = useState(false);

  const [otp, setOtp] = useState("");

  const [otpError, setOtpError] = useState(false);
  const [phone, setPhone] = useState("");

  const { userId } = useParams();

  const checkEmailOrPhone = phone.includes('@');

  useEffect(() => {
    setPhone(userId || "");
  }, []);

  const verifyOtp = async (event) => {
    event.preventDefault();
    let formData;
    if (checkEmailOrPhone) {
      formData = {
        email: phone,
        emailOtp: otp,
        module: "sign-in",
      };
    } else {
      formData = {
        phone: phone,
        phoneOtp: otp,
        module: "sign-in",
      };
    }

    try {
      setIsLoading(true)
      const { data } = await verifyOTP(formData);
      // if (data?.data?.isBlocked === true) {
      //   toast.error("You are blocked by Admin!")
      //   return;
      // }
      const token = data.data.token;
      const user = JSON.stringify(data.data);
      setToken(token);
      // Your secret key for encryption
      const secretKey = 'your-secret-key';
      // Encrypting the data
      const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data?.data), secretKey).toString();

      // Storing encrypted data in local storage
      localStorage.setItem('encryptedData', encryptedData);
      navigate("/", { replace: true });
    } catch (e) {
      // toast.error(e.response.data.message);
    } finally{
      setIsLoading(false);
    }
  };
  function focusNextInput(el, prevId, nextId) {
    if (el.value.length === 0) {
      // if (prevId) {
      //   document.getElementById(prevId).focus();
      // }
    } else {
      if (nextId) {
        document.getElementById(nextId).focus();
      }
    }
  }

  document.querySelectorAll('[data-focus-input-init]').forEach(function (element) {
    element.addEventListener('keyup', function () {
      const prevId = this.getAttribute('data-focus-input-prev');
      const nextId = this.getAttribute('data-focus-input-next');
      focusNextInput(this, prevId, nextId);
    });
  });
  return (
    <>
          <section className="otp-page flex flex-col md:flex-row min-h-screen items-center justify-center">
            <div className="bg-white w-full md:w-full lg:w-1/2 xl:w-1/3 mx-auto  flex items-center justify-center px-4">
              <div className="w-full p-4">
                <div className="mb-20">
                  <img className="w-20" src="/image/Logo(1).png" alt="techtime logo" />
                </div>
                <AutochangeTitle />
                <h1 className=" leading-tight mb-8   font-light text-sm ">
                  Log in to Tech Times to start investing.
                </h1>
                <form
                  className="mt-6"
                  onSubmit={(event) => {
                    verifyOtp(event);
                  }}
                >
                  <div>
                    <p className="font-light">
                      We have sent an OTP to <b>{phone}</b>
                    </p>
                    <form className="">
                      <div className="flex mb-2 space-x-2 rtl:space-x-reverse">
                      <input type="number" length="6"
                            value={otp} onChange={(e) => setOtp(e.target.value)}
                            className='w-full  px-4 py-3 rounded-lg hover:border-purple-500 mt-2 border  focus:border-purple-200 focus:bg-white focus:outline-none'
                            required />
                      </div>
                    </form>

                    {otpError && (
                      <p className="text-red-600 font-light mt-5">
                        OTP does not match. Please try again.
                      </p>
                    )}
                  </div>
                  <button
                    title='Continue to submit'
                    type="submit"
                    className={`w-full block rounded-lg py-3 mt-6 ${otp === ''
                      ? "bg-purple-200 text-gray-600 cursor-not-allowed"
                      : " bg-purple-500 hover:bg-purple-400 focus:bg-purple-400 text-white font-semibold"
                      }`}
                      style={{marginLeft:"-1px"}}
                    disabled={otp === ''}
                  >
                     {
                      isLoading ?
                        <div class="three-body-button">
                          <div class="three-body__dot"></div>
                          <div class="three-body__dot"></div>
                          <div class="three-body__dot"></div>
                        </div>
                        :
                        'Continue'
                    }
                  </button>
                </form>
              </div>
            </div>
            <div className="hidden md:block lg:block sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2">
              <img
                src="/image/image.jpg"
                alt=""
                className="w-full min-[768px]:w-auto h-auto max-w-full max-h-full"
              />
            </div>
            <ToastContainer />
          </section>S
    </>
  );
}
export default LoginOtp;
