import { getToken } from "../utils/getToken";
import { defaultApi, ifscFetch } from "./api";



export async function getAllBankDetails() {
    const res = await defaultApi.get(`/api/bank/all`);
    return res.data;
  }

  export function addBankDetails(data) {
    return defaultApi.post(`/api/bank/add`, data);
  }

  export async function deleteBank(id) {
    const res = await defaultApi.delete(`/api/bank/delete/${id}`);
    return res;
  }

  export function updateBank(id,data) {
    // const token = getToken(); 
    return defaultApi.put(`/api/bank/update/${id}`,data);
  } 

  export async function getIfsc(ifsc) {
    const res = await ifscFetch.get(`/${ifsc}`);
    return res.data; 
  }