import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { UserGenerateOtp } from "../Services/UserServices";
import { ToastContainer, toast } from "react-toastify";
import AutochangeTitle from "./UserPanel/Common/AutochangeTitle";
import { IoLogIn } from "react-icons/io5";
import { useGoogleLogin } from "@react-oauth/google";
import { useMounted } from '@casper124578/useful';
import qs from "qs";
import { defaultApi } from "../Services/api";
import Spinner from "./Spinner/Spinner";


function Login() {
  const navigate = useNavigate();
  const mounted = useMounted();
  const [input, setInput] = useState('');
  const [error, setError] = useState('');
  const [blankError, setBlankError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateMobile = (mobile) => {
    const mobileRegex = /^[0-9]{10}$/;
    return mobileRegex.test(mobile);
  };
  const handleInputChange = (e) => {
    setInput(e.target.value);
    if (validateEmail(e.target.value) || validateMobile(e.target.value)) {
      setError('');
      setBlankError(false);
      // Handle successful validation (e.g., submit form)
      console.log('Form submitted:', e.target.value);
    } else {
      setBlankError(true);
      setError('Please enter a valid email or mobile number');
      return;
    }
  };
  const generateOtp = async (e) => {
    let formData;
    if (input.includes('@')) {
      formData = {
        email: input,
        module: "sign-in"
      }
    } else {
      formData = {
        phone: input,
        module: "sign-in"
      }
    }

    e.preventDefault();
    if (validateEmail(input) || validateMobile(input)) {
      setError('');
      // Handle successful validation (e.g., submit form)
      console.log('Form submitted:', input);
    } else {
      setError('Please enter a valid email or mobile number');
      return;
    }
    try {
      setIsLoading(true);
      const { data } = await UserGenerateOtp(formData);
      if (data?.data?.isBlocked === true) {
        toast.error("You are blocked by Admin!")
        return;
      }
      // Pass email and mobile as parameters to Signupotp page
      navigate(`/otp/${input}`);
      if (!data) return;
    } catch (error) {
      console.error(error);
      // toast.error(error?.response?.data?.message);
    } finally{
      setIsLoading(false)
    }
  };

  const handleGoogleLogin = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: async (codeResponse) => {
      console.log(codeResponse)
      try {
        let body = qs.stringify({
          'client_id': '664309932986-4p8of2nkoaf52c6595ak1dahpj8j4dij.apps.googleusercontent.com',
          'client_secret': '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',
          'code': codeResponse.code,
          'grant_type': 'authorization_code',
          'redirect_uri': window.location.origin
        });

        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: 'https://oauth2.googleapis.com/token',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data: body
        };
        axios.request(config)
          .then(async (response) => {
            const { data } = await axios.post(`${defaultApi}/user/social_login`, response?.data?.id_token, 'google')
            if (!data) return;
            else {
              window.localStorage.setLoginData
                ("token", data?.data?.access_token);
              navigate("/dashboard");
            };
          })
          .catch((error) => {
            console.log(error);
            toast(mounted ? 'somethingwrong' : null);
          });
        // console.log(data);
      } catch (e) {
        console.log(e);
        toast(mounted ? 'somethingwrong' : null);
      }

    },
    onError: (error) => console.log('Login Failed:', error)
  });
  return (
    <>
        <section className="flex flex-col md:flex-row min-h-screen items-center justify-center" style={{ overflow: "hidden" }}>
          <div className="bg-white w-full md:w-1/3 lg:w-1/2 xl:w-1/3 mx-auto  flex items-center justify-center">
            <div className="w-full p-4">
              <div className=" flex items-center">
                <img className="w-20" src="/image/Logo(1).png" alt="techtime logo" />
                <div className="text-purple-700 text-2xl font-semibold mt-5">Techtime</div>
              </div>
              <AutochangeTitle />
              <h1 className="leading-tight mb-8 font-light text-sm">
                Log in to Tech Times to start investing.
              </h1>
              <form className="mt-6" onSubmit={generateOtp}>
                <div className="relative">
                  <IoLogIn className="absolute top-2 w-10 rounded-l-lg text-3xl text-white bg-purple-700  p-1.5" style={{ height: "85%" }} />
  
                  <input
                    type="text"
                    name="mobile"
                    id="login"
                    value={input}
                    onChange={handleInputChange}
                    placeholder="9087654321 or abc123@gmail.com"
                    className={`ps-12 w-full px-10 py-3 rounded-lg bg-purple-50 mt-2 border ${error ? "border-red-500" : "border-purple-500"
                      } focus:bg-white  focus:ring focus:ring-purple-100 focus:outline-none`}
                    autoComplete="tel"
                    required
                  />
  
                </div>
                {error && <p style={{ color: 'red' }}>{error}</p>}
                <div className="flex items-center justify-between mt-4">
                  <a
                    href="#"
                    className="text-sm text-purple-700 font-semibold hover:text-blue-700 focus:text-blue-700"
                  >
                    Forgot Email or Phone Number?
                  </a>
                </div>
                <button
                  type="submit"
                  title="Continue"
                  className={`w-full block rounded-lg px-4 py-3 mt-6 ${error || blankError === ''
                    ? "bg-purple-200 text-gray-600 cursor-not-allowed"
                    : "bg-purple-500 hover:bg-purple-400 focus:bg-purple-400 text-white font-semibold"
                    }`}
                  disabled={error}
                >
                  {
                      isLoading ?
                        <div class="three-body-button">
                          <div class="three-body__dot"></div>
                          <div class="three-body__dot"></div>
                          <div class="three-body__dot"></div>
                        </div>
                        :
                        'Continue'
                    }
                  
                </button>
              </form>
              {/* <div className="my-6 w-full flex items-center">
                <hr className="flex-grow border-gray-500" />
                <div className="mx-3 text-gray-500 text-sm"> or Continue with</div>
                <hr className="flex-grow border-gray-500" />
              </div> */}
              {/* <button
                type="button"
                className="w-full font-semibold focus:outline-none focus:ring-[#4285F4]/50 text-sm py-2.5 inline-flex dark:focus:ring-[#4285F4]/55 items-center justify-center"
              > */}
              {/* <button
                type="button"
                title="Google Account"
                className="w-full font-semibold focus:outline-none focus:ring-[#4285F4]/50 text-sm py-2.5 inline-flex dark:focus:ring-[#4285F4]/55 items-center justify-center"
              // onClick={handleGoogleSignIn} // Call the function on button click
              >
                <svg
                  className="h-4 mx-2"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fab"
                  data-icon="google(1)"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 48 48"
                >
                  <path
                    fill="currentColor"
                    d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z" /><path fill="#FF3D00" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z" /><path fill="#4CAF50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z" /><path fill="#1976D2" d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
                    ></path>
                </svg>
                <button className="text-purple-700" onClick={handleGoogleLogin}>Google Account</button>
              </button> */}
  
              <div className=" justify-center  items-center flex flex-col ">
  
  
                {/* <GoogleLogin
                  clientId={process.env.REACT_APP_CLIENT_ID}
                  buttonText="Login"
                  onSuccess={() => handleSuccess()}
                  onFailure={() => handleFailure()}
                  cookiePolicy={'single_host_origin'}
                  render={renderProps => (
                    <button onClick={renderProps.onClick} disabled={renderProps.disabled}>Google Login</button>
                  )}
                /> */}
              </div>
  
              <p className="mt-5">
                Don't have an account?{" "}
                <button
                  className="text-purple-700 hover:text-blue-700 font-semibold hover:decoration-blue-400"
                  onClick={() => navigate('signup')}
                >
                  SignUp
                </button>
              </p>
            </div>
          </div>
          <div className="hidden md:block lg:block sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2">
            <img
              src="/image/image.jpg"
              alt="image"
              className="w-full h-auto max-w-full"
            />
          </div>
  
        </section>
        <ToastContainer />
       </>
  );
}
export default Login;

