import React, { useState, useEffect } from "react";
import { FiArrowRight, FiSearch } from "react-icons/fi";
import axios from "axios";
import { getToken } from "../../utils/getToken";
import { CircleDollarSign, User } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import Spinner from "../Spinner/Spinner";
import Dashboard1 from "./Dashboard1";

const AdminDashboard = () => {
  const [viewAll, setViewAll] = useState(false);
  const [details, setDetails] = useState("");
  const [withdrawalData, setWithdrawalData] = useState([]);
  const [chartlData, setChartData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [chart, setChart] = useState([]);
  const [hoveredItem, setHoveredItem] = useState(null);
  const data = [
    { month: "Jan", progress: 0 },
    { month: "Feb", progress: 0 },
    { month: "Mar", progress: 0 },
    { month: "Apr", progress: 0 },
    { month: "May", progress: 0 },
    { month: "Jun", progress: 100 },
    { month: "Jul", progress: 131201 },
    { month: "Aug", progress: 0 },
    { month: "Sep", progress: 0 },
    { month: "Oct", progress: 0 },
    { month: "Nov", progress: 0 },
    { month: "Dec", progress: 0 },
  ];
  const [timeFilter, setTimeFilter] = useState("today");

  const calculatePercentage = (value) => {
    return `${value}%`;
  };


  const handleBarHover = (index) => {
    setHoveredItem(data[index]);
  };

  const handleBarLeave = () => {
    setHoveredItem(null);
  };

  const navigate = useNavigate();

  const viewall = () => {
    navigate("/financial-data")
  }
  const progressBars = chartlData.map((item, index) => (
    <div
      key={index}
      className="flex flex-col items-center mx-2 justify-between flex-grow"
      onMouseEnter={() => handleBarHover(index)}
      onMouseLeave={handleBarLeave}
    >
      <div className="h-24  bg-gray-300 w-10 rounded-t"></div>
      <div
        className="
        bg-blue-500 w-10 rounded-b"
        style={{ height: calculatePercentage(item.totalInvestment) }}
      ></div>
      <div className="mt-2">{item.monthName}</div>
    </div>
  ));


  const handleData = async () => {
    try {
      setLoading(true);
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `https://techtime.pb18.in/api/user/admin/dashboard?timeFilter=${timeFilter}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setDetails(response.data.data);
        setWithdrawalData(
          response.data.data.dashBoardData.clientWithdrawalTransaction
        );
        setChart(response.data.data.dashBoardData.financialDataForGraph);
        setChartData(response.data.data.dashBoardData.financialDataForGraph);
      } else {
        console.error("Token is not available.");
      }
    } catch (error) {
      console.error("Failed Fetching Details", error);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    handleData();
  }, [timeFilter]);


  return (
    <div className="rounded-2xl">
      {loading ? (
        <div className="flex justify-center " style={{ marginTop: "35vh" }}>
          <Spinner />
        </div>
      ) : (

        <div
          className="bg-white rounded-2xl shadow-md mb-8 ml-3 mr-3 sm:ml-3 border-style p-2"
        // style={{
        //   height: "calc(100vh - 120px)",
        //   maxHeight: "80vh",
        //   overflowY: "auto",
        // }}
        >
          <div className="mb-4 flex justify-between ">
            <h2 className="text-xl font-thin ml-4 mt-2">Hi Admin 👋</h2>

            <div className="rounded py-2 px-6 text-black">
              <select
                className="p-2 rounded border shadow-md bg-white"
                value={timeFilter}
                onChange={(e) => { setTimeFilter(e.target.value) }}
              >
                <option
                  value="today"
                  style={{
                    backgroundColor: timeFilter === "today" ? "#7C35F1" : "white",
                    color: timeFilter === "today" ? "white" : "black",
                  }}
                >
                  Today
                </option>
                <option
                  value="weekly"
                  style={{
                    backgroundColor: timeFilter === "weekly" ? "#7C35F1" : "white",
                    color: timeFilter === "weekly" ? "white" : "black",
                  }}
                >
                  Weekly
                </option>
                <option
                  value="monthly"
                  style={{
                    backgroundColor: timeFilter === "monthly" ? "#7C35F1" : "white",
                    color: timeFilter === "monthly" ? "white" : "black",
                  }}
                >
                  Monthly
                </option>
                <option
                  value="yearly"
                  style={{
                    backgroundColor: timeFilter === "yearly" ? "#7C35F1" : "white",
                    color: timeFilter === "yearly" ? "white" : "black",
                  }}
                >
                  Yearly
                </option>
              </select>
            </div>

          </div>
          <div className="grid grid-cols-1 gap-4 w-full min-[1024px]:grid-cols-2">
            <div className="w-full mb-4 px-2 ">
              <div className="bg-white shadow-md rounded-lg p-4 flex border">
                <div className="rounded-full  mt-2">
                  <CircleDollarSign alt="" className="rounded-full  w-11 h-11 text-purple-700" />
                </div>
                <div className="ml-4">
                  <h3 className="text-sm font-semibold mb-2 text-nowrap">Total Amount Invested</h3>
                  <p>₹ {details.dashBoardData?.investmentSum}</p>
                </div>
              </div>
            </div>
            <div className="w-full mb-4 px-2">
              <div className="bg-white shadow-md rounded-lg p-4 flex items-center gap-2 border">
                <div>
                  <img src="/image/Group 41.png" alt="image" className="w-12 " />
                </div>
                <div className="ml-2 flex items-center">
                  <div>
                    <h3 className="text-sm font-semibold mb-2 ">Withdrawal</h3>
                    <p className="inline">₹ {details.dashBoardData?.withdrawalSum}</p>
                  </div>
                  <img src="/image/Graph(3).png" className="inline hidden sm:block md:block lg:block xl:block 2xl:block" alt="image" />
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-4 w-full min-[1024px]:grid-cols-2">
            <div className="w-full mb-4 px-2">
              <div className="bg-white shadow-md rounded-lg p-4 flex border">
                <div className="rounded-full">
                  <img src="/image/Earning.png" alt="earning" className="rounded-full p-1 w-12" style={{ backgroundColor: "#EEEEF0" }} />
                </div>
                <div className="ml-4 ">
                  <h3 className="text-sm font-semibold mb-2">Balance</h3>
                  <p>₹ {details.dashBoardData?.Balance}</p>
                </div>
              </div>
            </div>
            <Link to='/clients' className="w-full">
              <div className="w-full mb-4 px-2">
                <div className="flex items-center gap-2 text-white shadow-md rounded-2xl bg-purple-700 p-4">
                  <div className="rounded-full  mr-4">
                    <User color="#ffffff" alt="" className="rounded-full w-8 h-8" />
                  </div>
                  <div className="flex flex-col">
                    <h3 className="text-sm font-semibold mb-2 text-nowrap">Total Clients</h3>
                    <p className="text-xl">{details.dashBoardData?.numberOfClients}</p>

                  </div>
                  <img src="/image/Chart.png" className="" alt="chart" />
                </div>
              </div>
            </Link>

          </div>
          <div
            className="bg-white shadow-md rounded-lg  px-2 ml-4 p-4 mt-5 mr-4  "
            style={{ overflow: "hidden" }}
          >
            <h2 className="text-xl font-thin ml-4 ">Withdrawal</h2>
            <p className="font-bold ml-4">₹{details.dashBoardData?.withdrawalSum}</p>

            <hr className="bg-slate-100 w-full mt-5 "></hr>
            <Dashboard1 chart={chart} />
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-8 mr-4 mb-4">
            <div className="bg-white shadow-md rounded-lg p-4 mt-5 ml-4 justify-items-center">
              <div className="">
                <div className="text-center">
                  <img
                    src="/image/Avatar.png"
                    className="bg-slate-100 rounded-full inline-block"
                    alt="error"
                  />
                </div>

                <h1 className="text-center font-bold text-2xl text-black">
                  {details.dashBoardData?.employeeProfileData?.name}
                </h1>
                <p className="text-center " style={{ textTransform: "capitalize" }}>
                  {details.dashBoardData?.employeeProfileData?.designation},{" "}
                  {details.dashBoardData?.employeeProfileData?.department}{" "}
                </p>
                <div className="flex justify-center space-x-10 mt-10">
                  {/* <div>
                                <h1 className="text-sm">Projects</h1>
                                <p className="font-bold text-center">28</p>
                            </div> */}
                  <div>
                    <h1 className="text-sm text-center text-black">Employee ID</h1>
                    <p className="font-bold text-center">
                      {details.dashBoardData?.employeeProfileData?.employeeId}
                    </p>
                  </div>
                  <div>
                    <h1 className="text-sm text-center text-black">Phone</h1>
                    <p className="font-bold text-center">
                      {details.dashBoardData?.employeeProfileData?.userId?.phone}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-white shadow-md rounded-lg p-2 mt-5 ml-4 ">
              <div className="px-4">
                <h1 className="font-bold text-2xl text-black">Recents transactions</h1>
                <div className="mt-10 overflow-auto">
                  <table className=" min-w-full">
                    <thead>
                      <tr className="py-2">
                        <th className="p-4 text-left">Member ID</th>
                        <th className=" p-4 text-left">Status</th>
                        <th className=" p-4 text-left">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        viewAll ?
                          withdrawalData?.map((withdrawal) => (
                            <tr key={withdrawal?._id}>
                              <td className="p-4 py-2">{withdrawal?.userId?.memberId}</td>
                              <td>{withdrawal?.status}</td>
                              <td>{withdrawal?.amount}</td>
                            </tr>
                          ))
                          :
                          withdrawalData?.slice(0, 3)?.map((withdrawal) => (
                            <tr key={withdrawal?._id}>
                              <td className=" p-4 py-2">{withdrawal?.userId?.memberId}</td>
                              <td className=" p-4 py-2">{withdrawal?.status}</td>
                              <td className=" p-4 py-2">{withdrawal?.amount}</td>
                            </tr>
                          ))

                      }

                    </tbody>
                  </table>
                </div>
              </div>
              <div className="mt-5 flex justify-end mr-4">
                <div className="flex items-center mb-4 cursor-pointer">
                  {/* <h1 className="text-purple-600" onClick={() => setViewAll(!viewAll)}>{viewAll ? 'View Less' : 'View all'}</h1> */}
                  <h1 className="text-purple-700" onClick={viewall}>View All</h1>
                  <FiArrowRight className="text-purple-700" />
                </div>
              </div>
            </div>
            {/* <Dashboard1/> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminDashboard;
